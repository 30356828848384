import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useQuery } from '@apollo/client/react';
import ME_QUERY from 'app/graphql/query/me';
import { Loader } from 'app/component';
import style from './style';

import Login from './container/Login';
import Dashboard from './container/Dashboard';
import type { Theme } from './theme';

const useStyles = createUseStyles(style);

const App = () => {
    useStyles();
    const { data, loading } = useQuery(ME_QUERY);
    const theme = useTheme<Theme>();

    React.useEffect(() => {
        [
            'meta[name=theme-color]',
            'meta[name=msapplication-navbutton-color]',
            'meta[name=apple-mobile-web-app-status-bar-style]'
        ]
            .map((selector) => document.querySelector(selector))
            .filter(Boolean)
            .forEach((node) => node!.setAttribute('content', theme.navigation.dropdown.background));
    }, [theme.navigation.background]);

    if (loading) {
        return <Loader/>;
    }

    if (!data || !data.me || !data.me.login) {
        return <Login/>;
    }

    return <Dashboard/>;
};

export default App;
