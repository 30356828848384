import type { Theme } from 'app/theme';

export default (({ color }: Theme) => ({
    Disabled: {
        opacity: 0.4
    },
    Label: {
        userSelect: 'none',
        color,
        textAlign: 'left',

        transition: {
            property: 'all',
            duration: 300,
            timingFunction: 'ease-in'
        }
    },
    Radio: {
        boxSizing: 'border-box',
        display: 'inline-block',
        position: 'relative',
        top: 2,

        margin: {
            right: 5
        }
    }
}));
