import * as React from 'react';
import { validator } from 'vx-form';
import { object } from 'vx-std';
import { InputField } from 'app/composition/Form';
import MutationWindow from 'app/composition/MutationWindow';
import ROOMS_QUERY from 'app/graphql/query/rooms';
import { useModal } from 'app/hook';
import CREATE_HOME_MUTATION from 'app/graphql/mutation/home/create';

import type { DataProxy, FetchResult } from '@apollo/client';
import type i18next from 'i18next';
import type { RoomsQueryData, HomeType } from 'app/graphql/query/rooms';
import type { CreateHomeMutationData } from 'app/graphql/mutation/home/create';
import type { CloserSig } from 'app/hook/useModal';

const update = (store: DataProxy, { data }: FetchResult<CreateHomeMutationData>) => {
    const cache = store.readQuery<RoomsQueryData>({ query: ROOMS_QUERY });
    const newHomes = [...cache?.me.homes || [], data?.home.create];
    newHomes.sort((a: HomeType | undefined, b: HomeType | undefined) => String(a?.name).localeCompare(String(b?.name)));

    store.writeQuery({ query: ROOMS_QUERY, data: object.replaceIn(cache, 'me.homes', newHomes) });
};

export default (t: i18next.TFunction) => useModal((close: CloserSig) => (
    <MutationWindow
        mutation={CREATE_HOME_MUTATION}
        close={close}
        title={t('home.create.title')}
        labelOk={t('home.create.create')}
        labelCancel={t('home.create.cancel')}
        update={update as any}
    >
        <InputField name="name" placeholder={t('home.create.name')} validator={validator.required()}/>
    </MutationWindow>
));
