import type { Theme } from 'app/theme';

export default (theme: Theme) => ({
    Active: {
        background: theme.button.success.background,
        color: theme.button.default.color
    },
    Edit: {
        marginLeft: 'auto',
        paddingLeft: 10,
        paddingRight: 10,
        background: theme.button.primary.background,
        color: theme.button.default.color
    },
    Delete: {
        paddingLeft: 8,
        paddingRight: 10,
        background: theme.button.danger.background,
        color: theme.button.default.color
    },
    Title: {
        paddingLeft: 10,
        paddingRight: 10
    },
    Editing: {
        cursor: 'default'
    }
});
