import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import style from './ButtonFooter.style';

const useStyles = createUseStyles(style);

type ButtonFooterProps = JSX.IntrinsicElements['div'];

const ButtonFooter = ({ children, className, ...props }: ButtonFooterProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return (
        <div {...props} className={cx(classes.ButtonFooter, className)} ref={ref}>
            {children}
        </div>
    );
};

export default React.forwardRef(ButtonFooter);
