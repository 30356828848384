import * as React from 'react';
import { Modal } from 'app/component';
import BaseConfirmation from './Confirmation';

type ConfirmationProps = React.ComponentProps<typeof BaseConfirmation> & {
    isOpen?: boolean;
};

export default function Confirmation({ isOpen, ...props }: ConfirmationProps) {
    return (
        <Modal isOpen={isOpen}>
            <BaseConfirmation {...props} />
        </Modal>
    );
}

export { BaseConfirmation as Confirmation };
