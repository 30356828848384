import gql from 'graphql-tag';

export default gql`
    mutation ThingShortcutOff($thingId: ID!, $subsystemId: ID!, $shortcutId: ID!) {
        thing(id: $thingId) {
            subsystem(id: $subsystemId) {
                shortcut(id: $shortcutId) {
                    off
                }
            }
        }
    }
`;
