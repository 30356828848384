import type { Theme } from 'app/theme';

export default (({ navigation }: Theme) => ({
    Logo: {
        width: 120,
        height: navigation.height,
        display: 'flex',
        userSelect: 'none',
        overflow: 'hidden',
        flexGrow: 0,
        flexShrink: 0,

        background: navigation.background
    },
    Wrapper: {
        flexGrow: 1,
        position: 'relative',

        borderRadius: [0, 10, 10, 0],
        overflow: 'hidden',

        boxShadow: {
            inset: 'inset',
            blur: 5,
            x: -2,
            y: -2,
            spread: -2,
            color: '#2b2b2b'
        }
    },
    Loader: {
        position: 'absolute',
        width: 120,
        height: 120,
        top: -30,
        left: 60
    },
    Text: {
        position: 'relative',

        fontSize: 20,
        color: '#dddddd',
        fontWeight: 'bold',
        padding: {
            top: 8,
            left: 5
        }
    }
}));
