export default {
    ThingsList: {
        boxSizing: 'border-box',

        padding: '10px',

        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',

        width: '100%',

        '& > * + *': {
            marginTop: 10
        }
    },
    Room: {
        padding: 10
    },
    Things: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 10,

        '& > * + *': {
            marginLeft: 10
        }
    },
    NoRoom: {
        color: '#AAAAAA'
    }
};
