import * as React from 'react';
import { useMutation } from '@apollo/client/react';
import { Map } from 'immutable';
import { time } from 'vx-std';
import { Duration, Event, stream, Time, Trigger, Window } from 'pondjs';
import Interval from 'app/utils/Interval';
import { useElementProp } from 'app/utils/react';

import SUBSYSTEM_HISTORY_MUTATION from 'app/graphql/mutation/pushSubsystemHistory';

import type { Index } from 'pondjs';
import type { HistoryParams } from 'app/hook/useSubsystem/useSubsystemHistory';
import type { SubsystemStatusOptionsType } from './types';

const useStatusAggregator = (
    id: string,
    userOptions: Partial<SubsystemStatusOptionsType> | null,
    status: string | null,
    historyParams: HistoryParams
) => {
    const [push] = useMutation(SUBSYSTEM_HISTORY_MUTATION);

    const options: SubsystemStatusOptionsType = {
        aggregate: {},
        window: '1m',
        ...userOptions
    };

    const { dispatchStatus } = useElementProp(() => {
        const s = stream<Time>()
            .groupByWindow({
                window: new Window(new Duration(options.window)),
                trigger: Trigger.onDiscardedWindow
            })
            .aggregate(options.aggregate || {})
            .output((event: Event<Index>) => {
                const { data } = event.toJSON() as any;
                const time = event.timerange().end();

                setTimeout(() => push({ variables: { id, time, data, interval: historyParams.interval, from: historyParams.from } }));
            });

        return {
            dispatchStatus: (status: string | null) => {
                if (status) {
                    const value = options.parser ? options.parser(status) : { status };
                    s.addEvent(new Event(new Time(), Map(Object.entries(value as any))));
                }
            }
        };
    }, [push, historyParams.interval, historyParams.from]);


    const interval = useElementProp(() => {
        return new Interval(time.parseUnits(options.window), dispatchStatus);
    });

    React.useEffect(() => {
        dispatchStatus(status);
        interval.setArguments([status]);
        interval.start();

    }, [status, dispatchStatus, interval]);

    React.useEffect(() => {
        return () => {
            interval.stop();
        };
    }, []);
};

export default useStatusAggregator;
