import type { Theme } from 'app/theme';

export default ((theme: Theme) => ({
    Room: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& + *': {
            margin: {
                top: 10
            }
        }
    },
    Buttons: {
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'flex-end',

        '& > *': {
            fontSize: 20,
            cursor: 'pointer',
            opacity: 0.4,

            '&:hover': {
                opacity: 1
            },

            '& + *': {
                marginLeft: 10
            }
        }
    },
    Danger: {
        color: theme.button.danger.background
    }
}));
