import * as React from 'react';
import { GradientDefs, HorizontalGridLines, LineSeries, VerticalGridLines, XAxis, YAxis } from 'react-vis';
import { formatDateTick } from 'app/helper/graph';
import { Graph } from 'app/component';

import type { SubsystemGraphProps } from 'app/subsystem';


const HumidityGraph = ({ history = [], onOpenSettings }: SubsystemGraphProps<{ amps: number }>) => {
    const data = React.useMemo(() => history.map(({ time, data: { amps: y } }) => ({ x: time.getTime(), y })), [history]);
    const max = React.useMemo(() => data.reduce((max, { y }) => Math.max(max, y + 2), 5), [data]);
    const [hoveredNode, hoverNode] = Graph.useHintState();
    return (
        <Graph height={250} yDomain={[0, max]} onOpenSettings={onOpenSettings} onMouseLeave={() => hoverNode(null)}>
            <GradientDefs>
                <linearGradient
                    id="acMeterGradient"
                    gradientUnits="userSpaceOnUse"
                    x1="0" y1="250" x2="0" y2="0"
                >
                    <stop offset="10%" stopColor="green"/>
                    <stop offset="100%" stopColor="red"/>
                </linearGradient>
            </GradientDefs>
            <HorizontalGridLines/>
            <VerticalGridLines/>
            <XAxis tickFormat={formatDateTick}/>
            <YAxis tickFormat={v => v + 'A'}/>
            <LineSeries color={'url(#acMeterGradient)'} data={data} onNearestXY={hoverNode} />
            {hoveredNode && (
                <Graph.Hint
                    {...hoveredNode}
                    value={`${Math.floor(hoveredNode.value as number * 1000) / 1000}A`}
                />
            )}
        </Graph>
    );
};

export default HumidityGraph;
