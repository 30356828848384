import * as React from 'react';
import { useField, useFieldMeta, useFieldValidation, useFormMeta } from 'vx-form';
import { Button, Input, Select } from 'app/component';
import Feedback from './Feedback';
import type { JSXElementConstructor } from 'react';
import type { AsyncValidatorSig, ValidatorSig } from 'vx-form/dist/type';

export type FieldProps = {
    name: string | number;
    validator?: ValidatorSig | ValidatorSig[];
    asyncValidator?: AsyncValidatorSig | AsyncValidatorSig[];
};

export type FieldAdditionalProps<T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>> =
    Omit<React.ComponentProps<T>, 'name'> & FieldProps;

export { default as MutationForm } from 'vx-form-apollo';
export { Form, FieldSet, HiddenField, validator } from 'vx-form';

export const FormFeedback = (props: React.ComponentProps<typeof Feedback>) => {
    const { errors, warnings } = useFormMeta();

    return <Feedback {...props} errors={errors} warnings={warnings}/>;
};

export const InputField = ({ name, validator, asyncValidator, ...props }: FieldAdditionalProps<typeof Input>) => {
    const { value, onFocus, onBlur, onChange } = useField(name);
    const { errors, warnings } = useFieldMeta(name);
    const { setAsyncValidator, setValidator } = useFieldValidation(name);

    setValidator(validator);
    setAsyncValidator(asyncValidator);

    return (
        <Feedback errors={errors} warnings={warnings}>
            <Input {...props} value={value || ''} onFocus={onFocus} onBlur={onBlur}
                onChange={e => onChange(e.currentTarget.value)}/>
        </Feedback>
    );
};

export const SelectField = ({ name, validator, asyncValidator, ...props }: FieldAdditionalProps<typeof Select>) => {
    const { value, onFocus, onBlur, onChange } = useField(name);
    const { errors, warnings } = useFieldMeta(name);
    const { setAsyncValidator, setValidator } = useFieldValidation(name);

    setValidator(validator);
    setAsyncValidator(asyncValidator);

    const handleToggle = React.useCallback((isOpen: boolean) => {
        if (isOpen) {
            onFocus();
        } else {
            onBlur();
        }
    }, [onBlur, onFocus]);

    return (
        <Feedback errors={errors} warnings={warnings}>
            <Select
                {...props}
                value={value || null}
                onSelect={onChange}
                onToggle={handleToggle}
            />
        </Feedback>
    );
};

export const FormButton = (props: React.ComponentProps<typeof Button>) => {
    const { isValid, isDirty, isSubmitting } = useFormMeta();
    return <Button {...props} disabled={!(isDirty && isValid)} loading={isSubmitting}/>;
};
