import * as React from 'react';
import { useQuery } from '@apollo/client/react';
import SUBSYSTEM_HISTORY_QUERY from 'app/graphql/query/subsystemHistory';
import { extractThingId } from 'app/helper/subsystem';
import * as subsystems from 'app/subsystem';

import type { SubsystemTypeType } from 'app/subsystem';
import type { HistoryEntryType, SubsystemHistoryType } from 'app/graphql/query/subsystemHistory';

export type SubsystemHistoryResultType<D> = {
    loading: boolean;
    history: Array<HistoryEntryType<D, Date>>;
};

export interface HistoryParams {
    from?: Date,
    to?: Date,
    interval: string;
}

const mapHistory = <D>({ time, data }: { time: string, data: D }) => ({ time: new Date(time), data });

const useSubsystemHistory = <D = string>(id: string, params: HistoryParams): SubsystemHistoryResultType<D> => {
    const variables = {
        ...params,
        thingId: extractThingId(id),
        subsystemId: id
    };
    const { data, loading } = useQuery<SubsystemHistoryType>(SUBSYSTEM_HISTORY_QUERY, { variables });

    const history = React.useMemo(() => {
        const subsystem = data?.thing?.subsystem;
        if (subsystem) {
            const history = (subsystem.history || []).map(mapHistory);

            const config = subsystems[subsystem.type as SubsystemTypeType] || {};

            if ('filter' in config) {
                return history.filter(config.filter);
            }

            return history;
        }
        
        return [];
    }, [data]);
    
    
    return {
        history,
        loading
    };
};

export default useSubsystemHistory;
