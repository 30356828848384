import Blockly from '../blockly';

import { hydrator } from './hydrator';

import type { FlatNode, Node } from '../types';

const createOrGetBlock = (workspace: Blockly.WorkspaceSvg, type: string, id: string): Blockly.BlockSvg => {
    const existingBlock = workspace.getBlockById(id);
    if (existingBlock) {
        if (existingBlock.type === type) {
            return existingBlock as Blockly.BlockSvg;
        }

        workspace.removeBlockById(id);
    }

    return workspace.newBlock(type, id) as Blockly.BlockSvg;
};

const mutationsToDom = (mutation: FlatNode['mutations']): Element => {
    const container = Blockly.utils.xml.createElement('mutation');
    for (const [name, value] of Object.entries(mutation!)) {
        if (name === 'children') {
            for (const child of value as Array<FlatNode['mutations']>) {
                container.appendChild(mutationsToDom(child));
            }
        } else {
            container.setAttribute(name, value as string);
        }
    }
    return container;
};

// eslint-disable-next-line max-statements,complexity
function _jsonToBlock(
    workspace: Blockly.WorkspaceSvg,
    input: Node,
    parentConnection?: Blockly.Connection,
    connectedToParentNext?: boolean
): Blockly.BlockSvg {
    if (!input.type) {
        throw TypeError('Block type unspecified: ' + JSON.stringify(input));
    }

    const block = createOrGetBlock(workspace, input.type, input.id);

    let shouldCallInitSvg = false;
    if (block.domToMutation && input.mutations) {
        block.domToMutation(mutationsToDom(input.mutations));
        // @ts-ignore
        if (block.initSvg) {
            // Mutation may have added some elements that need initializing.
            shouldCallInitSvg = true;
        }
    }

    if (input.comment) {
        block.setCommentText(input.comment);
    }

    // Connect parent after processing mutation and before setting fields.
    if (parentConnection) {
        if (connectedToParentNext) {
            if (block.previousConnection) {
                parentConnection.connect(block.previousConnection);
            } else {
                throw TypeError('Next block does not have previous statement.');
            }
        } else {
            if (block.outputConnection) {
                parentConnection.connect(block.outputConnection);
            } else if (block.previousConnection) {
                parentConnection.connect(block.previousConnection);
            } else {
                throw TypeError('Child block does not have output or previous statement.');
            }
        }
    }

    if (input.fields) {
        const fields = { ...input.fields };
        if (['variables_get', 'variables_set'].includes(input.type) && fields.VAR) {
            const variable = workspace.getVariable(fields.VAR as string);
            if (!variable) {
                fields.VAR = workspace.createVariable(fields.VAR as string).getId();
            } else {
                fields.VAR = variable.getId();
            }
        }
        for (const [name, value] of Object.entries(fields)) {
            const field = block.getField(name);
            if (!field) {
                // eslint-disable-next-line no-console
                console.warn('Ignoring non-existent field ' + name + ' in block ' + block.type);
                continue;
            }
            field.setValue(value);
        }
    }

    for (const fields of [input.values, input.statements]) {
        if (fields) {
            for (const [name, node] of Object.entries(fields)) {
                const blockInput = block.getInput(name);
                if (!blockInput) {
                    // eslint-disable-next-line no-console
                    console.warn('Ignoring non-existent blockInput ' + name + ' in block ' + input.type);
                    continue;
                }
                if (!blockInput.connection) {
                    // eslint-disable-next-line no-console
                    console.warn('Ignoring non-existent blockInput ' + name + ' in block ' + input.type);
                    continue;
                }
                _jsonToBlock(workspace, node, blockInput.connection, false);
            }
        }
    }

    if (input.next) {
        if (!block.nextConnection) {
            throw TypeError('Next statement does not exist.');
        }
        // If there is more than one XML 'next' tag.
        if (block.nextConnection.isConnected() && block.nextConnection.targetBlock().id !== input.next.id) {
            throw TypeError('Next statement is already connected.');
        }
        // Create child block.
        _jsonToBlock(workspace, input.next, block.nextConnection, true);
    }

    if (shouldCallInitSvg) {
        // InitSvg needs to be called after variable fields are loaded.
        block.initSvg();
    }

    return block;
}

// eslint-disable-next-line max-statements
function jsonToBlock(workspace: Blockly.WorkspaceSvg, input: Node): Blockly.BlockSvg {
    Blockly.Events.disable();
    const variablesBeforeCreation = workspace.getAllVariables();
    try {
        const topBlock = _jsonToBlock(workspace, input);
        // Generate list of all blocks.
        const blocks = topBlock.getDescendants(false) as Blockly.BlockSvg[];
        if (workspace.rendered) {
            // Wait to track connections to speed up assembly.
            topBlock.setConnectionTracking(false);

            const revBlocks = [...blocks].reverse();
            for (const block of revBlocks) {
                block.initSvg();
            }
            for (const block of revBlocks) {
                block.render(false);
            }
            // Populating the connection database may be deferred until after the
            // blocks have rendered.
            setTimeout(function() {
                if (!topBlock.disposed) {
                    topBlock.setConnectionTracking(true);
                }
            }, 1);
            topBlock.updateDisabled();
            // Allow the scrollbars to resize and move based on the new contents.
            workspace.resizeContents();
        } else {
            for (const block of blocks) {
                block.initModel();
            }
        }
        Blockly.Events.enable();

        if (Blockly.Events.isEnabled()) {
            const newVariables = Blockly.Variables.getAddedVariables(workspace, variablesBeforeCreation);

            const VarCreateEvent: typeof Blockly.Events.VarCreate = Blockly.Events.get(Blockly.Events.VAR_CREATE) as any;

            for (const thisVariable of newVariables) {
                Blockly.Events.fire(new VarCreateEvent(thisVariable));
            }

            const CreateEvent: typeof Blockly.Events.Create = Blockly.Events.get(Blockly.Events.CREATE) as any;
            Blockly.Events.fire(new CreateEvent(topBlock));
        }

        return topBlock;
    } catch (e) {
        Blockly.Events.enable();
        throw e;
    }

}

// eslint-disable-next-line max-statements
export default function (workspace: Blockly.WorkspaceSvg, input: FlatNode[]) {
    Blockly.utils.dom.startTextWidthCache();
    const existingGroup = Blockly.Events.getGroup();
    if (!existingGroup) {
        Blockly.Events.setGroup(true);
    }

    // Disable workspace resizes as an optimization.
    if (workspace.setResizesEnabled) {
        workspace.setResizesEnabled(false);
    }

    try {
        const hydrated = hydrator(input);
        let y = 10;
        for (const node of hydrated) {
            const block = jsonToBlock(workspace, node);
            const position = block.getRelativeToSurfaceXY();
            if (position.x < 10 && position.y < 10) {
                block.moveTo(new Blockly.utils.Coordinate(10, y));
                y += 10 + block.height;
            }
        }
    } finally {
        if (!existingGroup) {
            Blockly.Events.setGroup(false);
        }
        Blockly.utils.dom.stopTextWidthCache();
    }
    // Re-enable workspace resizing.
    if (workspace.setResizesEnabled) {
        workspace.setResizesEnabled(true);
    }

    const EventClass: typeof Blockly.Events.FinishedLoading = Blockly.Events.get(Blockly.Events.FINISHED_LOADING) as any;
    Blockly.Events.fire(new EventClass(workspace));
}
