import type { Theme } from 'app/theme';

export default (theme: Theme) => ({
    Container: {
        display: 'flex',
        flexDirection: 'column',
        userSelect: 'none',
        textAlign: 'initial',

        background: theme.window.background,

        border: {
            width: 1,
            style: 'solid',
            color: theme.window.border.color
        },

        padding: 10,

        borderRadius: 6,

        boxShadow: {
            x: 2,
            y: 2,
            blur: 10,
            spread: -3,
            color: '#121212'
        }
    },
    Success: {

    },
    Preview: {
        fontFamily: 'monospace',
        fontSize: 18,
        marginTop: 5,

        '& > *': {
            lineHeight: '24px'
        }
    }
});
