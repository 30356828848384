export function traverseToRelativeOrBody(element: HTMLElement): HTMLElement {
    while (element && element.tagName.toLowerCase() !== 'body') {
        const style = window.getComputedStyle(element, null);
        if (!element.parentElement || style.position === 'relative') {
            return element;
        }
        element = element.parentElement;
    }
    return element;
}
