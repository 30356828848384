import { max } from 'pondjs';
import type { Time } from 'pondjs';
import type { Aggregation } from 'pondjs/lib/types';

export { default as Graph } from './component/Graph';
export { default as Value } from './component/Value';

export const parser = (status: string) => ({ brightness: parseFloat((status || '0').split(':').pop() as string) });
export const aggregate = {
    brightness: [ 'brightness', max() ] as Aggregation<Time>
};

export const shortcuts = {
    off: ['off:0', 'on:0']
};
