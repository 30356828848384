import TracedRunner from './TracedRunner';
import UiTracedInterpreter from './interpreter/UiTracedInterpreter';

import type Blockly from '../blockly';

export default class UiRunner extends TracedRunner {
    public buildInterpreter(workspace: Blockly.WorkspaceSvg): UiTracedInterpreter<Blockly.WorkspaceSvg> {
        this.actionsProxy.clear();
        return new UiTracedInterpreter(this.instructions, workspace, this.actionsProxy);
    }
}
