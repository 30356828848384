import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { AreaSeries, HorizontalGridLines, XAxis } from 'react-vis';
import { max } from 'pondjs';
import { time } from 'vx-std';

import { Graph, Loader, ThingStatus } from 'app/component';
import { formatDateTick } from 'app/helper/graph';
import useSubsystem from 'app/hook/useSubsystem/useSubsystemRead';
import useSubsystemHistory from 'app/hook/useSubsystem/useSubsystemHistory';
import style from './Status.style';
import type { SubsystemStatusOptionsType } from 'app/hook/useSubsystem/types';

const useStyles = createUseStyles(style);

const options: SubsystemStatusOptionsType = {
    parser(status: string | null | undefined) {
        return {
            online: String(status).trim() === 'online' ? 1 : 0
        };
    },
    aggregate: {
        online: ['online', max() ]
    },
    window: '10s'
};

type StatusProps = React.ComponentProps<'div'> & {
    id: string;
    className?: string;
};

const getStatusFrom = () => {
    const date = new Date();
    date.setTime(date.getTime() - time.msFromTime(4, 0, 0));
    return date;
};

const Status = ({ className, id, ...props }: StatusProps) => {
    const classes = useStyles();
    const [from] = React.useState(getStatusFrom);
    const historyParams = { from, interval: '2m' };
    const { subsystem, loading } = useSubsystem(id, options, historyParams);
    const { history } = useSubsystemHistory<{ online: boolean }>(id, historyParams);

    if (loading || !subsystem) {
        return <Loader/>;
    }

    return (
        <div {...props} className={cx(classes.Status, className)}>
            <ThingStatus status={(subsystem.status as any)} className={classes.Value}/>
            <Graph
                height={40}
                className={classes.Graph}
                yDomain={[0, 1]}
                margin={{ left: 0, right: 10, top: 10, bottom: 20 }}
            >
                <HorizontalGridLines tickTotal={3}/>
                <XAxis tickFormat={formatDateTick} tickPadding={0}/>
                <AreaSeries
                    stroke="none"
                    color="rgba(103,191,103,0.85)"
                    curve="curveStep"
                    data={history.length > 1 ?
                        history.map(({ time, data: { online: y } }) => ({ x: time.getTime(), y: y ? 1 : 0 })) :
                        [
                            { x: from.getTime(), y: subsystem.status === 'online' ? 1 : 0 },
                            { x: new Date().getTime(), y: subsystem.status === 'online' ? 1 : 0 }
                        ]
                    }
                />
            </Graph>
        </div>
    );
};

export default Status;
