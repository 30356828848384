import * as React from 'react';
import { validator } from 'vx-form';
import { object } from 'vx-std';
import { InputField } from 'app/composition/Form';
import MutationWindow from 'app/composition/MutationWindow';
import AUTOMATIONS_QUERY from 'app/graphql/query/automations';
import CREATE_AUTOMATION_MUTATION from 'app/graphql/mutation/automation/create';
import { useModal } from 'app/hook';

import TriggerSelectField from './component/TriggerSelect/field';

import type { DataProxy } from '@apollo/client';
import type i18next from 'i18next';
import type { AutomationsQueryData } from 'app/graphql/query/automations';
import type { CreateAutomationResult } from 'app/graphql/mutation/automation/create';
import type { CloserSig } from 'app/hook/useModal';

const update = (store: DataProxy, { data }: CreateAutomationResult) => {
    if (data?.automation.create) {
        const cache = store.readQuery<AutomationsQueryData>({ query: AUTOMATIONS_QUERY });
        const newAutomations = [...cache?.me.automations || [], data.automation.create];
        newAutomations.sort((a, b) => String(a.name).localeCompare(String(b.name)));

        store.writeQuery({ query: AUTOMATIONS_QUERY, data: object.replaceIn(cache, 'me.automations', newAutomations) });
    }
};

export default (t: i18next.TFunction, contentClassName?: string) => useModal((close: CloserSig) => (
    <MutationWindow
        mutation={CREATE_AUTOMATION_MUTATION}
        close={close}
        title={t('automation.create.title')}
        labelOk={t('automation.create.create')}
        labelCancel={t('automation.create.cancel')}
        update={update as any}
        contentClassName={contentClassName}
        initialValues={{ trigger: { type: 'push' } }}
    >
        <InputField name="name" placeholder={t('automation.create.name')} validator={validator.required()}/>
        <TriggerSelectField name="trigger" placeholder={t('automation.create.name')} validator={validator.required()}/>
    </MutationWindow>
));
