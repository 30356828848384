import Runner from 'app/component/Blockly/runner';
import IotInterpreter from './IotInterpreter';

import type { InstructionSet } from 'app/component/Blockly/runner/types';
import type { ContextWorkspaceSvg } from 'app/component/Blockly/types';
import type { Context } from './context';

export type SetOutputsSig = (fn: (old: Record<string, any>) => Record<string, any>) => void;

export default class IotRunner extends Runner {
    protected readonly setOutputs: SetOutputsSig;

    public constructor(instructions: InstructionSet, setOutputs: SetOutputsSig) {
        super(instructions);
        this.setOutputs = setOutputs;
    }

    public buildInterpreter(workspace: ContextWorkspaceSvg<Context>): IotInterpreter {
        this.actionsProxy.clear();
        return new IotInterpreter(this.instructions, workspace, this.actionsProxy, this.setOutputs);
    }
}
