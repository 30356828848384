export default {
    SettingsEntry: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        flexGrow: 0,
        flexShrink: 1,

        padding: 10,

        '& + $SettingsEntry': {
            borderTop: 'none'
        },

        borderTop: {
            width: 1,
            color: '#dddddd',
            style: 'solid'
        },
        borderBottom: {
            width: 1,
            color: '#dddddd',
            style: 'solid'
        }
    },
    Label: {
        flexGrow: 1,
        flexShrink: 1,

        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    Wrapper: {
        flexGrow: 0,
        flexShrink: 0
    }
};
