import BlocklyCore from 'blockly';
import { predicate } from 'vx-std';

import type { ToolboxCategory, ToolboxConfig } from '../types';
import type { BlockCategory, BlockBuilder, TypeBlockDefinition, BlockModule } from './types';

export const extractDefinitions = (categories: Record<string, BlockCategory>): Array<TypeBlockDefinition> => {
    return Object.entries(categories).reduce((acc, [categoryName, category]) => {
        return Object.entries(category).reduce((acc, [blockName, block]) => {
            if (blockName === 'default') {
                return acc;
            }

            if (block.definition) {
                acc.push({
                    ...block.definition,
                    type: block.name || (categoryName + '_' + blockName)
                });
            }
            if (block.helperDefinitions) {
                acc.push(...block.helperDefinitions);
            }

            return acc;
        }, acc);
    }, [] as Array<TypeBlockDefinition>);
};

export const extractBuilders = (categories: Record<string, BlockCategory>): Record<string, { init: BlockBuilder }> => {
    return Object.entries(categories).reduce((acc, [categoryName, category]) => {
        return Object.entries(category).reduce((acc, [blockName, block]) => {
            if (blockName === 'default') {
                return acc;
            }

            if (block.builder) {
                acc[block.name || (categoryName + '_' + blockName)] = predicate.isFunction(block.builder) ?
                    { init: block.builder } : block.builder;
            }
            return acc;
        }, acc);
    }, {} as Record<string, { init: BlockBuilder }>);
};

export const extractExecutors = (categories: Record<string, BlockCategory>): Record<string, BlockModule['default']> => {
    return Object.entries(categories).reduce((acc, [categoryName, category]) => {
        return Object.entries(category).reduce((acc, [blockName, block]) => {
            if (blockName === 'default') {
                return acc;
            }

            if (block.default) {
                acc[block.name || (categoryName + '_' + blockName)] = block.default;
            }
            return acc;
        }, acc);
    }, {} as Record<string, BlockModule['default']>);
};

export const extractCategories = (categories: Record<string, BlockCategory>): ToolboxCategory[] => {
    return Object.entries(categories).map(([categoryName, category]) => {
        if (category.default.custom) {
            if (predicate.isFunction(category.default.custom)) {
                return {
                    ...category.default,
                    custom: 'CUSTOM' + Math.random().toString(16).replace('.', ''),
                    builder: category.default.custom,
                    kind: 'category'
                } as ToolboxCategory;
            }
            return {
                ...category.default,
                kind: 'category'
            } as ToolboxCategory;
        }

        const result = {
            ...category.default,
            kind: 'category',
            contents: []
        } as ToolboxCategory;

        for (const [blockName, block] of Object.entries(category)) {
            if (blockName === 'default') {
                continue;
            }

            result.contents!.push({
                kind: 'block',
                type: block.name || (categoryName + '_' + blockName)
            });
        }

        return result;
    });
};

export const buildToolbox = (categories: Record<string, BlockCategory>): ToolboxConfig => ({
    kind: 'categoryToolbox',
    contents: extractCategories(categories)
});

export const initializeBlocks = (categories: Record<string, BlockCategory>) => {
    BlocklyCore.defineBlocksWithJsonArray(extractDefinitions(categories));
    Object.assign(BlocklyCore.Blocks, extractBuilders(categories));
};
