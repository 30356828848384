import * as React from 'react';
import { autoUpdate, offset, useFloating, arrow, shift } from '@floating-ui/react-dom';
import Portal from 'app/component/Portal';
import { traverseToRelativeOrBody } from 'app/utils/dom';

import PopconfirmArrow from './Arrow';
import PopconfirmBody from './Body';
import Background from './Background';

import type { ButtonKind } from 'app/component/Button';

type PopconfirmProps = {
    className?: string;
    children: React.ReactChild;
    message?: React.ReactNode;
    onConfirm?: () => void;
    onCancel?: () => void;
    confirmKind?: ButtonKind;
    confirmLabel?: string;
    cancelKind?: ButtonKind;
    cancelLabel?: string;
    isOpen?: boolean;
};

export default React.forwardRef<HTMLDivElement, PopconfirmProps>(function Popconfirm({
    className,
    message,
    onCancel,
    onConfirm,
    confirmKind,
    confirmLabel,
    cancelKind,
    cancelLabel,
    isOpen,
    children
}, ref) {
    const arrowRef = React.useRef<HTMLDivElement>(null);
    const { refs, floatingStyles, middlewareData } = useFloating({
        whileElementsMounted: autoUpdate,
        open: isOpen,
        placement: 'top',
        middleware: [
            offset(5),
            shift((state) => {
                const boundary = traverseToRelativeOrBody(state.elements.reference);
                return {
                    padding: 10,
                    boundary
                };
            }),
            arrow({
                element: arrowRef
            })
        ]
    });

    const child = React.Children.only(children);

    return (
        <React.Fragment>
            {React.cloneElement(child as React.ReactElement, { ref: refs.setReference })}
            <Portal isOpen={isOpen}>
                <Background onClick={onCancel}/>
            </Portal>
            <Portal isOpen={isOpen}>
                <div ref={refs.setFloating} style={floatingStyles}>
                    <PopconfirmBody
                        ref={ref}
                        className={className}
                        onCancel={onCancel}
                        onConfirm={onConfirm}
                        confirmKind={confirmKind}
                        confirmLabel={confirmLabel}
                        cancelKind={cancelKind}
                        cancelLabel={cancelLabel}
                    >
                        {message}
                    </PopconfirmBody>
                    <PopconfirmArrow
                        ref={arrowRef}
                        style={{ left: middlewareData.arrow?.x , top: middlewareData.arrow?.y }}
                    />
                </div>
            </Portal>
        </React.Fragment>
    );
});
