import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';

import style from './style';

const useStyles = createUseStyles(style);

const Link = ({ className, children, ...props }: JSX.IntrinsicElements['a'], ref: React.Ref<HTMLAnchorElement>) => {
    const classes = useStyles();
    return (
        <a {...props} className={cx(classes.Link, className)} ref={ref}>
            {children}
        </a>
    );
};

export default React.forwardRef(Link);
