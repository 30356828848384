import gql from 'graphql-tag';

export default gql`
    mutation CreateHome($name: String!) {
        home {
            create(name: $name) {
                id
                name
                rooms {
                    id
                    name
                }
            }
        }
    }
`;

export type CreateHomeMutationData = {
    home: {
        create: {
            id: number;
            name: string;
            rooms: Array<{
                id: number;
                name: string;
            }>
        }
    }
};
