import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import NestedRouter from 'react-router-nested';
import { withThings } from 'app/hoc';

import List from './component/List';
import Thing from './component/Thing';
import type { ThingType } from 'app/graphql/fragment/thing';

type ThingsProps = {
    things: Array<ThingType>
};

const Things = ({ things }: ThingsProps) => (
    <Switch>
        {things.map(({ id }) => <NestedRouter key={id} path={`/${id}`} render={() => <Thing key={id} id={id}/>}/>)}
        <Route path="/" render={() => <List things={things}/>}/>
    </Switch>
);

export default withThings(Things);
