import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import Button, { ButtonKind } from '../../Button';
import Radio from '../../Radio';
import Select from '../../Select';
import Checkbox from '../../Checkbox';
import NumEntry from '../NumEntry';
import Rule from '../Rule';
import style from './style';
import { makeActionCreators, makeInitialState, makeReducer, RuleMode, stateToExpression } from './state';

type MonthsProps = Omit<React.ComponentProps<'div'>, 'onChange'> & {
    close?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    value?: string;
    onChange?: (value: string) => void;
};

type Month = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface MonthEntryProps extends Omit<React.ComponentProps<'div'>, 'id'> {
    id: Month
}

const useStyles = createUseStyles(style);

const months: Month[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const actions =  makeActionCreators<Month>();
const reducer =  makeReducer<Month>();

function MonthEntry({ id, ...props }: MonthEntryProps) {
    return <div id={String(id)} {...props} />;
}

// eslint-disable-next-line complexity
const Months = ({ className, onChange, value, close, ...props }: MonthsProps, ref?: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    const [t] = useTranslation('components');
    const [state, dispatch] = React.useReducer(reducer, value, makeInitialState);

    React.useEffect(() => {
        onChange?.(stateToExpression(state));
    }, [state]);

    return (
        <div {...props} ref={ref} className={cx(classes.Container, className)}>
            <div className={cx(classes.All, state.all || classes.Hidden)}>
                {t('cron.months.all')}
            </div>
            <div className={classes.Rules}>
                {state.rules.map((rule, index) => (
                    <Rule id={index + 1} onDelete={() => dispatch(actions.removeRule(index))} key={`${index}-${rule.mode}`} >
                        <div className={classes.Mode}>
                            <Radio
                                checked={rule.mode === RuleMode.every}
                                onChange={() => dispatch(actions.setMode(index, RuleMode.every))}
                            >
                                {t('cron.months.every')}
                                {' '}
                                <Select
                                    disabled={rule.mode !== RuleMode.every}
                                    value={rule.every || ''}
                                    onSelect={(value) => dispatch(actions.setEvery(index, value))}
                                >
                                    {months.slice(1, 10).map((i) => (
                                        <NumEntry key={i} id={i}>{i}</NumEntry>
                                    ))}
                                </Select>
                            </Radio>
                            {' '}
                            {t('cron.months.months')}
                        </div>
                        <div className={classes.Mode}>
                            <Radio
                                checked={rule.mode === RuleMode.specific}
                                onChange={() => dispatch(actions.setMode(index, RuleMode.specific))}
                            >
                                {t('cron.months.specific')}
                            </Radio>
                            <div className={cx(classes.ShortGrid, rule.mode !== RuleMode.specific && classes.Hidden)}>
                                {months.map((i) => (
                                    <Checkbox
                                        key={i}
                                        disabled={rule.mode !== RuleMode.specific}
                                        checked={rule.specific.includes(i)}
                                        onChange={() => dispatch(actions.toggleSpecific(index, i))}
                                    >
                                        {t('cron.month-names.' + i)}
                                    </Checkbox>
                                ))}
                            </div>
                        </div>
                        <div className={classes.Mode}>
                            <Radio
                                checked={rule.mode === RuleMode.between}
                                onChange={() => dispatch(actions.setMode(index, RuleMode.between))}
                            >
                                {t('cron.months.between')}
                                {' '}
                                <Select
                                    disabled={rule.mode !== RuleMode.between}
                                    value={rule.between.min || ''}
                                    onSelect={(value) => dispatch(actions.setBetweenMin(index, value))}
                                >
                                    {months.map((i) => (
                                        <MonthEntry key={i} id={i}>{t('cron.month-names.' + i)}</MonthEntry>
                                    ))}
                                </Select>
                                {' '}
                                {t('cron.months.between-and')}
                                {' '}
                                <Select
                                    disabled={rule.mode !== RuleMode.between}
                                    value={rule.between.max || ''}
                                    onSelect={(value) => dispatch(actions.setBetweenMax(index, value))}
                                >
                                    {months.map((i) => (
                                        <MonthEntry key={i} id={i}>{t('cron.month-names.' + i)}</MonthEntry>
                                    ))}
                                </Select>
                            </Radio>
                        </div>
                    </Rule>
                ))}
            </div>
            <div className={classes.Buttons}>
                <Button
                    className={classes.Ok}
                    kind={ButtonKind.primary}
                    onClick={close}
                >
                    {t('cron.ok')}
                </Button>
                <Button
                    className={classes.Add}
                    kind={ButtonKind.success}
                    onClick={() => dispatch(actions.addRule())}
                >
                    {t('cron.add-rule')}
                </Button>
            </div>
        </div>
    );
};

export default React.forwardRef(Months);
