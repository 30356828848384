import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Input, Title, Window } from 'app/component';

import style from './style';
import type { ButtonKind } from 'app/component/Button';
import type { WindowProps } from 'app/component/Window';

type ConfirmationProps = Omit<WindowProps, 'children'> & {
    onConfirm?: () => void;
    onCancel?: () => void;

    mode?: symbol | string;
    title?: React.ReactNode;
    message?: React.ReactNode;
    confirmLabel?: string;
    cancelLabel?: string;

    confirmKind?: ButtonKind;
    cancelKind?: ButtonKind;
};

const useStyles = createUseStyles(style);

const Confirmation = ({
    onConfirm,
    onCancel,
    mode,
    title,
    message,
    confirmLabel,
    cancelLabel,
    confirmKind,
    cancelKind,
    ...props
}: ConfirmationProps) => {
    const [value, setValue] = React.useState('');
    const classes = useStyles();
    const [t] = useTranslation('components');

    return (
        <Window {...props} title={<Title className={classes.Title}>{title || t('confirmation.title')}</Title>}>
            <div className={classes.Message}>
                {message || t('confirmation.message')}
                {mode !== Confirmation.mode.YesNo && <React.Fragment>
                    <br/>
                    <br/>
                    <Trans t={t} i18nKey="confirmation.message-input">
                        <b>{{ mode }}</b>
                        <Input value={value} className={classes.Input} onChange={e => setValue(e.currentTarget.value)}/>
                    </Trans>
                </React.Fragment>}
            </div>
            <Window.ButtonFooter>
                <Button kind={confirmKind} onClick={onConfirm}
                    disabled={!(mode === Confirmation.mode.YesNo || mode === value)}>
                    {confirmLabel || t('confirmation.confirm')}
                </Button>
                <Button kind={cancelKind} onClick={onCancel}>
                    {cancelLabel || t('confirmation.cancel')}
                </Button>
            </Window.ButtonFooter>
        </Window>
    );
};

Confirmation.mode = {
    YesNo: Symbol('YesNo')
};

Confirmation.defaultProps = {
    mode: Confirmation.mode.YesNo,
    confirmKind: 'danger',
    cancelKind: 'lite'
};

export default Confirmation;
