import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import style from './Value.style';

import type { SubsystemValueProps } from 'app/subsystem';

const clipPadding = 10;

const useStyles = createUseStyles(style);

const Value = ({ status, mini }: SubsystemValueProps) => {
    const classes = useStyles();

    const percent = parseFloat(status || '0');
    const clipHeight = (1 - percent / 100) * (212.16 - 2 * clipPadding) + clipPadding;
    const height = Math.round(clipHeight * 100) / 100;

    const id = `clip-${height.toString().replace('.', '-')}`;
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 141.68 212.16"
                className={cx(classes.Droplet, mini && classes.Mini)}
            >
                <defs>
                    <clipPath id={id}>
                        <rect x="0" y="0" width="141.68" height={height}/>
                    </clipPath>
                </defs>
                <path
                    className={classes.Foreground}
                    // eslint-disable-next-line max-len
                    d="M124.22 107.31c-18-36.66-30-55-43.16-84.9a11.34 11.34 0 0 0-20.72-.06C47 52.26 35.46 70.63 17.46 107.31c-21.85 44.49 10.54 89.2 53.38 89.2s75.22-44.71 53.38-89.2z"
                />
                <path
                    className={classes.Background}
                    // eslint-disable-next-line max-len
                    d="M124.22 107.31c-18-36.66-30-55-43.16-84.9a11.34 11.34 0 0 0-20.72-.06C47 52.26 35.46 70.63 17.46 107.31c-21.85 44.49 10.54 89.2 53.38 89.2s75.22-44.71 53.38-89.2z"
                    clipPath={`url(#${id})`}
                />
                <text textAnchor="middle" x="70.84" y="145" className={classes.Text}>{percent.toFixed(2)}%</text>
                <text textAnchor="middle" x="70.84" y="145" className={classes.Text}>{percent.toFixed(2)}%</text>
            </svg>
        </div>
    );
};

export default Value;
