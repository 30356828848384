import * as React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Cron, Select } from 'app/component';

import style from './style';

import type { AutomationTrigger } from 'app/graphql/fragment/automation';

type TriggerSelectProps = Omit<React.HTMLProps<HTMLDivElement>, 'children' | 'value' | 'onChange'> & {
    value?: AutomationTrigger | null;
    onChange?(value: AutomationTrigger): any;
    onFocus?(): any;
    onBlur?(): any;
}

const useStyles = createUseStyles(style);

export const TriggerSelect = ({ name, className, onChange, value, onFocus, onBlur, ...props }: TriggerSelectProps) => {
    const classes = useStyles();

    const [type, setType] = React.useState(value?.type || 'push');
    const [config, setConfig] = React.useState(value?.config || '');

    React.useEffect(() => {
        let cfg = config;
        if (type === 'cron' && value?.type !== 'cron') {
            cfg = '* * * * *';
        }
        if (type === 'push') {
            cfg = '';
        }
        onChange?.({ type, config: cfg } as AutomationTrigger);
    }, [type, config]);

    React.useEffect(() => {
        if (value?.type && value.type != type) {
            setType(value.type);
        }
        if (value?.config && value.config != config) {
            setConfig(value.config);
        }
    }, [value]);

    const handleToggle = React.useCallback((isOpen: boolean) => {
        if (isOpen) {
            onFocus?.();
        } else {
            onBlur?.();
        }
    }, [onBlur, onFocus]);

    return (
        <div {...props} className={cx(classes.Container, className)}>
            <Select
                value={value?.type || ''}
                onSelect={setType}
                onToggle={handleToggle}
                className={classes.Select}
            >
                <div id="cron">Cron</div>
                <div id="push">Push</div>
            </Select>
            {value?.type === 'cron' && (
                <Cron
                    value={value?.config || ''}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={setConfig}
                />
            )}
        </div>
    );
};

export default TriggerSelect;
