import { time } from 'vx-std';

import { FieldNumber, FieldDropdown } from '../../fields';

import { TIME_PERIOD_COLOR } from './constants';

import type { Interpreter, Block, Node } from '../../types';


export const name = 'field_time_period';

export function builder(this: Block) {
    this.appendDummyInput()
        .appendField(new FieldNumber(1), 'value')
        .appendField(new FieldDropdown([
            ['${seconds}', 's'],
            ['${minutes}', 'm'],
            ['${hours}', 'h'],
            ['${days}', 'd'],
            ['${weeks}', 'w'],
            ['${months}', 'M'],
            ['${years}', 'y']
        ]), 'unit');
    this.setOutput(true, 'TimePeriod');

    this.setColour(TIME_PERIOD_COLOR);
}

export const DurationMark = Symbol('duration');
export default function run(this: Interpreter, node: Node): number {
    const value = node.fields?.value;
    if (!value) {
        throw new Error('period value missing');
    }

    const unit = node.fields?.unit;
    if (!unit) {
        throw new Error('period unit missing');
    }
    const duration = new Number(time.parseUnits(`${value}${unit}`) / 1000);
    // @ts-ignore
    duration[DurationMark] = true;
    // @ts-ignore
    return duration;
}
run.noTrace = true;
