import * as React from 'react';
import type { HistoryParams } from 'app/hook/useSubsystem/useSubsystemHistory';

export interface HistoryContext extends HistoryParams {
    open: () => void;
}

export default React.createContext<HistoryContext>({
    interval: '10m',
    open() {
        // noop
    }
});
