import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { makeVisFlexible, XYPlot } from 'react-vis';
import { EntypoCog } from 'react-entypo-icons';
import style from './style';

import { Hint, useHintState } from './Hint';

const useStyles = createUseStyles(style);

// @ts-ignore
const FlexibleXYPlot = makeVisFlexible(XYPlot, true, true);

interface GraphProps extends React.ComponentProps<typeof FlexibleXYPlot> {
    onOpenSettings?: () => void;
}

const Graph = ({ onOpenSettings, ...props }: GraphProps) => {
    const classes = useStyles();
    const ref = React.useRef();

    React.useEffect(() => {
        // @ts-ignore
        setTimeout(() => ref?.current?._onResize(), 100);
        // @ts-ignore
        setTimeout(() => ref?.current?._onResize(), 200);
    }, [ref?.current]);

    return (
        <div className={classes.Graph}>
            <FlexibleXYPlot {...props} ref={ref}/>
            {onOpenSettings && (
                <div className={classes.SettingsIconContainer} onClick={onOpenSettings}>
                    <EntypoCog />
                </div>
            )}
        </div>
    );
};

Graph.Hint = Hint;
Graph.useHintState = useHintState;

export default Graph;
