import { useSubscription } from '@apollo/client/react';
import AUTOMATION_OUTPUT_SUBSCRIPTION from 'app/graphql/subscription/onAutomationOutput';

import type { AutomationOutputSubscription } from 'app/graphql/subscription/onAutomationOutput';

const useAutomationOutputSubscription = (automationId: string | null |undefined, outputId: string) => {
    useSubscription<AutomationOutputSubscription>(AUTOMATION_OUTPUT_SUBSCRIPTION, {
        skip: !automationId,
        variables: { automationId, outputId }
    });
};

export default useAutomationOutputSubscription;
