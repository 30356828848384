import type { Theme } from 'app/theme';

const matrixSize = 5;
const shadowBlur = 50;

const scaleDelay = 100;
const scaleDuration = 1000;

export interface StyleData {
    flat?: boolean;
    animate?: boolean;
    size?: number | string;
    blur?: number
}

export default ({ loader }: Theme) => ({
    '@keyframes scale': {
        '100%': {
            transform: 'scale(0.1)',
            opacity: 0
        }
    },
    '@keyframes scale-flat': {
        '100%': {
            transform: 'scale(0.1) rotate(-45deg)',
            opacity: 0
        }
    },
    Flat: {
        '& > ul': {
            transform: 'rotate(0deg)',
            gridColumnGap: '10%',

            '& > li': {
                transform: 'rotate(-45deg)',
                animationName: '$scale-flat'
            }
        }
    },
    Size: ({ size }: StyleData) => size ? { width: size } : {},
    Shape: {
        width: '50%',
        '@media (min-width: 600px)': {
            width: '20%'
        },
        maxHeight: 'intrinsic',

        marginBlockStart: 0,
        marginBlockEnd: 0,
        marginInlineStart: 0,
        marginInlineEnd: 0,
        paddingInlineStart: 0,

        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gridColumnGap: '5%',
        gridRowGap: '5%',

        listStyle: 'none',
        margin: 0,
        padding: 0,

        transform: 'rotate(-45deg)'
    },
    Square: ({ blur }: StyleData) => ({
        background: loader.color,
        border: loader.border,
        borderRadius: 4,
        boxShadow: [
            { x: 0, y: 0, blur: 1, color: '#000' }, {
                x: 0,
                y: 0,
                blur: 5 / 55 * (blur || shadowBlur),
                color: loader.color
            },
            { x: 0, y: 0, blur: 10 / 55 * (blur || shadowBlur), color: loader.color },
            { x: 0, y: 0, blur: 15 / 55 * (blur || shadowBlur), color: loader.color }
        ],
        '&::before': {
            content: '""',
            display: 'block',
            paddingBottom: '100%',
            paddingLeft: '100%'
        }
    }),
    Animation: {
        '& > li': {
            animation: {
                duration: scaleDuration,
                timingFunction: 'linear',
                iterationCount: 'infinite',
                direction: 'alternate'
            },
            animationName: '$scale',

            ...(() => {
                const result = {};

                for (let i = 0; i < matrixSize * matrixSize; ++i) {
                    const col = i % matrixSize;
                    const row = Math.floor(i / matrixSize);

                    // @ts-ignore
                    result[`&:nth-child(${i + 1})`] = {
                        zIndex: matrixSize * matrixSize - i - 1,
                        animationDelay: (col + row + 1) * scaleDelay
                    };
                }

                return result;
            })()
        }
    }
});
