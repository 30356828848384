export default {
    Face: {
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        userSelect: 'none',
        textAlign: 'initial',

        '& > :first-child': {
            flexGrow: 1,
            flexShrink: 1
        }
    },
    Chevron: {
        flexGrow: 0,
        flexShrink: 0,

        marginLeft: 5
    }
};
