import { FieldDropdown } from '../../fields';

import { TIME_PERIOD_COLOR } from './constants';
import { DurationMark } from './field_time_period';

import type { Interpreter, Block, Node } from '../../types';


export const name = 'op_period_period';

export function builder(this: Block) {
    this.appendValueInput('a')
        .setCheck('TimePeriod');
    this.appendValueInput('b')
        .setCheck('TimePeriod')
        .appendField(new FieldDropdown([
            ['+', '+'],
            ['-', '-']
        ]), 'operation');
    this.setInputsInline(true);
    this.setOutput(true, 'TimePeriod');

    this.setColour(TIME_PERIOD_COLOR);
}

export default async function run(this: Interpreter, node: Node): Promise<number> {
    const aNode = node.values?.a;
    if (!aNode) {
        throw new Error('a missing');
    }

    const bNode = node.values?.b;
    if (!bNode) {
        throw new Error('b missing');
    }

    const operation = node.fields?.operation;
    if (!operation) {
        throw new Error('operation missing');
    }

    const a: number = await this.execute(aNode);
    const b = await this.execute(bNode) * (operation === '-' ? -1 : 1);

    const duration = new Number(a + b);
    // @ts-ignore
    duration[DurationMark] = true;
    // @ts-ignore
    return duration;
}

