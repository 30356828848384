export default {
    Center: ({ verticalPosition }: any) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        height: '100%',

        padding: 10,

        '& > *': {
            flexGrow: 0,
            height: 'max-content',

            maxWidth: '100%',
            maxHeight: '100%'
        },

        '&::before': {
            content: '""',
            flexBasis: verticalPosition + '%',
            flexGrow: 1
        },

        '&::after': {
            content: '""',
            flexBasis: 100 - verticalPosition + '%',
            flexGrow: 1
        }
    })
};
