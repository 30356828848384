import gql from 'graphql-tag';

export default gql`
    mutation ThingShortcutRemove($thingId: ID!, $subsystemId: ID!, $id: ID!) {
        thing(id: $thingId) {
            subsystem(id: $subsystemId) {
                shortcut(id: $id) {
                    remove
                }
            }
        }
    }
`;
