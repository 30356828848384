import * as React from 'react';
import { validator } from 'vx-form';
import { object } from 'vx-std';
import { InputField } from 'app/composition/Form';
import MutationWindow from 'app/composition/MutationWindow';
import CREATE_ROOM_MUTATION from 'app/graphql/mutation/room/create';
import ROOMS_QUERY from 'app/graphql/query/rooms';
import { useModal } from 'app/hook';

import type { DataProxy } from '@apollo/client';
import type i18next from 'i18next';
import type { RoomsQueryData } from 'app/graphql/query/rooms';
import type { CreateRoomMutationResult } from 'app/graphql/mutation/room/create';
import type { CloserSig } from 'app/hook/useModal';

export default ((id: number, t: i18next.TFunction) => useModal((close: CloserSig) => (
    <MutationWindow
        mutation={CREATE_ROOM_MUTATION} close={close} title={t('room.create.title')} labelOk={t('room.create.create')}
        labelCancel={t('room.create.cancel')} variables={{ homeId: id }}
        update={((store: DataProxy, { data }: CreateRoomMutationResult) => {
            const cache = store.readQuery<RoomsQueryData>({ query: ROOMS_QUERY });

            const homeIndex = cache?.me.homes.findIndex(home => home.id === id) || -1;
            if (homeIndex > -1) {
                const home = cache?.me.homes[homeIndex];
                const newRooms = [...home?.rooms || [], data?.room.create];
                newRooms.sort((a, b) => String(a?.name).localeCompare(String(b?.name)));

                store.writeQuery({
                    query: ROOMS_QUERY,
                    data: object.replaceIn(cache, `me.homes[${homeIndex}].rooms`, newRooms)
                });
            }
        }) as any}>
        <InputField name="name" placeholder={t('room.create.name')} validator={validator.required()}/>
    </MutationWindow>
)));
