import type { Theme } from 'app/theme';

export default ((theme: Theme) => ({
    Homes: {
        margin: 10,
        padding: 10,
        flexGrow: [0, '!important']
    },
    HomesList: {
        '& > * + *': {
            marginTop: 10
        }
    },
    TopRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        margin: {
            bottom: 15
        }
    },
    AddHome: {
        marginTop: 5,
        marginRight: 8,
        marginLeft: 'auto',
        color: theme.button.success.background,
        fontSize: 24,
        cursor: 'pointer',
        opacity: 0.4,

        '&:hover': {
            opacity: 1
        }
    }
}));
