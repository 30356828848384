import type { Theme } from 'app/theme';

export default ({ color, link, button }: Theme) => ({
    Container: {
        display: 'inline-block',
        verticalAlign: 'middle',
        overflow: 'hidden',

        borderRadius: 5,
        padding: 2,
        background: link.color + '22',
        border: {
            style: 'solid',
            width: 1,
            color: link.color
        },

        '& .boolean, & .null': {
            fontWeight: 'bold',
            fontFamily: 'monospace',
            fontSize: 16
        },
        '& .boolean-true': {
            color: button.success.background
        },
        '& .boolean-false': {
            color: button.danger.background
        },
        '& .null': {
            color: button.primary.background
        },
        '& .number': {
            fontFamily: 'monospace',
            fontSize: 16,
            color: '#b6b733'
        },
        '& .duration': {
            fontFamily: 'monospace',
            fontSize: 16,
            color: '#e6b63d'
        },
        '& .date': {
            fontFamily: 'monospace',
            fontSize: 16,
            color: '#e69a3d'
        },
        '& .string': {
            '&:before, &:after': {
                color: color + '88'
            },
            '&:before': {
                content: '"„"'
            },

            '&:after': {
                content: '"”"'
            }
        },
        '& .variable': {
            '&:before, &:after, & .title': {
                color: color + '88'
            },

            '&:after': {
                content: '"”"'
            }
        }
    }
});
