import gql from 'graphql-tag';
import automation from 'app/graphql/fragment/automation';
import type { FetchResult } from '@apollo/client/link/core';
import type { AutomationType } from 'app/graphql/fragment/automation';

export default gql`
    mutation CreateAutomation($name: String!, $trigger: AutomationTriggerInput!, $actions: [AutomationActionInput!]) {
        automation {
            create(name: $name, trigger: $trigger, actions: $actions) {
                ...automation
            }
        }
    }
    ${automation}
`;
export type CreateAutomationResult = FetchResult<{
    automation: {
        create: AutomationType
    }
}>;
