import { useQuery } from '@apollo/client/react';
import THINGS_QUERY from 'app/graphql/query/thingsFull';

import type { ThingsQueryData } from 'app/graphql/query/thingsFull';
import type { ThingType } from 'app/graphql/fragment/thing';

type ThingsFullQueryResultType = {
    loading: boolean;
    things: ThingType[] | undefined;
};

const useThingsFullQuery = (): ThingsFullQueryResultType => {
    const { data, loading } = useQuery<ThingsQueryData>(THINGS_QUERY);

    return {
        things: data?.me?.things || undefined,
        loading
    };
};

export default useThingsFullQuery;
