import * as React from 'react';
// @ts-ignore
import BaseCarousel from 're-carousel';
import { useViewport } from 'app/hook';

const isMobile = (width: number) => width <= 600;

const Carousel = (props: React.ComponentProps<typeof BaseCarousel>) => {
    const ref = React.useRef();
    const [height, setHeight] = React.useState(0);

    const { width } = useViewport();
    const mobile = isMobile(width);

    // @ts-ignore
    const refs = ref?.current?.refs || {};

    React.useLayoutEffect(() => {
        if (!mobile) {
            return;
        }

        const frames = Object.entries(refs).filter(([key]) => key.startsWith('f')).map(([_, el]): HTMLElement => (el as any));

        setHeight(frames.reduce((height, el) => {
            if (el.firstChild) {
                const elHeight = (el.firstChild as HTMLElement).getBoundingClientRect().height;
                if (elHeight > height) {
                    return elHeight;
                }
            }

            return height;
        }, 0));
    }, [refs, mobile]);

    if (!mobile) {
        return <div {...props} />;
    }

    return React.Children.toArray(props.children).filter((el) => !!el).length > 1 ? (
        <div style={{ height }}>

            <BaseCarousel {...props} ref={ref}/>
        </div>
    ) : (
        props.children
    );
};

export default Carousel;
