import * as React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

import style from './Arrow.style';

const useStyles = createUseStyles(style);

const TooltipArrow = ({ className, ...props }: JSX.IntrinsicElements['div'], ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return <div {...props} className={cx(classes.Arrow, className)} ref={ref}/>;
};

export default React.forwardRef(TooltipArrow);
