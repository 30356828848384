import * as React from 'react';
import cx from 'classnames';
import Color from 'color';
import { createUseStyles } from 'react-jss';
import Chunk from './Chunk';
import style from './style';

interface ColorProvider {
    get(arg0: React.ReactNode): string;
}

type ColorOrProviderType = string | ColorProvider;
type ColorPropType = ColorOrProviderType | Array<ColorOrProviderType>;


type LabelProps = JSX.IntrinsicElements['div'] & {
    color: ColorPropType;
};

const grabColor = (color: ColorPropType, i: number, child: React.ReactNode): string => {
    if (Array.isArray(color)) {
        color = color[i % color.length];
    }

    if (typeof color === 'object' && typeof color.get === 'function') {
        return color.get(child);
    }

    return color as string;
};

const useStyles = createUseStyles(style);

const Label = ({ className, color, children, ...props }: LabelProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return (
        <div {...props} className={cx(classes.Label, className)} ref={ref}>
            {React.Children.map(children, (child, i) => {
                const computedColor = grabColor(color, i, child);

                return child && (
                    <Chunk style={{
                        borderColor: Color(computedColor).saturate(1).darken(0.25).hex(),
                        background: computedColor,
                        color: Color(computedColor).darken(0.15).isLight() ? '#484848' : '#ffffff'
                    }}>
                        {child}
                    </Chunk>
                );
            })}
            &nbsp;
        </div>
    );
};

export default React.forwardRef(Label);
