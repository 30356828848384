import * as React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

import style from './Duration.style';

const useStyles = createUseStyles(style);

interface DurationProps extends Omit<React.ComponentProps<'div'>, 'children'> {
    seconds?: number;
    start?: Date | null;
    stop?: Date | null;
}

export const renderDuration = (value: number) => {
    const children = [];

    if (Math.floor(value / 1000 / 60) % 60) {
        children.push(<span key="m" className="m">{Math.floor(value / 1000 / 60) % 60}m</span>);
    }
    if (Math.floor(value / 1000) % 60) {
        children.push(<span key="s" className="s">{Math.floor(value / 1000) % 60}s</span>);
    }
    if (value % 1000) {
        children.push(<span key="ms" className="ms">{value % 1000}ms</span>);
    }
    return <>{children}</>;
};

const Duration = ({ className, start, stop, seconds, ...props }: DurationProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return (
        <div {...props } className={cx(classes.Container, className)} ref={ref}>
            {seconds ? renderDuration(seconds * 1000) : (start && stop) && renderDuration(+stop - +start)}
        </div>
    );
};

export default React.forwardRef(Duration);
