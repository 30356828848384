import * as React from 'react';
import ReactDOM from 'react-dom';
import { predicate } from 'vx-std';

type ChildrenType = React.ReactNode | React.ReactNodeArray;
type ElementOrRendererType = ChildrenType | (() => ChildrenType);

const renderContent = (elementOrRenderer: ElementOrRendererType): ChildrenType => {
    if (predicate.isFunction(elementOrRenderer)) {
        return (elementOrRenderer as any)();
    }

    return elementOrRenderer;
};

const createContainer = () => document.createElement('div');

const initContainerAttributes = (el: HTMLDivElement, containerProps: React.ComponentPropsWithRef<'div'>) => {
    const { style, ref, children, ...props } = containerProps;

    if (style) {
        Object.assign(el.style, style);
    }

    if (ref) {
        if ('current' in ref) {
            // @ts-ignore
            ref.current = el;
        } else if (predicate.isFunction(ref)) {
            ref(el);
        }
    }

    Object.assign(el, props);
};

export type SetPortalStatusSig = (status: boolean) => void;
export type UsePortalResult = [React.ReactPortal|null, SetPortalStatusSig];

export default function usePortal(
    elementOrRenderer: ElementOrRendererType,
    containerProps?: React.ComponentPropsWithRef<'div'>,
    defaultOpen = false,
    root?: Element
): UsePortalResult {
    const [isOpen, setOpen] = React.useState<boolean>(defaultOpen);
    const [el] = React.useState(createContainer);

    if (isOpen && containerProps) {
        initContainerAttributes(el, containerProps);
    }

    React.useEffect(() => {
        if (isOpen) {
            if (root === null) {
                return;
            }

            (root || document.body as any).appendChild(el);
        } else {
            if (el.parentNode) {
                el.parentNode.removeChild(el);
            }
        }
    }, [isOpen, el, root]);

    return [isOpen ? ReactDOM.createPortal(renderContent(elementOrRenderer), el) : null, setOpen];
}
