import gql from 'graphql-tag';

export default gql`
    subscription OnSubsystemStatus($id: String!) {
        onSubsystemStatus(id: $id) {
            id
            status
        }
    }
`;

export type SubsystemStatusSubscription = {
    onSubsystemStatus: {
        id: string;
        status: string;
    }
}
