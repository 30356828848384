import i18n from 'i18next';
// @ts-ignore
import LanguageDetector from 'i18next-browser-languagedetector';
// @ts-ignore
import resources from 'locales';
import type i18next from 'i18next';

i18n.use(LanguageDetector).init({
    detection: {
        order: ['localStorage', 'navigator', 'htmlTag']
    },
    resources,
    react: {
        wait: false
    },

    checkWhitelist: true,
    checkForSimilarInWhitelist: true,
    fallbackLng: 'en',
    debug: false,
    interpolation: {
        escapeValue: false
    }
} as i18next.InitOptions);

export default i18n;
