import Blockly, { TranslatorSymbol } from '../../blockly';
import { FieldTextInput } from '../../fields';

import type { BlockSvg } from '../../types';

export interface DefNoReturnBlock extends BlockSvg {
    callType_: string;
    arguments_: string[];
    paramIds_: string[];
    hasStatements_: boolean;
    argumentVarModels_: Array<Blockly.VariableModel>;
    statementConnection_: Blockly.Connection | null;
    updateParams_(): void;
    setStatements_(this: DefNoReturnBlock, hasStatements: boolean): void;
    displayRenamedVar_(this: DefNoReturnBlock, oldName: string, newName: string): void;
}

export const builder = {
    init(this: DefNoReturnBlock): void {
        const initName = Blockly.Procedures.findLegalName('', this);
        const nameField = new FieldTextInput(initName, Blockly.Procedures.rename);
        nameField.setSpellcheck(false);
        this.appendDummyInput()
            .appendField(this[TranslatorSymbol]('procedures.def.title'))
            .appendField(nameField, 'NAME')
            .appendField('', 'PARAMS');
        this.setMutator(new Blockly.Mutator(['procedures_mutatorarg']));
        if (this.workspace.options.comments || this.workspace.options.parentWorkspace?.options.comments) {
            this.setCommentText(this[TranslatorSymbol]('procedures.def.comment'));
        }
        this.setStyle('procedure_blocks');
        this.arguments_ = [];
        this.argumentVarModels_ = [];
        this.setStatements_(true);
        this.statementConnection_ = null;
    },
    setStatements_(this: DefNoReturnBlock, hasStatements: boolean): void {
        if (this.hasStatements_ === hasStatements) {
            return;
        }
        if (hasStatements) {
            this.appendStatementInput('STACK')
                .appendField(this[TranslatorSymbol]('procedures.def.do'));
            if (this.getInput('RETURN')) {
                this.moveInputBefore('STACK', 'RETURN');
            }
        } else {
            this.removeInput('STACK', true);
        }
        this.hasStatements_ = hasStatements;
    },
    updateParams_(this: DefNoReturnBlock): void {
        // Merge the arguments into a human-readable list.
        let paramString = '';
        if (this.arguments_.length) {
            paramString = this[TranslatorSymbol]('procedures.before-params') + ' ' + this.arguments_.join(', ');
        }
        // The params field is deterministic based on the mutation,
        // no need to fire a change event.
        Blockly.Events.disable();
        try {
            this.setFieldValue(paramString, 'PARAMS');
        } finally {
            Blockly.Events.enable();
        }
    },
    mutationToDom(this: DefNoReturnBlock, opt_paramIds?: boolean): Element {
        const container = Blockly.utils.xml.createElement('mutation');
        if (opt_paramIds) {
            container.setAttribute('name', this.getFieldValue('NAME'));
        }
        for (let i = 0; i < this.argumentVarModels_.length; i++) {
            const parameter = Blockly.utils.xml.createElement('arg');
            const argModel = this.argumentVarModels_[i];
            parameter.setAttribute('type', 'arg');
            parameter.setAttribute('name', argModel.name);
            parameter.setAttribute('varId', argModel.getId());
            if (opt_paramIds && this.paramIds_) {
                parameter.setAttribute('paramId', this.paramIds_[i]);
            }
            container.appendChild(parameter);
        }

        // Save whether the statement input is visible.
        if (!this.hasStatements_) {
            container.setAttribute('statements', 'false');
        }
        return container;
    },
    domToMutation(this: DefNoReturnBlock, xmlElement: Element): void {
        this.arguments_ = [];
        this.argumentVarModels_ = [];
        for (let i = 0, childNode; (childNode = xmlElement.childNodes[i] as Element); i++) {
            if (childNode.getAttribute('type') == 'arg') {
                const varName = childNode.getAttribute('name')!;
                const varId = childNode.getAttribute('varId')!;
                this.arguments_.push(varName);
                const variable = Blockly.Variables.getOrCreateVariablePackage(this.workspace, varId, varName, '');
                if (variable != null) {
                    this.argumentVarModels_.push(variable);
                } else {
                    // eslint-disable-next-line no-console
                    console.log('Failed to create a variable with name ' + varName + ', ignoring.');
                }
            }
        }
        this.updateParams_();
        Blockly.Procedures.mutateCallers(this);

        // Show or hide the statement input.
        this.setStatements_(xmlElement.getAttribute('statements') !== 'false');
    },
    // eslint-disable-next-line max-statements
    decompose(this: DefNoReturnBlock, workspace: Blockly.Workspace): Blockly.Block {
        /*
         * Creates the following XML:
         * <block type="procedures_mutatorcontainer">
         *   <statement name="STACK">
         *     <block type="procedures_mutatorarg">
         *       <field name="NAME">arg1_name</field>
         *       <next>etc...</next>
         *     </block>
         *   </statement>
         * </block>
         */

        const containerBlockNode = Blockly.utils.xml.createElement('block');
        containerBlockNode.setAttribute('type', 'procedures_mutatorcontainer');
        const statementNode = Blockly.utils.xml.createElement('statement');
        statementNode.setAttribute('name', 'STACK');
        containerBlockNode.appendChild(statementNode);

        let node = statementNode;
        for (const argument of this.arguments_) {
            const argBlockNode = Blockly.utils.xml.createElement('block');
            argBlockNode.setAttribute('type', 'procedures_mutatorarg');
            const fieldNode = Blockly.utils.xml.createElement('field');
            fieldNode.setAttribute('name', 'NAME');
            const argumentName = Blockly.utils.xml.createTextNode(argument);
            fieldNode.appendChild(argumentName);
            argBlockNode.appendChild(fieldNode);
            const nextNode = Blockly.utils.xml.createElement('next');
            argBlockNode.appendChild(nextNode);

            node.appendChild(argBlockNode);
            node = nextNode;
        }

        const containerBlock = Blockly.Xml.domToBlock(containerBlockNode, workspace);

        if (this.type == 'procedures_defreturn') {
            containerBlock.setFieldValue(this.hasStatements_, 'STATEMENTS');
        } else {
            containerBlock.removeInput('STATEMENT_INPUT');
        }

        // Initialize procedure's callers with blank IDs.
        Blockly.Procedures.mutateCallers(this);
        return containerBlock;
    },
    // eslint-disable-next-line max-statements
    compose(this: DefNoReturnBlock, containerBlock: Blockly.Block) {
        // Parameter list.
        this.arguments_ = [];
        this.paramIds_ = [];
        this.argumentVarModels_ = [];
        let paramBlock = containerBlock.getInputTargetBlock('STACK');
        while (paramBlock && !paramBlock.isInsertionMarker()) {
            const varName = paramBlock.getFieldValue('NAME');
            this.arguments_.push(varName);
            const variable = this.workspace.getVariable(varName, '');
            this.argumentVarModels_.push(variable);

            this.paramIds_.push(paramBlock.id);
            paramBlock = paramBlock.nextConnection && paramBlock.nextConnection.targetBlock();
        }
        this.updateParams_();
        Blockly.Procedures.mutateCallers(this);

        // Show/hide the statement input.
        let hasStatements = containerBlock.getFieldValue('STATEMENTS');
        if (hasStatements !== null) {
            hasStatements = hasStatements == 'TRUE';
            if (this.hasStatements_ != hasStatements) {
                if (hasStatements) {
                    this.setStatements_(true);
                    // Restore the stack, if one was saved.
                    Blockly.Mutator.reconnect(this.statementConnection_!, this, 'STACK');
                    this.statementConnection_ = null;
                } else {
                    // Save the stack, then disconnect it.
                    const stackConnection = this.getInput('STACK').connection;
                    this.statementConnection_ = stackConnection.targetConnection;
                    if (this.statementConnection_) {
                        const stackBlock = stackConnection.targetBlock();
                        stackBlock.unplug();
                        stackBlock.bumpNeighbours();
                    }
                    this.setStatements_(false);
                }
            }
        }
    },
    getProcedureDef(this: DefNoReturnBlock): any[] {
        return [this.getFieldValue('NAME'), this.arguments_, false];
    },
    getVars(this: DefNoReturnBlock): string[] {
        return this.arguments_;
    },
    getVarModels(this: DefNoReturnBlock):Array<Blockly.VariableModel> {
        return this.argumentVarModels_;
    },
    renameVarById(this: DefNoReturnBlock, oldId: string, newId: string) {
        const oldVariable = this.workspace.getVariableById(oldId);
        if (oldVariable.type != '') {
            // Procedure arguments always have the empty type.
            return;
        }
        const oldName = oldVariable.name;
        const newVar = this.workspace.getVariableById(newId);

        let change = false;
        for (let i = 0; i < this.argumentVarModels_.length; i++) {
            if (this.argumentVarModels_[i].getId() == oldId) {
                this.arguments_[i] = newVar.name;
                this.argumentVarModels_[i] = newVar;
                change = true;
            }
        }
        if (change) {
            this.displayRenamedVar_(oldName, newVar.name);
            Blockly.Procedures.mutateCallers(this);
        }
    },
    updateVarName(this: DefNoReturnBlock, variable: Blockly.VariableModel): void {
        const newName = variable.name;
        let change = false;
        let oldName = '';
        for (let i = 0; i < this.argumentVarModels_.length; i++) {
            if (this.argumentVarModels_[i].getId() == variable.getId()) {
                oldName = this.arguments_[i]!;
                this.arguments_[i] = newName;
                change = true;
            }
        }
        if (change) {
            this.displayRenamedVar_(oldName, newName);
            Blockly.Procedures.mutateCallers(this);
        }
    },
    displayRenamedVar_(this: DefNoReturnBlock, oldName: string, newName: string): void {
        this.updateParams_();
        // Update the mutator's variables if the mutator is open.
        if (this.mutator && this.mutator.isVisible()) {
            const blocks = this.mutator.getWorkspace().getAllBlocks(false);
            for (const block of blocks) {
                if (block.type == 'procedures_mutatorarg' && Blockly.Names.equals(oldName, block.getFieldValue('NAME'))) {
                    block.setFieldValue(newName, 'NAME');
                }
            }
        }
    },
    customContextMenu(this: DefNoReturnBlock, options: any[]): void {
        if (this.isInFlyout) {
            return;
        }
        // Add option to create caller.
        const name = this.getFieldValue('NAME');
        const xmlMutation = Blockly.utils.xml.createElement('mutation');
        xmlMutation.setAttribute('name', name);
        for (const argument of this.arguments_) {
            const xmlArg = Blockly.utils.xml.createElement('arg');
            xmlArg.setAttribute('name', argument);
            xmlMutation.appendChild(xmlArg);
        }
        const xmlBlock = Blockly.utils.xml.createElement('block');
        xmlBlock.setAttribute('type', this.callType_);
        xmlBlock.appendChild(xmlMutation);
        options.push({
            enabled: true,
            text: this[TranslatorSymbol]('procedures.def.create-call').replace('%1', name),
            callback: Blockly.ContextMenu.callbackFactory(this, xmlBlock)
        });

        // Add options to create getters for each parameter.
        if (!this.isCollapsed()) {
            for (const argVar of  this.argumentVarModels_) {
                const argXmlField = Blockly.Variables.generateVariableFieldDom(argVar);
                const argXmlBlock = Blockly.utils.xml.createElement('block');
                argXmlBlock.setAttribute('type', 'variables_get');
                argXmlBlock.appendChild(argXmlField);
                options.push({
                    enabled: true,
                    callback: Blockly.ContextMenu.callbackFactory(this, argXmlBlock),
                    text: this[TranslatorSymbol]('variables.set-create-get').replace('%1', argVar.name)
                });
            }
        }
    },
    callType_: 'procedures_callnoreturn'
};
