import type { Theme } from 'app/theme';

export default ({ color, window }: Theme) => ({
    Body: {
        userSelect: 'none',

        borderRadius: 15,
        border: window.border,
        background: window.background,
        padding: 12,
        color,

        boxShadow: {
            x: 1,
            y: 1,
            blur: 10,
            spread: -5,
            color: '#000000'
        }
    },
    Footer: {
        marginTop: 10
    }
});
