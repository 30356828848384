import type { FlatNode, Node } from '../types';

type MapTracerSig<M extends Map<any, any>> = (name: Parameters<M['set']>[0], value: Parameters<M['set']>[1]) => void;

export const traceMap = <M extends Map<any, any>>(map: M, tracer: MapTracerSig<M>) => {
    return new Proxy<Map<string, any>>(map, {
        get(target, key: keyof typeof target) {
            if (!(key in target)) {
                return undefined;
            }
            if (key === 'set') {
                return (name: string, value: any) => {
                    tracer(name, value);
                    return target.set(name, value);
                };
            }

            if (typeof target[key] === 'function') {
                // @ts-ignore
                return target[key].bind(target);
            }

            return target[key];
        }
    });
};

// eslint-disable-next-line complexity
export const shallowFlattenNode = (node: Node): FlatNode => {
    const block: FlatNode = {
        id: node.id,
        type: node.type
    };

    if (node.comment) {
        block.comment = node.comment;
    }

    if (node.fields) {
        block.fields = { ...node.fields };
    }

    if (node.mutations) {
        block.mutations = { ...node.mutations };
    }

    if (node.values) {
        block.values = {};
        for (const [name, childNode] of Object.entries(node.values)) {
            block.values[name] = childNode.id;
        }
    }
    if (node.statements) {
        block.statements = {};
        for (const [name, childNode] of Object.entries(node.statements)) {
            block.statements[name] = childNode.id;
        }
    }
    if (node.next) {
        block.next = node.next.id;
    }

    return block;
};
