import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { predicate } from 'vx-std';
import Shortcut from './Shortcut';
import style from './style';
import AddShortcut from './AddShortcut';
import EditShortcuts from './EditShortcuts';

import type { ShortcutType } from 'app/graphql/fragment/shortcut';

const useStyles = createUseStyles(style);

interface ShortcutsConfig {
    off: string | RegExp | (RegExp | string)[]

}

interface ShortcutsProps {
    thingId: number;
    subsystemId: string;
    config: ShortcutsConfig
    disabled?: boolean
    shortcuts: Array<ShortcutType>
    status: string;
}

const isOff = (status: string, check: string | RegExp) => {
    if (predicate.isString(check)) {
        return status === check;
    }

    return check.test(status);
};

const isOn = (status: string | null | undefined, config: ShortcutsConfig) => {
    if (!status) {
        return false;
    }

    if (Array.isArray(config.off)) {
        return !config.off.some((off: string | RegExp) => isOff(status, off));
    }

    return !isOff(status, config.off);
};

const getFirstOff = (off: ShortcutsConfig['off']) => {
    if (predicate.isString(off)) {
        return off;
    }

    if (Array.isArray(off)) {
        for (const o of off) {
            if (predicate.isString(o)) {
                return o;
            }
        }
    }

    return 'off';
};

const Shortcuts = ({ thingId, subsystemId, config, disabled, shortcuts, status: currentStatus }: ShortcutsProps) => {
    const classes = useStyles();
    const [editing, setEditing] = React.useState(false);
    return (
        <div className={classes.Shortcuts}>
            {shortcuts?.map(({ id, name, status }) => (
                <Shortcut
                    key={id}
                    name={name}
                    thingId={thingId}
                    subsystemId={subsystemId}
                    id={id}
                    status={!editing && status}
                    disabled={!editing && disabled}
                    editing={editing}
                />
            ))}

            <AddShortcut
                thingId={thingId}
                subsystemId={subsystemId}
                on={currentStatus as string}
                off={getFirstOff(config.off)}
                disabled={disabled || shortcuts?.some(({ status }) => status)}
                hidden={!(isOn(currentStatus, config) && !editing)}
            />

            <EditShortcuts editing={editing} onClick={() => setEditing(!editing)} />
        </div>
    );
};

export default Shortcuts;
