import * as math from './math';
import * as logic from './logic';
import * as loops from './loops';
import * as date_time from './dateTime';
import * as variables from './variables';
import * as procedures from './procedures';

const baseCategories = {
    math,
    logic,
    date_time,
    loops,
    variables,
    procedures
};

export default baseCategories;

