import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import style from './style';

type TableProps = JSX.IntrinsicElements['div'] & {
    children: React.ReactNodeArray;
    columns: number;
    sizes?: Array<number | string | null> | {
        [key: string]: string | number;
    };
};

const useStyles = createUseStyles(style);

const Table = ({ children, className, columns, sizes, ...props }: TableProps, ref: React.Ref<HTMLDivElement>): React.ReactElement<'div'> => {
    const classes = useStyles({ columns, sizes });
    return (
        <div {...props} className={cx(classes.Table, className)} ref={ref}>
            {children}
        </div>
    );
};

export default React.forwardRef(Table);
