import MapOverlay from './MapOverlay';

import type { Node } from '../../types';

export default class Context {
    protected _variables = new Map<string, any>();
    public get variables() {
        return this._variables;
    }

    protected _procedures = new Map<string, Node>();
    public get procedures() {
        return this._procedures;
    }

    public withScope(variables: Map<string, string | number | null>) {
        const scope = Object.create(this.constructor.prototype);
        scope._variables = (new MapOverlay(this.variables)).load(variables);
        scope._procedures = new MapOverlay(this.procedures);
        return scope;
    }
}
