import * as React from 'react';
import { GradientDefs, HorizontalGridLines, LineSeries, VerticalGridLines, XAxis, YAxis } from 'react-vis';
import { formatDateTick } from 'app/helper/graph';
import { Graph } from 'app/component';

import type { SubsystemGraphProps } from 'app/subsystem';

const DimmerGraph = ({ history = [], onOpenSettings }: SubsystemGraphProps<{ brightness: number }>) => {
    const [hoveredNode, hoverNode] = Graph.useHintState();
    return (
        <Graph height={250} yDomain={[0, 110]} onOpenSettings={onOpenSettings} onMouseLeave={() => hoverNode(null)}>
            <GradientDefs>
                <linearGradient
                    id="dimmGradient"
                    gradientUnits="userSpaceOnUse"
                    x1="0" y1="250" x2="0" y2="0"
                >
                    <stop offset="0%" stopColor="gray"/>
                    <stop offset="80%" stopColor="orange"/>
                </linearGradient>
            </GradientDefs>
            <HorizontalGridLines/>
            <VerticalGridLines/>
            <XAxis tickFormat={formatDateTick}/>
            <YAxis tickFormat={v => v + '%'}/>
            <LineSeries
                color={'url(#dimmGradient)'}
                curve="curveStepAfter"
                data={history.map(({ time, data: { brightness: y } }) => ({ x: time.getTime(), y: y || 0 }))}
                onNearestXY={hoverNode}
            />
            {hoveredNode && (
                <Graph.Hint
                    {...hoveredNode}
                    value={`${Math.floor(hoveredNode.value as number)}%`}
                />
            )}
        </Graph>
    );
};

export default DimmerGraph;
