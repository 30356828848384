import * as React from 'react';
import { SketchPicker } from 'react-color';
import { createUseStyles } from 'react-jss';
import { Button, Slider } from 'app/component';
import { ButtonKind } from 'app/component/Button';
import style from './Value.style';

import type { SubsystemValueProps } from 'app/subsystem';

const useStyles = createUseStyles(style);

const breakStatusDown = (status: string) => {
    const chunks = (status || '').split(':');
    let command = chunks.shift();
    if (command === 'effect') {
        command = chunks.shift();
    }

    return chunks.reduce((acc: any, chunk: string) => {
        switch (chunk[0]) {
            case '#':
                acc.color = chunk;
                break;
            case 's':
                acc.speed = parseInt(chunk.slice(1));
                break;
            case 'b':
                acc.brightness = parseInt(chunk.slice(1));
                break;
            default:
                break;

        }
        return acc;
    }, {
        color: '#FFFFFF',
        command,
        speed: 75,
        brightness: 100
    });
};

const buildMessage = (command: string, color: string, speed: number, brightness: number) => {
    switch (command) {
        case 'off':
            return 'off';
        case 'color':
            return `color:${color.toUpperCase()}`;
        default:
            return `effect:${command}:${color.toUpperCase()}:s${speed}:b${brightness}`;

    }
};

const COLOR_COMMANDS = ['off', 'color', 'meteor', 'cylon', 'kitt', 'running', 'chase'];

// eslint-disable-next-line complexity
const Stripe = ({ status: s = 'off', push }: SubsystemValueProps) => {
    const [color, setLocalColor] = React.useState('#000000');
    const classes = useStyles();

    const { color: c, command, speed, brightness } = breakStatusDown(s);
    React.useEffect(() => setLocalColor(c), [c]);

    if (!push) {
        return null;
    }

    return (
        <div className={classes.Container}>
            <div className={classes.Layout}>
                <div className={classes.Colors}>
                    <SketchPicker
                        disableAlpha
                        color={color}
                        onChangeComplete={color => {
                            setLocalColor(color.hex);
                            if (COLOR_COMMANDS.includes(command)) {
                                push(buildMessage(command === 'off' ? 'color' : command, color.hex, speed, brightness));
                            }
                        }}
                        // @ts-ignore
                        styles={{ picker: { padding: 0 } }}
                        presetColors={[
                            '#FF0000', '#D0021B', '#F5A623', '#F8E71C',
                            '#00FF00', '#417505', '#7ED321', '#B8E986',
                            '#0000FF', '#4A90E2', '#50E3C2', '#192328',
                            '#8B572A', '#FF00FF', '#9013FE', '#BD10E0',
                            '#FFFFFF', '#9B9B9B', '#4A4A4A', '#010101'
                        ]}
                    />
                </div>
                <div className={classes.Controls}>
                    <div className={classes.Buttons}>
                        <Button
                            key={`off-${command}`}
                            kind={command === 'off' ? ButtonKind.primary : ButtonKind.lite}
                            onClick={() => push(buildMessage('off', color, speed, brightness))}
                        >
                            OFF
                        </Button>
                        <Button
                            key={`color-${command}`}
                            kind={command === 'color' ? ButtonKind.primary : ButtonKind.lite}
                            onClick={() => push(buildMessage('color', color, speed, brightness))}
                        >
                            Color
                        </Button>
                        <Button
                            key={`fire-${command}`}
                            kind={command === 'fire' ? ButtonKind.primary : ButtonKind.lite}
                            onClick={() => push(buildMessage('fire', color, speed, brightness))}
                        >
                            Fire
                        </Button>
                        <Button
                            key={`rainbow-${command}`}
                            kind={command === 'rainbow' ? ButtonKind.primary : ButtonKind.lite}
                            onClick={() => push(buildMessage('rainbow', color, speed, brightness))}
                        >
                            Rainbow
                        </Button>
                        <Button
                            key={`meteor-${command}`}
                            kind={command === 'meteor' ? ButtonKind.primary : ButtonKind.lite}
                            onClick={() => push(buildMessage('meteor', color, speed, brightness))}
                        >
                            Meteor
                        </Button>
                        <Button
                            key={`eyes-${command}`}
                            kind={command === 'eyes' ? ButtonKind.primary : ButtonKind.lite}
                            onClick={() => push(buildMessage('eyes', color, speed, brightness))}
                        >
                            Eyes
                        </Button>
                        <Button
                            key={`cylon-${command}`}
                            kind={command === 'cylon' ? ButtonKind.primary : ButtonKind.lite}
                            onClick={() => push(buildMessage('cylon', color, speed, brightness))}
                        >
                            Cylon
                        </Button>
                        <Button
                            key={`kitt-${command}`}
                            kind={command === 'kitt' ? ButtonKind.primary : ButtonKind.lite}
                            onClick={() => push(buildMessage('kitt', color, speed, brightness))}
                        >
                            KITT
                        </Button>
                        <Button
                            key={`running-${command}`}
                            kind={command === 'running' ? ButtonKind.primary : ButtonKind.lite}
                            onClick={() => push(buildMessage('running', color, speed, brightness))}
                        >
                            Running
                        </Button>
                        <Button
                            key={`chase-${command}`}
                            kind={command === 'chase' ? ButtonKind.primary : ButtonKind.lite}
                            onClick={() => push(buildMessage('chase', color, speed, brightness))}
                        >
                            Chase
                        </Button>
                    </div>
                    <div className={classes.Slider}>
                        <b>Speed</b> {speed}/100
                        <br/>
                        <Slider
                            min={0}
                            max={100}
                            value={speed}
                            disabled={['off', 'color'].includes(command)}
                            onValueChange={speed => {
                                if (!['off', 'color'].includes(command)) {
                                    push(buildMessage(command, color, speed, brightness));
                                }
                            }}
                        />
                    </div>
                    <div className={classes.Slider}>
                        <b>Brightness:</b> {brightness}/100
                        <br/>
                        <Slider
                            min={0}
                            max={100}
                            value={brightness}
                            disabled={!['rainbow', 'fire', 'chase'].includes(command)}
                            onValueChange={brightness => {
                                if (['rainbow', 'fire', 'chase'].includes(command)) {
                                    push(buildMessage(command, color, speed, brightness));
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Stripe;
