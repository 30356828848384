import { CSS_DESKTOP_BREAKPOINT, CSS_MOBILE_BREAKPOINT } from 'app/theme';

import type { Theme } from 'app/theme';

export default ((theme: Theme) => ({
    Automations: {
        display: 'flex',
        flexDirection: 'column',

        margin: 10,
        padding: 0,

        [CSS_MOBILE_BREAKPOINT]: {
            margin: 0,
            border: 'none',
            padding: 0,
            borderRadius: 0
        }
    },
    Content: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1
    },
    AutomationsDetails: {
        flexGrow: 1,
        flexShrink: 1,

        width: '70%',

        borderTop: theme.box.border,

        display: 'flex',
        flexDirection: 'column'
    },
    AutomationsList: {
        flexGrow: 1,
        flexShrink: 1,

        [CSS_DESKTOP_BREAKPOINT]: {
            width: '30%',
            maxWidth: 320
        },
        [CSS_MOBILE_BREAKPOINT]: {
            width: '100%'
        },

        borderTop: theme.box.border,
        borderRight: theme.box.border,

        '& > * + *': {
            marginTop: 10
        }
    },
    TopRow: {
        flexGrow: 0,
        flexShrink: 0,

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        margin: 10
    },
    AddAutomation: {
        margin: 8,
        color: 'white',
        background: theme.button.success.background,
        borderRadius: 6,
        fontSize: 24,
        cursor: 'pointer',
        opacity: 0.4,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '&:hover': {
            opacity: 0.8
        }
    },
    Outputs: {
        display: 'flex',
        flexGrow: 0,
        flexShrink: 0,
        height: 90,

        '& > div': {
            display: 'flex'
        }
    },
    ModalContent: {
        overflow: 'hidden'
    }
}));
