import gql from 'graphql-tag';

export default gql`
    query ThingStatusQuery($id: ID!) {
        thing(id: $id) {
            id
            status
        }
    }
`;

export type ThingStatusQueryData = {
    thing: {
        id: number;
        status: 'online' | 'offline';
    }
};

