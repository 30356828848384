import gql from 'graphql-tag';
export type { OidcLogoutMutation, OidcLogoutMutationVariables } from 'app/graphql/types.generated';

export default gql`
    mutation OIDCLogout($token: String!) {
        oidc {
            logout(refreshToken: $token)
        }
    }
`;
