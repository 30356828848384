export default {
    Message: {
        pointerEvents: 'all',


        height: 'max-content',

        padding: 10,

        border: {
            width: 1,
            style: 'solid',
            color: '#bbbbbb'
        },

        borderRadius: 6,

        userSelect: 'none',

        boxShadow: {
            x: 2,
            y: 2,
            blur: 10,
            spread: -3
        },

        background: '#ffffff'
    },
    MessageContainer: {
        pointerEvents: 'none',

        position: 'fixed',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',

        top: 60,
        left: 0,
        bottom: 0,
        right: 0,

        padding: 10,

        '& > * + *': {
            marginTop: 10
        },

        zIndex: 2000
    }
};
