import * as React from 'react';
import { useTheme } from 'react-jss';
import Gauge from 'react-svg-gauge';
import { number } from 'vx-std';
import { shadeBetween } from 'app/utils/color';

import type { SubsystemValueProps } from 'app/subsystem';
import type { Theme } from 'app/theme';

const Value = ({ status, mini }: SubsystemValueProps) => {
    const { gauge } = useTheme<Theme>();
    const percent = Math.round(parseFloat(status || '0') * 100) / 100;
    const [max, setMax] = React.useState(5);
    React.useEffect(() => {
        const newMax = Math.max(percent + 2, max);
        if (newMax > max) {
            setMax(newMax);
        }
    }, [percent]);

    return (
        <div style={mini ? { marginTop: 10 } : undefined}>
            <Gauge
                value={percent}
                width={mini ? 100 : 200}
                height={mini ? 67 : 135}
                min={0}
                max={max}
                label="amps/s"
                backgroundColor={gauge.background}
                color={shadeBetween('green', 'red', number.map(0, max, 0, 100)(percent))}
                topLabelStyle={{
                    ...Gauge.defaultProps.topLabelStyle,
                    fill: gauge.topLabel
                }}
                valueLabelStyle={{
                    ...Gauge.defaultProps.valueLabelStyle,
                    fill: gauge.valueLabel,
                    transform: mini ? 'translate(0px, 10px)' : 'translate(0px, 15px)'
                }}
                minMaxLabelStyle={{
                    ...Gauge.defaultProps.minMaxLabelStyle,
                    fill: gauge.minMaxLabel,
                    display: mini ? 'none' : undefined
                }}
            />
        </div>
    );
};

export default Value;
