import * as React from 'react';
import { MOBILE_THRESHOLD } from 'app/theme';

const getWindowSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight
});

export default function useIsMobile() {
    const [size, setSize] = React.useState(getWindowSize);

    React.useLayoutEffect(() => {
        const listener = () => setSize(getWindowSize());

        window.addEventListener('resize', listener);
        return () => {
            window.removeEventListener('resize', listener);
        };
    }, []);

    return size.width < MOBILE_THRESHOLD;
}
