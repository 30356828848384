import TimingBase from './TimingBase';

export default class Timer extends TimingBase<any, any> {

    trigger() {
        this._setUp();
    }

    clear() {
        this._tearDown();
    }

    static init(fn: () => void, timeout: number) {
        return setTimeout(fn, timeout);
    }

    static clear(id: any) {
        clearTimeout(id);
    }

    _preCallback() {
        this._tearDown();
    }
}
