import { predicate } from 'vx-std';

export const numerize = (input: string | number): string | number => {
    if (predicate.isNumber(input)) {
        return input;
    }
    if (isNaN(input as any)) {
        return input;
    }
    if (input !== '') {
        return parseFloat(input);
    }

    return input;
};
