import gql from 'graphql-tag';
import thingBase from '../fragment/thing-base';

import type { ThingBaseType } from '../fragment/thing-base';

export default gql`
    query Things {
        me {
            id
            things {
                ...thingBase
            }
        }
    }
    ${thingBase}
`;

export type ThingsQueryData = {
    me: {
        id: number,
        things: Array<ThingBaseType>
    }
};
