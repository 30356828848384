export default {
    Status: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        userSelect: 'none'
    },
    Value: {
        flexShrink: 0,
        flexGrow: 0,

        padding: 5,
        fontSize: 28
    },
    Graph: {
        flexGrow: 1,
        flexShrink: 1
    }
};
