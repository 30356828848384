import apollo from 'app/connector/graphql';
import QUERY_THING_STATUS from 'app/graphql/query/thingStatus';

import { CATEGORY_INPUT_COLOR } from './constants';

import type { Interpreter, Node, Block } from 'app/component/Blockly/types';
import type { ThingStatusQueryData } from 'app/graphql/query/thingStatus';
import type { FlatThing } from '../context';

export const name = 'input_current_device_status';

export function builder(this: Block) {
    this.appendValueInput('device')
        .setCheck('IotDevice')
        .appendField('${device_status}');
    this.setColour(CATEGORY_INPUT_COLOR);
    this.setTooltip('');
    this.setHelpUrl('');
    this.setOutput(true, 'Boolean');
}

export const StatusMark = Symbol('status');
export default async function run(this: Interpreter, node: Node): Promise<boolean> {
    const deviceNode = node.values?.device;
    if (!deviceNode) {
        throw new Error('device missing');
    }

    const device: FlatThing = await this.execute(deviceNode);

    const result = await apollo.query<ThingStatusQueryData>({
        query: QUERY_THING_STATUS,
        variables: {
            id: device.id
        },
        fetchPolicy: 'network-only'
    });

    const status = new Boolean(result.data.thing.status === 'online');
    // @ts-ignore
    status[StatusMark] = true;
    // @ts-ignore
    return status;
}
