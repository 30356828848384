import gql from 'graphql-tag';

export default gql`
    subscription OnThingStatus($id: String!) {
        onThingStatus(id: $id) {
            id
            status
        }
    }
`;
