import gql from 'graphql-tag';
export type { OidcExchangeMutation, OidcExchangeMutationVariables } from 'app/graphql/types.generated';

export default gql`
    mutation OIDCExchange($code: String!) {
        oidc {
            exchange(code: $code) {
                token
                refresh
            }
        }
    }
`;
