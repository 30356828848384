import { MOBILE_THRESHOLD } from 'app/theme';
import type { Theme } from 'app/theme';

const dotSize = 6;

export default (theme: Theme) => ({
    Shortcut: {
        position: 'relative',
        height: 35,
        background: theme.box.background,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        borderLeft: theme.box.border,
        borderBottom: theme.box.border,
        borderRight: theme.box.border,

        userSelect: 'none',
        cursor: 'pointer',

        display: 'flex',
        '& > *': {
            lineHeight: '35px'
        },
        '& > *:first-child': {
            paddingLeft: 10
        },
        '& > *:last-child': {
            paddingRight: 10,
            borderBottomRightRadius: 8
        },
        flexShrink: 0,

        [`@media screen and (max-width: ${MOBILE_THRESHOLD - 1}px)`]: {
            marginTop: 5,
            marginBottom: 5,
            width: '100%',

            '&:before': {
                content: '""',
                position: 'absolute',
                top: `calc(50% - ${dotSize / 2 + 1}px)`,
                left: -dotSize - 3,
                width: dotSize,
                height: dotSize,

                borderRadius: '100%',
                background: theme.box.background,
                border: theme.box.border
            },
            '& > *:last-child': {
                borderTopRightRadius: 8
            }
        }
    } as any,
    Disabled: {
        background: theme.box.border.color,
        color: 'white',
        pointerEvents: 'none'
    },
    Wrapped: {
        marginTop: 5,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8
    }
});
