import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { EntypoCheck, EntypoPencil } from 'react-entypo-icons';
import cx from 'classnames';
import ShortcutBase from './ShortcutBase';
import style from './EditShortcuts.style';

import type { ShortcutBaseProps } from './ShortcutBase';

type EditShortcutsProps = ShortcutBaseProps & {
    editing?: boolean;
}

const useStyles = createUseStyles(style);

const EditShortcuts = ({ editing, className, onClick, ...props }: EditShortcutsProps) => {
    const classes = useStyles();
    return (
        <ShortcutBase
            {...props}
            className={cx(className, classes.Shortcut, editing && classes.Editing)}
        >
            <div onClick={onClick}>{editing ? <EntypoCheck /> : <EntypoPencil />}</div>
        </ShortcutBase>
    );
};

export default EditShortcuts;
