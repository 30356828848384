import Color from 'color';

export const prefersDarkTheme = ((): boolean => {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
})();

export const shadeBetween = (from: string, to: string, step: number) => {
    const [fromR, fromG, fromB] = Color(from).array();
    const [toR, toG, toB] = Color(to).array();
    const diffR = (toR - fromR) / 100;
    const diffG = (toG - fromG) / 100;
    const diffB = (toB - fromB) / 100;

    return Color([Math.round(fromR + diffR * step), Math.round(fromG + diffG * step), Math.round(fromB + diffB * step)]).hex();
};


export const colorBrightness = (color: string, factor = 0): string => {
    if (factor > 0) {
        return new Color(color).lighten(factor).hex();
    } else if (factor < 0) {
        return new Color(color).darken(-factor).hex();
    }

    return color;
};
export const saturation = (color: string, factor = 0): string => {
    return new Color(color).saturationl(factor).hex();
};

export const gradientStyleFromColor = (color: string, factor = 0.2): string => {
    const bgColor = new Color(color);
    return `linear-gradient(to bottom, ${bgColor.lighten(factor).hex()}, ${bgColor.darken(factor).hex()})`;
};
