import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { Button } from 'app/component';
import { ButtonKind } from 'app/component/Button';
import sanitizeNode from 'app/component/Blockly/json/sanitizeNode';
import { useIsBlocklyDirty } from 'app/component/Blockly/useBlockly';
import useLockOnPage from 'app/hook/useLockOnPage';

import TriggerSelect from '../TriggerSelect';

import Blockly from './blockly';
import useSetAutomationActionsMutation from './useSetAutomationActionsMutation';
import useSetAutomationTriggerMutation from './useSetAutomationTriggerMutation';
import style from './style';

import type { ThingType } from 'app/graphql/fragment/thing';
import type { AutomationTrigger } from 'app/graphql/fragment/automation';
import type { FlatNode } from './blockly';


type AutomationViewProps = {
    id: string;
    trigger: AutomationTrigger;
    actions: FlatNode[];
    things: ThingType[];
    history?: any;
};

const useStyles = createUseStyles(style);

const AutomationView = ({ id, trigger, actions, things, history }: AutomationViewProps) => {
    const classes = useStyles();
    const [t] = useTranslation('automations');
    const [newActions, setActions] = React.useState<FlatNode[]>(() => (actions || []).map(sanitizeNode));
    const [newTrigger, setTrigger] = React.useState<AutomationTrigger>(trigger);
    const [tracerOpen, setTracerOpen] = React.useState(false);
    const updateActions = useSetAutomationActionsMutation();
    const updateTrigger = useSetAutomationTriggerMutation();

    const isDirty = useIsBlocklyDirty(actions, newActions);
    useLockOnPage(t('unsaved-changes')).lock(isDirty);
    React.useEffect(() => {
        if (isDirty) {
            return history.block(isDirty ? t('unsaved-changes') : undefined);
        }
    }, [history, isDirty]);

    return (
        <>
            <div className={classes.Top}>
                <div className={classes.Label}>
                    {t('view.trigger')}:
                </div>
                <TriggerSelect
                    value={newTrigger}
                    onChange={setTrigger}
                />
                <Button
                    kind={ButtonKind.lite}
                    active={tracerOpen}
                    onClick={() => setTracerOpen(!tracerOpen)}
                    className={classes.TracerButton}
                >
                    {t('tracer')}
                </Button>
            </div>
            <Blockly
                value={newActions}
                className={classes.Playground}
                onChange={setActions}
                things={things}
                tracerOpen={tracerOpen}
                toolboxAppendixContainerClass={classes.SaveContainer}
                toolboxAppendix={(
                    <Button
                        disabled={!(isDirty || trigger.type !== newTrigger.type || trigger.config !== newTrigger.config)}
                        onClick={async () => {
                            await updateTrigger(id, newTrigger);
                            await updateActions(id, newActions);
                        }}
                    >
                        {t('view.save')}
                    </Button>
                )}
            />
        </>
    );
};

export default withRouter(AutomationView as any) as any as typeof AutomationView;
