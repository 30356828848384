import gql from 'graphql-tag';

export default gql`
    mutation RoomThing($id: ID!, $roomId: ID) {
        thing(id: $id) {
            setRoom(roomId: $roomId) {
                id
                room {
                    id
                    name
                }
            }
        }
    }
`;
