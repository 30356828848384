import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
// @ts-ignore
import Thermometer from 'react-thermometer-component';

import { prefersDarkTheme } from 'app/utils/color';

import style from './Value.style';

import type { SubsystemValueProps } from 'app/subsystem';

const useStyles = createUseStyles(style);

const Value = ({ status, mini }: SubsystemValueProps) => {
    const classes = useStyles();
    return (
        <div className={cx(classes.Thermometer, mini && classes.Mini)}>
            <Thermometer
                theme={prefersDarkTheme ? 'dark' : 'light'}
                value={status || 0}
                max="50"
                min="-30"
                format="°C"
                size="normal"
                height="220"
            />
        </div>
    );
};

export default Value;
