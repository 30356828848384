import { CSS_DESKTOP_BREAKPOINT, CSS_MOBILE_BREAKPOINT } from 'app/theme';

import type { Theme } from 'app/theme';

export default ({ window, box }: Theme) => ({
    Blockly: {
        position: 'relative',

        '& .blocklyMainBackground': {
            stroke: 'none'
        },
        /* Makes our label white. */
        '& .blocklyTreeLabel': {
            color: 'white'
        },
        /* Adds padding around the group of categories and separators. */
        '& .blocklyToolboxContents': {
            padding: '0.5em'
        },
        /* Adds space between the categories, rounds the corners and adds space around the label. */
        '& .blocklyTreeRow': {
            padding: 5,
            marginBottom: 0,
            borderRadius: 6,
            height: 'initial'
        }
    },
    LogContainer: {
        position: 'absolute',
        top: 0,
        right: 0,

        maxHeight: '100%',
        width: 600,

        background: box.background,

        [CSS_MOBILE_BREAKPOINT]: {
            width: '100vw',
            height: '100%'
        },
        [CSS_DESKTOP_BREAKPOINT]: {
            height: 'calc(100% - 100px)',
            maxWidth: '40%',

            border: window.border,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderTop: box.border,
            borderRightWidth: 0
        },

        zIndex: 100
    }
});
