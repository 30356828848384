import * as React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { Popconfirm } from 'app/component';
import { getDisplayName } from 'app/utils/react';

type WithPopconfirmProps = {
    message?: React.ReactNode;
    confirmKind?: string;
    confirmLabel?: string;
    cancelKind?: string;
    cancelLabel?: string;
};

type OptionsType = {
    trigger?: string;
    stopPropagation?: boolean;
};

export default <C extends React.ComponentType<any>>(Component: C, { trigger = 'onClick', stopPropagation }: OptionsType = {}) => {
    class WithPopconfirm extends React.Component<React.ComponentProps<C> & WithPopconfirmProps> {

        static displayName = `WithPopconfirm(${getDisplayName(Component)})`;

        state = {
            isOpen: false
        };

        constructor(...args: any[]) {
            // @ts-ignore
            super(...args);

            this.handleConfirm = this.handleConfirm.bind(this);
        }

        handleConfirm(...args: any) {
            this.setState({ isOpen: false });
            if (this.props[trigger]) {
                this.props[trigger](...args);
            }
        }

        render() {
            const {
                message,
                confirmKind,
                confirmLabel,
                cancelKind,
                cancelLabel,
                ...props
            } = this.props;
            return (
                <Popconfirm
                    message={message} confirmKind={confirmKind} confirmLabel={confirmLabel}
                    cancelKind={cancelKind} cancelLabel={cancelLabel} isOpen={this.state.isOpen}
                    onCancel={() => this.setState({ isOpen: false })} onConfirm={this.handleConfirm}
                >
                    {React.createElement(Component, {
                        ...props,
                        [trigger]: (e: React.SyntheticEvent) => {
                            if (e && stopPropagation) {
                                e.nativeEvent.stopImmediatePropagation();
                                e.stopPropagation();
                            }
                            this.setState({ isOpen: true });
                        }
                    })}
                </Popconfirm>
            );
        }
    }

    return hoistStatics(WithPopconfirm, Component, { contextType: true });
};
