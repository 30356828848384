import gql from 'graphql-tag';
import thing from '../fragment/thing';
import type { ThingType } from '../fragment/thing';

export default gql`
    query ThingQuery($id: ID!) {
        thing(id: $id) {
            ...thing
        }
    }
    ${thing}
`;

export type ThingQueryData = {
    thing: ThingType
};

