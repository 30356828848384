import * as React from 'react';

import * as demo from './demo';
import * as dimmer from './dimmer';
import * as humitemp from './humitemp';
import * as pirhumitemp from './pirhumitemp';
import * as servo from './servo';
import * as sw from './switch';
import * as stripe from './stripe';
import * as acmeter from './acmeter';
import * as outside from './outside';

export { demo, dimmer, humitemp, pirhumitemp, servo, sw as switch, stripe, acmeter, outside };
const things = { demo, dimmer, humitemp, pirhumitemp, servo, switch: sw, stripe, acmeter, outside };

type ThingModuleType = {
    Icon(props: { type: string, name: string, status: 'online' | 'offline' }): JSX.Element
};

interface IconProps {
    type: string;
}

const defaultThing: ThingModuleType = {
    Icon({ type }: IconProps) {
        return <span>{type}</span>;
    }
};

export type ThingTypeType = keyof typeof things;

export default ((type: ThingTypeType): ThingModuleType => {
    if (type in things) {
        return things[type];
    }

    return defaultThing;
});
