import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Query } from '@apollo/client/react/components';
import { Button, Title, Window } from 'app/component';
import { ButtonKind } from 'app/component/Button';
import { FormButton, FormFeedback, MutationForm, SelectField } from 'app/composition/Form';
import { createWithModal } from 'app/hoc';
import ROOM_THING_MUTATION from 'app/graphql/mutation/thing/setRoom';
import ROOMS_QUERY from 'app/graphql/query/rooms';
import style from './ChangeRoom.style';

import type { QueryResult } from '@apollo/client';
import type { RoomsQueryData } from 'app/graphql/query/rooms';

const { withModal, withTrigger } = createWithModal();


type ChangeRoomProps = {
    close: () => void;
    id?: number;
    roomId?: string;
};

const useStyles = createUseStyles(style);

const ChangeRoom = ({ close, id, roomId }: ChangeRoomProps) => {
    const classes = useStyles();
    const [t] = useTranslation('thing');

    return (
        <MutationForm
            mutation={ROOM_THING_MUTATION}
            onSubmitSuccess={close} variables={{ id }}
            initialValues={{ roomId }}
            touchOn="blur"
        >
            <Window
                className={classes.Window}
                contentClassName={classes.Content}
                title={<Title>{t('change-room.title')}</Title>}
            >
                <Query query={ROOMS_QUERY}>
                    {({ data, loading }: QueryResult<RoomsQueryData>) => (
                        <SelectField
                            name="roomId"
                            keyProp="id"
                            className={classes.Select}
                            loading={loading || !data || !data.me}
                        >
                            {(data?.me?.homes || []).reduce((options, home) => {
                                options.push(
                                    <div
                                        key={'home-' + home.id}
                                        className={classes.Home}
                                    >
                                        {t('change-room.home')}: {home.name}
                                    </div>
                                );

                                return home.rooms.reduce((options, room) => {
                                    options.push(<div key={'room-' + room.id} id={String(room.id)}>{room.name}</div>);

                                    return options;
                                }, options);
                            }, [<div key="null">{t('change-room.no-room')}</div>] as React.ReactChild[])}
                        </SelectField>
                    )}
                </Query>
                <FormFeedback/>
                <Window.ButtonFooter>
                    <FormButton kind={ButtonKind.primary} type="submit">
                        {t('change-room.change')}
                    </FormButton>
                    <Button kind={ButtonKind.lite} onClick={close}>
                        {t('change-room.cancel')}
                    </Button>
                </Window.ButtonFooter>
            </Window>
        </MutationForm>
    );
};

export default withModal(ChangeRoom);
export const withChangeRoomTrigger = withTrigger;
