import * as React from 'react';
import { EntypoCircleWithCross } from 'react-entypo-icons';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import style from './TitleWithCloseButton.style';

type TitleWithCloseButtonProps = JSX.IntrinsicElements['div'] & {
    onCloseClick?: (arg0: React.MouseEvent<any>) => void;
};

const useStyles = createUseStyles(style);

const TitleWithCloseButton = ({ children, className, onCloseClick, ...props }: TitleWithCloseButtonProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return (
        <div {...props} className={cx(classes.Title, className)} ref={ref}>
            {children}
            <div className={classes.Close}>
                <EntypoCircleWithCross onClick={onCloseClick}/>
            </div>
        </div>
    );
};

export default React.forwardRef(TitleWithCloseButton);
