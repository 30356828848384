import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { predicate } from 'vx-std';
import style from './style';

type ColonnadeProps = React.ComponentProps<'div'> & {
    columnClassName?: string;
    width: number;
    className?: string;
};

const getWidth = (ref: React.RefObject<HTMLElement>): number => {
    if (predicate.isFunction(ref || predicate.isString(ref))) {
        throw new Error('Old fashioned (string, callback) ref not supported!');
    }

    return ref?.current?.getBoundingClientRect().width || 0;
};

const useStyles = createUseStyles(style);

const Colonnade = ({ children, className, columnClassName, width, ...props }: ColonnadeProps, eRef: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();

    const internalRef = React.useRef<HTMLDivElement>();
    const ref = eRef || internalRef;

    const [initialized, setInitialized] = React.useState(false);
    const [columns, setColumns] = React.useState(0);

    const handleResize = React.useCallback(() => {
        const newColumns = Math.floor(getWidth(ref as React.RefObject<HTMLElement>) / width) || 1;
        if (newColumns !== columns) {
            setColumns(newColumns);
            setInitialized(true);
        }
    }, [ref, columns, width]);

    React.useLayoutEffect(() => {
        const callback = handleResize;
        window.addEventListener('resize', callback);
        setTimeout(callback);
        return () => {
            window.removeEventListener('resize', callback);
        };
    }, [handleResize]);

    React.useLayoutEffect(handleResize);

    return (
        <div {...props} className={cx(classes.Colonnade, className)} ref={ref as React.Ref<HTMLDivElement>}>
            {initialized && Array(columns).fill(0).map((_, i: number) => (
                <div key={`col-${i + 1}-of-${columns}`} className={columnClassName} style={{ width: `${100 / columns}%` }}>
                    {React.Children.toArray(children).filter((_, j) => j % columns === i)}
                </div>
            ))}
        </div>
    );
};

export default React.forwardRef(Colonnade);
