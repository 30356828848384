export default {
    Arrow: {
        position: 'absolute',

        userSelect: 'none',
        width: 5,
        height: 5,

        borderStyle: 'solid',
        borderWidth: [5, 5, 0, 5],
        borderColor: ['#222222', 'transparent', 'transparent', 'transparent'],
        boxShadow: {
            x: 1,
            y: 1,
            blur: 10,
            spread: -5,
            color: '#000000'
        }
    }
};
