import type { Theme } from 'app/theme';

export default ((theme: Theme) => ({
    Box: {
        background: theme.box.background,
        padding: {
            top: 5,
            left: 5,
            right: 5,
            bottom: 5
        },
        borderRadius: 20,
        border: theme.box.border,
        overflow: 'hidden'
    }
}));
