import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { withRouter } from 'react-router-dom';
import { Button, Centered, Loader, Title, Window } from 'app/component';
import { ButtonKind } from 'app/component/Button';
import Feedback from 'app/composition/Form/Feedback';
import { ChallengeKind } from 'app/graphql/mutation/oidc/challenge';
import { oidc } from 'app/hook';
import style from './style';
import type { RouteComponentProps } from 'react-router';

const useStyles = createUseStyles(style);

const Login = ({ history }: RouteComponentProps): React.ReactElement<typeof Centered> => {
    const classes = useStyles();
    const [t] = useTranslation('login');

    const challenge = oidc.useChallenge(ChallengeKind.User);
    const { loading, error } = oidc.useExchange(history, '/oidc-auth', true);

    return (
        <Centered className={classes.Wrapper}>
            <Loader className={classes.BGLoader} size="100%" animate={false}/>
            <Window
                className={classes.Window}
                contentClassName={classes.Content}
                title={<Title>IOT :: {t('caption.login')}</Title>}
            >
                {loading && <Loader flat />}
                <Feedback errors={[error]} />

                <Window.ButtonFooter>
                    <Button autoFocus type="button" kind={ButtonKind.success} onClick={challenge} disabled={loading}>
                        {t('button.login')}
                    </Button>
                </Window.ButtonFooter>
            </Window>
        </Centered>
    );
};

export default withRouter(Login);
