import type { Interpreter, Node } from '../../types';
import type { BlockDefinition } from '../types';

export const definition: BlockDefinition = {
    message0: '%1 %2 %3',
    args0: [
        {
            type: 'input_value',
            name: 'A'
        },
        {
            type: 'field_dropdown',
            name: 'OP',
            options: [
                ['=', 'EQ'],
                ['\u2260', 'NEQ'],
                ['\u200F<', 'LT'],
                ['\u200F\u2264', 'LTE'],
                ['\u200F>', 'GT'],
                ['\u200F\u2265', 'GTE']
            ]
        },
        {
            type: 'input_value',
            name: 'B'
        }
    ],
    inputsInline: true,
    output: 'Boolean',
    style: 'logic_blocks',
    extensions: ['logic_compare']
};


const logicCompare = (a: number, op: string, b: number): boolean => {
    switch (op) {
        case 'EQ':
            return a == b;
        case 'NEQ':
            return a != b;
        case 'LT':
            return a < b;
        case 'GT':
            return a > b;
        case 'LTE':
            return a <= b;
        case 'GTE':
            return a >= b;
        default:
            throw new Error('Invalid operation `' + op + '`');
    }
};

export default async function run(this: Interpreter, node: Node): Promise<boolean> {
    const aNode = node.values?.A;
    if (!aNode) {
        throw new Error('a missing');
    }

    const bNode = node.values?.B;
    if (!bNode) {
        throw new Error('b missing');
    }

    const operation = node.fields?.OP;
    if (!operation) {
        throw new Error('operation missing');
    }

    const a = await this.execute(aNode);
    const b = await this.execute(bNode);

    return logicCompare(a, operation as string, b);
}

