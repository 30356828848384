// @ts-ignore
import DarkTheme from '@blockly/theme-dark';
import { gradientStyleFromColor } from 'app/utils/color';

export default {
    blocklyTheme: DarkTheme,
    background: '#2c2c2c',
    color: '#dbdbdb',
    font: {
        base: 'Montserrat',
        heading: 'Quicksand'
    },
    input: {
        fontSize: 13,
        color: '#dbdbdb',
        background: '#44484a',
        border: {
            radius: 4,
            width: 1,
            style: 'solid',
            color: '#626262'
        }
    },
    button: {
        default: {
            color: 'white'
        },
        primary: {
            background: '#337ab7'
        },
        success: {
            background: '#428f42'
        },
        danger: {
            background: '#d43f3a'
        },
        lite: {
            color: '#b5b5b5',
            background: '#44484a'
        }
    },
    error: {
        fontSize: 13,
        color: '#d43f3a'
    },
    warning: {
        fontSize: 13,
        color: '#cb8b17'
    },
    window: {
        background: '#3b3f41',
        border: {
            radius: 6,
            width: 1,
            style: 'solid',
            color: '#525252'
        }
    },
    box: {
        background: '#323436',
        attention: '#473939',
        border: {
            width: 1,
            style: 'solid',
            color: '#424242'
        }
    },
    navigation: {
        background: gradientStyleFromColor('#20252d', 0.15),
        height: 60,
        dropdown: {
            background: '#20252d'
        }
    },
    link: {
        color: '#337ab7'
    },
    menu: {
        background: {
            active: '#4f565d',
            hover: '#545454'
        }
    },
    thing: {
        icon: {
            // eslint-disable-next-line max-len
            background: 'rgba(0, 0, 0, 0) linear-gradient(to bottom right, rgba(155, 155, 155, 0) 0%, rgba(100, 100, 100, 0.6) 100%) repeat scroll 0 0',
            color: 'rgba(220,220,220,0.65)',
            footerColor: '#252525',
            borderColor: '#5d5d5d'
        },
        status: {
            offline: 'rgba(171,67,67,0.85)',
            online: 'rgba(70,145,70,0.85)'
        }
    },
    graph: {
        grid: '#5d5d5d'
    },
    gauge: {
        background: '#2c2c2c',
        topLabel: '#dbdbdb',
        valueLabel: '#dbdbdb',
        minMaxLabel: '#dbdbdb'
    },
    loader: {
        color: 'rgba(43,107,204,0.67)',
        border: {
            color: 'rgba(168,205,255,0.67)',
            style: 'solid',
            width: 1
        }
    }
};
