import * as React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { Tooltip } from 'app/component';
import { getDisplayName } from 'app/utils/react';
import Timer from '../utils/Timer';

type WithTooltipProps = {
    tooltip?: React.ReactNode;
    elementRef?: React.Ref<any>;
    placement?: string;
};

// eslint-disable-next-line max-len
export default <C extends React.ComponentType<any> | React.ElementType>(Component: C, triggerOn = 'onMouseEnter', triggerOff = 'onMouseLeave') => {
    class WithTooltip extends React.Component<React.ComponentProps<C> & WithTooltipProps> {

        static displayName = `WithTooltip(${getDisplayName(Component)})`;

        state = {
            isOpen: false
        };

        constructor(...args: any) {
            // @ts-ignore
            super(...args);

            this.handleTriggerOn = this.handleTriggerOn.bind(this);
            this.handleTriggerOff = this.handleTriggerOff.bind(this);
            this.handleTooltipOn = this.handleTooltipOn.bind(this);
            this.handleTooltipOff = this.handleTooltipOff.bind(this);
        }

        _closeTimer = new Timer(250, this.setState, [{ isOpen: false }], this);
        // todo: jak touch to od razu ;)
        _openTimer = new Timer(1000, this.setState, [{ isOpen: true }], this);

        _handleOpen() {
            this._closeTimer.clear();
            if (!this.state.isOpen) {
                this._openTimer.trigger();
            }
        }

        _handleClose() {
            this._openTimer.clear();
            this._closeTimer.trigger();
        }

        handleTriggerOn(...args: any) {
            this._handleOpen();
            if (this.props[triggerOn]) {
                return this.props[triggerOn](...args);
            }
        }

        handleTriggerOff(...args: any) {
            this._handleClose();
            if (this.props[triggerOff]) {
                return this.props[triggerOff](...args);
            }
        }

        handleTooltipOn() {
            this._handleOpen();
        }

        handleTooltipOff() {
            this._handleClose();
        }

        render() {
            const { tooltip, elementRef, placement, ...props } = this.props;
            return (
                <Tooltip
                    tooltip={<div onMouseEnter={this.handleTooltipOn} onMouseLeave={this.handleTooltipOff}>{tooltip}</div>}
                    isOpen={!!tooltip && this.state.isOpen}
                    ref={elementRef}
                    placement={placement}
                >
                    {React.createElement(Component, ({
                        ...props,
                        [triggerOn]: this.handleTriggerOn,
                        [triggerOff]: this.handleTriggerOff
                    } as any))}
                </Tooltip>
            );
        }
    }

    const forwardRef = <C extends React.ComponentType<any>>(Component: C, prop = 'elementRef') => (
        // eslint-disable-next-line react/display-name
        React.forwardRef((props: React.ComponentProps<C>, ref) => React.createElement(Component, {
            ...props,
            [prop]: ref
        })));

    return forwardRef(hoistStatics(WithTooltip, Component as React.ComponentType<any>, { contextType: true }));
};
