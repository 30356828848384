import gql from 'graphql-tag';

export default gql`
    query Rooms {
        me {
            id
            homes {
                id
                name
                rooms {
                    id
                    name
                }
            }
        }
    }
`;

export type RoomType = {
    id: number,
    name: string
};

export type HomeType = {
    id: number,
    name: string,
    rooms: Array<RoomType>
};

export type RoomsQueryData = {
    me: {
        id: number,
        homes: Array<HomeType>
    }
};

