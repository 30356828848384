import type { FlatNode } from '../types';

export default function sanitizeNode(input: FlatNode): FlatNode {
    const node: FlatNode = {
        id: input.id,
        type: input.type
    };

    if (input.mutations) {
        node.mutations = input.mutations;
    }
    if (input.fields) {
        node.fields = input.fields;
    }
    if (input.comment) {
        node.comment = input.comment;
    }
    if (input.fields) {
        node.fields = input.fields;
    }
    if (input.values) {
        node.values = input.values;
    }
    if (input.statements) {
        node.statements = input.statements;
    }

    if (input.next) {
        node.next = input.next;
    }

    return node;
}
