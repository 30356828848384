import type { Interpreter, Node } from '../../types';
import type { BlockDefinition } from '../types';

export const definition: BlockDefinition = {
    message0: '%1',
    args0: [{
        type: 'field_number',
        name: 'NUM',
        value: 0
    }],
    output: 'Number',
    style: 'math_blocks'
};

export default function run(this: Interpreter, node: Node): number {
    const number = node.fields?.NUM;
    if (!number && number !== 0 && number !== '0') {
        throw new Error('number missing');
    }

    return parseFloat(number.toString());
}
run.noTrace = true;
