import { MOBILE_THRESHOLD } from 'app/theme';
import type { Theme } from 'app/theme';

const size = 50;

export default (theme: Theme) => ({
    Shortcut: {
        [`@media screen and (max-width: ${MOBILE_THRESHOLD - 1}px)`]: {
            order: -1,
            width: 'calc(50% - 2.5px)',
            marginRight: [0, '!important'],

            background: ['transparent', '!important'],
            border: 'none',


            height: size + 2,
            '& > *, & > *:last-child': {
                width: size,
                height: size,
                lineHeight: `${size}px`,
                textAlign: 'center',
                borderRadius: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
                fontSize: 25,

                paddingTop: 1,

                background: theme.button.primary.background,
                color: theme.button.default.color,
                border: theme.window.border
            }
        }
    },

    Editing: {
        background: theme.button.success.background,
        color: theme.button.default.color,

        [`@media screen and (max-width: ${MOBILE_THRESHOLD - 1}px)`]: {
            '& > *, & > *:last-child': {
                background: theme.button.success.background
            }
        }
    }
});
