import * as React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import Item from './Item';
import style from './style';

const useStyles = createUseStyles(style);

const Menu = ({ children, className, ...props }: JSX.IntrinsicElements['div'], ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return (
        <div {...props} className={cx(classes.Menu, className)} ref={ref}>
            {children}
        </div>
    );
};

type RefMenuType = typeof Menu & {
    Item: typeof Item
};

const RefMenu = (React.forwardRef(Menu) as any) as RefMenuType;

RefMenu.Item = Item;

export default RefMenu;
export { Item };
