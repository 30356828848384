import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import Button, { ButtonKind } from '../../Button';
import Radio from '../../Radio';
import Checkbox from '../../Checkbox';
import Select from '../../Select';
import NumEntry from '../NumEntry';
import Rule from '../Rule';
import style from './style';
import { RuleMode, makeActionCreators, makeInitialState, makeReducer, stateToExpression } from './state';

type DaysProps = Omit<React.ComponentProps<'div'>, 'onChange'> & {
    close?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    value?: string;
    onChange?: (value: string) => void;
};

type DayOfWeekBase = 1 | 2 | 3 | 4 | 5 | 6 | 7;
type DayOfWeek = DayOfWeekBase | '1L' | '2L' | '3L' | '4L' | '5L' | '6L' | '7L';

interface DayOfWeekEntryProps extends Omit<React.ComponentProps<'div'>, 'id'> {
    id: DayOfWeek
}

const useStyles = createUseStyles(style);

const dayOfWeek: DayOfWeekBase[] = [1, 2, 3, 4, 5, 6, 7];
const actions =  makeActionCreators<DayOfWeek>();
const reducer =  makeReducer<DayOfWeek>();

function DayOfWeekEntry({ id, ...props }: DayOfWeekEntryProps) {
    return <div id={String(id)} {...props} />;
}

// eslint-disable-next-line complexity
const DaysOfWeek = ({ className, onChange, value, close, ...props }: DaysProps, ref?: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    const [t] = useTranslation('components');
    const [state, dispatch] = React.useReducer(reducer, value, makeInitialState);

    React.useEffect(() => {
        onChange?.(stateToExpression(state));
    }, [state]);

    return (
        <div {...props} ref={ref} className={cx(classes.Container, className)}>
            <div className={cx(classes.All, state.all || classes.Hidden)}>
                {t('cron.day-of-week.all')}
            </div>
            <div className={classes.Rules}>
                {state.rules.map((rule, index) => (
                    <Rule id={index + 1} onDelete={() => dispatch(actions.removeRule(index))} key={`${index}-${rule.mode}`} >
                        <div className={classes.Mode}>
                            <Radio
                                checked={rule.mode === RuleMode.every}
                                onChange={() => dispatch(actions.setMode(index, RuleMode.every))}
                            >
                                {t('cron.day-of-week.every')}
                                {' '}
                                <Select
                                    disabled={rule.mode !== RuleMode.every}
                                    value={rule.every || ''}
                                    onSelect={(value) => dispatch(actions.setEvery(index, value))}
                                >
                                    {dayOfWeek.slice(1).map((i) => (
                                        <NumEntry key={i} id={i}>{i}</NumEntry>
                                    ))}
                                </Select>
                            </Radio>
                            {' '}
                            {t('cron.day-of-week.days')}
                        </div>
                        <div className={classes.Mode}>
                            <Radio
                                checked={rule.mode === RuleMode.specific}
                                onChange={() => dispatch(actions.setMode(index, RuleMode.specific))}
                            >
                                {t('cron.day-of-week.specific')}
                            </Radio>
                            <div className={cx(classes.ShortGrid, rule.mode !== RuleMode.specific && classes.Hidden)}>
                                {dayOfWeek.map((i) => (
                                    <Checkbox
                                        key={i}
                                        disabled={rule.mode !== RuleMode.specific}
                                        checked={rule.specific.includes(i) || rule.specific.includes(i + 'L' as DayOfWeek)}
                                        onChange={() => {
                                            if (rule.specific.includes(i + 'L' as DayOfWeek)) {
                                                dispatch(actions.toggleSpecific(index, i + 'L' as DayOfWeek));
                                            } else {
                                                dispatch(actions.toggleSpecific(index, i));
                                            }
                                        }}
                                    >
                                        {t('cron.day-names.' + i)}
                                        <br />
                                        <Checkbox
                                            key={i}
                                            disabled={
                                                rule.mode !== RuleMode.specific ||
                                                !(rule.specific.includes(i) || rule.specific.includes(i + 'L' as DayOfWeek))
                                            }
                                            checked={rule.specific.includes(i + 'L' as DayOfWeek)}
                                            onChange={() => {
                                                dispatch(actions.toggleSpecific(index, i));
                                                dispatch(actions.toggleSpecific(index, i + 'L' as DayOfWeek));
                                            }}
                                        >
                                            ({t('cron.day-of-week.last')})
                                        </Checkbox>
                                    </Checkbox>
                                ))}
                            </div>
                        </div>
                        <div className={classes.Mode}>
                            <Radio
                                checked={rule.mode === RuleMode.between}
                                onChange={() => dispatch(actions.setMode(index, RuleMode.between))}
                            >
                                {t('cron.day-of-week.between')}
                                {' '}
                                <Select
                                    disabled={rule.mode !== RuleMode.between}
                                    value={rule.between.min || ''}
                                    onSelect={(value) => dispatch(actions.setBetweenMin(index, value))}
                                >
                                    {dayOfWeek.map((i) => (
                                        <DayOfWeekEntry key={i} id={i}>{t('cron.day-names.' + i)}</DayOfWeekEntry>
                                    ))}
                                </Select>
                                {' '}
                                {t('cron.day-of-week.between-and')}
                                {' '}
                                <Select
                                    disabled={rule.mode !== RuleMode.between}
                                    value={rule.between.max || ''}
                                    onSelect={(value) => dispatch(actions.setBetweenMax(index, value))}
                                >
                                    {dayOfWeek.map((i) => (
                                        <DayOfWeekEntry key={i} id={i}>{t('cron.day-names.' + i)}</DayOfWeekEntry>
                                    ))}
                                </Select>
                            </Radio>
                        </div>
                    </Rule>
                ))}
            </div>
            <div className={classes.Buttons}>
                <Button
                    className={classes.Ok}
                    kind={ButtonKind.primary}
                    onClick={close}
                >
                    {t('cron.ok')}
                </Button>
                <Button
                    className={classes.Add}
                    kind={ButtonKind.success}
                    onClick={() => dispatch(actions.addRule())}
                >
                    {t('cron.add-rule')}
                </Button>
            </div>
        </div>
    );
};

export default React.forwardRef(DaysOfWeek);
