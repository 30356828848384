import * as React from 'react';
import { AreaSeries, HorizontalGridLines, VerticalGridLines, XAxis } from 'react-vis';
import { formatDateTick } from 'app/helper/graph';
import { Graph } from 'app/component';

import type { SubsystemGraphProps } from 'app/subsystem';

const PirGraph = ({ history = [], onOpenSettings }: SubsystemGraphProps<{ movement: boolean }>) => (
    <Graph height={150} margin={{ left: 10, right: 10, top: 10, bottom: 40 }} onOpenSettings={onOpenSettings}>
        <HorizontalGridLines tickTotal={3}/>
        <VerticalGridLines/>
        <XAxis tickFormat={formatDateTick}/>
        <AreaSeries
            stroke="none"
            color="orange"
            curve="curveStepAfter"
            data={history.map(({ time, data: { movement: y } }) => ({ x: time.getTime(), y: y ? 1 : 0 }))}
        />
    </Graph>
);

export default PirGraph;
