type TimerCallbackType<A> = (...args: Array<A>) => void;

export default class TimingBase<A, C> {

    _timer: NodeJS.Timeout | null | undefined;
    _callback: TimerCallbackType<A> | undefined;
    _args: Array<A> = [];
    // @ts-ignore
    _context: C;
    _timeout = 0;

    // @ts-ignore
    static init(fn: () => void, timeout: number): any;

    // @ts-ignore
    static clear(id: any): void;

    constructor(timeout?: number, cb?: TimerCallbackType<A>, args?: Array<A>, context?: C) {
        if (timeout) {
            this.setTimeout(timeout);
        }

        if (cb) {
            this.setCallback(cb);
        }

        if (args) {
            this.setArguments(args);
        }

        if (context) {
            this.setContext(context);
        }
    }

    setCallback(cb: TimerCallbackType<A>) {
        this._callback = cb;
    }

    setArguments(args: Array<A>) {
        this._args = args;
    }

    setContext(context: C) {
        this._context = context;
    }

    setTimeout(timeout: number) {
        this._timeout = timeout;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    _preCallback() {
    }

    _execute() {
        if (this._timer) {
            this._preCallback();
            if (this._callback) {
                this._callback.apply(this._context, this._args);
            }
        }
    }

    _tearDown() {
        if (this._timer) {
            (this.constructor as typeof TimingBase).clear(this._timer);
            this._timer = null;
        }
    }

    _setUp() {
        if (!this._timer) {
            this._timer = (this.constructor as typeof TimingBase).init(this._execute.bind(this), this._timeout);
        }
    }
}
