import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

import Duration from './Duration';
import DefaultValue from './Value';
import style from './Frame.style';

import type i18next from 'i18next';
import type { StackFrame } from './state';

const useStyles = createUseStyles(style);

interface ValueProps {
    raw?: boolean;
    resolved?: boolean;
    value?: any;
}

interface FrameProps extends Omit<React.ComponentProps<'div'>, 'children'> {
    frame: StackFrame;
    Value?: React.ComponentType<ValueProps>
}

const renderInput = (
    Value: React.ComponentType<ValueProps>,
    frame: StackFrame,
    input: StackFrame['inputs'][0],
    t: i18next.TFunction
) => {
    if (input.source === 'field') {
        if (['-', '+', '/', '%'].includes(input.value)) {
            return <Value key={input.name} raw value={input.value} />;
        }

        const trans = t(frame.type + '.' + String(input.value).toLowerCase(), { defaultValue: '' });
        if (trans != '') {
            return <Value key={input.name} raw value={trans} />;
        }
    }

    return (
        <Value
            key={input.name}
            resolved={input.resolved}
            value={input.value}
        />
    );
};

const Frame = ({ className, frame, Value = DefaultValue, ...props }: FrameProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    const [t] = useTranslation('blockly');

    const inputs = React.useMemo(() => {
        const result: Record<string, React.ReactNode> = {};
        for (const input of frame.inputs) {
            result[input.name.toLowerCase()] = renderInput(Value, frame, input, t);
        }
        return result;
    }, [frame.inputs]);

    return (
        <div {...props} className={cx(classes.Container, className)} ref={ref}>
            <div className={classes.Description}>
                <Trans
                    t={t}
                    i18nKey={`${frame.type}.trace_string`}
                    components={inputs}
                    defaults={`${frame.type}.trace_string [${Object.keys(inputs).map((name) => `${name} <${name} />`).join('; ')}]`}
                />
            </div>
            <Duration className={classes.Time} start={frame.start} stop={frame.stop} />
            <div className={classes.Result}>
                {frame.output !== undefined && (
                    <div className={classes.Output}>
                        <span className={classes.OutputPath} />
                        <Value resolved value={frame.output} />
                    </div>
                )}
                {frame.error && (
                    <div className={classes.Error}>{frame.error.message}</div>
                )}
            </div>
        </div>
    );
};

export default React.forwardRef(Frame);
