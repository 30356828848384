export default class EarlyReturn extends Error {
    public readonly value: any;

    constructor(value: any) {
        super();
        this.value = value;
        const actualProto = new.target.prototype;
        if (Object.setPrototypeOf) { Object.setPrototypeOf(this, actualProto); }
        // @ts-ignore
        else { this.__proto__ = actualProto; }
    }
}
