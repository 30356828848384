import type { ThingType } from 'app/graphql/fragment/thing';

export type FlatThing = Omit<ThingType, 'subsystems' | 'shortcuts'>;
export type SubsystemWithThing = ThingType['subsystems'][0] & { thing: FlatThing };

export interface Context {
    things: FlatThing[];
    subsystems: SubsystemWithThing[];
}

export const buildContext = (things: ThingType[]): Context => ({
    things: things?.map(({ subsystems, shortcuts, ...thing }) => thing) || [],
    subsystems: things?.reduce((acc, { subsystems, shortcuts, ...thing }) => {
        acc.push(...subsystems
            .filter(({ id }) => !id.includes('#'))
            .map((subsystem) => ({ ...subsystem, thing }))
        );
        return acc;
    }, [] as Array<SubsystemWithThing>)
});
