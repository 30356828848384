import type { Theme } from 'app/theme';

const size = 30;


export default (theme: Theme) => ({
    Output: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 0,

        minWidth: size,
        margin: {
            top: 5,
            bottom: 5,
            left: 7,
            right: 7
        }
    },
    Icon: {
        flexGrow: 0,
        flexShrink: 0,

        width: size,
        height: size,
        margin: {
            left: 'auto',
            right: 'auto'
        },

        textAlign: 'center',
        color: theme.thing.icon.color,

        borderRadius: 6,
        border: [1, 'solid', theme.thing.icon.borderColor],
        fontSize: (size - 10) + 'px',

        position: 'relative',

        userSelect: 'none',

        boxShadow: '0px 1px 5px 0px #0000004d',

        '&:before': {
            background: theme.thing.icon.background,
            content: '""',
            display: 'block',
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%'
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 2
    },
    Name: {
        flexGrow: 1,
        flexShrink: 1,
        fontSize: 10,

        marginTop: 3,
        width: '100%',
        textAlign: 'center'
    }
});
