import * as React from 'react';
import parser from 'cron-parser';

import type { CronExpression } from 'cron-parser/types';

interface UseExpressionOkResult {
    expression: CronExpression;
    error: null;
}
interface UseExpressionErrorResult {
    expression: null;
    error: any;
}

type UseExpressionResult = UseExpressionOkResult | UseExpressionErrorResult;

export const useExpression = (expression?: string | null): UseExpressionResult => {
    return React.useMemo(() => {
        try {
            return {
                expression: parser.parseExpression(expression || '* * * * *'),
                error: null
            };
        } catch (e) {
            if (e instanceof Error) {
                return {
                    expression: null,
                    error: e.message
                };
            }
            return {
                expression: null,
                error: 'Invalid expression'
            };
        }
    }, [expression]);
};
