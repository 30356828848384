import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import style from './VisualCron.style';

import Minutes from './tab/minutes';
import Hours from './tab/hours';
import DaysOfMonth from './tab/daysOfMonth';
import Months from './tab/months';
import DaysOfWeek from './tab/daysOfWeek';

type VisualCronProps = Omit<React.ComponentProps<'div'>, 'onChange'> & {
    value?: string;
    onChange?: (value: string) => void;
};

const useStyles = createUseStyles(style);

enum Tab {
    Minutes,
    Hours,
    DaysOfMonth,
    Months,
    DaysOfWeek
}

type ExpressionParts = [string, string, string, string, string];

const breakdownExpression = (expression: string): ExpressionParts => {
    const result: ExpressionParts = ['*', '*', '*', '*', '*'];
    const parts = expression.split(' ');

    for (let i = 0; i < result.length; ++i) {
        if (i < parts.length) {
            result[i] = parts[i];
        }
    }

    return result;
};

const composeExpression = (minutes: string, hours: string, daysOfMonth: string, months: string, daysOfWeek: string) => {
    return [
        minutes,
        hours,
        daysOfMonth,
        months,
        daysOfWeek
    ].join(' ');
};

// eslint-disable-next-line complexity
const VisualCron = ({ className, onChange, value, onClick: close, ...props }: VisualCronProps, ref?: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    const [tab, setTab] = React.useState(Tab.Minutes);
    const [t] = useTranslation('components');

    const parts = breakdownExpression(value || '');
    const [minutes, setMinutes] = React.useState(parts[0]);
    const [hours, setHours] = React.useState(parts[1]);
    const [daysOfMonth, setDaysOfMonth] = React.useState(parts[2]);
    const [months, setMonths] = React.useState(parts[3]);
    const [daysOfWeek, setDaysOfWeek] = React.useState(parts[4]);

    React.useEffect(() => {
        const parts = breakdownExpression(value || '');
        setMinutes(parts[0]);
        setHours(parts[1]);
        setDaysOfMonth(parts[2]);
        setMonths(parts[3]);
        setDaysOfWeek(parts[4]);
    }, [value]);

    React.useEffect(() => {
        if (!onChange) {
            return;
        }

        const composed = composeExpression(minutes, hours, daysOfMonth, months, daysOfWeek);
        if (composed != value) {
            onChange(composed);
        }
    }, [onChange, minutes, hours, daysOfMonth, months, daysOfWeek]);


    return (
        <div {...props} ref={ref} className={cx(classes.Container, className)}>
            <div className={classes.Tabs}>
                <div
                    className={cx(classes.Tab, tab === Tab.Minutes && classes.TabActive)}
                    onClick={() => setTab(Tab.Minutes)}
                >
                    {t('cron.tab.minutes')}
                </div>
                <div
                    className={cx(classes.Tab, tab === Tab.Hours && classes.TabActive)}
                    onClick={() => setTab(Tab.Hours)}
                >
                    {t('cron.tab.hours')}
                </div>
                <div
                    className={cx(classes.Tab, tab === Tab.DaysOfMonth && classes.TabActive)}
                    onClick={() => setTab(Tab.DaysOfMonth)}
                >
                    {t('cron.tab.days-of-month')}
                </div>
                <div
                    className={cx(classes.Tab, tab === Tab.Months && classes.TabActive)}
                    onClick={() => setTab(Tab.Months)}
                >
                    {t('cron.tab.months')}
                </div>
                <div
                    className={cx(classes.Tab, tab === Tab.DaysOfWeek && classes.TabActive)}
                    onClick={() => setTab(Tab.DaysOfWeek)}
                >
                    {t('cron.tab.days-of-week')}
                </div>
            </div>
            {tab === Tab.Minutes && (
                <Minutes close={close as any} value={minutes} onChange={setMinutes} className={classes.View} />
            )}
            {tab === Tab.Hours && (
                <Hours close={close as any} value={hours} onChange={setHours} className={classes.View} />
            )}
            {tab === Tab.DaysOfMonth && (
                <DaysOfMonth close={close as any} value={daysOfMonth} onChange={setDaysOfMonth} className={classes.View} />
            )}
            {tab === Tab.Months && (
                <Months close={close as any} value={months} onChange={setMonths} className={classes.View} />
            )}
            {tab === Tab.DaysOfWeek && (
                <DaysOfWeek close={close as any} value={daysOfWeek} onChange={setDaysOfWeek} className={classes.View} />
            )}
        </div>
    );
};

export default React.forwardRef(VisualCron);
