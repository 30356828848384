import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import Multimap from 'multimap';
import { Box, ThingIcon, Title } from 'app/component';
import { withThings } from 'app/hoc';
import thingByType from 'app/thing';

import style from './style';
import type { ThingType } from 'app/graphql/fragment/thing';

type ThingsType = Array<ThingType>;

type ThingsListProps = {
    things: ThingsType;
};

const segregateThings = (things: ThingsType) => {
    const roomThing = new Multimap();
    const noRoomThings = new Set();
    for (const thing of things) {
        if (thing.room) {
            roomThing.set(thing.room.id, thing);
        } else {
            noRoomThings.add(thing);
        }
    }

    const result: Array<{ home: string | undefined, room: string | undefined, things: ThingsType }> = [];
    roomThing.forEachEntry((things: ThingsType) => {
        result.push({ home: things[0]?.room?.home.name, room: things[0]?.room?.name, things });
    });

    result.sort((a, b) => {
        const homeResult = (a.home || '').localeCompare(b.home || '');
        if (homeResult === 0) {
            return (a.room || '').localeCompare(b.room || '');
        }

        return homeResult;
    });

    if (noRoomThings.size > 0) {
        result.push({ home: undefined, room: undefined, things: Array.from(noRoomThings.values()) as ThingsType });
    }

    return result;
};

const useStyles = createUseStyles(style);

const ThingsList = ({ things }: ThingsListProps): React.ReactElement<'div'> => {
    const classes = useStyles();
    const [t] = useTranslation('thing');

    const segregated = segregateThings(things);
    return (
        <div className={classes.ThingsList}>
            {segregated.map(({ room, home, things }) => (
                <Box key={String(home) + '-' + String(room)} className={classes.Room}>
                    {(home && room) ? <Title>{home} :: {room}</Title> : <Title className={classes.NoRoom}>{t('no-room')}</Title>}
                    <div className={classes.Things}>
                        {things.map(({ id, name, type, status }: ThingType) => {
                            const { Icon } = thingByType(type);

                            return (
                                <Link key={id} to={`/${id}`} style={{ height: 'min-content' }}>
                                    <ThingIcon key={`off-${id}`} name={name} status={status} type={type}>
                                        <Icon name={name} status={status} type={type}/>
                                    </ThingIcon>
                                </Link>
                            );
                        })}
                    </div>
                </Box>
            ))}

        </div>
    );
};

export default withThings(ThingsList);
