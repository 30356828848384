import type { Theme } from 'app/theme';

export default ({ color, window }: Theme) => ({
    Container: {
        width: '100%',
        height: '100%',

        display: 'flex',
        flexDirection: 'column'
    },
    Controls: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 5,

        '& > div': {
            display: 'flex',

            '& > * + *': {
                marginLeft: 5
            }
        }
    },
    Variables: {
        borderBottom: window.border,
        padding: 5,
        '& tbody': {

        },

        '& th': {
            textAlign: 'left'
        }
    },
    Tracer: {
        height: '100%',
        overflowY: 'scroll'
    },
    Entry: {
        margin: {
            top: 5,
            bottom: 5,
            left: 5,
            right: 10
        },

        borderRadius: 5,
        '&:nth-child(even)': {
            background: color + '20'
        }
    },
    StepButton: {
        // position: 'relative',
        // width: 44,
        // height: 30,
        // fontSize: '16px',
        //
        '& > *': {
            position: 'relative',
            display: 'inline-block',
            overflow: 'hidden',
            left: -2,
            verticalAlign: 'bottom'
        }
    },
    StepPlay: {
        width: 8
    },
    StepPause: {
        width: 6
    }
});
