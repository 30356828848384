import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import style from './style';

const useStyles = createUseStyles(style);


type AutomationOutputsProps = JSX.IntrinsicElements['div'] & {
    name?: string;
}

const AutomationOutputs = ({ className, name, children, ...props }: AutomationOutputsProps) => {
    const classes = useStyles();
    return (
        <div { ...props } className={cx(classes.AutomationOutputs, className)}>
            {name && (
                <div className={classes.Name}>
                    {name}
                </div>
            )}
            <div className={classes.Outputs}>
                {children}
            </div>
        </div>
    );
};

export default AutomationOutputs;
