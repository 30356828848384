import gql from 'graphql-tag';
import shortcut from './shortcut';

import type { SubsystemTypeType } from 'app/subsystem';
import type { ShortcutType } from './shortcut';

export default gql`
    fragment subsystem on Subsystem {
        id
        type
        name
        status
        shortcuts {
            ...shortcut
        }
    }
    ${shortcut}
`;

export type SubsystemType = {
    id: string;
    name: string;
    type: SubsystemTypeType
    status: string | null;
    shortcuts: Array<ShortcutType>;
};
