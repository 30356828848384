import gql from 'graphql-tag';
import type { ThingTypeType } from 'app/thing';

export default gql`
    fragment thingBase on Thing {
        id
        name
        type
        status
        serial
        room {
            id
            name
            home {
                id
                name
            }
        }
    }
`;

export type ThingBaseType = {
    id: number;
    name: string;
    type: ThingTypeType;
    serial: string;
    status: 'online' | 'offline';
    room?: {
        id: string;
        name: string;
        home: {
            id: string;
            name: string;
        };
    };
};
