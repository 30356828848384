import BreakLoop from '../../runner/BreakLoop';
import ContinueLoop from '../../runner/ContinueLoop';

import type { Interpreter, Node } from '../../types';
import type { BlockDefinition } from '../types';

export const name = 'controls_repeat_ext';

export const definition: BlockDefinition = {
    message0: '${title} %1',
    args0: [{
        type: 'input_value',
        name: 'TIMES',
        check: 'Number'
    }],
    message1: '${do} %1',
    args1: [{
        type: 'input_statement',
        name: 'DO'
    }],
    previousStatement: null,
    nextStatement: null,
    style: 'loop_blocks'
};

// eslint-disable-next-line max-statements,complexity
export default async function run(this: Interpreter, node: Node) {
    const timesNode = node.values?.TIMES;
    if (!timesNode) {
        throw new Error('times missing');
    }
    const task = node.statements?.DO;
    if (!task) {
        throw new Error('do missing');
    }

    const times = await this.execute(timesNode);

    for (let i = 0; i < times; ++i) {
        try {
            await this.executeStatement(task);
        } catch (e) {
            if (e instanceof BreakLoop) {
                break;
            }
            if (e instanceof ContinueLoop) {
                continue;
            }
            throw e;
        }
    }
}
