import * as React from 'react';
import { ReactField } from 'app/component/Blockly/fields';
import ThingIcon from 'app/component/ThingIcon';
import thingByType from 'app/thing';

import { CATEGORY_BASE_COLOR } from './constants';

import type { Node, ContextBlock } from 'app/component/Blockly/types';
import type IotInterpreter from '../IotInterpreter';
import type { Context, FlatThing } from '../context';

export const name = 'iot_device';

export function builder(this: ContextBlock<Context>) {
    this.appendDummyInput()
        .appendField(new ReactField((context: Context, value) => {
            const device = context.things.find((thing) => thing.id == value);
            if (!device) {
                return (
                    <ThingIcon name="&nbsp;" status="offline">
                        <b>?</b>
                    </ThingIcon>
                );
            }
            const { Icon } = thingByType(device.type);
            return (
                <ThingIcon name={device.name} type={device.type} status={device.status}>
                    <Icon name={device.name} type={device.type} status={device.status} />
                </ThingIcon>
            );
        }), 'device');
    this.setColour(CATEGORY_BASE_COLOR);
    this.setTooltip(() => {
        const value = this.getFieldValue('device');
        const device = this.workspace.context.things.find((thing) => thing.id == value);
        if (device?.room) {
            return device.room.home.name + ': ' + device.room.name;
        }
        return '';
    });
    this.setOutput(true, 'IotDevice');
}

export default function run(this: IotInterpreter, node: Node): FlatThing {
    const deviceId = node.fields?.device;
    if (!deviceId) {
        throw new Error('device id missing');
    }

    const device = this.workspace.context.things.find(({ id }) => id == deviceId);
    if (!device) {
        throw new Error('device id invalid');
    }

    return device;
}
run.noTrace = true;
