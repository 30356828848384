import gql from 'graphql-tag';
export type { OidcRefreshMutation, OidcRefreshMutationVariables } from 'app/graphql/types.generated';

export default gql`
    mutation OIDCRefresh($token: String!) {
        oidc {
            refresh(token: $token) {
                token
                refresh
            }
        }
    }
`;
