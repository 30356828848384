import * as React from 'react';
import { useMutation } from '@apollo/client/react';

import SUBSYSTEM_MUTATION from 'app/graphql/mutation/thing/subsystem/push';

type SubsystemMutationSig = (arg0: any) => void;

const useSubsystemMutation = (id: string): SubsystemMutationSig => {
    const thingId = String(id).replace(/[^0-9].*$/, '');
    const [push] = useMutation(SUBSYSTEM_MUTATION);

    return React.useCallback((status: any) => push({ variables: { thingId, subsystemId: id, status: String(status) } }), [push]);
};

export default useSubsystemMutation;
