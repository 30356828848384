import gql from 'graphql-tag';
import subsystem from '../fragment/subsystem';

export const typeDef = gql`
    extend type Query {
        subsystem(id: ID!): Subsystem
    }
`;

export const resolver = (_: any, { id }: any, { cache }: any) => cache.readFragment({
    fragment: subsystem,
    fragmentName: 'subsystem',
    id: `Subsystem:${id}`
});

export default gql`
    query SubsystemQuery($id: String!) {
        subsystem(id: $id) @client {
            ...subsystem
        }
    }
    ${subsystem}
`;
