import { builder as base } from './callnoreturn';

import type { Interpreter, Node } from '../../types';
import type { CallNoReturnBlock } from './callnoreturn';

export const builder = {
    init(this: CallNoReturnBlock) {
        this.appendDummyInput('TOPROW')
            .appendField('', 'NAME');
        this.setOutput(true);
        this.setStyle('procedure_blocks');
        this.arguments_ = [];
        this.argumentVarModels_ = [];
        this.quarkConnections_ = {};
        this.quarkIds_ = null;
        this.previousEnabledState_ = true;
    },

    getProcedureCall: base.getProcedureCall,
    renameProcedure: base.renameProcedure,
    setProcedureParameters_: base.setProcedureParameters_,
    updateShape_: base.updateShape_,
    mutationToDom: base.mutationToDom,
    domToMutation: base.domToMutation,
    getVars: base.getVars,
    getVarModels: base.getVarModels,
    onchange: base.onchange,
    customContextMenu: base.customContextMenu,
    defType_: 'procedures_defreturn'
};

export default async function run(this: Interpreter, node: Node): Promise<any> {
    const name = node.fields?.NAME;
    if (!name) {
        throw new Error('variable name missing');
    }

    const variables = new Map<string, string | number | null>();
    if (node.mutations?.children && Array.isArray(node.mutations?.children)) {
        for (let i = 0; i < node.mutations.children.length; ++i) {
            // @ts-ignore
            const varName = node.mutations.children[i]?.name as string;

            const key = 'ARG' + i.toString();
            if (node.values?.[key]) {
                const varNode = node.values[key];
                variables.set(varName, await this.execute(varNode));
            } else {
                variables.set(varName, null);
            }
        }
    }

    return this.executeProcedure(String(name), variables);
}
