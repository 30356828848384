import BreakLoop from '../../runner/BreakLoop';
import ContinueLoop from '../../runner/ContinueLoop';

import type { Interpreter, Node } from '../../types';
import type { BlockDefinition } from '../types';

export const name = 'controls_for';

export const definition: BlockDefinition = {
    message0: '${for} %1 ${from} %2 ${to} %3 ${step} %4',
    args0: [
        {
            type: 'field_variable',
            name: 'VAR',
            variable: null
        },
        {
            type: 'input_value',
            name: 'FROM',
            check: 'Number',
            align: 'RIGHT'
        },
        {
            type: 'input_value',
            name: 'TO',
            check: 'Number',
            align: 'RIGHT'
        },
        {
            type: 'input_value',
            name: 'BY',
            check: 'Number',
            align: 'RIGHT'
        }
    ],
    message1: '${do} %1',
    args1: [{
        type: 'input_statement',
        name: 'DO'
    }],
    inputsInline: true,
    previousStatement: null,
    nextStatement: null,
    style: 'loop_blocks',
    extensions: [
        'contextMenu_newGetVariableBlock'
    ]
};

// eslint-disable-next-line max-statements,complexity
export default async function run(this: Interpreter, node: Node) {
    const varNode = node.fields?.VAR as string;
    if (!varNode) {
        throw new Error('var missing');
    }
    const toNode = node.values?.TO;
    if (!toNode) {
        throw new Error('to missing');
    }
    const fromNode = node.values?.FROM;
    if (!fromNode) {
        throw new Error('from missing');
    }
    const byNode = node.values?.BY;
    if (!byNode) {
        throw new Error('by missing');
    }
    const task = node.statements?.DO;
    if (!task) {
        throw new Error('do missing');
    }

    const to = await this.execute(toNode);
    const from = await this.execute(fromNode);
    const by = await this.execute(byNode);

    this.variables.set(varNode, from);
    for (let i = from; i <= to; i += by) {
        this.variables.set(varNode, i);
        try {
            await this.executeStatement(task);
        } catch (e) {
            if (e instanceof BreakLoop) {
                break;
            }
            if (e instanceof ContinueLoop) {
                continue;
            }
            throw e;
        }
    }
}
