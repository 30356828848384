import type { Interpreter, Node } from '../../types';
import type { BlockDefinition } from '../types';

export const definition: BlockDefinition = {
    message0: '%1',
    args0: [
        {
            type: 'field_dropdown',
            name: 'BOOL',
            options: [
                ['${true}', 'TRUE'],
                ['${false}', 'FALSE']
            ]
        }
    ],
    output: 'Boolean',
    style: 'logic_blocks'
};

export default function run(this: Interpreter, node: Node): boolean {
    const bool = node.fields?.BOOL;
    if (!bool) {
        throw new Error('value missing');
    }
    return bool === 'TRUE';
}
run.noTrace = true;
