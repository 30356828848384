import * as React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import style from './style';

const useStyles = createUseStyles(style);

type CheckboxProps = Omit<React.ComponentProps<'input'>, 'type'>;

const Checkbox = ({ className, children, disabled, ...props }: CheckboxProps, ref: React.Ref<HTMLInputElement>) => {
    const classes = useStyles();

    return (
        <label className={cx(classes.Label, disabled && classes.Disabled, className)}>
            <input
                {...props}
                type="checkbox"
                className={cx(classes.Checkbox, className)}
                ref={ref}
                disabled={disabled}
            />
            {children}
        </label>
    );
};

export default React.forwardRef(Checkbox);
