import { CSS_DESKTOP_BREAKPOINT, CSS_MOBILE_BREAKPOINT } from 'app/theme';
import type { Theme } from 'app/theme';

export default (({ navigation }: Theme) => ({
    Navigation: {
        maxHeight: navigation.height,
        minHeight: navigation.height,

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexGrow: 1,
        flexShrink: 1,

        background: navigation.background
    },
    Container: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexGrow: 1,
        flexShrink: 1,

        [CSS_DESKTOP_BREAKPOINT]: {
            display: 'flex'
        },
        [CSS_MOBILE_BREAKPOINT]: {
            display: 'none',

            position: 'absolute',
            left: 0,
            right: 0,
            top: navigation.height,

            flexDirection: 'column',

            background: navigation.dropdown.background,
            zIndex: 1024
        }
    } as any,
    Open: {
        [CSS_MOBILE_BREAKPOINT]: {
            display: 'flex'
        }
    } as any,
    Hamburger: {
        display: 'none',

        [CSS_MOBILE_BREAKPOINT]: {
            display: 'block'
        },

        cursor: 'pointer',

        fontSize: 40,
        color: '#ffffff',
        marginLeft: 'auto'
    } as any
}));
