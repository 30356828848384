import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { EntypoControllerPlay, EntypoControllerPaus, EntypoControllerStop, EntypoEraser } from 'react-entypo-icons';
import { useTranslation } from 'react-i18next';

import Button, { ButtonKind } from '../../Button';
import Frame from './Frame';
import DefaultValue from './Value';
import style from './style';

import type { ContextWorkspaceSvg } from 'app/component/Blockly/types';
import type UiTracedInterpreter from '../runner/interpreter/UiTracedInterpreter';
import type { State } from './state';

const useStyles = createUseStyles(style);

interface ValueProps {
    raw?: boolean;
    resolved?: boolean;
    value?: any;
}

export interface TracerProps extends Omit<React.ComponentProps<'div'>, 'children'> {
    run: () => Promise<UiTracedInterpreter>;
    clear: () => void;
    footer?: React.ReactNode;
    state: State;
    workspace: ContextWorkspaceSvg<any>;
    Value?: React.ComponentType<ValueProps>
}

const Tracer = ({
    className,
    footer,
    run,
    state,
    Value = DefaultValue,
    workspace,
    clear,
    ...props
}: TracerProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    const [t] = useTranslation('blockly');
    const [showVariables, setShowVariables] = React.useState(false);
    const bottomRef = React.useRef<HTMLDivElement>(null);
    React.useLayoutEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [state.stack]);

    const [interpreter, setInterpreter] = React.useState<UiTracedInterpreter|null>(null);
    const [interpreterState, setInterpreterState] = React.useState<string>('idle');

    const boot = async () => {
        const newInterpreter = await run();
        newInterpreter.onComplete(() => {
            setInterpreter(null);
            setInterpreterState('completed');
        });
        return newInterpreter;
    };

    return (
        <div {...props} className={cx(classes.Container, className)} ref={ref}>
            <div className={classes.Controls}>
                <div>
                    <Button
                        kind={ButtonKind.primary}
                        active={interpreterState === 'run'}
                        onClick={async () => {
                            if (!interpreter) {
                                setInterpreter(await boot());
                            } else {
                                interpreter?.continue();
                            }
                            setInterpreterState('run');
                        }}
                        disabled={['run'].includes(interpreterState)}
                    >
                        <EntypoControllerPlay />
                    </Button>
                    <Button
                        kind={ButtonKind.lite}
                        onClick={() => {
                            interpreter?.pause();
                            setInterpreterState('paused');
                        }}
                        disabled={!interpreter || interpreterState !== 'run'}
                    >
                        <EntypoControllerPaus />
                    </Button>
                    <Button
                        className={classes.StepButton}
                        kind={ButtonKind.lite}
                        active={interpreterState === 'run'}
                        onClick={async () => {
                            if (!interpreter) {
                                const newInterpreter = await boot();
                                newInterpreter.pause();
                                setInterpreter(newInterpreter);
                            } else {
                                interpreter.step();
                            }
                            setInterpreterState('paused');
                        }}
                        disabled={['run', 'completed'].includes(interpreterState)}
                    >
                        <span className={classes.StepPlay}><EntypoControllerPlay /></span>
                        <span className={classes.StepPause}><EntypoControllerPaus /></span>
                    </Button>
                    <Button
                        kind={ButtonKind.danger}
                        onClick={() => {
                            if (interpreterState === 'completed') {
                                clear();
                                setInterpreterState('idle');
                            } else {
                                interpreter?.stop();
                            }
                        }}
                        disabled={interpreterState === 'idle'}
                    >
                        {(interpreterState === 'completed') ? (
                            <EntypoEraser />
                        ) : (
                            <EntypoControllerStop />
                        )}
                    </Button>
                </div>
                <Button
                    kind={ButtonKind.lite}
                    active={showVariables}
                    onClick={() => setShowVariables(!showVariables)}
                >
                    {t('tracer.show-variables')}
                </Button>
            </div>
            {showVariables && (
                <table className={classes.Variables}>
                    <tbody>
                        {Object.entries(state.variables).map(([name, value]) => (
                            <tr key={name}>
                                <th>{name}</th>
                                <td><Value value={value} /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <div className={classes.Tracer}>
                {state.stack.filter(({ hidden }) => !hidden).map((frame) => (
                    <Frame
                        key={frame.id}
                        className={classes.Entry}
                        frame={frame}
                        Value={Value}
                        onMouseOver={() => workspace.highlightBlock(frame.id, true)}
                        onMouseOut={() => workspace.highlightBlock(frame.id, false)}
                    />
                ))}
                <div style={{ float:'left', clear: 'both' }} ref={bottomRef} />
            </div>
            {footer}
        </div>
    );
};

export default React.forwardRef(Tracer);
