import gql from 'graphql-tag';
import thingBase from '../fragment/thing-base';
import type { ThingBaseType } from '../fragment/thing-base';

export default gql`
    subscription OnThingUpdate {
        onThingUpdate {
            ...thingBase
            ... on ThingUpdate {
                id
                deleted
            }
        }
    }
    ${thingBase}
`;

export type OnThingUpdateSubscription = {
    onThingUpdate: ThingBaseType & {
        deleted?: boolean
    }
};
