import { useSubscription } from '@apollo/client/react';
import AUTOMATION_STATUS_SUBSCRIPTION from 'app/graphql/subscription/onAutomationStatus';

import type { AutomationStatusSubscription } from 'app/graphql/subscription/onAutomationStatus';

const useAutomationStatusSubscription = (id: string) => {
    useSubscription<AutomationStatusSubscription>(AUTOMATION_STATUS_SUBSCRIPTION, { variables: { id } });
};

export default useAutomationStatusSubscription;
