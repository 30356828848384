export default class ExecutionCancelled extends Error {
    public readonly executionCancelled = true;

    constructor(message: string) {
        super(message);
        const actualProto = new.target.prototype;
        if (Object.setPrototypeOf) { Object.setPrototypeOf(this, actualProto); }
        // @ts-ignore
        else { this.__proto__ = actualProto; }
    }
}
