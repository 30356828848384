export default {
    Chunk: {
        flexShrink: 0,
        flexGrow: 1,

        padding: ['0.3em', '0.5em'],

        borderWidth: 1,
        borderTopStyle: 'solid',
        borderBottomStyle: 'solid'
    }
};
