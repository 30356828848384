import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { time } from 'vx-std';
import { Button, DateTime, Modal, Slider, Title, Window } from 'app/component';
import { DateTimeResolution } from 'app/component/DateTime';
import style from './style';

import context from './context';

type HistoryProviderProps = {
    children?: React.ReactNode;
};

const useStyles = createUseStyles(style);

const ContextProvider = context.Provider;

const units = ['s', 'm'];
const secondsToInterval = (seconds: number) => [
    seconds % 60,
    Math.floor(seconds / 60)
]
    .map((val, i) => val ? String(val) + units[i] : false)
    .filter(Boolean)
    .reverse()
    .join('');


const HistoryProvider = ({ children }: HistoryProviderProps) => {
    const [t] = useTranslation('graph');
    const classes = useStyles();

    const [open, setOpen] = React.useState<boolean>(false);
    const [interval, setInterval] = React.useState<string>('10m');
    const [from, setFrom] = React.useState<Date|undefined>(undefined);
    const [to, setTo] = React.useState<Date|undefined>(undefined);

    const [localInterval, setLocalInterval] = React.useState<number>(time.parseUnits(interval) / 1000);
    const [localFrom, setLocalFrom] = React.useState<Date|undefined>(from);
    const [localTo, setLocalTo] = React.useState<Date|undefined>(to);

    const values = React.useMemo(() => ({ interval, to, from, open: () => setOpen(true) }), [interval, to, from, setOpen]);

    return (
        <ContextProvider value={values}>
            {children}
            <Modal isOpen={open}>
                <Window>
                    <Window.TitleWithCloseButton onCloseClick={() => setOpen(false)}>
                        <Title>{t('config.window.title')}</Title>
                    </Window.TitleWithCloseButton>
                    <div className={classes.Content}>
                        <div>
                            {t('config.window.interval')}:<br />
                            <Slider
                                className={classes.Slider}
                                value={localInterval}
                                min={1}
                                max={1800}
                                onValueChange={setLocalInterval}
                            /><br />
                            <span>{secondsToInterval(localInterval)}</span>
                        </div>
                        <div>
                            {t('config.window.from')}:<br />
                            <DateTime value={localFrom} onChange={setLocalFrom} maxDetail={DateTimeResolution.Minute} />
                        </div>
                        <div>
                            {t('config.window.to')}:<br />
                            <DateTime value={localTo} onChange={setLocalTo} maxDetail={DateTimeResolution.Minute} />
                        </div>
                    </div>
                    <Window.ButtonFooter>
                        <Button
                            onClick={() => {
                                setFrom(localFrom || undefined);
                                setTo(localTo || undefined);
                                setInterval(secondsToInterval(localInterval));
                                setOpen(false);
                            }}
                        >
                            {t('config.window.set')}
                        </Button>
                    </Window.ButtonFooter>
                </Window>
            </Modal>
        </ContextProvider>
    );
};

export default HistoryProvider;
