import * as React from 'react';
import Color from 'color';

import type { SubsystemValueProps } from 'app/subsystem';

const color = ['#FFC72D', '#F59D00', '#FF905E', '#E87E04', '#CF7004', '#FFE49C'];

const inactiveColor = color.map(color => Color(color).grayscale().hex());

const Value = ({ status, mini }: SubsystemValueProps) => {
    const movement = String(status).trim() === '1';
    const fill = movement ? color : inactiveColor;
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512.001 512.001"
                width={mini ? 50 : 110}
                x="0px"
                y="0px"
                // @ts-ignore
                style={{ enableBackground: 'new 0 0 512.001 512.001' }}
            >
                <path
                    style={{ fill: fill[0] }}
                    d="M445.935,385.883H66.065c-12.588,0-22.182-11.297-20.152-23.715L99.224,36.264
                        c1.616-9.873,10.147-17.123,20.152-17.123h273.249c10.005,0,18.536,7.249,20.152,17.123l53.31,325.903
                        C468.117,374.59,458.517,385.883,445.935,385.883z"
                />
                <path
                    style={{ fill: fill[1] }}
                    d="M466.086,362.167l-53.31-325.903c-1.614-9.873-10.147-17.123-20.152-17.123H256.001v366.741h189.934
                        C458.523,385.883,468.117,374.586,466.086,362.167z"
                />
                <path
                    style={{ fill: fill[2] }}
                    d="M330.436,385.883H181.564c-12.588,0-22.182-11.297-20.152-23.715l20.893-127.722
                        c1.614-9.873,10.147-17.123,20.152-17.123h107.088c10.005,0,18.536,7.249,20.152,17.123l20.893,127.722
                        C352.619,374.59,343.02,385.883,330.436,385.883z"
                />
                <g>
                    <path
                        style={{ fill: fill[3] }}
                        d="M350.588,362.167l-20.893-127.723c-1.614-9.873-10.147-17.122-20.152-17.122h-53.544v168.56h74.437
                        C343.024,385.883,352.618,374.586,350.588,362.167z"
                    />
                    <path
                        style={{ fill: fill[3] }}
                        d="M491.581,492.861H20.419C9.142,492.861,0,483.718,0,472.442V365.463
                            c0-11.277,9.142-20.419,20.419-20.419h471.162c11.277,0,20.419,9.142,20.419,20.419v106.979
                            C512,483.72,502.858,492.861,491.581,492.861z"
                    />
                </g>
                <path
                    style={{ fill: fill[4] }}
                    d="M491.581,345.045h-235.58v147.816h235.582c11.277,0,20.419-9.142,20.419-20.419V365.463
                          C512,354.186,502.858,345.045,491.581,345.045z"
                />
                <path
                    style={{ fill: fill[5] }}
                    // eslint-disable-next-line max-len
                    d="M322.479,84.36c-26.651,0-50.526,12.07-66.48,31.02c-15.953-18.95-39.828-31.02-66.48-31.02c-11.277,0-20.419,9.142-20.419,20.419
                        v132.963c0,11.277,9.142,20.419,20.419,20.419c26.651,0,50.526-12.07,66.48-31.02c15.953,18.949,39.828,31.02,66.48,31.02
                        c11.277,0,20.419-9.142,20.419-20.419V104.779C342.898,93.503,333.757,84.36,322.479,84.36z"
                />
                <path
                    style={{ fill: fill[0] }}
                    d="M322.479,84.36c-26.651,0-50.526,12.07-66.48,31.02v111.76c15.953,18.949,39.828,31.02,66.48,31.02
                        c11.277,0,20.419-9.142,20.419-20.419V104.779C342.898,93.503,333.757,84.36,322.479,84.36z"
                />
            </svg>
        </div>
    );
};

export default Value;
