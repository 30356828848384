import useStatusAggregator from './useStatusAggregator';
import useSubsystemQuery from './useSubsystemQuery';
import useSubsystemSubscription from './useSubsystemSubscription';
import useHistoryParams from './history/useHistoryParams';

import type { SubsystemType } from 'app/graphql/fragment/subsystem';
import type { HistoryParams } from './useSubsystemHistory';
import type { SubsystemStatusOptionsType } from './types';

type SubsystemReadResultType = {
    loading: boolean;
    subsystem: SubsystemType | undefined;
};

const useSubsystemRead = (id: string, options?: Partial<SubsystemStatusOptionsType>, historyParams?: HistoryParams): SubsystemReadResultType => {
    const { subsystem, loading } = useSubsystemQuery(id);
    useSubsystemSubscription(id);

    historyParams = historyParams || useHistoryParams();

    useStatusAggregator(id, options || null, subsystem?.status || null, historyParams);

    return {
        subsystem,
        loading
    };
};

export default useSubsystemRead;
