import { CSS_DESKTOP_BREAKPOINT, CSS_MOBILE_BREAKPOINT } from 'app/theme';

export default {
    Container: {
        padding: 10
    },
    All: {
        padding: 5
    },
    Mode: {
        '& + *': {
            marginTop: 5
        }
    },
    Grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        paddingLeft: 20,
        marginTop: 5,
        gridGap: 8
    },
    ShortGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        paddingLeft: 20,
        marginTop: 5,
        gridGap: 8,

        [CSS_MOBILE_BREAKPOINT]: {
            gridTemplateColumns: 'repeat(2, 1fr)'
        }
    },
    Hidden: {
        display: 'none'
    },
    Rules: {
        '& > * + *': {
            marginTop: 5
        },
        marginBottom: 5
    },
    Add: {
        marginLeft: 'auto'
    },
    Ok: {
        [CSS_DESKTOP_BREAKPOINT]: {
            display: 'none'
        }
    },
    Buttons: {
        display: 'flex',
        justifyContent: 'space-between'
    }
};
