import * as React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Slider } from 'app/component';
import style from './Value.style';

import type { SubsystemValueProps } from 'app/subsystem';

const useStyles = createUseStyles(style);

const Bulb = ({ width, percent, mini, ...props }: any, ref: React.Ref<HTMLOrSVGElement>) => {
    const classes = useStyles({ percent });

    return (
        <svg {...props} width={width} viewBox="8 0 32 48" ref={ref}>
            <defs>
                <linearGradient id="linearGradient4119">
                    {/* @ts-ignore */}
                    <stop style={{ stopColor: '#000000', stopOpacity: '1' }} offset="0"/>
                    {/* @ts-ignore */}
                    <stop style={{ stopColor: '#000000', stopOpacity: '0.38938054' }} offset="1"/>
                </linearGradient>
                <linearGradient id="linearGradient4029">
                    {/* @ts-ignore */}
                    <stop style={{ stopColor: '#ffd543', stopOpacity: '1' }} offset="0"/>
                    {/* @ts-ignore */}
                    <stop style={{ stopColor: '#ffeca9', stopOpacity: '1' }} offset="1"/>
                </linearGradient>
                <linearGradient id="linearGradient3856">
                    {/* @ts-ignore */}
                    <stop style={{ stopColor: '#ffffff', stopOpacity: '1' }} offset="0"/>
                    {/* @ts-ignore */}
                    <stop style={{ stopColor: '#ffffff', stopOpacity: '0' }} offset="1"/>
                </linearGradient>
                <linearGradient id="linearGradient3817">
                    <stop className={classes.GradientWireHolderA} offset="0"/>
                    <stop className={classes.GradientWireHolderB} offset="1"/>
                </linearGradient>
                <linearGradient id="linearGradient3807">
                    {/* @ts-ignore */}
                    <stop style={{ stopColor: '#ffffff', stopOpacity: '1' }} offset="0"/>
                    {/* @ts-ignore */}
                    <stop style={{ stopColor: '#ffffff', stopOpacity: '0' }} offset="1"/>
                </linearGradient>
                <linearGradient id="linearGradient3795">
                    <stop className={classes.Gradient1A} offset="0"/>
                    <stop className={classes.Gradient1B} offset="1"/>
                </linearGradient>
                <linearGradient id="linearGradient3777">
                    <stop className={classes.Gradient2A} offset="0"/>
                    <stop className={classes.Gradient2B} offset="1"/>
                </linearGradient>
                <filter id="filter4115" x="-0.10620745" width="1.2124149" y="-0.41538914" height="1.8307783">
                    <feGaussianBlur stdDeviation="1.1543757"/>
                </filter>
                <radialGradient
                    xlinkHref="#linearGradient4119" id="radialGradient4145" gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(1,0,0,0.38608624,0,29.058401)" cx="-12.894664" cy="47.333035"
                    fx="-12.894664" fy="47.333035" r="15.81338"
                />
                <linearGradient
                    xlinkHref="#linearGradient4029" id="linearGradient4147" gradientUnits="userSpaceOnUse"
                    spreadMethod="reflect" x1="18.928572" y1="1045.3086" x2="25.178572" y2="1045.4872"
                />
                <radialGradient
                    xlinkHref="#linearGradient3795" id="radialGradient4149" gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(0.99940255,-0.03456211,0.05050681,1.4604616,-0.5742278,997.49536)"
                    cx="23.65625" cy="18.720327" fx="23.65625" fy="18.720327" r="15.000242"
                />
                <linearGradient
                    xlinkHref="#linearGradient3777" id="linearGradient4151" gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(0.35714286,1005.2977)" x1="8.7191429" y1="1.1057925"
                    x2="30.701538" y2="35.135307"
                />
                <linearGradient
                    xlinkHref="#linearGradient3807" id="linearGradient4153" gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(0,-4.6001274)" x1="15.535929" y1="1015.4172" x2="42.622627"
                    y2="1039.6136"
                />
                <linearGradient
                    xlinkHref="#linearGradient3817" id="linearGradient4155" gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(0.71907198,-0.2804113,0.46212483,1.1850486,-6.2100047,1005.7087)"
                    x1="21.732143" y1="21.437498" x2="21.571428" y2="31.794641"
                />
                <linearGradient
                    xlinkHref="#linearGradient3817" id="linearGradient4157" gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(-0.71907198,-0.2804113,-0.46212483,1.1850486,53.825074,1005.7087)"
                    x1="21.732143" y1="21.437498" x2="21.571428" y2="31.794641"
                />
                <linearGradient
                    xlinkHref="#linearGradient3856" id="linearGradient4159" gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(0,-4.6001274)" spreadMethod="reflect" x1="23.886833"
                    y1="1040.762" x2="26.033989" y2="1040.6989"
                />
            </defs>
            <g transform="translate(0,-1004.3622)">
                <g transform="translate(0.1722681,-0.14017388)">
                    <g transform="matrix(0.87934157,0,0,0.87934157,2.6194417,121.64002)">
                        <path style={{
                            fill: '#d0bfac',
                            // @ts-ignore
                            fillOpacity: '1',
                            stroke: '#5a5249',
                            strokeWidth: '1px',
                            strokeLinecap: 'butt',
                            strokeLinejoin: 'miter',
                            // @ts-ignore
                            strokeOpacity: '1'
                        }} d="m 21.034635,1049.4122 6,0 c 0,4 -6,4 -6,0 z"/>
                        <path
                            style={{
                                fill: 'none',
                                stroke: '#d59600',
                                strokeLinecap: 'square',
                                strokeLinejoin: 'bevel',
                                // @ts-ignore
                                strokeOpacity: '1'
                            }}
                            d="m 17.361998,1040.4384 c 4.166879,-2.946 8.270624,-2.8962 12.500638,0 0.04667,1.0552 0.100776,1.8872
                                      -1.607143,2.4148 2.390859,0.2047 2.207104,1.9163 0.35714,2.8572 2.052113,0.9877 2.765145,1.6946
                                      1.250003,3.0962 -4.903679,3.3991 -7.82287,3.2821 -12.500638,-0.045 -0.833191,-0.66 -0.222217,-1.5596
                                      2.053572,-1.9356 -2.585862,-0.4353 -2.681307,-2.8088 -0.178576,-3.1696 -1.993241,-1.2189 -1.806861,-2.1317
                                      -1.874996,-3.2184 z"
                        />
                        <path
                            style={{
                                fill: 'url(#linearGradient4147)',
                                // @ts-ignore
                                fillOpacity: '1',
                                stroke: 'none'
                            }}
                            d="m 17.80394,1040.7067 c 4.166879,-2.946 8.094466,-2.4059 11.585187,-0.032 0.141372,0.8184 -0.278031,1.5083
                                      -1.11785,2.1622 2.406642,1.057 0.897062,2.5003 0.199303,2.9835 2.2573,1.3191 2.181151,1.663 0.666009,3.0646
                                      -5.250919,3.1939 -7.82287,1.7669 -10.575035,0.3023 -1.13308,-0.9599 -2.021551,-1.1177 0.964501,-2.3302
                                      -2.31754,-1.1772 -2.065745,-2.3353 -0.09966,-3.3432 -1.349927,-1.07 -1.538582,-1.7451 -1.622457,-2.8077 z"
                        />
                        <path
                            className={classes.Bulb}
                            d="m 24.013393,1005.6415 c -8.007995,0 -14.5,6.492 -14.5,14.5 0,3.4078 1.188415,6.5247 3.15625,9 0.009,0.011
                                      0.02223,0.02 0.03125,0.031 3.084848,5.1457 3.372318,6.1735 3.90625,8.25 0.213766,0.8314 0.456566,2.5914
                                      1.28125,3.4063 1.846103,1.8242 9.115703,2.3353 11.53125,0 2.069117,-4.148 3.529118,-8.7439 5.875,-11.625
                                      2.000396,-2.4857 3.21875,-5.6235 3.21875,-9.0625 0,-8.008 -6.492005,-14.5 -14.5,-14.5 z"
                        />
                        <path
                            style={{
                                fill: 'url(#radialGradient4149)',
                                // @ts-ignore
                                fillOpacity: '1',
                                stroke: 'url(#linearGradient4151)',
                                strokeWidth: '1.00048339',
                                // @ts-ignore
                                strokeMiterlimit: '4',
                                // @ts-ignore
                                strokeOpacity: '1',
                                strokeDasharray: 'none'
                            }}
                            d="m 24.013393,1005.6415 c -8.007995,0 -14.5000001,6.492 -14.5000001,14.5 0,3.4078 1.1884151,6.5247 3.1562501,9
                                      0.009,0.011 0.02223,0.02 0.03125,0.031 3.084848,5.1457 3.372318,6.1735 3.90625,8.25 0.213766,0.8314
                                      0.456566,2.5914 1.28125,3.4063 1.846103,1.8242 9.115703,2.3353 11.53125,0 2.069117,-4.148 3.529118,-8.7439
                                      5.875,-11.625 2.000396,-2.4857 3.21875,-5.6235 3.21875,-9.0625 0,-8.008 -6.492005,-14.5 -14.5,-14.5 z"
                        />
                        <path
                            style={{
                                opacity: '0.75',
                                fill: 'none',
                                stroke: 'url(#linearGradient4153)',
                                strokeWidth: '1',
                                // @ts-ignore
                                strokeMiterlimit: '4',
                                // @ts-ignore
                                strokeOpacity: '1',
                                strokeDasharray: 'none'
                            }}
                            d="m 24.299671,1006.5116 c -7.788744,0.089 -13.810601,6.0526 -13.799441,13.8973 0.0047,3.2885 1.472225,6.2125
                                      2.896445,8.0208 1.25547,1.8218 3.312054,5.4871 4.010866,8.3928 0.388975,0.5498 0.426249,2.5128
                                      1.129207,3.2545 2.025898,1.4954 7.751697,1.9539 10.163716,0.089 1.931722,-4.0027 3.293928,-8.4053
                                      5.484037,-11.1855 1.829465,-2.2157 3.153354,-4.2724 3.384481,-8.7004 0.02317,-7.2372 -5.471571,-13.2097
                                      -13.269311,-13.7685 z"
                        />
                        <g transform="translate(0.20585845,-3.4566274)">
                            <path
                                style={{
                                    fill: 'none',
                                    stroke: 'url(#linearGradient4155)',
                                    strokeWidth: '1',
                                    strokeLinecap: 'round',
                                    strokeLinejoin: 'miter',
                                    // @ts-ignore
                                    strokeMiterlimit: '4',
                                    // @ts-ignore
                                    strokeOpacity: '1',
                                    strokeDasharray: 'none'
                                }}
                                d="m 18.690387,1025.9367 3.424682,8.782"
                            />
                            <path
                                style={{
                                    fill: 'none',
                                    stroke: 'url(#linearGradient4157)',
                                    strokeWidth: '1',
                                    strokeLinecap: 'round',
                                    strokeLinejoin: 'miter',
                                    // @ts-ignore
                                    strokeMiterlimit: '4',
                                    // @ts-ignore
                                    strokeOpacity: '1',
                                    strokeDasharray: 'none'
                                }}
                                d="M 28.924682,1025.9367 25.5,1034.7187"
                            />
                            <path
                                className={classes.Wire}
                                d="m 18.727678,1025.9113 c 1.097487,0.1673 2.558504,-0.051 3.348215,-1.2723 1.049519,0.5915
                                   2.122427,0.9725 3.348215,-0.022 0.793286,0.7468 1.877627,1.3589 3.482142,1.2946"
                            />
                        </g>
                        <path
                            style={{
                                opacity: '0.5',
                                fill: 'url(#linearGradient4159)',
                                // @ts-ignore
                                fillOpacity: '1',
                                stroke: '#d3cdc2',
                                strokeWidth: '1px',
                                strokeLinecap: 'round',
                                strokeLinejoin: 'round',
                                // @ts-ignore
                                strokeOpacity: '1'
                            }}
                            d="m 20.72503,1040.932 0.152641,-8.77 c 1.875363,-1.6856 4.35464,-1.6457 6.424084,0 l -0.05887,8.77"
                        />
                        <path
                            style={{
                                opacity: '0.37000002',
                                fill: '#ffffff',
                                // @ts-ignore
                                fillOpacity: '1',
                                fillRule: 'nonzero',
                                stroke: 'none'
                            }}
                            d="m 19.761109,15.548849 c 0,2.894064 -2.883162,5.240166 -6.439723,5.240166 -3.5565602,0 -6.4397222,-2.346102
                                      -6.4397222,-5.240166 0,-2.894064 2.883162,-5.240166 6.4397222,-5.240166 3.556561,0 6.439723,2.346102
                                      6.439723,5.240166 z"
                            transform="matrix(1.0883009,-0.49095888,0.51575398,1.1432637,-1.5879594,1004.417)"
                        />
                        <path
                            style={{
                                fill: '#d59600',
                                // @ts-ignore
                                fillOpacity: '1',
                                stroke: 'none'
                            }}
                            d="m 19.537615,39.27054 c 1.297337,0.874533 3.139249,1.402735 8.816413,2.109487
                               -4.420497,0.495261 -8.4085,-0.631196 -8.816413,-2.109487 z"
                            transform="translate(0,1004.3622)"
                        />
                        <path
                            d="m 19.838,1046.7323 c 1.297337,0.8746 3.139249,1.4028 8.816413,2.1095
                               -4.420497,0.4953 -8.4085,-0.6312 -8.816413,-2.1095 z"
                            style={{
                                fill: '#d59600',
                                // @ts-ignore
                                fillOpacity: '1',
                                stroke: 'none'
                            }}
                        />
                    </g>
                </g>
            </g>
            <text textAnchor="middle" x="24" y={mini ? 45 : 40} className={cx(classes.Text, mini && classes.MiniText)}>
                {percent.toFixed(2)}%
            </text>
            <text textAnchor="middle" x="24" y={mini ? 45 : 40} className={classes.Text}>{percent.toFixed(2)}%</text>
        </svg>
    );
};

const BulbWithRef = React.forwardRef(Bulb);

const Dimmer = ({ status, push, mini }: SubsystemValueProps) => {
    const percent = parseFloat((status || '0').split(':').pop() as string);

    const classes = useStyles({ percent });

    const bulb = (
        <BulbWithRef
            width={mini ? 50 : 110}
            mini={mini}
            percent={percent}
        />
    );

    if (!push) {
        return bulb;
    }

    return (
        <div>
            {bulb}
            <Slider
                orientation="vertical"
                className={classes.Slider}
                min={0} max={100}
                value={percent}
                onValueChange={v => {
                    if (percent === 0) {
                        push('on:' + v);
                    } else {
                        push(String(v));
                    }
                }}
            />
        </div>
    );
};

export default Dimmer;
