import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import Portal from '../Portal';
import style from './style';

type MIdType = string;

type MessageProps = React.ComponentProps<'div'> & {
    className?: string;
    children: React.ReactNode | React.ReactChildren;
    timeout?: number;
    defaultOpen?: boolean;
    mId: MIdType;
};

const CONTAINER_ID = `message-container-${Math.random().toString(16).substr(2)}`;

const messageStore = new Map();

export const createMId = (): MIdType => `message-${Math.random().toString(16).substr(2)}`;

export const message = (mId: MIdType) => {
    const message = messageStore.get(mId);
    if (message) {
        message.open();
    }
};

const getContainer = (className: string) => {
    const existing = document.getElementById(CONTAINER_ID);
    if (existing) {
        return existing;
    }

    const fresh = document.createElement('div');
    fresh.id = CONTAINER_ID;
    fresh.className = className;
    document.body.appendChild(fresh);

    return fresh;
};

const useStyles = createUseStyles(style);

export default function Message({ className, children, timeout = 2000, mId = createMId(), defaultOpen = false, ...props }: MessageProps) {
    const classes = useStyles();
    const [isOpen, setIsOpen] = React.useState(false);
    const timerRef = React.useRef<NodeJS.Timeout>();
    React.useEffect(() => {
        messageStore.set(mId, {
            open: () => {
                setIsOpen(true);
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }
                timerRef.current = setTimeout(() => setIsOpen(false), timeout);
            }
        });

        if (defaultOpen) {
            setIsOpen(true);
        }

        return () => {
            messageStore.delete(mId);
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [mId]);

    React.useEffect(() => {
        if (isOpen) {
            const container = getContainer(classes.MessageContainer);
            return () => {
                if (container.children.length === 0 && container.parentNode) {
                    container.parentNode.removeChild(container);
                }
            };
        }
    }, [isOpen]);

    return (
        <Portal isOpen={isOpen} container={getContainer(classes.MessageContainer)}>
            <div {...props} className={cx(classes.Message, className)}> {children} </div>
        </Portal>
    );
}
