import * as React from 'react';
import { useMutation } from '@apollo/client/react';

import SHORTCUT_ON_MUTATION from 'app/graphql/mutation/thing/shortcut/on';
import SHORTCUT_OFF_MUTATION from 'app/graphql/mutation/thing/shortcut/off';

type ShortcutMutationSig = (arg0: boolean) => void;

const useShortcutMutation = (thingId: number, subsystemId: string, shortcutId: number): ShortcutMutationSig => {
    const [pushOn] = useMutation(SHORTCUT_ON_MUTATION, { variables: { thingId, subsystemId, shortcutId } });
    const [pushOff] = useMutation(SHORTCUT_OFF_MUTATION, { variables: { thingId, subsystemId, shortcutId } });

    return React.useCallback((status: boolean) => status ? pushOn() : pushOff(), [pushOn, pushOff]);
};

export default useShortcutMutation;
