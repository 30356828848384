import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { predicate } from 'vx-std';
import OriginalValue, { renderValue as originalRenderValue } from 'app/component/Blockly/Tracer/Value';

import { StatusMark } from './blocks/input_current_device_status';
import style from './Value.style';

import type { ValueProps , RenderValueSig, RendererContext } from 'app/component/Blockly/Tracer/Value';

const useStyles = createUseStyles(style);

export function renderValue(this: RendererContext<RenderValueSig>, value: any): React.ReactNode {
    if (value?.[StatusMark]) {
        return (
            <span className={'boolean ' + (value.valueOf() ? 'boolean-true' : 'boolean-false')}>
                {this.t(`input_current_device_status.trace_value.${value.valueOf() ? 'online' : 'offline'}`)}
            </span>
        );
    }
    if (predicate.isObject(value)) {
        if (value.__typename === 'Subsystem') {
            return (
                <span className="subsystem">
                    {this.t('iot_subsystem.trace_value', { replace: value })}
                </span>
            );
        }
        if (value.__typename === 'Thing') {
            return (
                <span className="device">
                    {this.t('iot_device.trace_value', { replace: value })}
                </span>
            );
        }

    }
    return originalRenderValue.call(this,value);
}

// eslint-disable-next-line complexity
const Value = ({ className, ...props }: ValueProps, ref: React.Ref<HTMLSpanElement>) => {
    const classes = useStyles();

    return (
        <OriginalValue
            {...props}
            renderer={renderValue}
            className={cx(classes.Container, className)}
            ref={ref}
        />
    );
};

export default React.forwardRef(Value);
