import { CSS_DESKTOP_BREAKPOINT, CSS_MOBILE_BREAKPOINT } from 'app/theme';

export default {
    Container: {
        width: ['100%', '!important'],

        display: 'flex',
        flexDirection: 'column',

        '& > *': {
            width: '100%',
            flexGrow: 1,
            flexShrink: 1
        }
    },
    Layout: {
        paddingTop: 15,
        paddingBottom: 10,

        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',

        '& > *': {
            flexShrink: 0
        },

        [CSS_MOBILE_BREAKPOINT]: {
            paddingLeft: 10,
            paddingRight: 10
        }
    } as any,
    Buttons: {
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap'
    },
    Slider: {
        paddingTop: 15,

        '& input': {
            width: 300
        }
    },
    Colors: {
        width: 280,

        [CSS_MOBILE_BREAKPOINT]: {
            width: '100%'
        }
    },
    Controls: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,

        [CSS_DESKTOP_BREAKPOINT]: {
            marginLeft: 10
        }
    }
};
