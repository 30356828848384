import type { Theme } from 'app/theme';

export default ({ window }: Theme) => ({
    Arrow: {
        position: 'absolute',

        borderStyle: 'solid',
        borderWidth: [5, 5, 0, 5],
        borderColor: [window.border.color, 'transparent', 'transparent', 'transparent'],
        boxShadow: {
            x: 1,
            y: 1,
            blur: 10,
            spread: -5,
            color: '#000000'
        },

        '&::after': {
            content: '""',
            position: 'absolute',
            top: -6,
            left: -5,

            borderStyle: 'solid',
            borderWidth: [5, 5, 0, 5],
            borderColor: [window.background, 'transparent', 'transparent', 'transparent']
        }
    }
});
