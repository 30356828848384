import type { Theme } from 'app/theme';

export default ({ background }: Theme) => ({
    Layout: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',

        flexGrow: 1
    },
    Content: {
        flexGrow: 1,
        flexShrink: 1,

        display: 'flex',
        flexDirection: 'column',

        background,

        paddingBottom: 3,

        '& > *': {
            flexGrow: 1,
            flexShrink: 1
        }
    },
    Logout: {
        '@media screen and (min-width: 600px)': {
            marginLeft: 'auto'
        }
    },
    Top: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',

        flexShrink: 0
    }
});
