import * as serviceWorker from './serviceWorker';

import App from './App';
import render from './rootRenderer';

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// @ts-ignore
if (module.hot) {
    // @ts-ignore
    module.hot.accept([
        './index', './App', './component', './container/Dashboard', './connector/i18n', './page/Automations', './page/Homes', './hook',
        './theme', './subsystem', './page/Things', './page/User', './hoc', './composition/Form', './composition/Confirmation'
    ], () => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        render(require('./App').default);
    });
    // @ts-ignore
    module.hot.accept(['./rootRenderer'], () => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        require('./rootRenderer').default(require('./App').default);
    });
}
