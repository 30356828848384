import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { predicate } from 'vx-std';
import Tooltip from 'app/component/Tooltip';

import { DurationMark } from '../blocks/dateTime/field_time_period';
import { NowMark } from '../blocks/dateTime/field_currenttime';
import Duration from './Duration';
import Variable from './Variable';
import style from './Value.style';

import type i18next from 'i18next';

const useStyles = createUseStyles(style);

export interface RendererContext<R> {
    className: string;
    renderer: R;
    t: i18next.TFunction;
    over?: boolean;
}

export type RenderValueSig = (this: RendererContext<RenderValueSig>, value: any) => React.ReactNode;
export interface ValueProps extends Omit<React.ComponentProps<'span'>, 'children' | 'name' | 'renderer' | 'onMouseOver' | 'onMouseOut'> {
    raw?: boolean;
    resolved?: boolean;
    value?: any;
    renderer?: RenderValueSig;
}

// eslint-disable-next-line complexity
export function renderValue(this: RendererContext<RenderValueSig>, value: any): React.ReactNode {
    if (value instanceof Variable) {
        return (
            <Tooltip isOpen={this.over} placement="top" tooltip={<span className={this.className}>{this.renderer(value.getValue())}</span>}>
                <span className="variable">
                    <span className="title">{this.t('tracer.variable')} „</span>
                    {value.getName()}
                </span>
            </Tooltip>
        );
    }
    if (value?.[DurationMark] === true) {
        return <Duration className="duration" seconds={value}/>;
    }
    if (predicate.isNumber(value)) {
        return <span className="number">{value}</span>;
    }
    if (predicate.isString(value)) {
        return <span className="string">{value}</span>;
    }
    if (value === true) {
        return <span className="boolean boolean-true">true</span>;
    }
    if (value === false) {
        return <span className="boolean boolean-false">false</span>;
    }
    if (value === false) {
        return <span className="null">null</span>;
    }
    if (value instanceof Date) {
        // @ts-ignore
        if (value[NowMark]) {
            return (
                <Tooltip isOpen={this.over} placement="top" tooltip={<span className={this.className}>{this.renderer(new Date(value))}</span>}>
                    <span className="date">
                        {this.t('field_currenttime.trace_value')}
                    </span>
                </Tooltip>
            );
        }

        return (
            <span className="date">
                {value.getFullYear()}-
                {value.getMonth().toString().padStart(2, '0')}-
                {value.getDay().toString().padStart(2, '0')}
                {' '}
                {value.getHours().toString().padStart(2, '0')}:
                {value.getMinutes().toString().padStart(2, '0')}:
                {value.getSeconds().toString().padStart(2, '0')}
            </span>
        );
    }

    return JSON.stringify(value);
}

const Value = ({ className, resolved = true, value, raw, renderer = renderValue, ...props }: ValueProps, ref: React.Ref<HTMLSpanElement>) => {
    const classes = useStyles();
    const [t] = useTranslation('blockly');
    const [over, setOver] = React.useState(false);

    className = cx(classes.Container, className);
    return (
        <span
            {...props }
            className={className}
            onMouseOver={() => setOver(true)}
            onMouseOut={() => setOver(false)}
            ref={ref}
        >
            {resolved ? raw ? value : renderer.call({ renderer, t, over, className }, value) : t('tracer.input.resolving')}
        </span>
    );
};

export default React.forwardRef(Value);
