import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

import Button, { ButtonKind, ButtonSize } from '../Button';

import style from './Rule.style';

interface RuleProps extends Omit<React.ComponentProps<'div'>, 'id'> {
    id: number;
    onDelete: () => void;
}

const useStyles = createUseStyles(style);

export default function Rule({ id, onDelete, children, className, ...props }: RuleProps) {
    const classes = useStyles();
    const [t] = useTranslation('components');
    return (
        <div id={String(id)} {...props} className={cx(classes.Container, className)}>
            <div className={classes.Header}>
                <div className={classes.Number}>{id}.</div>
                <Button kind={ButtonKind.danger} size={ButtonSize.small} onClick={onDelete}>{t('cron.remove-rule')}</Button>
            </div>
            <div className={classes.Options}>
                {children}
            </div>
        </div>
    );
}
