import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import style from './ShortcutBase.style';

const useStyles = createUseStyles(style);

export type ShortcutBaseProps = JSX.IntrinsicElements['div'] & {
    disabled?: boolean;
};

const ShortcutBase = ({ className, children, disabled, ...props }: ShortcutBaseProps) => {
    const ref = React.useRef<HTMLDivElement & Node>();
    const [wrapped, setWrapped] = React.useState(false);
    const classes = useStyles();

    React.useEffect(() => {
        if (ref.current) {
            const obj = ref.current;
            const childPos = obj.offsetTop || 0;

            if (childPos > 5) {
                setWrapped(true);
            } else {
                setWrapped(false);
            }
        }
    });
    return (
        <div {...props} ref={ref as any} className={cx(classes.Shortcut, disabled && classes.Disabled, wrapped && classes.Wrapped, className)}>
            {children}
        </div>
    );
};

export default ShortcutBase;
