import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import Button, { ButtonKind } from '../../Button';
import Radio from '../../Radio';
import Checkbox from '../../Checkbox';
import Select from '../../Select';
import NumEntry from '../NumEntry';
import Rule from '../Rule';
import style from './style';
import { RuleMode, makeActionCreators, makeInitialState, makeReducer, stateToExpression } from './state';

type MinutesProps = Omit<React.ComponentProps<'div'>, 'onChange'> & {
    close?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    value?: string;
    onChange?: (value: string) => void;
};

const useStyles = createUseStyles(style);

const minutes = (new Array(60)).fill(0).map((_, i) => i);
const actions =  makeActionCreators<number>();
const reducer =  makeReducer<number>();

const Minutes = ({ className, onChange, value, close, ...props }: MinutesProps, ref?: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    const [t] = useTranslation('components');
    const [state, dispatch] = React.useReducer(reducer, value, makeInitialState);

    React.useEffect(() => {
        onChange?.(stateToExpression(state));
    }, [state]);

    return (
        <div {...props} ref={ref} className={cx(classes.Container, className)}>
            <div className={cx(classes.All, state.all || classes.Hidden)}>
                {t('cron.minutes.all')}
            </div>
            <div className={classes.Rules}>
                {state.rules.map((rule, index) => (
                    <Rule id={index + 1} onDelete={() => dispatch(actions.removeRule(index))} key={`${index}-${rule.mode}`} >
                        <div className={classes.Mode}>
                            <Radio
                                checked={rule.mode === RuleMode.every}
                                onChange={() => dispatch(actions.setMode(index, RuleMode.every))}
                            >
                                {t('cron.minutes.every')}
                                {' '}
                                <Select
                                    disabled={rule.mode !== RuleMode.every}
                                    value={rule.every || ''}
                                    onSelect={(value) => dispatch(actions.setEvery(index, value))}
                                >
                                    {minutes.slice(2).map((i) => (
                                        <NumEntry key={i} id={i}>{i}</NumEntry>
                                    ))}
                                </Select>
                            </Radio>
                            {' '}
                            {t('cron.minutes.minutes')}
                        </div>
                        <div className={classes.Mode}>
                            <Radio
                                checked={rule.mode === RuleMode.specific}
                                onChange={() => dispatch(actions.setMode(index, RuleMode.specific))}
                            >
                                {t('cron.minutes.specific')}
                            </Radio>
                            <div className={cx(classes.Grid, rule.mode !== RuleMode.specific && classes.Hidden)}>
                                {minutes.map((i) => (
                                    <Checkbox
                                        key={i}
                                        disabled={rule.mode !== RuleMode.specific}
                                        checked={rule.specific.includes(i)}
                                        onChange={() => dispatch(actions.toggleSpecific(index, i))}
                                    >
                                        {i.toString().padStart(2, '0')}
                                    </Checkbox>
                                ))}
                            </div>
                        </div>
                        <div className={classes.Mode}>
                            <Radio
                                checked={rule.mode === RuleMode.between}
                                onChange={() => dispatch(actions.setMode(index, RuleMode.between))}
                            >
                                {t('cron.minutes.between')}
                                {' '}
                                <Select
                                    disabled={rule.mode !== RuleMode.between}
                                    value={rule.between.min || ''}
                                    onSelect={(value) => dispatch(actions.setBetweenMin(index, value))}
                                >
                                    {minutes.map((i) => (
                                        <NumEntry key={i} id={i}>{i.toString().padStart(2, '0')}</NumEntry>
                                    ))}
                                </Select>
                                {' '}
                                {t('cron.minutes.between-and')}
                                {' '}
                                <Select
                                    disabled={rule.mode !== RuleMode.between}
                                    value={rule.between.max || ''}
                                    onSelect={(value) => dispatch(actions.setBetweenMax(index, value))}
                                >
                                    {minutes.map((i) => (
                                        <NumEntry key={i} id={i}>{i.toString().padStart(2, '0')}</NumEntry>
                                    ))}
                                </Select>
                            </Radio>
                        </div>
                    </Rule>
                ))}
            </div>
            <div className={classes.Buttons}>
                <Button
                    className={classes.Ok}
                    kind={ButtonKind.primary}
                    onClick={close}
                >
                    {t('cron.ok')}
                </Button>
                <Button
                    className={classes.Add}
                    kind={ButtonKind.success}
                    onClick={() => dispatch(actions.addRule())}
                >
                    {t('cron.add-rule')}
                </Button>
            </div>
        </div>
    );
};

export default React.forwardRef(Minutes);
