import { promise } from 'vx-std';
import { FieldTextInput } from 'app/component/Blockly/fields';
import { TranslatorSymbol } from 'app/component/Blockly/blockly';

import { CATEGORY_OUTPUT_COLOR } from './constants';

import type { Node, Block } from 'app/component/Blockly/types';
import type IotInterpreter from '../IotInterpreter';

export const name = 'output_alert';

export function builder(this: Block) {
    this.appendDummyInput()
        .appendField('${alert}')
        .appendField(new FieldTextInput(this[TranslatorSymbol]('output_alert.name')), 'name');
    this.setColour(CATEGORY_OUTPUT_COLOR);
    this.setTooltip('');
    this.setHelpUrl('');
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
}

export default async function run(this: IotInterpreter, node: Node): Promise<void> {
    const name = node.fields?.name;
    if (!name) {
        throw new Error('name missing');
    }

    this.setOutput(node.id, true);
    await promise.wait(5000);
    this.setOutput(node.id, false);
}
