import Blockly, { TranslatorSymbol } from '../../blockly';
import EarlyReturn from '../../runner/EarlyReturn';

import type { Block, Interpreter, Node } from '../../types';

interface IfReturnBlock extends Block {
    hasReturnValue_: boolean
}

/**
 * List of block types that are functions and thus do not need warnings.
 * To add a new function type add this to your code:
 * import { FUNCTION_TYPES } from '...';
 * FUNCTION_TYPES.push('custom_func');
 */
export const FUNCTION_TYPES = ['procedures_defnoreturn', 'procedures_defreturn'];

export const builder = {
    /**
     * Block for conditionally returning a value from a procedure.
     * @this {Blockly.Block}
     */
    init(this: IfReturnBlock) {
        this.appendValueInput('CONDITION')
            .setCheck('Boolean')
            .appendField(this[TranslatorSymbol]('procedures.ifreturn.if'));
        this.appendValueInput('VALUE')
            .appendField(this[TranslatorSymbol]('procedures.ifreturn.return'));
        this.setInputsInline(true);
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle('procedure_blocks');
        this.hasReturnValue_ = true;
    },
    /**
     * Create XML to represent whether this block has a return value.
     * @return {!Element} XML storage element.
     * @this {Blockly.Block}
     */
    mutationToDom(this: IfReturnBlock) {
        const container = Blockly.utils.xml.createElement('mutation');
        container.setAttribute('value', String(Number(this.hasReturnValue_)));
        return container;
    },
    domToMutation(this: IfReturnBlock, xmlElement: Element) {
        const value = xmlElement.getAttribute('value');
        this.hasReturnValue_ = (value == '1');
        if (!this.hasReturnValue_) {
            this.removeInput('VALUE');
            this.appendDummyInput('VALUE')
                .appendField(this[TranslatorSymbol]('procedures.ifreturn.return'));
        }
    },
    // eslint-disable-next-line max-statements,complexity
    onchange(this: IfReturnBlock, _e: Blockly.Events.Abstract) {
        // @ts-ignore
        if (!this.workspace.isDragging || this.workspace.isDragging()) {
            return;  // Don't change state at the start of a drag.
        }
        let legal = false;
        // Is the block nested in a procedure?
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let block: Blockly.Block = this;
        do {
            if (FUNCTION_TYPES.indexOf(block.type) != -1) {
                legal = true;
                break;
            }
            block = block.getSurroundParent();
        } while (block);
        if (legal) {
            // If needed, toggle whether this block has a return value.
            if (block.type == 'procedures_defnoreturn' && this.hasReturnValue_) {
                this.removeInput('VALUE');
                this.appendDummyInput('VALUE')
                    .appendField(this[TranslatorSymbol]('procedures.ifreturn.return'));
                this.hasReturnValue_ = false;
            } else if (block.type == 'procedures_defreturn' &&
                !this.hasReturnValue_) {
                this.removeInput('VALUE');
                this.appendValueInput('VALUE')
                    .appendField(this[TranslatorSymbol]('procedures.ifreturn.return'));
                this.hasReturnValue_ = true;
            }
            this.setWarningText('');
            if (!this.isInFlyout) {
                this.setEnabled(true);
            }
        } else {
            this.setWarningText(this[TranslatorSymbol]('procedures.ifreturn.warning'));
            if (!this.isInFlyout && !this.getInheritedDisabled()) {
                this.setEnabled(false);
            }
        }
    }
};

export default async function run(this: Interpreter, node: Node): Promise<any> {
    let condition = false;
    if (node.values?.CONDITION) {
        condition = await this.execute(node.values.CONDITION);
    }
    let value = null;
    if (node.values?.VALUE) {
        value = await this.execute(node.values.VALUE);
    }

    if (condition) {
        throw new EarlyReturn(value);
    }
}
