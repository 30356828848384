import * as React from 'react';
import { validator } from 'vx-form';
import { InputField } from 'app/composition/Form';
import MutationWindow from 'app/composition/MutationWindow';
import { useModal } from 'app/hook';
import RENAME_ROOM_MUTATION from 'app/graphql/mutation/room/rename';
import type i18next from 'i18next';
import type { CloserSig } from 'app/hook/useModal';

export default (id: number, name: string, t: i18next.TFunction) => useModal((close: CloserSig) => (
    <MutationWindow mutation={RENAME_ROOM_MUTATION} close={close} title={t('room.rename.title')}
        labelOk={t('room.rename.change')} labelCancel={t('room.rename.cancel')}
        variables={{ id }} initialValues={{ name }}>
        <InputField name="name" placeholder={t('room.rename.new-name')} validator={validator.required()}/>
    </MutationWindow>
));
