import * as React from 'react';
import { createUseStyles } from 'react-jss';
// @ts-ignore
import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle';
import cx from 'classnames';
import style from './style';

const useStyles = createUseStyles(style);

export enum DateTimeResolution {
    Hour = 'hour',
    Minute = 'minute',
    Second = 'second',
}

export interface DateTimeProps {
    amPmAriaLabel?: string;
    autoFocus?: boolean;
    calendarAriaLabel?: string;
    calendarClassName?: string | string[];
    calendarIcon?: React.ReactNode;
    className?: string | string[];
    clearAriaLabel?: string;
    clearIcon?: React.ReactNode;
    clockClassName?: string | string[];
    closeWidgets?: boolean;
    dayAriaLabel?: string;
    dayPlaceholder?: string;
    disableCalendar?: boolean;
    disableClock?: boolean;
    disabled?: boolean;
    format?: string;
    hourAriaLabel?: string;
    hourPlaceholder?: string;
    isCalendarOpen?: boolean;
    isClockOpen?: boolean;
    locale?: string;
    maxDate?: Date;
    maxDetail?: DateTimeResolution;
    minDate?: Date;
    minuteAriaLabel?: string;
    minutePlaceholder?: string;
    monthAriaLabel?: string;
    monthPlaceholder?: string;
    name?: string;
    nativeInputAriaLabel?: string;
    onCalendarClose?: () => void;
    onCalendarOpen?: () => void;
    onChange?: (value: Date) => void;
    onClockClose?: () => void;
    onClockOpen?: () => void;
    onFocus?: () => void;
    openWidgetsOnFocus?: boolean;
    required?: boolean;
    secondAriaLabel?: string;
    secondPlaceholder?: string;
    showLeadingZeros?: boolean;
    value?: Date | Date[];
    yearAriaLabel?: string;
    yearPlaceholder?: string;
}

export default function DateTime({ className, ...props }: DateTimeProps) {
    const classes = useStyles();

    return (
        <DateTimePicker {...props} className={cx(classes.DateTime, className)}/>
    );
}
