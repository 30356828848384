import Blockly, { TranslatorSymbol } from '../../blockly';

import type { Interpreter, Node } from '../../types';
import type { BlockDefinition, TypeBlockDefinition } from '../types';

export const name = 'controls_if';

export const definition: BlockDefinition = {
    message0: '${if} %1',
    args0: [
        {
            type: 'input_value',
            name: 'IF0',
            check: 'Boolean'
        }
    ],
    message1: '${do} %1',
    args1: [
        {
            type: 'input_statement',
            name: 'DO0'
        }
    ],
    previousStatement: null,
    nextStatement: null,
    style: 'logic_blocks',
    mutator: 'controls_if_mutator'
};

export const helperDefinitions: TypeBlockDefinition[] = [
    // Block representing the if statement in the controls_if mutator.
    {
        type: 'controls_if_if',
        message0: '${if}',
        nextStatement: null,
        enableContextMenu: false,
        style: 'logic_blocks'
    },
    // Block representing the else-if statement in the controls_if mutator.
    {
        type: 'controls_if_elseif',
        message0: '${elseif}',
        previousStatement: null,
        nextStatement: null,
        enableContextMenu: false,
        style: 'logic_blocks'
    },
    // Block representing the else statement in the controls_if mutator.
    {
        type: 'controls_if_else',
        message0: '${else}',
        previousStatement: null,
        enableContextMenu: false,
        style: 'logic_blocks'
    }
];

// eslint-disable-next-line complexity
export default async function run(this: Interpreter, node: Node): Promise<void> {
    if (node.statements) {
        for (let i = 0; i <= (node.mutations?.elseif || 0); ++i) {
            const conditionNode = node.values?.['IF' + i];
            if (!conditionNode) {
                throw new Error('condition ' + i + ' missing');
            }
            const condition = await this.execute(conditionNode);
            if (condition) {
                const statementNode = node.statements['DO' + i];
                if (statementNode) {
                    await this.executeStatement(statementNode);
                }
                return;
            }
        }
        const elseNode = node.statements?.ELSE;
        if (elseNode) {
            await this.executeStatement(elseNode);
        }
    }
}
run.noTrace = true;

// @ts-ignore
Blockly.Constants.Logic.CONTROLS_IF_MUTATOR_MIXIN.updateShape_ = function() {
    // Delete everything.
    if (this.getInput('ELSE')) {
        this.removeInput('ELSE');
    }

    let i = 1;
    while (this.getInput('IF' + i)) {
        this.removeInput('IF' + i);
        this.removeInput('DO' + i);
        i++;
    }
    // Rebuild block.
    for (i = 1; i <= this.elseifCount_; i++) {
        this.appendValueInput('IF' + i)
            .setCheck('Boolean')
            .appendField(this[TranslatorSymbol]('${elseif}'));
        this.appendStatementInput('DO' + i)
            .appendField(this[TranslatorSymbol]('${do}'));
    }
    if (this.elseCount_) {
        this.appendStatementInput('ELSE')
            .appendField(this[TranslatorSymbol]('controls_if.else'));
    }
};
