import * as React from 'react';
import cx from 'classnames';
import { EntypoChevronRight, EntypoPencil, EntypoSquaredCross } from 'react-entypo-icons';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Title } from 'app/component';
import { TitleSize } from 'app/component/Title/style';
import { withPopconfirm, withTooltip } from 'app/hoc';
import useRenameRoomModal from './useRenameRoomModal';
import useRemoveRoomMutation from './useRemoveRoomMutation';
import style from './style';
import type { Box } from 'app/component';

type RoomProps = Omit<React.ComponentProps<typeof Box>, 'id'> & {
    id: number;
    name: string;
};

const useStyles = createUseStyles(style);

const DivWithTooltip = withTooltip('div');
const DivWithConformAndTooltip = withPopconfirm(DivWithTooltip);

const Room = ({ id, name, className, ...props }: RoomProps) => {
    const classes = useStyles();
    const [t] = useTranslation('homes');
    const [renameRoom, openRenameRoom] = useRenameRoomModal(id, name, t);
    const remove = useRemoveRoomMutation(id);

    return (
        <div {...props} className={cx(classes.Room, className)}>
            {renameRoom}
            <EntypoChevronRight valign="bottom"/><Title size={TitleSize.small}>{name}</Title>
            <div className={classes.Buttons}>
                <DivWithTooltip tooltip={t('room.rename.tooltip')} onClick={openRenameRoom}>
                    <EntypoPencil/>
                </DivWithTooltip>
                <DivWithConformAndTooltip className={classes.Danger} tooltip={t('room.remove.tooltip')}
                    confirmLabel={t('room.remove.confirm')}
                    message={t('room.remove.message', { name })} onClick={() => remove()}>
                    <EntypoSquaredCross/>
                </DivWithConformAndTooltip>
            </div>
        </div>
    );
};

export default Room;
