import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { EntypoCross, EntypoEdit } from 'react-entypo-icons';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import useShortcutSubscription from 'app/hook/useSubsystem/useShortcutSubscription';
import useShortcutMutation from 'app/hook/useSubsystem/useShortcutMutation';
import { withConfirmation, withMutation, withTooltip } from 'app/hoc';
import SHORTCUT_REMOVE_MUTATION from 'app/graphql/mutation/thing/shortcut/remove';
import RenameShortcut, { withRenameTrigger } from 'app/page/Things/component/Shortcuts/RenameShortcut';
import ShortcutBase from './ShortcutBase';
import style from './Shortcut.style';

type ShortcutProps = Omit<React.ComponentProps<typeof ShortcutBase>, 'onClick'|'id'> & {
    thingId: number;
    subsystemId: string;
    id: number;
    status: boolean
    name: string;
    editing?: boolean;
}

const useStyles = createUseStyles(style);

const DivWithTooltip = withTooltip('div');
const ButtonRename = withRenameTrigger(DivWithTooltip);
const ButtonRemove = withMutation(SHORTCUT_REMOVE_MUTATION)(withConfirmation(DivWithTooltip));

const Shortcut = ({ id, thingId, subsystemId, name, status, editing, className, ...props }: ShortcutProps) => {
    const classes = useStyles();
    const [t] = useTranslation('shortcut');

    const onOff = useShortcutMutation(thingId, subsystemId, id);
    useShortcutSubscription(id);

    const onClick = React.useCallback(() => {
        if (!editing) {
            onOff(!status);
        }
    }, [onOff, status, editing]);

    return (
        <ShortcutBase {...props} className={cx(className, status && classes.Active)} onClick={onClick}>
            <RenameShortcut mId={String(id)} thingId={thingId} subsystemId={subsystemId} id={id} name={name} />
            <div className={cx(classes.Title, editing && classes.Editing)}>{name}</div>
            {editing && (
                <React.Fragment>
                    <ButtonRename mId={String(id)} className={classes.Edit} tooltip={t('rename.tooltip')}>
                        <EntypoEdit />
                    </ButtonRename>
                    <ButtonRemove
                        tooltip={t('remove.tooltip')}
                        confirmLabel={t('remove.confirm')}
                        message={t('remove.message', { name })}
                        className={classes.Delete}
                        variables={{ thingId, subsystemId, id }}
                    >
                        <EntypoCross />
                    </ButtonRemove>
                </React.Fragment>
            )}
        </ShortcutBase>
    );
};

export default Shortcut;
