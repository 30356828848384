import Blockly  from 'blockly';
import { CATEGORY_INPUT_COLOR } from './constants';

import type { BlockCategoryInfo } from 'app/component/Blockly/blocks/types';
import type { ContextWorkspace } from 'app/component/Blockly/types';
import type { Context } from '../context';

export * as iot_device from './iot_device';

export default {
    name: '${iot-things}',
    colour: CATEGORY_INPUT_COLOR,
    custom(workspace: ContextWorkspace<Context>) {
        return workspace.context.things.map((thing) => {
            const field = Blockly.utils.xml.createElement('field');
            field.setAttribute('name', 'device');
            field.appendChild(Blockly.utils.xml.createTextNode(String(thing.id)));
            
            const block = Blockly.utils.xml.createElement('block');
            block.setAttribute('type', 'iot_device');
            block.setAttribute('gap', '8');
            block.appendChild(field);
            return block;
        });
    }
} as BlockCategoryInfo;
