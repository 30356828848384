import type { FlatNode, Node } from '../types';

// eslint-disable-next-line max-statements,complexity
function hydrateBlock(this: Map<string, FlatNode | Node>, node: FlatNode | Node): Node {
    const block: Node = {
        id: node.id,
        type: node.type
    };

    if (node.comment) {
        block.comment = node.comment;
    }

    if (node.fields) {
        block.fields = { ...node.fields };
    }

    if (node.mutations) {
        block.mutations = { ...node.mutations };
    }

    if (node.values) {
        block.values = {};
        for (const [name, idOrNode] of Object.entries(node.values)) {
            if (typeof idOrNode === 'string') {
                block.values[name] = hydrateBlock.call(this, this.get(idOrNode)!);
            } else {
                block.values[name] = idOrNode;
            }
        }
    }
    if (node.statements) {
        block.statements = {};
        for (const [name, idOrNode] of Object.entries(node.statements)) {
            if (typeof idOrNode === 'string') {
                block.statements[name] = hydrateBlock.call(this, this.get(idOrNode)!);
            } else {
                block.statements[name] = idOrNode;
            }
        }
    }
    if (node.next) {
        if (typeof node.next === 'string') {
            block.next = hydrateBlock.call(this, this.get(node.next)!);
        } else {
            block.next = node.next;
        }
    }

    this.delete(node.id);
    return block;
}

export const hydrator = (input: FlatNode[]): Node[] => {
    const map = new Map<string, FlatNode | Node>();
    for (const node of input) {
        map.set(node.id, node);
    }

    for (const node of input) {
        if (map.has(node.id)) {
            map.set(node.id, hydrateBlock.call(map, node));
        }
    }

    return [ ...map.values() ] as Node[];
};
