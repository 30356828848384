import type { Theme } from 'app/theme';

export default (theme: Theme) => ({
    Menu: {
        display: 'flex',
        flexDirection: 'column',

        background: theme.window.background,

        border: {
            width: 1,
            style: 'solid',
            color: theme.box.border.color
        },

        padding: {
            top: 10,
            bottom: 15
        },

        borderRadius: 6,

        userSelect: 'none',

        boxShadow: {
            x: 2,
            y: 2,
            blur: 10,
            spread: -3,
            color: '#121212'
        },

        '& > * + *': {
            marginTop: 5
        },

        width: 300,
        maxHeight: '40vh',
        overflow: 'scroll'
    }
});
