import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import style from './Background.style';

const useStyles = createUseStyles(style);

const Background = ({ className, ...props }: JSX.IntrinsicElements['div'], ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return <div {...props} className={cx(classes.Background, className)} ref={ref}/>;
};

export default React.forwardRef(Background);
