import * as React from 'react';
import { Query, Subscription } from '@apollo/client/react/components';
import { object } from 'vx-std';
import { getDisplayName } from 'app/utils/react';
import { Loader, Watch } from 'app/component';
import THING_UPDATED_SUBSCRIPTION from 'app/graphql/subscription/onThingUpdate';
import ON_SHORTCUT_UPDATE_SUBSCRIPTION from 'app/graphql/subscription/onShortcutUpdate';
import THING_STATUS_SUBSCRIPTION from 'app/graphql/subscription/onThingStatus';
import THING_QUERY from 'app/graphql/query/thing';

import type { ThingQueryData } from 'app/graphql/query/thing';
import type { ThingType } from 'app/graphql/fragment/thing';
import type { QueryResult } from '@apollo/client';
import type { SubscribeToMoreOptions } from '@apollo/client';
import type { OnShortcutUpdateSubscription } from 'app/graphql/subscription/onShortcutUpdate';

interface WithThingProps {
    id: number;
}

interface WithThingDerivedProps {
    thing: ThingType;
}

export default (<P extends WithThingDerivedProps>(Component: React.ComponentType<P>) => {
    return class WithThing extends React.Component<Omit<P, 'thing'|'id'> & WithThingProps> {

        static displayName = `WithThing(${getDisplayName(Component)})`;

        constructor(...args: any) {
            // @ts-ignore
            super(...args);

            this.renderThing = this.renderThing.bind(this);
        }

        watch(subscribeToMore: (options: SubscribeToMoreOptions<ThingQueryData, any, OnShortcutUpdateSubscription>) => () => void) {
            return subscribeToMore({
                document: ON_SHORTCUT_UPDATE_SUBSCRIPTION,
                // eslint-disable-next-line complexity
                updateQuery: (prev, { subscriptionData }) => {
                    if (!subscriptionData.data || !prev) return prev;
                    const shortcutUpdate = subscriptionData.data.onShortcutUpdate;


                    const subsystems = prev.thing.subsystems;
                    const subsystemIndex = subsystems.findIndex(subsystem => {
                        if (!shortcutUpdate.subsystemId) {
                            return subsystem.shortcuts.some((shortcut) => shortcut.id === shortcutUpdate.id);
                        }
                        return subsystem.id === shortcutUpdate.subsystemId;
                    }) || -1;
                    if (subsystemIndex >= 0) {
                        const path = `thing.subsystems[${subsystemIndex}].shortcuts`;
                        const base = subsystems[subsystemIndex].shortcuts;

                        const index = base.findIndex(thing => thing.id === shortcutUpdate.id) || -1;
                        if (index >= 0) {
                            if (shortcutUpdate.deleted) {
                                const things = [...base];
                                things.splice(index, 1);
                                return object.replaceIn(prev, path, things);
                            }

                            const things = [...base];
                            things.splice(index, 1, shortcutUpdate);
                            return object.replaceIn(prev, path, things);
                        }

                        if (!shortcutUpdate.deleted) {
                            return object.replaceIn(prev, path, [...base, shortcutUpdate]);
                        }
                    }

                    return prev;
                }
            });
        }

        renderThing({ loading, data, subscribeToMore }: QueryResult<ThingQueryData>) {
            if (loading || !data || !data.thing) {
                return <Loader/>;
            }

            const { id, ...props } = this.props;

            return (
                <Watch watch={() => this.watch(subscribeToMore)}>
                    {/* @ts-ignore */}
                    <Component {...props} thing={data.thing}/>
                </Watch>
            );
        }

        render() {
            return (
                <React.Fragment>
                    <Subscription subscription={THING_UPDATED_SUBSCRIPTION}/>
                    <Subscription subscription={THING_STATUS_SUBSCRIPTION} variables={{ id: this.props.id }}/>
                    <Query query={THING_QUERY} variables={{ id: this.props.id }}>
                        {this.renderThing}
                    </Query>
                </React.Fragment>
            );
        }
    };
});
