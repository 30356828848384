import type { Theme } from 'app/theme';

export default ({ background }: Theme) => ({
    Wrapper: {
        position: 'fixed',
        width: '100vw',
        height: '100vh',

        background
    },
    Window: {
        width: 350
    },
    Content: {
        '& > * + *': {
            marginTop: 10
        }
    },
    BGLoader: {
        position: 'absolute',
        right: -50,
        bottom: 100,
        width: '110vmin',
        height: '110vmin',
        zIndex: -1
    }
});
