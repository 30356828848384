import gql from 'graphql-tag';
import { object } from 'vx-std';
import { extractThingId } from 'app/helper/subsystem';
import subsystemHistory from '../query/subsystemHistory';

import type { SubsystemHistoryType } from '../query/subsystemHistory';
import type { DataProxy } from '@apollo/client/cache/core/types/DataProxy';

export const typeDef = gql`
    extend type Mutation {
        pushSubsystemHistory(id: ID!, time: DateTime!, data: JSON!, from: DateTime, interval: String!): Boolean
    }
`;

export const resolver = (_: any, { id, time, data, interval, from }: any, { cache }: { cache: DataProxy }) => {
    const variables = { thingId: extractThingId(id), subsystemId: id, interval, from };
    try {
        const root = cache.readQuery<SubsystemHistoryType>({ query: subsystemHistory, variables });
        const subsystem = root?.thing?.subsystem;
        if (subsystem) {
            const newEntry = {
                __typename: 'HistoryEntry',
                ...(subsystem.history?.[0] || {}),
                data,
                time: time.toISOString()
            };

            const newSubsystem = object.replaceIn(root, 'thing.subsystem.history', [...subsystem.history, newEntry]);
            cache.writeQuery({ query: subsystemHistory, variables, data: newSubsystem });
            return true;
        }
    } catch (e) {
        // no-op
    }

    return false;
};

export default gql`
    mutation PushSubsystemHistoryMutation($id: ID!, $time: DateTime!, $data: JSON!, $from: DateTime, $interval: String!) {
        pushSubsystemHistory(id: $id, time: $time, data: $data, from: $from, interval: $interval) @client
    }
`;
