import gql from 'graphql-tag';
import subsystem from './subsystem';
import shortcut from './shortcut';
import thingBase from './thing-base';
import type { ShortcutType } from './shortcut';
import type { SubsystemType } from './subsystem';
import type { ThingBaseType } from './thing-base';

export default gql`
    fragment thing on Thing {
        ...thingBase
        subsystems {
            ...subsystem
        }
        shortcuts {
            ...shortcut
        }
    }
    ${subsystem}
    ${shortcut}
    ${thingBase}
`;

export type ThingType = ThingBaseType & {
    subsystems: Array<SubsystemType>;
    shortcuts: Array<ShortcutType>;
};
