import * as React from 'react';
import {
    EntypoDirection,
    EntypoErase,
    EntypoEraser,
    EntypoHome,
    EntypoLoop,
    EntypoPencil,
    EntypoUploadToCloud
} from 'react-entypo-icons';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Box, Title } from 'app/component';
import { withConfirmation, withMutation, withPopconfirm, withPushSubsystem, withThing, withTooltip } from 'app/hoc';
import { segregateSubsystems } from 'app/helper/subsystem';
import REMOVE_THING_MUTATION from 'app/graphql/mutation/thing/remove';
import { TitleSize } from 'app/component/Title/style';
import HistoryProvider from 'app/hook/useSubsystem/history/Provider';

import Subsystem from '../Subsystem';
import Status from './Status';
import Rename, { withRenameTrigger } from './Rename';
import ChangeRoom, { withChangeRoomTrigger } from './ChangeRoom';

import style from './style';
import type { ThingType } from 'app/graphql/fragment/thing';
import type { SubsystemType } from 'app/graphql/fragment/subsystem';

type ThingProps = {
    thing: ThingType;
};

const indexOr = (thingType: string, ssType: string, fallback: number) => {
    const index = thingType.indexOf(ssType);

    if (index < 0) {
        return fallback;
    }

    return index;
};

const sortSubsystems = (thing: ThingType) => (a: SubsystemType, b: SubsystemType) => {
    const aPos = indexOr(thing.type, a.type, Infinity);
    const bPos = indexOr(thing.type, b.type, Infinity);

    if (aPos > bPos) {
        return 1;
    } else if (aPos < bPos) {
        return -1;
    }

    return a.name.localeCompare(b.name);
};

const DivWithTooltip = withTooltip('div');
const ButtonRename = withRenameTrigger(DivWithTooltip);
const ButtonSetRoom = withChangeRoomTrigger(DivWithTooltip);
const ButtonIdentify = withPushSubsystem(DivWithTooltip);
const ButtonUpdate = withPushSubsystem(withPopconfirm(DivWithTooltip));
const ButtonReset = withPushSubsystem(withPopconfirm(DivWithTooltip));
const ButtonWipe = withPushSubsystem(withConfirmation(DivWithTooltip));
const ButtonRemove = withMutation(REMOVE_THING_MUTATION)(withConfirmation(DivWithTooltip));

const useStyles = createUseStyles(style);

const Thing = ({ thing }: ThingProps): React.ReactElement<'div'> => {
    const classes = useStyles();
    const [t] = useTranslation('thing');

    const subsystems = segregateSubsystems(thing.subsystems);
    subsystems.thing.sort(sortSubsystems(thing));

    return (
        <HistoryProvider>
            <div className={classes.Thing}>
                <Rename id={thing.id} name={thing.name}/>
                <ChangeRoom id={thing.id} roomId={thing.room?.id}/>
                <Box>
                    <div className={classes.Row}>
                        <Title size={TitleSize.large} className={classes.Title}>{thing.name}</Title>
                        <Status id={subsystems.core.status.id}/>
                    </div>
                    <div className={classes.Row}>
                        <div className={classes.AlwaysRow}>
                            <div className={classes.Meta}>
                                <div><b>{t('meta.sn')}: </b>{thing.serial}</div>
                                <div><b>{t('meta.type')}: </b>{thing.type}</div>
                            </div>
                            {thing.room && <div className={classes.Room}>
                                <div><b>{t('meta.room')}:</b></div>
                                <div>{thing.room.name}</div>
                            </div>}
                            {thing.room && thing.room.home && <div className={classes.Home}>
                                <div><b>{t('meta.home')}:</b></div>
                                <div>{thing.room.home.name}</div>
                            </div>}
                        </div>
                        <div className={classes.Buttons}>
                            <ButtonRename tooltip={t('core.rename.tooltip')}>
                                <EntypoPencil/>
                            </ButtonRename>
                            <ButtonSetRoom tooltip={t('core.room.tooltip')}>
                                <EntypoHome/>
                            </ButtonSetRoom>
                            <ButtonIdentify
                                tooltip={t('core.identify.tooltip')}
                                className={classes.Identify}
                                id={subsystems.core.identify.id}
                            >
                                <EntypoDirection/>
                            </ButtonIdentify>
                            <ButtonUpdate
                                tooltip={t('core.update.tooltip')} confirmLabel={t('core.update.confirm')}
                                message={t('core.update.message', { name: thing.name })}
                                className={classes.Update} id={subsystems.core.update.id} confirmKind="primary"
                            >
                                <EntypoUploadToCloud/>
                            </ButtonUpdate>
                            <ButtonReset
                                tooltip={t('core.reset.tooltip')}
                                confirmLabel={t('core.reset.confirm')}
                                message={t('core.reset.message', { name: thing.name })}
                                className={classes.Warning} id={subsystems.core.reset.id}
                            >
                                <EntypoLoop/>
                            </ButtonReset>
                            <ButtonWipe
                                tooltip={t('core.wipe.tooltip')}
                                confirmLabel={t('core.wipe.confirm')}
                                message={t('core.wipe.message', { name: thing.name })}
                                className={classes.Danger} id={subsystems.core.wipe.id}
                            >
                                <EntypoEraser/>
                            </ButtonWipe>
                            <ButtonRemove
                                tooltip={t('core.remove.tooltip')}
                                confirmLabel={t('core.remove.confirm')}
                                message={t('core.remove.message', { name: thing.name })}
                                className={classes.Danger} variables={{ id: thing.id }}
                            >
                                <EntypoErase/>
                            </ButtonRemove>
                        </div>
                    </div>
                </Box>
                {subsystems.thing.map(({ id, type }) => (
                    <Subsystem key={id} id={id} type={type} disabled={thing.status !== 'online'} />
                ))}
            </div>
        </HistoryProvider>
    );
};

export default withThing(Thing);
