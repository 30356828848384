export default {
    User: {
        margin: 10,
        padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 15
        },
        flexGrow: [0, '!important']
    },
    Table: {
        marginTop: 15,
        gridRowGap: 8
    }
};
