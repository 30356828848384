export default {
    Colonnade: {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',

        display: 'flex',
        flexGrow: 1,
        flexShrink: 1,

        '& > *': {
            boxSizing: 'border-box',

            flexGrow: 1,
            flexShrink: 1,

            display: 'flex',
            flexDirection: 'column'
        }
    }
};
