import gql from 'graphql-tag';
import shortcut from 'app/graphql/fragment/shortcut';

import type { ShortcutType } from 'app/graphql/fragment/shortcut';

export default gql`
    subscription OnShortcutUpdate {
        onShortcutUpdate {
            ...shortcut
            ... on ShortcutUpdate {
                id
                deleted
            }
        }
    }
    ${shortcut}
`;

export type OnShortcutUpdateSubscription = {
    onShortcutUpdate: ShortcutType & {
        deleted?: boolean
    }
};
