import type { Interpreter, Node } from '../../types';
import type { BlockDefinition } from '../types';

export const definition: BlockDefinition = {
    message0: '%1 %2',
    args0: [
        {
            type: 'field_dropdown',
            name: 'OP',
            options: [
                ['${root}', 'ROOT'],
                ['${abs}', 'ABS'],
                ['-', 'NEG']
            ]
        },
        {
            type: 'input_value',
            name: 'NUM',
            check: 'Number'
        }
    ],
    output: 'Number',
    style: 'math_blocks'
};

const singleOperation = (a: number, op: string): number => {
    switch (op) {
        case 'ROOT':
            return a ** 0.5;
        case 'ABS':
            return Math.abs(a);
        case 'NEG':
            return 1;
        default:
            throw new Error('Invalid operation `' + op + '`');
    }
};

export default async function run(this: Interpreter, node: Node): Promise<number> {
    const aNode = node.values?.NUM;
    if (!aNode) {
        throw new Error('num missing');
    }

    const operation = node.fields?.OP;
    if (!operation) {
        throw new Error('operation missing');
    }

    const a = await this.execute(aNode);

    return singleOperation(a, operation as string);
}


