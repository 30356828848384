import type { Theme } from 'app/theme';

export default (({ input: { fontSize, color, border: { radius, ...border }, background } }: Theme) => ({
    Input: {
        boxSizing: 'border-box',
        padding: [6, 8],
        textAlign: 'left',
        width: '100%',
        background,
        fontSize,
        color,
        border,
        borderRadius: radius,
        display: 'block',

        '&[disabled]': {
            opacity: 0.4
        },

        '&::placeholder': {
            userSelect: 'none'
        }
    } as any
}));
