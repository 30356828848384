export default {
    Droplet: {
        userSelect: 'none',
        width: 110,

        '& path': {
            stroke: '#abcae5',
            strokeWidth: 10
        }
    },
    Mini: {
        width: 50
    },
    Background: {
        fill: '#e0f3f9'
    },
    Foreground: {
        fill: '#2d8bd6'
    },
    Text: {
        stroke: '#000000',
        fontFamily: 'Quicksand',
        strokeWidth: 3,
        fontSize: '30px',

        '& + &': {
            stroke: '#ffffff',
            strokeWidth: 2
        }
    }
};
