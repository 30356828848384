import gql from 'graphql-tag';

export default gql`
    query subsystemStatus($thingId: ID!, $subsystemId: ID!) {
        thing(id: $thingId) {
            id
            subsystem(id: $subsystemId) {
                id
                type
                status
            }
        }
    }
`;

export type SubsystemStatusType = {
    thing: {
        subsystem: {
            id: string;
            type: string;
            status: string
        }
    }
};
