import * as React from 'react';
import { createUseStyles } from 'react-jss';
import useAutomationOutputSubscription from '../../useAutomationOutputSubscription';
import style from './style';
import * as types from './type';

const useStyles = createUseStyles(style);

interface OutputProps {
    id: string;
    automationId?: string;
    name: string;
    type: keyof typeof types | string;
    status: string;
}

const Output = ({ id, automationId, name, type, status }: OutputProps) => {
    const classes = useStyles();
    useAutomationOutputSubscription(automationId, id);

    const TypeIcon = types[type as keyof typeof types];
    return (
        <div className={classes.Output}>
            <div className={classes.Icon}>
                {TypeIcon && <TypeIcon status={status} />}
            </div>
            <div className={classes.Name}>
                {name}
            </div>
        </div>
    );
};

export default Output;
