export default {
    Container: {
        '& .subsystem': {
            fontWeight: 'bold',
            fontSize: 16,
            color: '#d14dda'
        },
        '& .device': {
            fontWeight: 'bold',
            fontSize: 16,
            color: '#d14dda'
        }
    }
};
