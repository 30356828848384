import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Modal, Title, Window } from 'app/component';
import { ButtonKind } from 'app/component/Button';

import type { BlocklyModals } from 'app/component/Blockly/types';

export interface UseBlocklyModalResult extends BlocklyModals {
    element: React.ReactNode
}

export type ResolverSig = (value: string | boolean | void | null) => void;

export enum Mode {
    alert,
    confirm,
    prompt
}

type SetResolverSigSig<T> = () => T;
type SetResolverSig<T> = (state: null | SetResolverSigSig<T>) => void;

function createPromise<T>(setResolver: SetResolverSig<T>): Promise<T> {
    return new Promise<T>((res) => {
        setResolver(() => res as any);
    }).then((value) => {
        setResolver(null);
        return value;
    });
}

const modeToTitleKey = (mode: Mode): string => {
    switch (mode) {
        case Mode.alert:
            return 'alert';
        case Mode.prompt:
            return 'prompt';
        case Mode.confirm:
        default:
            return 'title';
    }
};

export default function useBlocklyModal(): UseBlocklyModalResult {
    const [t] = useTranslation('components');
    const [message, setMessage] = React.useState('');
    const [value, setValue] = React.useState('');
    const [mode, setMode] = React.useState(Mode.alert);
    const [resolver, setResolver] = React.useState<ResolverSig | null>(null);

    const title = modeToTitleKey(mode);
    const element = resolver ? (
        <Modal isOpen={true}>
            <Window title={<Title>{t('confirmation.' + title)}</Title>}>
                <div style={{ marginBottom: 20, whiteSpace: 'pre', minWidth: 300 }}>
                    {message || t('confirmation.message')}
                    {' '}
                    <Input value={value} style={{ display: 'inline-block', width: 150 }} onChange={e => setValue(e.currentTarget.value)}/>
                </div>
                <Window.ButtonFooter>
                    {mode === Mode.alert && (
                        <Button kind={ButtonKind.primary} onClick={() => resolver?.()}>
                            {t('confirmation.ok')}
                        </Button>
                    )}
                    {mode === Mode.confirm && (
                        <>
                            <Button kind={ButtonKind.primary} onClick={() => resolver?.(true)} disabled={!!value}>
                                {t('confirmation.confirm')}
                            </Button>
                            <Button kind={ButtonKind.lite} onClick={() => resolver?.(false)}>
                                {t('confirmation.cancel')}
                            </Button>
                        </>
                    )}
                    {mode === Mode.prompt && (
                        <>
                            <Button kind={ButtonKind.primary} onClick={() => resolver?.(value)}>
                                {t('confirmation.confirm')}
                            </Button>
                            <Button kind={ButtonKind.lite} onClick={() => resolver?.(null)}>
                                {t('confirmation.cancel')}
                            </Button>
                        </>
                    )}
                </Window.ButtonFooter>
            </Window>
        </Modal>
    ) : null;
    return {
        alert(message: string): Promise<void> {
            setMessage(message);
            setMode(Mode.alert);
            return createPromise<void>(setResolver);
        },
        confirm(message: string): Promise<boolean> {
            setMessage(message);
            setMode(Mode.confirm);
            return createPromise<boolean>(setResolver);
        },
        prompt(message: string, defaultValue: string): Promise<string> {
            setMessage(message);
            setValue(defaultValue || '');
            setMode(Mode.prompt);
            return createPromise<string>(setResolver);
        },
        element
    };
}
