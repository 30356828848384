import type { Interpreter, Node } from '../../types';
import type { BlockDefinition } from '../types';

export const definition: BlockDefinition = {
    message0: '${test} %1',
    args0: [
        {
            type: 'input_value',
            name: 'IF',
            check: 'Boolean'
        }
    ],
    message1: '${then} %1',
    args1: [
        {
            type: 'input_value',
            name: 'THEN'
        }
    ],
    message2: '${else} %1',
    args2: [
        {
            type: 'input_value',
            name: 'ELSE'
        }
    ],
    output: null,
    style: 'logic_blocks',
    extensions: ['logic_ternary']
};

export default async function run(this: Interpreter, node: Node): Promise<boolean> {
    const conditionNode = node.values?.OP;
    if (!conditionNode) {
        throw new Error('operation missing');
    }

    const aNode = node.values?.THEN;
    if (!aNode) {
        throw new Error('then missing');
    }

    const bNode = node.values?.ELSE;
    if (!bNode) {
        throw new Error('else missing');
    }

    const condition = await this.execute(conditionNode);
    if (condition) {
        return await this.execute(aNode);
    }
    return await this.execute(bNode);
}

