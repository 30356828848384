import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { EntypoChevronDown } from 'react-entypo-icons';
import cx from 'classnames';
import Center from '../Center';
import style from './Dropdown.style';

interface  NavigationDropdownProps extends React.ComponentProps<typeof Center> {
    verticalPosition?: number;
    active?: boolean;
    elementRef?: React.Ref<any>;
}

const useStyles = createUseStyles(style);

// eslint-disable-next-line max-len
const NavigationDropdown = ({ children, className, verticalPosition, active, ...props }: NavigationDropdownProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return (
        <div ref={ref}>
            <Center {...props} className={cx(classes.NavigationDropdown, active && classes.Active, className)}
                verticalPosition={50}>
                <div className={classes.Layout}>{children}<EntypoChevronDown/></div>
            </Center>
        </div>
    );
};

export default React.forwardRef(NavigationDropdown);
