import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { EntypoPlus } from 'react-entypo-icons';
import cx from 'classnames';

import CreateShortcut, { withCreateTrigger } from './CreateShortcut';
import ShortcutBase from './ShortcutBase';
import style from './AddShortcut.style';

type AddShortcutProps = Omit<React.ComponentProps<typeof ShortcutBase>, 'onClick'> & {
    thingId: number;
    subsystemId: string;
    on: string;
    off: string;

    hidden?: boolean;
}

const useStyles = createUseStyles(style);
const CreateTrigger = withCreateTrigger('div');

const AddShortcut = ({ on, off, thingId, subsystemId, className, hidden, ...props }: AddShortcutProps) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <CreateShortcut thingId={thingId} subsystemId={subsystemId} on={on} off={off} />
            <ShortcutBase
                {...props}
                className={cx(classes.Shortcut, hidden === true && classes.Hidden, className)}
            >
                <CreateTrigger>
                    <EntypoPlus />
                </CreateTrigger>
            </ShortcutBase>
        </React.Fragment>
    );
};

export default AddShortcut;
