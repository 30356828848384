import { CSS_MOBILE_BREAKPOINT } from 'app/theme';

export default {
    Playground: {
        flexGrow: 1,
        flexBasis: 1
    },
    Top: {
        display: 'flex'
    },
    Label: {
        marginLeft: 10,
        marginRight: 10,
        lineHeight: '30px'
    },
    SaveContainer: {
        marginLeft: 'auto',

        [CSS_MOBILE_BREAKPOINT]: {
            marginTop: 5
        }
    },
    TracerButton: {
        [CSS_MOBILE_BREAKPOINT]: {
            display: 'none'
        },
        marginLeft: 'auto'
    }
};
