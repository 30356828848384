import { CSS_DESKTOP_BREAKPOINT } from 'app/theme';

import type { Theme } from 'app/theme';

export default (theme: Theme) => ({
    Subsystem: {
        display: 'flex',
        flexDirection: 'column',

        width: '100%'
    },
    Statuses: {
        display: 'flex',
        flexDirection: 'row',

        userSelect: 'none',

        width: '100%',

        '& > *:first-child': {
            flexShrink: 0,
            [CSS_DESKTOP_BREAKPOINT]: {
                width: 200
            }
        },
        '@media screen and (min-width: 600px)': {
            '& > *:first-child': {
                margin: {
                    left: 20,
                    right: 20
                }
            }
        },
        '& > *': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    Title: {
        marginTop: 3,
        marginLeft: 10
    },
    Unsupported: {
        display: 'flex',
        flexDirection: 'column',

        width: '100%',
        margin: 10
    },
    Attention: {
        background: theme.box.attention
    }
});
