import Blockly, { TranslatorSymbol } from '../../blockly';
import { FieldTextInput } from '../../fields';

import type { Block } from '../../types';

interface ExtendedFieldTextInput extends Blockly.FieldTextInput {
    oldShowEditorFn_: () => void;
    createdVariables_: any[];
    onFinishEditing_: (arg: string) => void;
}

const fieldHelpers = {
    // eslint-disable-next-line max-statements,complexity
    validator_(this: ExtendedFieldTextInput, varName: string): string | null {
        const sourceBlock = this.getSourceBlock();
        const outerWs = Blockly.Mutator.findParentWs(sourceBlock.workspace);
        varName = varName.replace(/[\s\xa0]+/g, ' ').replace(/^ | $/g, '');
        if (!varName) {
            return null;
        }

        // Prevents duplicate parameter names in functions
        // @ts-ignore
        const workspace = sourceBlock.workspace.targetWorkspace || sourceBlock.workspace;
        const blocks = workspace.getAllBlocks(false);
        const caselessName = varName.toLowerCase();
        for (let i = 0; i < blocks.length; i++) {
            if (blocks[i].id == this.getSourceBlock().id) {
                continue;
            }
            // Other blocks values may not be set yet when this is loaded.
            const otherVar = blocks[i].getFieldValue('NAME');
            if (otherVar && otherVar.toLowerCase() == caselessName) {
                return null;
            }
        }

        // Don't create variables for arg blocks that
        // only exist in the mutator's flyout.
        if (sourceBlock.isInFlyout) {
            return varName;
        }

        let model = outerWs.getVariable(varName, '');
        if (model && model.name != varName) {
            // Rename the variable (case change)
            outerWs.renameVariableById(model.getId(), varName);
        }
        if (!model) {
            model = outerWs.createVariable(varName, '');
            if (model && this.createdVariables_) {
                this.createdVariables_.push(model);
            }
        }
        return varName;
    },
    deleteIntermediateVars_(this: ExtendedFieldTextInput, newText: string) {
        const outerWs = Blockly.Mutator.findParentWs(this.getSourceBlock().workspace);
        if (!outerWs) {
            return;
        }

        for (const model of this.createdVariables_) {
            if (model.name != newText) {
                outerWs.deleteVariableById(model.getId());
            }
        }
    }
};

export function builder(this: Block & typeof builder) {
    const field = new FieldTextInput(Blockly.Procedures.DEFAULT_ARG, fieldHelpers.validator_) as ExtendedFieldTextInput;
    // Hack: override showEditor to do just a little bit more work.
    // We don't have a good place to hook into the start of a text edit.
    field.oldShowEditorFn_ = field.showEditor_;
    field.showEditor_ = function() {
        this.createdVariables_ = [];
        this.oldShowEditorFn_();
    };

    this.appendDummyInput()
        .appendField(this[TranslatorSymbol]('procedures.mutatorarg.title'))
        .appendField(field, 'NAME');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setStyle('procedure_blocks');
    this.contextMenu = false;

    // Create the default variable when we drag the block in from the flyout.
    // Have to do this after installing the field on the block.
    field.onFinishEditing_ = fieldHelpers.deleteIntermediateVars_;
    // Create an empty list so onFinishEditing_ has something to look at, even
    // though the editor was never opened.
    field.createdVariables_ = [];
    field.onFinishEditing_('x');
}
