import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { EntypoControllerPlay, EntypoPencil, EntypoSquaredCross, EntypoCycle, EntypoBug } from 'react-entypo-icons';
import cx from 'classnames';
import { withPopconfirm, withTooltip } from 'app/hoc';
import { Title } from 'app/component';

import style from './style';
import useRemoveAutomationMutation from './useRemoveAutomationMutation';
import useRenameAutomationModal from './useRenameAutomationModal';
import useRunAutomationMutation from './useRunAutomationMutation';

import useAutomationStatusSubscription from './useAutomationStatusSubscription';

type AutomationProps = Omit<React.ComponentProps<'div'>, 'id' | 'onClick'> & {
    id: string;
    name: string;
    active?: boolean;
    onClick?: (id: string) => void;
    running: boolean;
    error: string;
};

const useStyles = createUseStyles(style);

const DivWithTooltip = withTooltip('div');
const EntypoBugWithTooltip = withTooltip(EntypoBug);
const DivWithConformAndTooltip = withPopconfirm(DivWithTooltip, { stopPropagation: true });

const Automation = ({ id, name, running, error, active, className, onClick, children, ...props }: AutomationProps) => {
    const classes = useStyles();
    const [t] = useTranslation('automations');
    const [renameAutomation, setRenameAutomationOpen] = useRenameAutomationModal(id, name, t);
    const remove = useRemoveAutomationMutation(id);
    const run = useRunAutomationMutation(id);
    useAutomationStatusSubscription(id);

    const handleClick = React.useCallback(() => onClick?.(id), [id, onClick]);

    return (
        <div {...props} className={cx(classes.Automation, active && classes.Active, className)} onClick={handleClick}>
            {renameAutomation}
            <Title>
                {running ? (
                    <EntypoCycle
                        key="running"
                        valign="bottom"
                        className={classes.Running}
                    />
                ) : (
                    <EntypoControllerPlay
                        key="play"
                        valign="bottom"
                        className={classes.Play}
                        onClick={(e: React.SyntheticEvent) => {
                            e.nativeEvent.stopImmediatePropagation();
                            e.stopPropagation();
                            run();
                        }}
                    />
                )}
                {error && (
                    <EntypoBugWithTooltip
                        key="running"
                        valign="bottom"
                        className={classes.Bug}
                        tooltip={error}
                    />
                )}
                {name}
            </Title>
            <div className={classes.Buttons}>
                <DivWithTooltip
                    tooltip={t('automation.rename.tooltip')}
                    onClick={(e: React.SyntheticEvent) => {
                        e.nativeEvent.stopImmediatePropagation();
                        e.stopPropagation();
                        setRenameAutomationOpen();
                    }}
                >
                    <EntypoPencil/>
                </DivWithTooltip>
                <DivWithConformAndTooltip
                    className={classes.Danger}
                    tooltip={t('automation.remove.tooltip')}
                    confirmLabel={t('automation.remove.confirm')}
                    message={t('automation.remove.message', { name })}
                    onClick={(e: React.SyntheticEvent) => {
                        e.nativeEvent.stopImmediatePropagation();
                        e.stopPropagation();
                        remove();
                    }}
                >
                    <EntypoSquaredCross/>
                </DivWithConformAndTooltip>
            </div>
        </div>
    );
};

export default Automation;
