import { useMutation } from '@apollo/client/react';
import { object } from 'vx-std';
import REMOVE_ROOM_MUTATION from 'app/graphql/mutation/room/remove';
import ROOMS_QUERY from 'app/graphql/query/rooms';

import type { RoomsQueryData } from 'app/graphql/query/rooms';

export default ((id: number) => {
    const [mutation] = useMutation(REMOVE_ROOM_MUTATION, {
        variables: { id },
        update(store, { data: { room: { remove } } }) {
            if (remove) {
                const data = store.readQuery<RoomsQueryData>({ query: ROOMS_QUERY });

                const homeIndex = data?.me.homes.findIndex(home => home.rooms.some(room => room.id === id)) || -1;
                if (homeIndex > -1) {
                    const rooms = data?.me.homes[homeIndex].rooms.filter(room => room.id !== id);
                    store.writeQuery({
                        query: ROOMS_QUERY,
                        data: object.replaceIn(data, `me.homes[${homeIndex}].rooms`, rooms)
                    });
                }
            }
        }
    });
    return mutation;
});
