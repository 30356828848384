import { MOBILE_THRESHOLD } from 'app/theme';
import type { Theme } from 'app/theme';

const size = 50;

export default (theme: Theme) => ({
    Shortcut: {
        [`@media screen and (max-width: ${MOBILE_THRESHOLD - 1}px)`]: {
            display: ['flex', '!important'],
            order: -2,
            width: 'calc(50% - 2.5px)',

            background: 'transparent',
            border: 'none',


            height: size + 2,
            '& > *, & > *:last-child': {
                width: size,
                height: size,
                lineHeight: `${size}px`,
                textAlign: 'center',
                borderRadius: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
                fontSize: 25,

                paddingTop: 1,

                background: theme.button.success.background,
                color: theme.button.default.color,
                border: theme.window.border
            }
        }
    },
    Hidden: {
        display: 'none'
    }
});
