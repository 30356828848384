import type { Theme } from 'app/theme';

export default (({ link, font }: Theme) => ({
    Link: {
        '&, &:active, &:hover, &:focus': {
            userSelect: 'none',
            textDecoration: 'none',
            cursor: 'pointer',
            fontFamily: font.heading,
            color: link.color,
            fontWeight: 'bold'
        }
    }
}));
