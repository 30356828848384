import EarlyReturn from '../EarlyReturn';
import ExecutionCancelled from '../ExecutionCancelled';
import { shallowFlattenNode, traceMap } from '../utils';
import Interpreter from './Interpreter';

import type { StateActions } from '../../Tracer/state';
import type { Node } from '../../types';
import type { InstructionSet, ExecutorResult, InstructionExecutor } from '../types';
import type Context from './Context';

export default class TracedInterpreter<C extends Context = Context> extends Interpreter<C> {
    protected readonly tracerActions: Omit<StateActions, 'clear'>;

    public constructor(instructions: InstructionSet, context: C, tracerActions: Omit<StateActions, 'clear'>) {
        super(instructions, context);
        this.tracerActions = tracerActions;
    }

    private _tracedVariables: Map<string, any> | undefined;

    public get variables(): Map<string, any> {
        if (!this._tracedVariables) {
            this._tracedVariables = traceMap(this._context.variables, this.tracerActions.variable.set);
        }
        return this._tracedVariables;
    }

    protected async _execute(executor: InstructionExecutor, node: Node): Promise<ExecutorResult> {
        try {
            const flat = shallowFlattenNode(node);
            this.tracerActions.block.startEvaluation(flat, new Date(), executor.noTrace);
            const result = await super._execute(executor, node);
            this.tracerActions.block.finishEvaluation(node.id, new Date(), result);
            return result;
        } catch (e) {
            if (e instanceof ExecutionCancelled) {
                throw e;
            } else if (e instanceof EarlyReturn) {
                this.tracerActions.block.finishEvaluation(node.id, new Date(), e.value);
            } else {
                // eslint-disable-next-line no-console
                console.error('Block interpreter error', e);
                this.tracerActions.block.failEvaluation(node.id, new Date(), e instanceof Error ? e : new Error('Unknown error'));
            }
            throw e;
        }
    }

    protected withScope(variables: Map<string, string | number | null>): any {
        const scoped = super.withScope(variables);
        scoped._tracedVariables = null;
        return scoped;
    }
}
