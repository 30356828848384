import { promise } from 'vx-std';

import { TranslatorSymbol } from '../../blockly';

import { TIME_PERIOD_COLOR } from './constants';

import type { Interpreter, Block, Node } from '../../types';


export function builder(this: Block) {
    this.appendValueInput('duration')
        .setCheck('TimePeriod')
        .appendField(this[TranslatorSymbol]('date_time.sleep'));

    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);

    this.setColour(TIME_PERIOD_COLOR);
}

export default async function run(this: Interpreter, node: Node): Promise<void> {
    const durationNode = node.values?.duration;
    if (!durationNode) {
        throw new Error('duration missing');
    }

    const duration: number = await this.execute(durationNode);

    await promise.wait(duration * 1000);
}

