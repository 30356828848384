import * as React from 'react';
import useSubsystemMutation from './useSubsystemMutation';
import useSubsystemRead from './useSubsystemRead';

import type { SubsystemStatusOptionsType } from './types';
import type { SubsystemType } from 'app/graphql/fragment/subsystem';

export type UseSubsystemResult = {
    subsystem: SubsystemType | undefined,
    push: (state: string) => void,
    loading: boolean
};

const useSubsystem = (id: string, options?: Partial<SubsystemStatusOptionsType>): UseSubsystemResult => {
    const { subsystem, loading } = useSubsystemRead(id, options);

    const push = useSubsystemMutation(id);

    return React.useMemo(() => ({ subsystem, push, loading }), [subsystem, push, loading]);
};

export default useSubsystem;
