import * as React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import NestedRouter, { createNav } from 'react-router-nested';
import { useQuery } from '@apollo/client/react';
import { EntypoPlus, EntypoChevronLeft } from 'react-entypo-icons';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

import { Box, Loader, Title } from 'app/component';
import { TitleSize } from 'app/component/Title/style';
import { withTooltip } from 'app/hoc';
import { useThingsFullQuery, useIsMobile } from 'app/hook';
import HOMES_AUTOMATIONS_QUERY from 'app/graphql/query/automations';

import AutomationView from './component/AutomationView';
import useCreateAutomationModal from './useCreateAutomationModal';
import style from './style';

import Automation from './component/Automation';
import AutomationOutputs from './component/AutomationOutputs';
import Output from './component/Output';

import type { AutomationsQueryData } from 'app/graphql/query/automations';

const useStyles = createUseStyles(style);

const DivWithTooltip = withTooltip('div');

const NavItem = createNav({ passedProps: { isActive: 'active' }, props: { global: 'global' } })(Automation);
const Back = createNav({ props: { global: 'global' } })(EntypoChevronLeft);

// eslint-disable-next-line complexity
const Automations = (props: any) => {
    const selectedAutomation = String(props.location.pathname).substr(props.match.path.length + 1).trim() || false;
    const isMobile = useIsMobile();
    const classes = useStyles();
    const [t] = useTranslation('automations');
    const [createAutomation, setCreateAutomationOpen] = useCreateAutomationModal(t, classes.ModalContent);

    const { data, loading } = useQuery<AutomationsQueryData>(HOMES_AUTOMATIONS_QUERY);
    const { things, loading: thingsLoading } = useThingsFullQuery();

    if (loading || thingsLoading || !data?.me || !things) {
        return <Loader/>;
    }

    const automationName = data.me.automations.find(({ id }) => selectedAutomation === id)?.name;

    return (
        <Box className={classes.Automations}>
            {createAutomation}
            <div className={classes.TopRow}>
                <Title size={TitleSize.large}>
                    {selectedAutomation && isMobile && (<Back to="/" valign="bottom" />)}
                    {(isMobile ? automationName : false) || t('title')}
                </Title>
            </div>
            <div className={classes.Content}>
                {!(selectedAutomation && isMobile) && (
                    <div className={classes.AutomationsList}>
                        <div>
                            {data.me.automations?.map(({ id, name, running, error }) => (
                                <NavItem
                                    key={id}
                                    id={id}
                                    to={`/${id}`}
                                    name={name}
                                    running={running}
                                    error={error}
                                />
                            ))}
                        </div>
                        <DivWithTooltip
                            className={classes.AddAutomation}
                            tooltip={t('automation.create.tooltip')}
                            onClick={setCreateAutomationOpen}
                        >
                            <EntypoPlus/>
                        </DivWithTooltip>
                    </div>
                )}
                <Switch>
                    {data.me.automations.map(({ id, trigger, actions }) => (
                        <NestedRouter
                            key={id}
                            path={`/${id}`}
                            render={() => (
                                <div className={classes.AutomationsDetails}>
                                    <AutomationView
                                        key={id}
                                        id={id}
                                        trigger={trigger}
                                        actions={actions}
                                        things={things}
                                    />
                                </div>
                            )}
                        />
                    ))}
                    {data.me.automations?.[0]?.id && !isMobile && (
                        <Redirect path="/" to={`/${data.me.automations[0].id}`} />
                    )}
                    {!isMobile && (
                        <Route path="/" render={() => <div className={classes.AutomationsDetails} />} />
                    )}
                </Switch>
            </div>
            <div className={classes.Outputs}>
                {data.me.automations.filter((automation) => automation.outputs.length > 0).map((automation) => (
                    <AutomationOutputs key={automation.id} name={automation.name}>
                        {automation.outputs.map((output) => (
                            <Output
                                key={output.id}
                                id={output.id}
                                name={output.name}
                                type={output.type}
                                status={output.status}
                                automationId={automation.id}
                            />
                        ))}
                    </AutomationOutputs>
                ))}
            </div>
        </Box>
    );
};

export default Automations;
