import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import style from './Chunk.style';

const useStyles = createUseStyles(style);

const Chunk = ({ children, color, className, ...props }: JSX.IntrinsicElements['div'], ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return <div {...props} className={cx(classes.Chunk, className)} ref={ref}>{children}</div>;
};

export default React.forwardRef(Chunk);
