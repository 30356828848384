import gql from 'graphql-tag';

export default gql`
    fragment shortcut on Shortcut {
        id
        name
        status
        subsystemId
    }
`;

export type ShortcutType = {
    id: number;
    name: string;
    status: boolean;
    subsystemId: string;
};
