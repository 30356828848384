import { array } from 'vx-std';

export default class MapOverlay<K, V> implements Map<K, V> {
    protected readonly _inner: Map<K, V>;
    protected readonly _fallback: Map<K, V>;

    constructor(fallback: Map<K, V>) {
        this._inner = new Map<K, V>();
        this._fallback = fallback;
    }

    public load(from: Map<K, V>) {
        from.forEach((value, key) => this._inner.set(key, value));
        return this;
    }

    public has(key: K): boolean {
        return this._inner.has(key) || this._fallback.has(key);
    }
    public get(key: K): V | undefined {
        const local = this._inner.get(key);
        if (local === undefined) {
            return this._fallback.get(key);
        }
        return local;
    }
    public clear(): void {
        return this._inner.clear();
    }
    public delete(key: K): boolean {
        return this._inner.delete(key);
    }
    public set(key: K, value: V): this {
        this._inner.set(key, value);
        return this;
    }
    public [Symbol.iterator](): IterableIterator<[K, V]> {
        return this.entries();
    }
    public [Symbol.toStringTag]: string;

    get size() {
        return array.uniq<K>([ ...this._inner.keys(), ...this._fallback.keys() ]).length;
    }
    public keys(): IterableIterator<K> {
        return array.uniq<K>([ ...this._inner.keys(), ...this._fallback.keys() ]).values();
    }
    public *values(): IterableIterator<V> {
        for (const key of this.keys()) {
            yield this.get(key)!;
        }
    }
    public *entries(): IterableIterator<[K, V]> {
        for (const key of this.keys()) {
            yield [key, this.get(key)!];
        }
    }
    public forEach(callbackfn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any): void {
        for (const key of this.keys()) {
            callbackfn.call(thisArg, this.get(key)!, key, this);
        }
    }
}
