import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import style from './style';

type ThingIconProps = React.ComponentProps<'div'> & {
    name: string;
    type?: string;
    status: 'online' | 'offline' | void;
};

const useStyles = createUseStyles(style);

const ThingIcon = ({ name, type, status, children, ...props }: ThingIconProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return (
        <div {...props} className={cx(classes.ThingIcon, status === 'online' ? 'online' : 'offline')} ref={ref}>
            <div className={classes.Icon}>{children}</div>
            <div className={classes.Footer}>{name}</div>
        </div>
    );
};

export default React.forwardRef(ThingIcon);
