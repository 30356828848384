import { max } from 'pondjs';
import type { Time } from 'pondjs';
import type { Aggregation } from 'pondjs/lib/types';

export { default as Graph } from './component/Graph';
export { default as Value } from './component/Value';
export const parser = (status: string) => ({ movement: String(status).trim() === '1' ? 1 : 0 });
export const aggregate = {
    movement: [ 'movement', max() ] as Aggregation<Time>
};
export const window = '15s';
export const shortcuts = false;
