import { useSubscription } from '@apollo/client/react';
import SHORTCUT_STATUS_SUBSCRIPTION from 'app/graphql/subscription/onShortcutStatus';

import type { ShortcutStatusSubscription } from 'app/graphql/subscription/onShortcutStatus';

const useShortcutSubscription = (id: number): boolean | undefined => {
    const { data } = useSubscription<ShortcutStatusSubscription>(SHORTCUT_STATUS_SUBSCRIPTION, { variables: { id } });

    return data?.onShortcutStatus.status;
};

export default useShortcutSubscription;
