export default {
    Label: {
        display: 'flex',
        flexDirection: 'row',

        flexGrow: 0,
        flexShrink: 1,
        width: 'max-content',

        '& > *:first-child': {
            borderBottomLeftRadius: 6,
            borderTopLeftRadius: 6,
            borderLeftStyle: 'solid'

        },

        '& > *:last-child': {
            borderBottomRightRadius: 6,
            borderTopRightRadius: 6,
            borderRightStyle: 'solid'

        },

        fontWeight: 'bold'
    }
};
