export default {
    Window: {
        width: 350
    },
    Content: {
        '& > * + *': {
            marginTop: 10
        }
    },
    Select: {
        width: '100%'
    },
    Home: {
        marginLeft: 5,
        fontWeight: 'bold'
    }
};
