import * as React from 'react';
import { predicate } from 'vx-std';
import { CONTAINER_CLASS_NAME, Modal } from 'app/component/Modal';
import usePortal from './usePortal';

export type CloserSig = () => void;
type ChildrenType = React.ReactNode | React.ReactNodeArray;
type ElementOrRendererType = ChildrenType | (() => ChildrenType);

const renderContent = (elementOrRenderer: ElementOrRendererType, setOpen: CloserSig): ChildrenType => {
    if (predicate.isFunction(elementOrRenderer)) {
        return (elementOrRenderer as any)(setOpen);
    }

    return elementOrRenderer;
};

export default function useModal(elementOrRenderer: ElementOrRendererType): [React.ReactPortal|null, CloserSig] {
    const [el, setOpen] = usePortal(() => (
        <Modal>
            {renderContent(elementOrRenderer, () => setOpen(false))}
        </Modal>
    ),
    {
        className: CONTAINER_CLASS_NAME
    }
    );

    return [el, () => setOpen(true)];
}
