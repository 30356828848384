import type { Theme } from 'app/theme';

/* eslint-disable max-len */
export default ({ font, color, background }: Theme) => ({
    '@global': {
        html: {
            margin: 0,
            padding: 0,
            background
        },
        body: {
            color,
            background,
            minHeight: '100vh',
            fontFamily: font.base,
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',

            '& > div#root': {
                minHeight: '100vh',
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',

                '& > *': {
                    flexGrow: 1,
                    flexShrink: 1
                }
            }
        },
        pre: {
            overflow: 'initial'
        },
        '*': {
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
            lineHeight: '1em'
        },
        ul: {
            paddingLeft: '2em'
        }
    }
});
