import * as React from 'react';
import { Hint as BaseHint } from 'react-vis';
import Window from 'app/component/Window';

interface HintProps {
    value: number | string | React.ReactNode;
    date: number;
    x: number;
    y: number;
}

export const Hint = ({ value, date, x, y }: HintProps) => {
    const dateObject = new Date(date);
    return (
        <BaseHint
            xType="literal"
            yType="literal"
            value={{ x: date, y: value }}
            getAlignStyle={() => ({
                left: x,
                top: y - 60
            })}
        >
            <Window>
                <div style={{ marginBottom: 5 }}>{dateObject.toLocaleDateString()} {dateObject.toLocaleTimeString()}</div>
                <div><b>{value}</b></div>
            </Window>
        </BaseHint>
    );
};

export const useHintState = (): [HintProps | null, (data: any, info?: any) => void] => {
    const [hoveredNode, hoverNode] = React.useState<HintProps | null>(null);

    return [hoveredNode, React.useCallback((data, info = null) => {
        if (data === null) {
            hoverNode(null);
        } else {
            hoverNode({ date: data.x, value: data.y, x: info.innerX, y: info.innerY });
        }
    }, [hoverNode])];
};
