import type { Interpreter, Node } from '../../types';
import type { BlockDefinition } from '../types';

export const definition: BlockDefinition = {
    message0: '${set} %1 ${to} %2',
    args0: [
        {
            type: 'field_variable',
            name: 'VAR',
            variable: '${default.name}'
        },
        {
            type: 'input_value',
            name: 'VALUE'
        }
    ],
    previousStatement: null,
    nextStatement: null,
    style: 'variable_blocks',
    extensions: ['contextMenu_variableSetterGetter']
};

export default async function run(this: Interpreter, node: Node): Promise<void> {
    const name = node.fields?.VAR;
    if (!name) {
        throw new Error('variable name missing');
    }
    const valueNode = node.values?.VALUE;
    if (!valueNode) {
        throw new Error('variable value missing');
    }

    const value = await this.execute(valueNode);

    this.variables.set(String(name), value);
}

