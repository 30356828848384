import * as React from 'react';
import { EntypoThermometer, EntypoWater, EntypoAdjust, EntypoAir } from 'react-entypo-icons';

const Icon = () => <React.Fragment>
    <EntypoAir className="overlay" style={{ top: 12, right: 18, fontSize: '64px', color: 'lightblue', zIndex: -1 }}/>
    <EntypoThermometer/>
    <EntypoWater className="overlay" style={{ color: '#1890ff', fontSize: '45px', top: 30, right: 10 }}/>
    <EntypoAdjust className="overlay" style={{ top: 5, right: 5, fontSize: '34px', color: '#b2b222' }}/>
</React.Fragment>;

export default Icon;
