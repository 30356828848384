import type { Interpreter, Node } from '../../types';
import type { BlockDefinition } from '../types';

export const definition: BlockDefinition = {
    message0: '${negate} %1',
    args0: [
        {
            type: 'input_value',
            name: 'BOOL',
            check: 'Boolean'
        }
    ],
    output: 'Boolean',
    style: 'logic_blocks'
};

export default async function run(this: Interpreter, node: Node): Promise<boolean> {
    const boolNode = node.values?.BOOL;
    if (!boolNode) {
        throw new Error('value missing');
    }

    const value = await this.execute(boolNode);
    return !value;
}


