import apollo from 'app/connector/graphql';
import QUERY_SUBSYSTEM_HISTORY from 'app/graphql/query/subsystemHistory';
import { FieldDropdown } from 'app/component/Blockly/fields';

import { CATEGORY_INPUT_COLOR } from './constants';

import type { Interpreter, Node, Block } from 'app/component/Blockly/types';
import type { SubsystemHistoryType } from 'app/graphql/query/subsystemHistory';
import type { SubsystemWithThing } from '../context';

export const name = 'input_history_subsystem_status';

export function builder(this: Block) {
    this.appendValueInput('subsystem')
        .setCheck('IotSubsystem')
        .appendField('${subsystem}');
    this.appendValueInput('from')
        .setCheck('DateTime')
        .appendField('${from}');
    this.appendValueInput('to')
        .setCheck('DateTime')
        .appendField('${to}');
    this.appendDummyInput()
        .appendField('${aggregator}')
        .appendField(new FieldDropdown([
            ['${max}', 'max'],
            ['${mean}', 'mean']
        ]), 'aggregator');
    this.setInputsInline(false);
    this.setOutput(true, 'IotSubsystemStatus');

    this.setColour(CATEGORY_INPUT_COLOR);
    this.setTooltip('');
    this.setHelpUrl('');
}

// eslint-disable-next-line max-statements
export default async function run(this: Interpreter, node: Node): Promise<number> {
    const subsystemNode = node.values?.subsystem;
    if (!subsystemNode) {
        throw new Error('subsystem missing');
    }

    const fromNode = node.values?.from;
    if (!fromNode) {
        throw new Error('from missing');
    }

    const toNode = node.values?.to;
    if (!toNode) {
        throw new Error('to missing');
    }

    const aggregator = node.fields?.aggregator;
    if (!aggregator) {
        throw new Error('aggregator missing');
    }

    const subsystem: SubsystemWithThing = await this.execute(subsystemNode);
    const from = await this.execute(fromNode);
    const to = await this.execute(toNode);

    const window = Math.floor((+to - from) / 1000); // seconds

    const result = await apollo.query<SubsystemHistoryType>({
        query: QUERY_SUBSYSTEM_HISTORY,
        variables: {
            thingId: subsystem.thing.id,
            subsystemId: subsystem.id,
            from,
            to,
            interval: `${Math.max(window, 1)}s`,
            aggregator
        },
        fetchPolicy: 'network-only'
    });

    const data = result.data.thing.subsystem.history[0].data;

    const keys = Object.keys(data);
    if (keys.length === 1) {
        return data[keys[0]];
    }

    return data;
}
