import * as React from 'react';

export default function useLockOnPage(message = 'Are you sure you want to leave this page?') {
    const [isLocked, setLock] = React.useState(false);
    const lockRef = React.useRef(false);

    React.useLayoutEffect(() => {
        const listener = (e: BeforeUnloadEvent) => {
            if (lockRef.current) {
                e.returnValue = message;
                return message;
            }

            return true;
        };

        window.addEventListener('beforeunload', listener);
        return () => {
            window.removeEventListener('beforeunload', listener);
        };
    }, []);

    return {
        isLocked,
        lock: React.useCallback((lock = true) => {
            if (lock !== lockRef.current) {
                lockRef.current = lock;
                setLock(lock);
            }
        }, [setLock]),
        unlock: React.useCallback(() => setLock(false), [setLock])
    };
}
