import * as React from 'react';
import { GradientDefs, HorizontalGridLines, LineSeries, VerticalGridLines, XAxis, YAxis } from 'react-vis';
import { formatDateTick } from 'app/helper/graph';
import { Graph } from 'app/component';

import type { SubsystemGraphProps } from 'app/subsystem';

const TemperatureGraph = ({ history = [], onOpenSettings }: SubsystemGraphProps<{ ratio: number }>) => {
    const data = React.useMemo(() => history.map(({ time, data: { ratio: y } }) => ({ x: time.getTime(), y })), [history]);
    const max = React.useMemo(() => data.reduce((max, { y }) => Math.max(max, y + 50), 1500), [data]);
    const [hoveredNode, hoverNode] = Graph.useHintState();
    return (
        <Graph height={250} yPadding={100} onOpenSettings={onOpenSettings} yDomain={[0, max]} onMouseLeave={() => hoverNode(null)}>
            <GradientDefs>
                <linearGradient
                    id="airQualityGradient"
                    gradientUnits="userSpaceOnUse"
                    x1="0" y1="0" x2="0" y2="250"
                >
                    <stop offset="0%" stopColor="#260404"/>
                    <stop offset="100%" stopColor="lightblue"/>
                </linearGradient>
            </GradientDefs>
            <YAxis
                // @ts-ignore
                style={{ text: { paddingLeft: 14, transform: 'translate(-6px, 0px)' } }}
            />
            <XAxis tickFormat={formatDateTick}/>
            <HorizontalGridLines/>
            <VerticalGridLines/>
            <LineSeries color={'url(#airQualityGradient)'} data={data} onNearestXY={hoverNode} />
            {hoveredNode && (
                <Graph.Hint
                    {...hoveredNode}
                    value={`${Math.floor(hoveredNode.value as number)} µg/m3`}
                />
            )}
        </Graph>
    );
};

export default TemperatureGraph;
