import gql from 'graphql-tag';
import shortcut from 'app/graphql/fragment/shortcut';

export default gql`
    mutation ThingShortcutRename($thingId: ID!, $subsystemId: ID!, $id: ID!, $name: String!) {
        thing(id: $thingId) {
            subsystem(id: $subsystemId) {
                shortcut(id: $id) {
                    rename(name: $name) {
                        ...shortcut
                    }
                }
            }
        }
    }
    ${shortcut}
`;
