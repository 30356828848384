import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import style from './style';

type SliderProps = JSX.IntrinsicElements['input'] & {
    orientation?: 'horizontal' | 'vertical';
    onValueChange?: (arg0: number) => void;
    className?: string;
};

const useStyles = createUseStyles(style);

const Slider = ({ children, className, orientation, onChange, onValueChange, ...props }: SliderProps, ref: React.Ref<HTMLInputElement>) => {
    const classes = useStyles();
    return (
        <input
            {...props}
            type="range"
            ref={ref}
            className={cx(classes.Slider, orientation === 'vertical' && classes.Vertical, className)}
            onChange={e => {
                onValueChange && onValueChange(parseFloat(e.currentTarget.value));
                return onChange && onChange(e);
            }}
        />
    );
};

export default React.forwardRef(Slider);
