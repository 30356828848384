import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Button, Title, Window } from 'app/component';
import { createWithModal } from 'app/hoc';
import { FormButton, FormFeedback, InputField, MutationForm, validator } from 'app/composition/Form';
import RENAME_THING_MUTATION from 'app/graphql/mutation/thing/rename';
import { ButtonKind } from 'app/component/Button';
import style from './Rename.style';

type RenameProps = {
    close: () => void;
    id: number;
    name: string;
};

const useStyles = createUseStyles(style);

const { withModal, withTrigger } = createWithModal();

const Rename = ({ close, id, name }: RenameProps) => {
    const classes = useStyles();
    const [t] = useTranslation('thing');
    return (
        <MutationForm mutation={RENAME_THING_MUTATION} onSubmitSuccess={close} variables={{ id }} initialValues={{ name }} touchOn="blur">
            <Window className={classes.Window} contentClassName={classes.Content} title={<Title>{t('rename.title')}</Title>}>
                <InputField name="name" placeholder={t('rename.new-name')} validator={validator.required()}/>

                <FormFeedback/>
                <Window.ButtonFooter>
                    <FormButton kind={ButtonKind.primary} type="submit">
                        {t('rename.change')}
                    </FormButton>
                    <Button kind={ButtonKind.lite} onClick={close}>
                        {t('rename.cancel')}
                    </Button>
                </Window.ButtonFooter>
            </Window>
        </MutationForm>
    );
};

export default withModal(Rename);
export const withRenameTrigger = withTrigger;
