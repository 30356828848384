import type { Theme } from 'app/theme';

export default (theme: Theme) => ({
    MenuItem: {
        flexGrow: 0,
        cursor: 'pointer',
        userSelect: 'none',

        padding: {
            left: 20,
            right: 20,
            top: 5,
            bottom: 5
        },

        '&:hover': {
            background: theme.menu.background.hover
        }
    },
    Active: {
        background: theme.menu.background.active
    }
});
