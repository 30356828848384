export default class Variable<T = any> {
    protected readonly name: string;
    protected readonly value: T;

    public constructor(name: string, value: T) {
        this.name = name;
        this.value = value;
    }

    public getName(): string {
        return this.name;
    }

    public getValue(): T {
        return this.value;
    }
}
