import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import style from './style';

import ButtonFooter from './component/ButtonFooter';
import TitleWithCloseButton from './component/TitleWithCloseButton';

export type WindowProps = Omit<React.ComponentProps<'div'>, 'title'> & {
    className?: string;
    contentClassName?: string;
    children: React.ReactNode;
    title?: React.ReactChild;
};

const useStyles = createUseStyles(style);

const Window = ({ children, className, contentClassName, title, ...props }: WindowProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return (
        <div {...props} className={cx(classes.Window, className)} ref={ref}>
            {title}
            <div className={cx(classes.Content, contentClassName)}>
                {children}
            </div>
        </div>
    );
};

type RefWindowType = typeof Window & {
    TitleWithCloseButton: typeof TitleWithCloseButton;
    ButtonFooter: typeof ButtonFooter;
};

const RefWindow = React.forwardRef(Window) as any as RefWindowType;

RefWindow.TitleWithCloseButton = TitleWithCloseButton;
RefWindow.ButtonFooter = ButtonFooter;

export default RefWindow;
