import { promise } from 'vx-std';

import { FUNCTION_TYPES } from '../blocks/procedures/ifreturn';

import Interpreter from './interpreter';
import Context from './interpreter/Context';
import ExecutionCancelled from './ExecutionCancelled';

import type Blockly from '../blockly';
import type { Node } from '../types';
import type { InstructionSet } from './types';

type MethodReturnType<T extends (this: any, ...args: any) => any> =
    T extends (this: any, ...args: any) => infer R ? R : any;

type MethodReturnValue<T extends (this: any, ...args: any) => any> = Awaited<MethodReturnType<T>>;

export default class Runner {
    protected readonly instructions: InstructionSet;

    public constructor(instructions: InstructionSet) {
        this.instructions = instructions;
    }

    public buildInterpreter(_: Blockly.WorkspaceSvg): Interpreter {
        return new Interpreter(this.instructions, new Context());
    }

    protected async _run(interpreter: MethodReturnValue<this['buildInterpreter']>, input: Node[]) {
        const program = input.filter((action) => !FUNCTION_TYPES.includes(action.type));
        try {
            for (const action of program) {
                await interpreter.executeStatement(action);
            }
        } catch (e) {
            if (!(e instanceof ExecutionCancelled)) {
                throw e;
            }
        } finally {
            interpreter.complete();
        }
    }

    public async run(workspace: Blockly.WorkspaceSvg, input: Node[]): Promise<MethodReturnValue<this['buildInterpreter']>> {
        const interpreter: MethodReturnValue<this['buildInterpreter']> = await this.buildInterpreter(workspace) as any;

        const procedures = input.filter((action) => FUNCTION_TYPES.includes(action.type));
        for (const procedure of procedures) {
            interpreter.setProcedure(procedure);
        }

        promise.wait(1).then(this._run.bind(this, interpreter, input));
        return interpreter;
    }
}
