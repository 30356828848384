import { useSubscription } from '@apollo/client/react';
import SUBSYSTEM_STATUS_SUBSCRIPTION from 'app/graphql/subscription/onSubsystemStatus';
import type { SubsystemStatusSubscription } from 'app/graphql/subscription/onSubsystemStatus';

const useSubsystemSubscription = (id: string): string | null | undefined => {
    const { data } = useSubscription<SubsystemStatusSubscription>(SUBSYSTEM_STATUS_SUBSCRIPTION, { variables: { id } });

    return data?.onSubsystemStatus.status;
};

export default useSubsystemSubscription;
