import * as React from 'react';

import type { HistoryEntryType } from 'app/graphql/query/subsystemHistory';

export const normalizeHistoryData = <T extends object>(min: number, max: number, history: HistoryEntryType<T, Date>[], key: keyof T) => {
    const data = React.useMemo(() => history.map(({ time, data: { [key]: y } }) => ({
        x: time.getTime(),
        y: Math.min(Math.max(min, y as any as number), max)
    })), [history]);

    const yDomain = React.useMemo(() => [min, max], [min, max]);

    return {
        data,
        yDomain
    };
};
