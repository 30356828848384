import * as React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import Confirmation from 'app/composition/Confirmation';
import { getDisplayName } from 'app/utils/react';

type WithConfirmationProps = {
    title?: React.ReactNode;
    message?: React.ReactNode;
    mode?: symbol | string;
    confirmKind?: string;
    confirmLabel?: string;
    cancelKind?: string;
    cancelLabel?: string;
};

export default (<C extends React.ComponentType<any>, P extends React.ComponentProps<C>>(Component: C, trigger = 'onClick') => {
    class WithConfirmation extends React.Component<P & WithConfirmationProps, any> {

        static displayName = `WithConfirmation(${getDisplayName(Component)})`;

        state = {
            isOpen: false
        };

        constructor(...args: any) {
            // @ts-ignore
            super(...args);

            this.handleConfirm = this.handleConfirm.bind(this);
        }

        handleConfirm(...args: any) {
            this.setState({ isOpen: false });
            if (this.props[trigger]) {
                this.props[trigger](...args);
            }
        }

        render() {
            const {
                mode,
                title,
                message,
                confirmKind,
                confirmLabel,
                cancelKind,
                cancelLabel,
                ...props
            } = this.props;
            return (
                <React.Fragment>
                    {React.createElement(Component, { ...props, [trigger]: () => this.setState({ isOpen: true }) })}
                    <Confirmation
                        mode={mode}
                        title={title}
                        message={message}
                        confirmKind={confirmKind}
                        confirmLabel={confirmLabel}
                        cancelKind={cancelKind}
                        cancelLabel={cancelLabel}
                        isOpen={this.state.isOpen}
                        onCancel={() => this.setState({ isOpen: false })}
                        onConfirm={this.handleConfirm}
                    />
                </React.Fragment>
            );
        }
    }

    return hoistStatics(WithConfirmation, Component, { contextType: true });
});
