import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import Center from '../Center';
import style from './style';

import type { StyleData } from './style';

type LoaderProps = React.ComponentProps<typeof Center> & StyleData;

const useStyles = createUseStyles<string, StyleData>(style as any);

const Loader = ({ className, flat, size, blur, animate = true, ...props }: LoaderProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles({ flat, size, blur, animate });
    return (
        <Center {...props} className={cx(flat && classes.Flat, className)} verticalPosition={50} ref={ref}>
            <ul className={cx(classes.Shape, animate && classes.Animation, classes.Size)}>
                {Array(flat ? 5 : 25).fill(0).map((_: any, i: number) => <li className={classes.Square} key={i}/>)}
            </ul>
        </Center>
    );
};

export default React.forwardRef(Loader);
