import type { Interpreter, Node } from '../../types';
import type { BlockDefinition } from '../types';

export const definition: BlockDefinition = {
    message0: '%1 %2 %3',
    args0: [
        {
            type: 'input_value',
            name: 'A',
            check: 'Boolean'
        },
        {
            type: 'field_dropdown',
            name: 'OP',
            options: [
                ['${and}', 'AND'],
                ['${or}', 'OR']
            ]
        },
        {
            type: 'input_value',
            name: 'B',
            check: 'Boolean'
        }
    ],
    inputsInline: true,
    output: 'Boolean',
    style: 'logic_blocks'
};

export default async function run(this: Interpreter, node: Node): Promise<boolean> {
    const aNode = node.values?.A;
    if (!aNode) {
        throw new Error('a missing');
    }

    const bNode = node.values?.B;
    if (!bNode) {
        throw new Error('b missing');
    }

    const operation = node.fields?.OP;
    if (!operation) {
        throw new Error('operation missing');
    }

    const a = await this.execute(aNode);
    const b = await this.execute(bNode);

    return operation === 'AND' ?
        (a && b) : (a || b);
}

