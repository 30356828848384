import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { AuthorizedHttpLink, splitSubscriptions } from 'vx-apollo-client';
import { getApiHost } from 'app/utils/host';

import { getToken } from './token';

const getAuthHeader = () => {
    const token = getToken();
    return {
        authorization: token ? `Bearer ${token}` : undefined
    };
};

const httpLink = new AuthorizedHttpLink({
    uri: `${getApiHost('http')}/graphql`,
    headers: getAuthHeader
});


const wsLink = new GraphQLWsLink(createClient({
    url: `${getApiHost('ws')}/graphql`,
    lazy: true,
    connectionParams: getAuthHeader
}));

export default splitSubscriptions(httpLink, wsLink);
