export default {
    Title: {
        position: 'relative',
        minHeight: 20
    },
    Close: {
        position: 'absolute',
        right: 0,
        top: 0,

        lineHeight: 0,
        fontSize: 20,

        cursor: 'pointer',

        opacity: 0.7,

        '&:hover': {
            opacity: 1
        }
    }
};
