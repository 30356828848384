import { colorBrightness } from 'app/utils/color';
import { getVariablesForKind } from 'app/utils/style';

import type { Theme } from 'app/theme';

const computeButtonStyle = <S extends { background: string; }>(style: S, changeBrightness = 0): S => {
    const background = colorBrightness(style.background, changeBrightness);
    return {
        ...style,
        background,
        borderColor: colorBrightness(background, -0.095)
    };
};

export const computeButtonSize = (size: string) => {
    switch (size) {
        case 'small':
            return {
                padding: [5, 10],
                borderRadius: 4,
                fontSize: 10
            };
        default:
            return {
                padding: [7, 14],
                borderRadius: 5,
                fontSize: 14
            };

    }
};

export default ({ button }: Theme) => ({
    Button: ({ kind, size }: any) => ({
        display: 'block',
        boxSizing: 'border-box',

        ...computeButtonSize(size),

        border: {
            width: 1,
            style: 'solid'
        },

        fontWeight: 400,
        textAlign: 'center',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',

        cursor: 'pointer',
        userSelect: 'none',
        overflow: 'hidden',
        outline: 'none',

        ...computeButtonStyle(getVariablesForKind(kind, button)),

        '&:hover, &:active': computeButtonStyle(getVariablesForKind(kind, button), -0.19),

        '&:hover:active': {
            transitionDuration: 50,
            ...computeButtonStyle(getVariablesForKind(kind, button), -0.315)
        },

        '&:disabled, &:disabled:hover, &:disabled:active, &:disabled:hover:active': {
            cursor: 'default',
            opacity: 0.65,
            ...computeButtonStyle(getVariablesForKind(kind, button))
        },

        transition: {
            property: 'all',
            duration: 300,
            timingFunction: 'ease-in'
        }
    }),
    Active({
        kind
    }: any) {
        return computeButtonStyle(getVariablesForKind(kind, button), -0.19);
    },
    Loader: {
        position: 'relative',
        top: '0.15em',
        left: '0.5em'
    }
});
