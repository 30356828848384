import * as React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Error, Warning } from 'app/component';

import style from './Feedback.style';

type TemplateEntryType = {
    template: string;
    values: {
        [K: string]: any
    }
};

type EntryType = string | TemplateEntryType;

type FeedbackProps = JSX.IntrinsicElements['div'] & {
    warnings?: EntryType[];
    errors?: EntryType[];
};

const renderError = (error: string, i: number) => <Error key={`E:${i}-${error}`}>{error}</Error>;
const renderWarning = (warning: string, i: number) => <Warning key={`W:${i}-${warning}`}>{warning}</Warning>;

const useStyles = createUseStyles(style);

const Feedback = ({ children, className, warnings, errors, ...props }: FeedbackProps) => {
    const classes = useStyles();
    const [t] = useTranslation('error');

    const feedback = (
        <div {...props} className={cx(classes.Feedback, className)}>
            {(errors || [])
                .map(error => t((error as TemplateEntryType).template || error as string, (error as TemplateEntryType).values || undefined))
                .map(renderError)
            }
            {(warnings || [])
                // eslint-disable-next-line max-len
                .map(warning => t((warning as TemplateEntryType).template || warning as string, (warning as TemplateEntryType).values || undefined))
                .map(renderWarning)
            }
        </div>
    );

    return children ? (
        <React.Fragment>
            {children}
            {feedback}
        </React.Fragment>
    ) : feedback;
};

export default Feedback;
