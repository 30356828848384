import * as React from 'react';
import { useMutation } from '@apollo/client/react';
import { setOIDCUrl } from 'app/connector/oidc';
import OIDC_CHALLENGE from 'app/graphql/mutation/oidc/challenge';
import type {
    ChallengeKind ,
    OidcChallengeMutation,
    OidcChallengeMutationVariables
} from 'app/graphql/mutation/oidc/challenge';


export default function useChallenge(kind: ChallengeKind) {
    const [challenge] = useMutation<OidcChallengeMutation, Partial<OidcChallengeMutationVariables>>(OIDC_CHALLENGE, {
        fetchPolicy: 'network-only',
        variables: { kind }
    });
    
    return React.useCallback(async () => {
        const { data } = await challenge({
            variables: { redirect: window.location.pathname }
        });

        if (!data || !('url' in data.oidc.challenge)) {
            return;
        }

        const parsed = new URL(data.oidc.challenge.url);
        setOIDCUrl(parsed.origin);
        window.location.replace(data.oidc.challenge.url);
    }, [challenge]);
}
