import * as React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

import style from './style';

const useStyles = createUseStyles(style);

const NoDetails = ({ className, ...props }: JSX.IntrinsicElements['div'], ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    const [t] = useTranslation('components');
    return <div {...props} className={cx(classes.NoDetails, className)} ref={ref}>{t('list.no-details')}</div>;
};

export default React.forwardRef(NoDetails);
