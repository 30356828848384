import * as React from 'react';
import { EntypoEye, EntypoShield, EntypoThermometer, EntypoWater } from 'react-entypo-icons';

const Icon = () => <React.Fragment>
    <EntypoThermometer/>
    <EntypoWater className="overlay" style={{ color: '#1890ff', fontSize: '45px', top: 30, right: 10 }}/>
    <EntypoShield className="overlay" style={{ color: 'orange', top: 10, right: 10 }}/>
    <EntypoEye className="overlay" style={{ top: 17, right: 18, fontSize: '20px' }}/>
</React.Fragment>;

export default Icon;
