import BlocklyCore from 'blockly';
import { ObjectId } from 'bson';
import type { BlocklyModals } from './types';
import type * as React from 'react';

export const MainSymbol = Symbol('main');
export const TranslatorSymbol = Symbol('translator');
export const ModalsSymbol = Symbol('modals');
export const PortalsSymbol = Symbol('portals');
export const SetPortalsSymbol = Symbol('set-portals');
const ContextSymbol = Symbol('context');

export type PortalType = () => React.ReactPortal | null;
declare module 'blockly' {
    interface Workspace__Class {
        [MainSymbol]: this;
        [ModalsSymbol]: BlocklyModals;
        context: Record<string, any> | undefined;
        [TranslatorSymbol](key: string): string;
        [PortalsSymbol]: PortalType[];
        [SetPortalsSymbol]: (fn: PortalType[] | ((old: PortalType[]) => PortalType[])) => void;
    }
    interface WorkspaceSvg__Class {
        [MainSymbol]: this;
        context: Record<string, any> | undefined;
        [TranslatorSymbol](key: string): string;
    }

    interface Block__Class {
        [TranslatorSymbol](key: string): string;
    }

    interface Field__Class {
        [TranslatorSymbol](key: string): string;
    }

    interface ToolboxCategory__Class {
        [TranslatorSymbol](key: string): string;
    }

    interface FlyoutButton__Class {
        text_: string;
        [TranslatorSymbol](key: string): string;
    }
}

BlocklyCore.utils.genUid = () => (new ObjectId()).toHexString();
BlocklyCore.ToolboxCategory.prototype.addColourBorder_ = function (colour) {
    (this.rowDiv_ as HTMLDivElement).style.cursor = 'pointer';
    (this.rowDiv_ as HTMLDivElement).style.paddingLeft = '';
    (this.rowDiv_ as HTMLDivElement).style.backgroundColor = colour;
};

BlocklyCore.ToolboxCategory.prototype.setSelected = function(isSelected) {
    // @ts-ignore
    const defaultColour = this.parseColour_(BlocklyCore.ToolboxCategory.defaultBackgroundColour);
    (this.rowDiv_ as HTMLDivElement).style.backgroundColor = this.colour_ || defaultColour;

    if (isSelected) {
        BlocklyCore.utils.dom.addClass(this.rowDiv_, this.cssConfig_['selected']);
    } else {
        BlocklyCore.utils.dom.removeClass(this.rowDiv_, this.cssConfig_['selected']);
    }
    BlocklyCore.utils.aria.setState(this.rowDiv_ as HTMLDivElement, BlocklyCore.utils.aria.State.SELECTED, isSelected);
};

const mainCache = Symbol('cache:main');
Object.defineProperty(BlocklyCore.Workspace.prototype, MainSymbol, {
    get() {
        if (this[mainCache]) {
            return this[mainCache];
        }
        // @ts-ignore
        const mainWorkspace = this.getThemeManager?.()?.workspace_;
        if (mainWorkspace) {
            this[mainCache] = mainWorkspace;
            return mainWorkspace;
        }

        const varWorkspace = this.getVariableMap?.()?.workspace;
        if (!varWorkspace || varWorkspace == this) {
            this[mainCache] = this;
            return this;
        }

        const varMain = varWorkspace[MainSymbol];
        this[mainCache] = varMain;
        return varMain;
    },
    set(value: BlocklyCore.Workspace) {
        this[mainCache] = value;
    }
});
Object.defineProperty(BlocklyCore.Workspace.prototype, 'context', {
    get() {
        return this[MainSymbol][ContextSymbol];
    },
    set(context: any) {
        this[MainSymbol][ContextSymbol] = context;
    }
});

BlocklyCore.Workspace.prototype[TranslatorSymbol] = function (key: string) {
    const main = this?.[MainSymbol];
    if (main?.[TranslatorSymbol]) {
        const translator = this[TranslatorSymbol] = main[TranslatorSymbol];
        return translator(key);
    }

    return key;
};


const defaultModals: BlocklyModals = {
    alert(message: string): Promise<void> {
        return new Promise((res) => BlocklyCore.alert(message, res));
    },
    confirm(message: string): Promise<boolean> {
        return new Promise((res) => BlocklyCore.confirm(message, res));
    },
    prompt(message: string, defaultValue: string): Promise<string> {
        return new Promise((res) => BlocklyCore.prompt(message, defaultValue, res));
    }
};

const modalsCache = Symbol('cache:modals');
Object.defineProperty(BlocklyCore.Workspace.prototype, ModalsSymbol, {
    get() {
        return this[MainSymbol][modalsCache] || defaultModals;
    },
    set(value: BlocklyModals | null) {
        this[MainSymbol][modalsCache] = value;
    }
});

BlocklyCore.Block.prototype[TranslatorSymbol] = function (key: string) {
    const main = this.workspace[MainSymbol];
    if (main?.[TranslatorSymbol]) {
        const translator = this[TranslatorSymbol] = main[TranslatorSymbol];
        return translator(key);
    }

    return key;
};
BlocklyCore.Field.prototype[TranslatorSymbol] = function (key: string) {
    const block = this.getSourceBlock();
    const main = block?.workspace[MainSymbol];
    if (main?.[TranslatorSymbol]) {
        const translator = this[TranslatorSymbol] = main[TranslatorSymbol];
        return translator(key);
    }

    return key;
};
BlocklyCore.ToolboxCategory.prototype[TranslatorSymbol] = function (key: string) {
    const main = this.workspace_?.[MainSymbol];
    if (main?.[TranslatorSymbol]) {
        const translator = this[TranslatorSymbol] = main[TranslatorSymbol];
        return translator(key);
    }

    return key;
};
BlocklyCore.FlyoutButton.prototype[TranslatorSymbol] = function (key: string) {
    const main = this.getTargetWorkspace()[MainSymbol];
    if (main?.[TranslatorSymbol]) {
        const translator = this[TranslatorSymbol] = main[TranslatorSymbol];
        return translator(key);
    }

    return key;
};

BlocklyCore.Blocks = {};



const MsgHardOverrides = {
    CREATE_VARIABLE: '${create}',
    RENAME_VARIABLE: '${rename}',
    DELETE_VARIABLE: '${delete}',
    NEW_VARIABLE: '${new}',
    BKY_NEW_VARIABLE: '${variables.new}'
};

BlocklyCore.utils.replaceMessageReferences = (text: string) => text;
BlocklyCore.utils.global['Blockly']['Msg'] = {};
BlocklyCore.setLocale(MsgHardOverrides);

const oldInit = BlocklyCore.ToolboxCategory.prototype.init;
BlocklyCore.ToolboxCategory.prototype.init = function (this: BlocklyCore.ToolboxCategory) {
    this.name_ = this.name_.replace(/\$\{([^}]+)}/ig, (m: string, key: string) => {
        return this[TranslatorSymbol]('category.' + key.toLowerCase());
    });

    return oldInit.call(this);
};

const oldCreateDom = BlocklyCore.FlyoutButton.prototype.createDom;
BlocklyCore.FlyoutButton.prototype.createDom = function (this: BlocklyCore.FlyoutButton) {
    this.text_ = this.text_.replace(/%\{([^}]+)}/ig, (m: string, key: string) => {
        // @ts-ignore
        return MsgHardOverrides[key.toUpperCase()] || text;
    }).replace(/\$\{([^}]+)}/ig, (m: string, key: string) => {
        return this[TranslatorSymbol](key.toLowerCase());
    });

    return oldCreateDom.call(this);
};

const oldGetText = BlocklyCore.Field.prototype.getText;
BlocklyCore.Field.prototype.getText = function (this: BlocklyCore.Field) {
    const text = oldGetText.call(this);
    const block = this.getSourceBlock();

    return text.replace(/\$\{([^}]+)}/ig, (m: string, key: string) => {
        return this[TranslatorSymbol](block.type + '.' + key.toLowerCase());
    });
};

const oldGetOptions = BlocklyCore.FieldDropdown.prototype.getOptions;
BlocklyCore.FieldDropdown.prototype.getOptions = function (this: BlocklyCore.FieldDropdown) {
    const options = oldGetOptions.call(this);
    const block = this.getSourceBlock();

    if (block?.workspace) {
        return options.map(([name, value]) => {
            const label = name.replace(/\$\{([^}]+)}/ig, (m: string, key: string) => {
                return this[TranslatorSymbol](block.type + '.' + key.toLowerCase());
            });

            return [label, value];
        });
    }

    return options;
};

// eslint-disable-next-line complexity
const updateMenuItemDefinition = (item: BlocklyCore.ContextMenuRegistry.RegistryItem) => {
    switch (item.id) {
        case 'undoWorkspace':
            item.displayText = (scope) => {
                return (scope.block || scope.workspace)[TranslatorSymbol]('menu.undo');
            };
            break;
        case 'redoWorkspace':
            item.displayText = (scope) => {
                return (scope.block || scope.workspace)[TranslatorSymbol]('menu.redo');
            };
            break;
        case 'cleanWorkspace':
            item.displayText = (scope) => {
                return (scope.block || scope.workspace)[TranslatorSymbol]('menu.workspace.clean-up');
            };
            break;
        case  'collapseWorkspace':
            item.displayText = (scope) => {
                return (scope.block || scope.workspace)[TranslatorSymbol]('menu.workspace.collapse-all');
            };
            break;
        case 'expandWorkspace':
            item.displayText = (scope) => {
                return (scope.block || scope.workspace)[TranslatorSymbol]('menu.workspace.expand-all');
            };
            break;
        case 'workspaceDelete':
            item.displayText = (scope) => {
                if (!scope.workspace) {
                    return '';
                }
                // @ts-ignore
                const deletableBlocksLength = BlocklyCore.ContextMenuItems.getDeletableBlocks_(scope.workspace).length;
                if (deletableBlocksLength == 1) {
                    return scope.workspace[TranslatorSymbol]('menu.delete.single');
                } else {
                    return scope.workspace[TranslatorSymbol]('menu.delete.multiple').replace('%1', String(deletableBlocksLength));
                }
            };
            break;
        case 'blockDuplicate':
            item.displayText = (scope) => {
                return scope.block[TranslatorSymbol]('menu.block.duplicate');
            };
            break;
        case 'blockInline':
            item.displayText = (scope) => {
                if (scope.block.getInputsInline()) {
                    return scope.block[TranslatorSymbol]('menu.block.external-inputs');
                } else {
                    return scope.block[TranslatorSymbol]('menu.block.inline-inputs');
                }
            };
            break;
        case 'blockCollapseExpand':
            item.displayText = (scope) => {
                if (scope.block.isCollapsed()) {
                    return scope.block[TranslatorSymbol]('menu.block.expand');
                } else {
                    return scope.block[TranslatorSymbol]('menu.block.collapse');
                }
            };
            break;
        case 'blockDisable':
            item.displayText = (scope) => {
                if (scope.block.isEnabled()) {
                    return scope.block[TranslatorSymbol]('menu.block.disable');
                } else {
                    return scope.block[TranslatorSymbol]('menu.block.enable');
                }
            };
            break;
        case 'blockDelete':
            item.displayText = (scope) => {
                const block = scope.block;
                // Count the number of blocks that are nested in this block.
                let descendantCount = block.getDescendants(false).length;
                const nextBlock = block.getNextBlock();
                if (nextBlock) {
                    // Blocks in the current stack would survive this block's deletion.
                    descendantCount -= nextBlock.getDescendants(false).length;
                }

                if (descendantCount == 1) {
                    return scope.block[TranslatorSymbol]('menu.block.delete.single');
                } else {
                    return scope.block[TranslatorSymbol]('menu.block.delete.multiple').replace('%1', String(descendantCount));
                }
            };
            break;
        case 'blockHelp':
            item.displayText = (scope) => {
                return scope.block[TranslatorSymbol]('menu.block.help');
            };
            break;
        case 'blockComment':
            item.displayText = (scope) => {
                if (scope.block.getCommentIcon()) {
                    // If there's already a comment,  option is to remove.
                    return scope.block[TranslatorSymbol]('menu.comment.remove');
                }
                // If there's no comment yet, option is to add.
                return scope.block[TranslatorSymbol]('menu.comment.add');
            };
            break;
    }
};

let onceGetContextMenuOptions = false;
const oldGetContextMenuOptions = BlocklyCore.ContextMenuRegistry.prototype.getContextMenuOptions;
BlocklyCore.ContextMenuRegistry.prototype.getContextMenuOptions = function (scopeType, scope) {
    if (!onceGetContextMenuOptions) {
        // @ts-ignore
        Object.values(this.registry_).forEach(updateMenuItemDefinition);
        onceGetContextMenuOptions = true;
    }
    return oldGetContextMenuOptions.call(this, scopeType, scope);
};

const oldRegister = BlocklyCore.ContextMenuRegistry.registry.register;
BlocklyCore.ContextMenuRegistry.registry.register = function(item) {
    oldRegister.call(this, item);
    updateMenuItemDefinition(item);
};

export default BlocklyCore;
