import type { Theme } from 'app/theme';

const knob = {
    width: 20,
    offset: 1,

    radius: 4
};

const radius = 11;

const width = 70;
const height = 35;

const knobSideRadius = 9.5;

export default (({ button }: Theme) => ({
    Switch: {
        position: 'relative',
        overflow: 'hidden',

        height,
        width,

        borderRadius: radius,

        userSelect: 'none',
        cursor: 'pointer'
    },
    Animated: {
        transition: 'all 0.3s'
    },
    Active: {
        '& > $ON': {
            opacity: 1
        },
        '& > $Knob': {
            left: width - knob.width - knob.offset,
            borderTopRightRadius: knobSideRadius,
            borderBottomRightRadius: knobSideRadius,
            borderTopLeftRadius: knob.radius,
            borderBottomLeftRadius: knob.radius
        }
    },
    Knob: {
        composes: '$Animated',

        position: 'absolute',
        left: knob.offset,
        top: knob.offset,
        bottom: knob.offset,
        right: knob.offset,

        width: knob.width,

        background: '#ffffff',

        borderTopRightRadius: knob.radius,
        borderBottomRightRadius: knob.radius,
        borderTopLeftRadius: knobSideRadius,
        borderBottomLeftRadius: knobSideRadius,

        boxShadow: {
            inset: 'inset',
            x: 0,
            y: -1,
            blur: 3,
            spread: -1.5
        }
    },
    State: {
        composes: '$Animated',

        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,

        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        flexGrow: 1,

        textShadow: {
            x: 1,
            y: 1,
            blur: 0,
            spread: 0
        },
        textTransform: 'uppercase'
    },
    ON: {
        composes: '$State',

        opacity: 0,
        color: '#ffffffA0',
        background: button.success.background,

        paddingRight: 15
    },
    OFF: {
        composes: '$State',

        color: '#33333380',
        background: button.danger.background,

        paddingLeft: 15
    },
    Shadow: {
        position: 'absolute',
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,

        borderRadius: radius,

        boxShadow: {
            inset: 'inset',
            x: 1,
            y: 2,
            blur: 10,
            spread: -5
        },

        background: '#ffffff20'
    }
}));
