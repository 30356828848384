import gql from 'graphql-tag';

export default gql`
    fragment automation on Automation {
        id
        name
        trigger {
            type
            config
        }
        actions {
            id
            type
            fields
            values
            statements
            mutations
            next
            comment
        }
        outputs {
            id
            type
            name
            status
        }
        error
        running
    }
`;

export type MutationsType = Record<string, string | number | Array<MutationsType>>;

export interface ActionType<T = string> {
    id: string;
    type: string;
    fields?: Record<string, string | number>; // name => value
    mutations?: MutationsType; // name => value
    values?: Record<string, T>; // name => id
    statements?: Record<string, T>; // name => id
    next?: T; // id
    comment?: string
}

export type AutomationTrigger = {
    type: 'cron' | 'push';
    config: string;
};

export type AutomationOutputType = {
    id: string;
    type: string;
    name: string;
    status: string;
}

export type AutomationType = {
    id: string;
    name: string;
    trigger: AutomationTrigger
    actions: ActionType[];
    outputs: AutomationOutputType[];
    error: string;
    running: boolean;
};
