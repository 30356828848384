import { resolver as subsystem, typeDef as subsystemDef } from './query/subsystem';
import { resolver as pushSubsystemHistory, typeDef as pushSubsystemHistoryDef } from './mutation/pushSubsystemHistory';

export const resolvers = {
    Query: {
        subsystem
    },
    Mutation: {
        pushSubsystemHistory
    }
};

export const typeDefs = [subsystemDef, pushSubsystemHistoryDef];
