import * as React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import style from './style';

const useStyles = createUseStyles(style);

const Radio = ({ className, children, disabled, ...props }: Omit<React.ComponentProps<'input'>, 'type'>, ref: React.Ref<HTMLInputElement>) => {
    const classes = useStyles();

    return (
        <label className={cx(classes.Label, disabled && classes.Disabled, className)}>
            <input
                {...props}
                type="radio"
                className={cx(classes.Radio, className)}
                ref={ref}
                disabled={disabled}
            />
            {children}
        </label>
    );
};

export default React.forwardRef(Radio);
