import { useMutation } from '@apollo/client/react';
import { object } from 'vx-std';
import REMOVE_HOME_MUTATION from 'app/graphql/mutation/home/remove';
import ROOMS_QUERY from 'app/graphql/query/rooms';

import type { RoomsQueryData } from 'app/graphql/query/rooms';

export default ((id: number) => {
    const [mutation] = useMutation(REMOVE_HOME_MUTATION, {
        variables: { id },
        update(store, { data: { home: { remove } } }) {
            if (remove) {
                const data = store.readQuery<RoomsQueryData>({ query: ROOMS_QUERY });
                const homes = data?.me.homes.filter(home => home.id !== id);
                store.writeQuery({ query: ROOMS_QUERY, data: object.replaceIn(data, 'me.homes', homes) });
            }
        }
    });
    return mutation;
});
