import { gradientStyleFromColor } from 'app/utils/color';

export default {
    blocklyTheme: undefined,
    background: '#f3f3f3',
    color: '#000000',
    font: {
        base: 'Montserrat',
        heading: 'Quicksand'
    },
    input: {
        fontSize: 13,
        color: '#000000',
        background: '#FFFFFF',
        border: {
            radius: 4,
            width: 1,
            style: 'solid',
            color: 'darkgrey'
        }
    },
    button: {
        default: {
            color: 'white'
        },
        primary: {
            background: '#337ab7'
        },
        success: {
            background: '#5cb85c'
        },
        danger: {
            background: '#d43f3a'
        },
        lite: {
            color: '#000000',
            background: '#FFFFFF'
        }
    },
    error: {
        fontSize: 13,
        color: '#FF0000'
    },
    warning: {
        fontSize: 13,
        color: '#ffa500'
    },
    window: {
        background: '#F8F8F8',
        border: {
            radius: 6,
            width: 1,
            style: 'solid',
            color: 'lightgrey'
        }
    },
    box: {
        background: '#fafafa',
        attention: '#ffeeee',
        border: {
            width: 1,
            style: 'solid',
            color: '#ececec'
        }
    },
    navigation: {
        background: gradientStyleFromColor('#132d56', 0.15),
        height: 60,
        dropdown: {
            background: '#102649'
        }
    },
    link: {
        color: '#337ab7'
    },
    menu: {
        background: {
            active: '#e5f0ff',
            hover: '#cccccc'
        }
    },
    thing: {
        icon: {
            // eslint-disable-next-line max-len
            background: 'rgba(0, 0, 0, 0) linear-gradient(to bottom right, rgba(255, 255, 255, 0) 0%, rgba(200, 200, 200, 0.6) 100%) repeat scroll 0 0',
            color: 'rgba(0, 0, 0, 0.65)',
            footerColor: '#FFF',
            borderColor: '#999'
        },
        status: {
            offline: 'rgba(224, 100, 100, 0.85)',
            online: 'rgba(103,191,103,0.85)'
        }
    },
    graph: {
        grid: '#e6e6e9'
    },
    gauge: {
        background: '#edebeb',
        topLabel: '#999999',
        valueLabel: '#010101',
        minMaxLabel: '#999999'
    },
    loader: {
        color: '#3587ffAA',
        border: {
            color: 'rgba(142,190,255,0.67)',
            style: 'solid',
            width: 1
        }
    }
};
