import BreakLoop from '../../runner/BreakLoop';
import ContinueLoop from '../../runner/ContinueLoop';

import type { Interpreter, Node } from '../../types';
import type { BlockDefinition } from '../types';

export const name = 'controls_flow_statements';

export const definition: BlockDefinition = {
    message0: '%1',
    args0: [{
        type: 'field_dropdown',
        name: 'FLOW',
        options: [
            ['${break}', 'BREAK'],
            ['${continue}', 'CONTINUE']
        ]
    }],
    previousStatement: null,
    style: 'loop_blocks',
    extensions: [
        'controls_flow_in_loop_check'
    ]
};

export default function run(this: Interpreter, node: Node) {
    const flow = node.fields?.FLOW;
    if (!flow) {
        throw new Error('value missing');
    }

    switch (flow) {
        case 'BREAK':
            throw new BreakLoop();
        case 'CONTINUE':
            throw new ContinueLoop();
        default:
            throw new Error('Invalid flow control statement ' + flow);
    }
}
