import type { Theme } from 'app/theme';

export default ({ button }: Theme) => ({
    '@keyframes spinner': {
        '0%': {
            transform: 'rotate(0)',
            animationTimingFunction: 'cubic-bezier(.55,.055,.675,.19)'
        },

        '50%': {
            transform: `rotate(${360 * 1.5}deg)`,
            animationTimingFunction: 'cubic-bezier(.215,.61,.355,1)'
        },

        '100%': {
            transform: `rotate(${360 * 3}deg)`
        }
    },
    Container: {
        padding: 0,
        boxSizing: 'border-box',
        display: 'inline-block',

        width: '1em',
        height: '1em',

        overflow: 'hidden'
    },
    Loader: {
        position: 'absolute',
        top: 0,
        right: 0,

        boxSizing: 'border-box',
        display: 'inline-block',

        width: '1em',
        height: '1em',

        transition: {
            property: 'all',
            duration: 300,
            timingFunction: 'ease-in'
        },

        animationDuration: 1000,
        animationIterationCount: 'infinite',
        animationName: '$spinner',

        '&:after': {
            content: '" "',
            display: 'inline-block',
            boxSizing: 'border-box',

            width: '100%',
            height: '100%',

            margin: 'auto',

            background: {
                position: 'center',
                repeat: 'no-repeat',
                size: 'cover'
            },

            border: {
                color: [button.default.color, button.default.color, button.default.color, 'transparent'],
                width: '.178em',
                style: 'solid'
            },
            borderRadius: '50%'
        }
    }
});
