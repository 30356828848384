import * as React from 'react';
import { autoUpdate, offset, useFloating, arrow, shift } from '@floating-ui/react-dom';
import Portal from 'app/component/Portal';

import TooltipArrow from './Arrow';
import TooltipBody from './Body';

import type { Placement } from '@floating-ui/react-dom';

type Props = {
    tooltip?: React.ReactNode;
    children: React.ReactChild;
    isOpen?: boolean;
    refRef?: React.Ref<any>;
    placement?: Placement;
};

export default React.forwardRef<HTMLDivElement, Props>(function Tooltip({
    tooltip,
    isOpen,
    children,
    placement = 'top'
}, ref) {
    const arrowRef = React.useRef<HTMLDivElement>(null);
    const { refs, floatingStyles, middlewareData } = useFloating({
        whileElementsMounted: autoUpdate,
        open: isOpen,
        placement,
        middleware: [
            offset(5),
            shift({
                padding: 10
            }),
            arrow({
                element: arrowRef
            })
        ]
    });

    const child = React.Children.only(children);

    return (
        <React.Fragment>
            {React.cloneElement(child as React.ReactElement, { ref: refs.setReference })}
            <Portal isOpen={isOpen}>
                <div ref={refs.setFloating} style={floatingStyles}>
                    <TooltipBody ref={ref}>{tooltip}</TooltipBody>
                    <TooltipArrow
                        ref={arrowRef}
                        style={{ left: middlewareData.arrow?.x , top: middlewareData.arrow?.y }}
                    />
                </div>
            </Portal>
        </React.Fragment>
    );
});
