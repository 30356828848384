import { InMemoryCache } from '@apollo/client';
import { SecretRenewingClient } from 'vx-apollo-client';
import { jwt, promise } from 'vx-std';
import REFRESH_TOKEN from 'app/graphql/mutation/oidc/refresh';
import { getRefreshToken, getToken, setRefreshToken, setToken } from './token';

import link from './link';
import local from './local';

import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';

const client = new SecretRenewingClient<NormalizedCacheObject>({
    link,
    cache: new InMemoryCache(),
    secret: {
        setter: setToken,
        getter: getToken,
        getExpiry(token: string) {
            const { exp = 0 } = jwt.readBody(token) || {};
            return new Date((exp - 5 * 60) * 1000);
        },
        async renew(client: ApolloClient<any>) {
            const data = await client.mutate({
                mutation: REFRESH_TOKEN,
                fetchPolicy: 'network-only',
                variables: { token: getRefreshToken() }
            });
            const result = data.data.oidc.refresh;
            setRefreshToken(result.refresh);
            return result.token;
        }
    },
    async onEarlyAuthenticationError() {
        if (!getToken()) {
            return;
        }

        do {
            await promise.wait(100);
            // @ts-ignore
        } while (client.queryManager.inFlightLinkObservables.size > 0 || client.queryManager.fetchCancelFns.size > 0 );

        setToken(null);
        setRefreshToken(null);
        try {
            await client.clearStore();
        } catch {}
        try {
            await client.resetStore();
        } catch {}

        // eslint-disable-next-line @typescript-eslint/no-var-requires
        setTimeout(() => require('app/rootRenderer').reset());
    },
    // @ts-ignore
    dataIdFromObject(o: any) {
        if (o.__typename != null && o.id != null) {
            return `${o.__typename}:${o.id}`;
        }
    },

    ...local
});

export default client;
