import * as React from 'react';
import { useMutation } from '@apollo/client/react';
import hoistStatics from 'hoist-non-react-statics';
import { getDisplayName } from 'app/utils/react';
import type { DocumentNode } from 'graphql';

type WithMutationDerivedProps = {
    variables?: {
        [key: string]: any;
    };
    mutation?: DocumentNode;
};

type WithMutationOptsType = {
    trigger?: string;
    extractVariables?: (propsVariables: Record<string, unknown> | null | undefined, ...rest: any) => Record<string, unknown>;
};

export default (mainMutation?: any, { trigger = 'onClick', extractVariables }: WithMutationOptsType = {}) => {
    return <C extends React.ComponentType<any>, P extends React.ComponentProps<C>>(Component: C): any => {
        const WithMutation = ({ variables, mutation, ...props }: P & WithMutationDerivedProps) => {
            const [mutate] = useMutation(mutation || mainMutation);

            return React.createElement(Component, {
                ...props,
                [trigger]: (...args: any[]) => {
                    mutate({ variables: extractVariables ? extractVariables(variables, ...args) : variables });

                    if (props[trigger]) {
                        return props[trigger](...args);
                    }
                }
            });
        };

        WithMutation.displayName = `WithMutation(${getDisplayName(Component)})`;
        return hoistStatics(WithMutation, Component, { contextType: true });
    };
};
