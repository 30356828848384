import { avg } from 'pondjs';
import type { Time } from 'pondjs';
import type { Aggregation } from 'pondjs/lib/types';

export { default as Graph } from './component/Graph';
export { default as Value } from './component/Value';
export const filter = (entry: any) => !!entry.data.amps;
export const parser = (status: string) => ({ amps: parseFloat(status) });
export const aggregate = {
    amps: [ 'amps', avg() ] as Aggregation<Time>
};
export const window = '15s';
export const shortcuts = false;
