import { FieldDropdown } from '../../fields';

import { TIME_DATETIME_COLOR } from './constants';

import type { Interpreter, Block, Node } from '../../types';


export const name = 'op_datetime_period';

export function builder(this: Block) {
    this.appendValueInput('datetime')
        .setCheck('DateTime');
    this.appendValueInput('period')
        .setCheck('TimePeriod')
        .appendField(new FieldDropdown([
            ['+', '+'],
            ['-', '-']
        ]), 'operation');
    this.setInputsInline(true);
    this.setOutput(true, 'DateTime');

    this.setColour(TIME_DATETIME_COLOR);
}

export default async function run(this: Interpreter, node: Node): Promise<Date> {
    const dateTimeNode = node.values?.datetime;
    if (!dateTimeNode) {
        throw new Error('dateTime missing');
    }

    const periodNode = node.values?.period;
    if (!periodNode) {
        throw new Error('period missing');
    }

    const operation = node.fields?.operation;
    if (!operation) {
        throw new Error('operation missing');
    }

    const dateTime: Date = await this.execute(dateTimeNode);
    const period = await this.execute(periodNode) * 1000 * (operation === '-' ? -1 : 1);

    return new Date(+dateTime + period);
}

