import gql from 'graphql-tag';

export default gql`
    subscription OnAutomationStatus($id: ID!) {
        onAutomationStatus(id: $id) {
            id
            error
            running
        }
    }
`;

export type AutomationStatusSubscription = {
    onAutomationStatus: {
        id: string;
        error: string;
        running: boolean;
    }
}
