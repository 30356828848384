import type { Theme } from 'app/theme';

export default (theme: Theme) => ({
    Container: {
        display: 'flex',
        flexDirection: 'column',
        userSelect: 'none',
        textAlign: 'initial',

        background: theme.window.background,

        border: {
            width: 1,
            style: 'solid',
            color: theme.window.border.color
        },

        padding: {
            top: 10
        },

        borderRadius: 6,

        boxShadow: {
            x: 2,
            y: 2,
            blur: 10,
            spread: -3,
            color: '#121212'
        }
    },
    Tabs: {
        display: 'flex',
        flexDirection: 'row',
        userSelect: 'none',
        textAlign: 'initial',
        maxWidth: '100vw',

        overflow: 'scroll',
        flexShrink: 1,
        flexGrow: 0,
        padding: {
            left: 7,
            right:7
        },

        borderBottom: {
            width: 1,
            style: 'solid',
            color: theme.window.border.color
        }
    },
    Tab: {
        cursor: 'pointer',
        border: {
            style: 'solid',
            color: theme.window.border.color
        },
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 0,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,

        padding: {
            top: 5,
            bottom: 5,
            left: 7,
            right:7
        },

        '& + *': {
            marginLeft: 5
        }
    },
    TabActive: {
        background: theme.color + '22'
    },
    View: {
        flexGrow: 1,
        flexShrink: 1
    }
});
