import Runner from './Runner';
import TracedInterpreter from './interpreter/TracedInterpreter';
import Context from './interpreter/Context';

import type { StateActions } from '../Tracer/state';
import type { FlatNode } from '..';
import type Blockly from '../blockly';

export default class TracedRunner extends Runner {
    private actions: StateActions | null = null;

    protected readonly actionsProxy: StateActions = {
        clear: () => this.actions?.clear(),
        block: {
            startEvaluation: (node: FlatNode, stamp: Date, hidden = false) => this.actions?.block.startEvaluation(node, stamp, hidden),
            finishEvaluation: (blockId: string, stamp: Date, value: any) => this.actions?.block.finishEvaluation(blockId, stamp, value),
            failEvaluation: (blockId: string, stamp: Date, error: Error) => this.actions?.block.failEvaluation(blockId, stamp, error)
        },
        variable: {
            set: (name: string, value: any) => this.actions?.variable.set(name, value)
        }
    };

    public buildInterpreter(_: Blockly.WorkspaceSvg): TracedInterpreter {
        this.actionsProxy.clear();
        return new TracedInterpreter(this.instructions, new Context(), this.actionsProxy);
    }

    public connectActions(actions: StateActions) {
        this.actions = actions;
    }
}
