import gql from 'graphql-tag';
export { ChallengeKind } from 'app/graphql/types.generated';
export type { OidcChallengeMutation, OidcChallengeMutationVariables } from 'app/graphql/types.generated';

export default gql`
    mutation OIDCChallenge($kind: ChallengeKind!, $redirect: String) {
        oidc {
            challenge(kind: $kind, redirect: $redirect) {
                ... on OAuthChallenge {
                    url
                }
            }
        }
    }
`;
