import ReactDOM from 'react-dom';
import Blockly, { MainSymbol, SetPortalsSymbol } from '../blockly';

import type * as React from 'react';

interface RendererColors {
    colourPrimary: string;
    colourSecondary: string;
    colourTertiary: string;
}

interface RendererMisc {
    inFlyout: boolean;
    colors?: RendererColors;
}

export type RendererSig<C> = (context: C, value: any, misc: RendererMisc) => React.ReactNode;

interface FieldOptions {
    clickable: boolean;
    renderRect: boolean;
    rectRadius: number;
}

export default class ReactField<C> extends Blockly.Field {
    protected _container: HTMLDivElement | null = null;
    protected _observer: MutationObserver | null = null;
    protected readonly _portalRenderer: RendererSig<C>;
    protected readonly _portal = () => {
        if (!this.mainWorkspace) {
            return null;
        }

        return ReactDOM.createPortal(this._portalRenderer(this.mainWorkspace.context as C, this.getValue(), {
            inFlyout: !!this.flyout,
            colors: (this.sourceBlock_ as Blockly.BlockSvg).style
        }), this.container);
    };

    protected _clickable = false;
    protected _renderRect = false;
    protected _rectRadius: number | null = null;

    public SERIALIZABLE = true;
    public DEFAULT_VALUE = '';
    public CURSOR = 'pointer';

    protected get workspace(): Blockly.WorkspaceSvg | null {
        return (this.getSourceBlock() as Blockly.BlockSvg)?.workspace || null;
    }

    protected get mainWorkspace(): Blockly.WorkspaceSvg | null {
        return this.workspace?.[MainSymbol] || null;
    }

    protected get flyout(): Blockly.WorkspaceSvg | null {
        if (this.workspace?.isFlyout) {
            return this.workspace;
        }
        return null;
    }

    protected get container(): HTMLDivElement {
        if (!this._container) {
            this._container = document.createElement('div');
            this._container.style.position = 'absolute';
            this._container.style.zIndex = '1000';
            this._container.style.width = 'min-content';
            this._container.style.height = 'min-content';
            this.mainWorkspace!.getInjectionDiv().appendChild(this.container);
        }
        return this._container;
    }

    public constructor(portalRenderer: RendererSig<C>, options?: Partial<FieldOptions>) {
        super(undefined, undefined, {});
        this._portalRenderer = portalRenderer;

        if (options?.clickable) {
            this._clickable = options.clickable;
        }
        if (options?.renderRect) {
            this._renderRect = options.renderRect;
        }
        if (options?.rectRadius !== undefined) {
            this._rectRadius = options.rectRadius;
        }
    }

    public positionBorderRect_() {
        super.positionBorderRect_();
        if (!this.borderRect_) {
            return;
        }

        const radius = this._rectRadius ?? this.getConstants().FIELD_BORDER_RECT_RADIUS;

        this.borderRect_.setAttribute('rx', radius as any);
        this.borderRect_.setAttribute('ry', radius as any);
    }

    public initView() {
        this._setupBlockCLick();

        if (this._renderRect) {
            this.createBorderRect_();
        }

        this.mainWorkspace?.[SetPortalsSymbol]((current) => ([...current, this._portal]));
        this.forceRerender();
        setTimeout(() => this.forceRerender(), 10);
    }

    public dispose() {
        this._observer?.disconnect();
        this.mainWorkspace?.[SetPortalsSymbol]((current) => current.filter((portal) => portal !== this._portal));
        this._container?.remove();
        super.dispose();
    }

    public setSourceBlock(block: Blockly.Block) {
        super.setSourceBlock(block);
        this.forceRerender();

        const svgBlock = block as Blockly.BlockSvg;

        this._observer?.disconnect();
        this._observer = new MutationObserver(() => this._positionPortal());
        this._observer.observe(svgBlock.getSvgRoot(), { attributes: true, subtree: true });
        if (this.mainWorkspace) {
            this._observer.observe(this.mainWorkspace.getBlockDragSurface().getSvgRoot(), { attributes: true, subtree: true });
        }
        if (this.flyout) {
            this._observer.observe(this.flyout.getParentSvg(), { attributes: true, subtree: true });
        }
    }

    /**
     * Use the `getText_` developer hook to override the field's text representation.
     * When we're currently editing, return the current HTML value instead.
     * Otherwise, return null which tells the field to use the default behaviour
     * (which is a string cast of the field's value).
     * @return {?string} The HTML value if we're editing, otherwise null.
     */
    public getText_(): string {
        return this.getEditorText_(this.getValue());
    }

    /**
     * Returns whether or not the field is tab navigable.
     */
    public isTabNavigable(): boolean {
        return true;
    }

    /**
     * Transform the provided value into a text to show in the HTML input.
     * Override this method if the field's HTML input representation is different
     * than the field's value. This should be coupled with an override of
     * `getValueFromEditorText_`.
     */
    protected getEditorText_(value: any): string {
        return String(value);
    }

    /**
     * Transform the text received from the HTML input into a value to store
     * in this field.
     * Override this method if the field's HTML input representation is different
     * than the field's value. This should be coupled with an override of
     * `getEditorText_`.
     */
    protected getValueFromEditorText_(text: string): any {
        return text;
    }

    private _setupBlockCLick() {
        if (this.getConstants().FULL_BLOCK_FIELDS) {
            // Step one: figure out if this is the only field on this block.
            // Rendering is quite different in that case.
            let nFields = 0;
            let nConnections = 0;

            // Count the number of fields, excluding text fields
            for (let i = 0, input; (input = this.sourceBlock_.inputList[i]); i++) {
                for (let j = 0; (input.fieldRow[j]); j++) {
                    nFields++;
                }
                if (input.connection) {
                    nConnections++;
                }
            }
            // The special case is when this is the only non-label field on the block
            // and it has an output but no inputs.
            if (nFields <= 1 && this.sourceBlock_.outputConnection && !nConnections) {
                this.clickTarget_ = (this.sourceBlock_ as Blockly.BlockSvg).getSvgRoot();
            }
        }
    }

    protected _positionPortal() {
        const div: HTMLDivElement = this.container;
        const sourceBlock = this.sourceBlock_ as Blockly.BlockSvg;
        if (sourceBlock.isInsertionMarker() || this.flyout?.getParentSvg().style.display === 'none') {
            div.style.display = 'block';
            div.style.visibility = 'hidden';
        } else if (sourceBlock.getSvgRoot().getAttribute('visibility') === 'hidden') {
            div.style.display = 'none';
            div.style.visibility = 'visible';
        } else {
            div.style.display = 'block';
            div.style.visibility = 'visible';
        }
        const bBox = this.getScaledBBox();
        // In RTL mode block fields and LTR input fields the left edge moves,
        // whereas the right edge is fixed.  Reposition the editor.
        const x = this.sourceBlock_.RTL ? bBox.right - div.offsetWidth : bBox.left;
        const xy = new Blockly.utils.Coordinate(x, bBox.top);

        const boundingClientRect = this.container.parentElement!.getBoundingClientRect();
        div.style.left = (xy.x - boundingClientRect.x) + 'px';
        div.style.top = (xy.y - boundingClientRect.y) + 'px';

        div.style.pointerEvents = this._clickable ? 'all' : 'none';
    }

    public render_() {
        super.render_();
        this._positionPortal();
    }

    public doValueUpdate_(newValue: any) {
        super.doValueUpdate_(newValue);
        this.forceRerender();
    }

    public updateSize_(opt_margin: number): void {
        const div: HTMLDivElement = this.container;
        this.size_.height = div.clientHeight;
        this.size_.width = div.clientWidth;
        if (this._renderRect) {
            this.positionBorderRect_();
        }
    }
}
