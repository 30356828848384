import apollo from 'app/connector/graphql';
import QUERY_SUBSYSTEM_STATUS from 'app/graphql/query/subsystemStatus';


import { CATEGORY_INPUT_COLOR } from './constants';

import type { Interpreter, Node, Block } from 'app/component/Blockly/types';
import type { SubsystemStatusType } from 'app/graphql/query/subsystemStatus';
import type { SubsystemWithThing } from '../context';

export const name = 'input_current_subsystem_status';

export function builder(this: Block) {
    this.appendValueInput('subsystem')
        .setCheck('IotSubsystem')
        .appendField('${subsystem_status}');
    this.setColour(CATEGORY_INPUT_COLOR);
    this.setTooltip('');
    this.setHelpUrl('');
    this.setOutput(true, 'Number');
}

export default async function run(this: Interpreter, node: Node): Promise<number> {
    const subsystemNode = node.values?.subsystem;
    if (!subsystemNode) {
        throw new Error('subsystem missing');
    }

    const subsystem: SubsystemWithThing = await this.execute(subsystemNode);

    const result = await apollo.query<SubsystemStatusType>({
        query: QUERY_SUBSYSTEM_STATUS,
        variables: {
            thingId: subsystem.thing.id,
            subsystemId: subsystem.id
        },
        fetchPolicy: 'network-only'
    });

    return parseFloat(result.data.thing.subsystem.status);
}
