const active = {
    background: '#00000050'
};

export default {
    Active: active,
    NavigationItem: {
        flexGrow: 0,
        justifyContent: 'center',

        cursor: 'pointer',
        userSelect: 'none',
        color: '#ffffff',
        fontWeight: 'bold',

        marginRight: 1,

        padding: {
            left: 20,
            right: 20
        },

        minHeight: 45,

        '&:hover': active
    }
};
