export default {
    Body: {
        userSelect: 'none',
        borderRadius: 15,
        background: '#222222',
        padding: 12,
        color: '#ffffff',

        boxShadow: {
            x: 1,
            y: 1,
            blur: 10,
            spread: -5,
            color: '#000000'
        }
    }
};
