import * as React from 'react';
import { Query } from '@apollo/client/react/components';
import { object } from 'vx-std';
import { getDisplayName } from 'app/utils/react';
import { Loader, Watch } from 'app/component';

import THING_UPDATED_SUBSCRIPTION from 'app/graphql/subscription/onThingUpdate';
import THINGS_QUERY from 'app/graphql/query/things';

import type { ThingsQueryData } from 'app/graphql/query/things';
import type { OnThingUpdateSubscription } from 'app/graphql/subscription/onThingUpdate';
import type { QueryResult, SubscribeToMoreOptions } from '@apollo/client';

export default (<C extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>, P = React.ComponentProps<C>>(Component: C) => {
    return class WithThings extends React.Component<P> {

        static displayName = `WithThings(${getDisplayName(Component)})`;

        constructor(...args: any) {
            // @ts-ignore
            super(...args);

            this.renderList = this.renderList.bind(this);
        }

        watch(subscribeToMore: (options: SubscribeToMoreOptions<ThingsQueryData, any, OnThingUpdateSubscription>) => () => void) {
            return subscribeToMore({
                document: THING_UPDATED_SUBSCRIPTION,
                updateQuery: (prev, { subscriptionData }) => {
                    if (!subscriptionData.data || !prev) return prev;

                    const index = prev.me.things.findIndex(thing => thing.id === subscriptionData.data.onThingUpdate.id) || -1;
                    if (index >= 0) {
                        if (subscriptionData.data.onThingUpdate.deleted) {
                            const things = [...prev.me.things];
                            things.splice(index, 1);
                            return object.replaceIn(prev, 'me.things', things);
                        }

                        const things = [...prev.me.things];
                        things.splice(index, 1, subscriptionData.data.onThingUpdate);
                        return object.replaceIn(prev, 'me.things', things);
                    }

                    if (subscriptionData.data.onThingUpdate.deleted) {
                        return prev;
                    }

                    return object.replaceIn(prev, 'me.things', [...prev.me.things, subscriptionData.data.onThingUpdate]);
                }
            });
        }

        renderList({ loading, data, subscribeToMore }: QueryResult<ThingsQueryData>) {
            if (loading || !data?.me?.things) {
                return <Loader/>;
            }

            return (
                <Watch watch={() => this.watch(subscribeToMore)}>
                    {/* @ts-ignore */}
                    <Component {...this.props} things={data.me.things}/>
                </Watch>
            );
        }

        render() {
            return (
                <React.Fragment>
                    <Query query={THINGS_QUERY}>
                        {this.renderList}
                    </Query>
                </React.Fragment>
            );
        }
    };
});
