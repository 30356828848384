import Blockly from '../blockly';
import { FatEncoder } from '../json/encoder';

import type Runner from '.';

const WIDTH = 48;
const HEIGHT = 48;
const MARGIN_VERTICAL = 20;
const MARGIN_HORIZONTAL = 20;

export default class RunnerControl<R extends Runner> {
    protected workspace_: Blockly.WorkspaceSvg | null = null;
    protected readonly runner_: R;
    protected readonly encoder_ = new FatEncoder();

    protected id = 'runner';
    protected initialized_ = false;

    protected svgGroup_: SVGElement | null = null;
    protected onRunnerWrapper_: Blockly.browserEvents.Data | null = null;

    constructor(runner: R) {
        this.runner_ = runner;
    }

    public init(workspace: Blockly.WorkspaceSvg) {
        this.workspace_ = workspace;
        this.workspace_.getComponentManager().addComponent({
            component: this,
            weight: 2,
            capabilities: [Blockly.ComponentManager.Capability.POSITIONABLE]
        });
        this.createDom_(this.workspace_);
        this.initialized_ = true;
        this.workspace_.resize();
    }

    public dispose() {
        if (this.svgGroup_) {
            Blockly.utils.dom.removeNode(this.svgGroup_);
        }
        if (this.onRunnerWrapper_) {
            Blockly.unbindEvent_(this.onRunnerWrapper_);
        }
    }

    public run() {
        if (!this.workspace_) {
            throw new Error('not initialized');
        }

        return this.runner_.run(this.workspace_, this.encoder_.encode(this.workspace_));
    }

    protected left_ = 0;
    protected top_ = 0;
    protected createDom_(workspace: Blockly.WorkspaceSvg) {
        this.svgGroup_ = Blockly.utils.dom.createSvgElement(
            Blockly.utils.Svg.PATH, {
                fill: 'currentColor',
                // eslint-disable-next-line max-len
                d: 'M393.538,203.629L102.557,5.543c-9.793-6.666-22.468-7.372-32.94-1.832c-10.472,5.538-17.022,16.413-17.022,28.26v396.173 c0,11.846,6.55,22.721,17.022,28.26c10.471,5.539,23.147,4.834,32.94-1.832l290.981-198.087 c8.746-5.954,13.98-15.848,13.98-26.428C407.519,219.477,402.285,209.582,393.538,203.629z',
                'class': 'runner'
            });

        Blockly.utils.dom.insertAfter(this.svgGroup_, workspace.getBubbleCanvas());

        // Attach listener.
        this.onRunnerWrapper_ = Blockly.browserEvents.conditionalBind(this.svgGroup_, 'mousedown', {}, this.run.bind(this));
    }

    public getBoundingRectangle() {
        return new Blockly.utils.Rect(
            this.top_, this.top_ + HEIGHT,
            this.left_, this.left_ + WIDTH
        );
    }

    // eslint-disable-next-line max-statements,complexity
    public position(metrics: Blockly.MetricsManager.UiMetrics, savedPositions: Array<Blockly.utils.Rect>) {
        if (!this.initialized_ || !this.workspace_) {
            return;
        }
        
        const hasVerticalScrollbars = this.workspace_.scrollbar && this.workspace_.scrollbar.canScrollHorizontally();
        const hasHorizontalScrollbars = this.workspace_.scrollbar && this.workspace_.scrollbar.canScrollVertically();
        if (metrics.toolboxMetrics.position === Blockly.TOOLBOX_AT_LEFT ||
            (this.workspace_.horizontalLayout && !this.workspace_.RTL)) {
            // Right corner placement.
            this.left_ = metrics.absoluteMetrics.left + metrics.viewMetrics.width - WIDTH - MARGIN_HORIZONTAL;
            if (hasVerticalScrollbars && !this.workspace_.RTL) {
                this.left_ -= Blockly.Scrollbar.scrollbarThickness;
            }
        } else {
            // Left corner placement.
            this.left_ = MARGIN_HORIZONTAL;
            if (hasVerticalScrollbars && this.workspace_.RTL) {
                this.left_ += Blockly.Scrollbar.scrollbarThickness;
            }
        }

        const startAtBottom =
            metrics.toolboxMetrics.position !== Blockly.TOOLBOX_AT_BOTTOM;
        if (startAtBottom) {
            // Bottom corner placement
            this.top_ = metrics.absoluteMetrics.top + metrics.viewMetrics.height - HEIGHT - MARGIN_VERTICAL;
            if (hasHorizontalScrollbars) {
                // The horizontal scrollbars are always positioned on the bottom.
                this.top_ -= Blockly.Scrollbar.scrollbarThickness;
            }
        } else {
            // Upper corner placement
            this.top_ = metrics.absoluteMetrics.top + MARGIN_VERTICAL;
        }

        // Check for collision and bump if needed.
        let boundingRect = this.getBoundingRectangle();
        for (let i = 0, otherEl; (otherEl = savedPositions[i]); i++) {
            if (boundingRect.intersects(otherEl)) {
                if (startAtBottom) { // Bump up.
                    this.top_ = otherEl.top - HEIGHT - MARGIN_VERTICAL;
                } else { // Bump down.
                    this.top_ = otherEl.bottom + MARGIN_VERTICAL;
                }
                // Recheck other savedPositions
                boundingRect = this.getBoundingRectangle();
                i = -1;
            }
        }

        this.svgGroup_?.setAttribute('transform', 'translate(' + this.left_ + ',' + this.top_ + '),scale(0.12)');
    }
}

Blockly.Css.register([`
  .runner {
    opacity: .4;
    cursor: pointer;
  }
  .runner:hover {
    opacity: .6;
  }
  .runner:active {
    opacity: .8;
  }
`]);
