import type { Theme } from 'app/theme';

const size = 100;

export default (theme: Theme) => ({
    ThingIcon: {
        position: 'relative',
        overflow: 'hidden',

        flexShrink: 0,
        flexGrow: 0,

        border: [1, 'solid', theme.thing.icon.borderColor],
        borderRadius: 8,

        height: size,
        width: size,

        cursor: 'pointer',
        userSelect: 'none',

        boxShadow: '0px 1px 5px 0px #0000004d',

        '&.offline $Footer': {
            backgroundColor: theme.thing.status.offline,
            color: 'rgba(0, 0, 0, 0.65) !important'
        },

        '&.online $Footer': {
            backgroundColor: theme.thing.status.online
        },

        '& > *': {
            zIndex: 2
        },

        '&:before': {
            background: theme.thing.icon.background,
            content: '""',
            display: 'block',
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 2
        },

        '&:after': {
            background: theme.box.background,
            content: '""',
            display: 'block',
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 1
        }
    },
    Footer: {
        position: 'absolute',
        bottom: 0,

        width: '100%',

        fontSize: '9pt',
        textAlign: 'center',
        color: theme.thing.icon.footerColor,
        backgroundColor: 'rgba(120, 120, 120, 0.6);',

        padding: {
            top: 2,
            bottom: 2
        }
    },
    Icon: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 12,
        left: 0,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        fontSize: '70px',
        textAlign: 'center',
        color: theme.thing.icon.color,

        '& .overlay': {
            position: 'absolute',
            fontWeight: 'bold',
            fontSize: '35px'
        }
    }
});
