import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client/react';
import { getOIDCUrl } from 'app/connector/oidc';
import { Box, Link, Loader, Table, Title } from 'app/component';
import ME_QUERY from 'app/graphql/query/me';
import { TitleSize } from 'app/component/Title/style';
import style from './style';

import type { MeQueryData } from 'app/graphql/query/me';

const useStyles = createUseStyles(style);

const User = () => {
    const classes = useStyles();
    const [t] = useTranslation('user');

    const { data, loading } = useQuery<MeQueryData>(ME_QUERY);
    if (loading || !data || !data.me) {
        return <Loader/>;
    }

    const oidcUrl = getOIDCUrl();

    return (
        <Box className={classes.User}>
            <Title size={TitleSize.large}>{t('title')}</Title>
            <Table columns={2} sizes={[200, null]} className={classes.Table}>
                <div><b>{t('details.id')}:</b></div>
                <div>{data.me.id}</div>
                <div><b>{t('details.login')}:</b></div>
                <div>{data.me.login}</div>
                {oidcUrl && (
                    <>
                        <div><b>{t('details.profile')}:</b></div>
                        <div>
                            <Link href={`${oidcUrl}/ui/console/users/me`} target="_blank">
                                {t('details.edit-profile')}
                            </Link>
                        </div>
                    </>
                )}
            </Table>
        </Box>
    );
};

export default User;
