import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import Center from '../Center';
import style from './Item.style';

interface NavigationItemProps extends React.ComponentProps<typeof Center> {
    active?: boolean;
}

const useStyles = createUseStyles(style);

const NavigationItem = ({ children, className, active, ...props }: NavigationItemProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return (
        <Center {...props} className={cx(classes.NavigationItem, active && classes.Active, className)} verticalPosition={50} ref={ref}>
            {children}
        </Center>
    );
};

export default React.forwardRef(NavigationItem);
