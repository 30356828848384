import * as React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

import style from './Body.style';

const useStyles = createUseStyles(style);

const TooltipBody = ({ className, children, ...props }: JSX.IntrinsicElements['div'], ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return (
        <div {...props} className={cx(classes.Body, className)} ref={ref}>
            {children}
        </div>
    );
};

export default React.forwardRef(TooltipBody);
