/* eslint-disable */
export default {
    Mini: {
        transform: 'scale(0.3)',
    },
    Battery: {
        userSelect: 'none',
        height: 150,
        paddingTop: 0,
    }
};
