import Blockly, { TranslatorSymbol } from '../../blockly';

import type { BlockCategoryInfo } from '../types';

export * as controls_flow_statements from './controls_flow_statements';
export * as controls_for from './controls_for';
export * as repeat_ext from './controls_repeat_ext';
export * as whileUntil from './controls_whileUntil';

export default {
    name: '${loops}',
    colour: 120
} as BlockCategoryInfo;


// @ts-ignore
Blockly.Constants.Loops.CUSTOM_CONTEXT_MENU_CREATE_VARIABLES_GET_MIXIN.customContextMenu = function(options) {
    if (this.isInFlyout) {
        return;
    }
    const variable = this.getField('VAR').getVariable();
    const varName = variable.name;
    if (!this.isCollapsed() && varName != null) {
        const xmlField = Blockly.Variables.generateVariableFieldDom(variable);
        const xmlBlock = Blockly.utils.xml.createElement('block');
        xmlBlock.setAttribute('type', 'variables_get');
        xmlBlock.appendChild(xmlField);
        options.push({
            enabled: true,
            text: this.workspace[TranslatorSymbol]('variables.set-create-get').replace('%1', varName),
            callback: Blockly.ContextMenu.callbackFactory(this, xmlBlock)
        });
    }
};

// @ts-ignore
Blockly.Constants.Loops.CONTROL_FLOW_IN_LOOP_CHECK_MIXIN.onchange = function(e) {
    // Don't change state if:
    //   * It's at the start of a drag.
    //   * It's not a move event.
    if (!this.workspace.isDragging || this.workspace.isDragging() ||
        e.type != Blockly.Events.BLOCK_MOVE) {
        return;
    }
    // @ts-ignore
    const enabled = Blockly.Constants.Loops.CONTROL_FLOW_IN_LOOP_CHECK_MIXIN.getSurroundLoop(this);
    this.setWarningText(enabled ? null : this.workspace[TranslatorSymbol]('loops.warning'));
    if (!this.isInFlyout) {
        const group = Blockly.Events.getGroup();
        // Makes it so the move and the disable event get undone together.
        Blockly.Events.setGroup(e.group);
        this.setEnabled(enabled);
        Blockly.Events.setGroup(group);
    }
};
