export default {
    Message: {
        userSelect: 'none',
        marginBottom: 10,
        whiteSpace: 'pre'
    },
    Input: {
        display: 'inline-block',
        width: 150
    },
    Title: {
        userSelect: 'none'
    }
};
