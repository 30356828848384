import { number } from 'vx-std';
import { shadeBetween } from 'app/utils/color';


const breakPoint = 33;
const mapStage1 = number.map(0, breakPoint, 0, 85);
const mapStage2 = number.map(breakPoint, 100, 20, 100);

type StyleVariables = {
  percent: number
};

const statusFadeColor = (from: string, to: string, to2?: string) => ({ percent }: StyleVariables) => {
    if (!to2) {
        return shadeBetween(from, to, percent);
    } else if (percent > breakPoint) {
        return shadeBetween(to, to2, mapStage2(percent));
    }

    return shadeBetween(from, to, mapStage1(percent));
};

export default {
    Bulb: {
        opacity: 0.3,
        fill: '#ffc803',
        fillOpacity: ({ percent }: StyleVariables) => percent / 100,
        stroke: 'none'
    },
    Gradient1A: {
        stopColor: '#ffffff',
        stopOpacity: ({ percent }: StyleVariables) => percent / 300
    },
    Gradient1B: {
        stopColor: statusFadeColor('#729fcf', '#ffffff', '#ffef91'),
        stopOpacity: 0.89
    },
    Gradient2A: {
        stopColor: statusFadeColor('#729fcf', '#ffffff', '#ffef91'),
        stopOpacity: 1
    },
    Gradient2B: {
        stopColor: statusFadeColor('#3465a4', '#ffffff', '#d4bf4a'),
        stopOpacity: 1
    },
    Wire: {
        fill: 'none',
        stroke: statusFadeColor('#ababab', '#ffffff'),
        strokeWidth: '1px',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeOpacity: 1
    },
    GradientWireHolderA: {
        stopColor: statusFadeColor('#9b9373', '#ffffff'),
        stopOpacity: 1
    },
    GradientWireHolderB: {
        stopColor: '#837356',
        stopOpacity: 1
    },
    Text: {
        stroke: '#000000',
        fontFamily: 'Quicksand',
        strokeWidth: 0.3 * 2.5,
        fontSize: `${3 * 2.5}px`,

        '& + &': {
            stroke: '#ffffff',
            strokeWidth: 0.2 * 2.5
        }
    },
    MiniText: {
        strokeWidth: 2
    },
    Slider: {
        marginLeft: 10,
        marginTop: 10,
        marginBottom: 25,
        height: 200
    }
};
