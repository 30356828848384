interface Between<T> {
    min: T;
    max: T;
}

export enum RuleMode {
    every,
    specific,
    between
}

interface Rule<T> {
    mode: RuleMode;
    every: T | null;
    specific: T[];
    between: Between<T | null>
}

interface State<T = number> {
    all: boolean;
    rules: Rule<T>[];
}

interface Action {
    type: string;
}

interface SetAllAction extends Action {
    type: 'set-all';
    value: boolean;
}
interface SetModeAction extends Action {
    type: 'set-mode';
    index: number;
    mode: RuleMode;
}
interface SetEveryAction<T> extends Action {
    type: 'set-every';
    index: number;
    value: T | null;
}
interface SetBetweenMinAction<T> extends Action {
    type: 'set-between-min';
    index: number;
    value: T | null;
}
interface SetBetweenMaxAction<T> extends Action {
    type: 'set-between-max';
    index: number;
    value: T | null;
}
interface ToggleSpecificAction<T> extends Action {
    type: 'toggle-specific';
    index: number;
    value: T;
}
interface AddRuleAction extends Action {
    type: 'add-rule';
}
interface RemoveRuleAction extends Action {
    type: 'remove-rule';
    index: number;
}

type Actions<T> = SetAllAction
    | SetModeAction
    | SetEveryAction<T>
    | SetBetweenMinAction<T>
    | SetBetweenMaxAction<T>
    | ToggleSpecificAction<T>
    | AddRuleAction
    | RemoveRuleAction;


export function makeRule<T>(): Rule<T>;
export function makeRule<T>(mode: RuleMode.every, value?: T | null): Rule<T>;
export function makeRule<T>(mode: RuleMode.specific, value?: T[] | null): Rule<T>;
export function makeRule<T>(mode: RuleMode.between, value?: Between<T> | null): Rule<T>;
export function makeRule<T>(mode: RuleMode = RuleMode.every, value?: any): Rule<T> {
    return {
        mode,
        every: mode === RuleMode.every && value || null,
        specific: mode === RuleMode.specific && value || [],
        between: mode === RuleMode.between && value || {
            min: null,
            max: null
        }
    };
}

const isSingleValue = (value: string) => {
    return !!value.match(/^[0-9]+L?$/);
};

const isBetweenValue = (value: string) => {
    return !!value.match(/^[0-9]+L?-[0-9]+L?$/);
};

const isEveryValue = (value: string) => {
    return !!value.match(/^\*\/[0-9]$/);
};

const toT = <T>(value: string): T => {
    if (!isNaN(parseInt(value)) && isFinite(value as any)) {
        return parseInt(value) as any;
    }
    return value as any;
};

// eslint-disable-next-line max-statements,complexity
export const makeInitialState = <T>(val?: string | null | undefined): State<T> => {
    const value = (val || '').trim();
    if (!value || value === '*') {
        return { all: true, rules: [] };
    }

    const rules: Rule<T>[] = [];
    const parts = value.split(',');
    const singleTemp = new Set<T>();

    let part;
    while ((part = parts.shift())) {
        if (singleTemp.size > 0 && !isSingleValue(part)) {
            rules.push(makeRule(RuleMode.specific, [...singleTemp]));
            singleTemp.clear();
        }
        switch (true) {
            case isSingleValue(part):
                singleTemp.add(toT<T>(part));
                break;
            case isBetweenValue(part): {
                const [min, max] = part.split('-');
                rules.push(makeRule(RuleMode.between, { min: toT<T>(min), max: toT<T>(max) }));
                break;
            }
            case isEveryValue(part): {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const [_, value] = part.split('/');
                rules.push(makeRule(RuleMode.every, toT<T>(value)));
                break;
            }
            default:
                break;
        }
    }
    if (singleTemp.size > 0) {
        rules.push(makeRule(RuleMode.specific, [...singleTemp]));
        singleTemp.clear();
    }

    return { all: false, rules };
};

type RuleReducer<T> = (rule: Rule<T>) => Rule<T>;

const reduceRule = <T>(state: State<T>, index: number, reducer: RuleReducer<T>): State<T> => {
    const rules = state.rules.map((rule, i) => {
        if (i === index) {
            return reducer(rule);
        }
        return rule;
    });

    return { ...state, all: false, rules };
};

export const makeReducer = <T = number>() => {
    // eslint-disable-next-line complexity
    return (state: State<T>, action: Actions<T>): State<T> => {
        switch (action.type) {
            case 'set-all':
                return { ...state, all: action.value };
            case 'add-rule':
                return { ...state, all: false, rules: [...state.rules, makeRule<T>()] };
            case 'remove-rule': {
                const rules = state.rules.filter((_, i) => i !== action.index);
                return { ...state, rules, all: rules.length === 0 };
            }
            case 'set-mode':
                return reduceRule(state, action.index, (rule) => ({ ...rule, mode: action.mode }));
            case 'set-every':
                return reduceRule(state, action.index, (rule) => ({ ...rule, every: action.value }));
            case 'toggle-specific':
                return reduceRule(state, action.index, (rule) => {
                    if (rule.specific.includes(action.value)) {
                        return { ...rule, specific: rule.specific.filter((item) => item !== action.value) };
                    }
                    return { ...rule, specific: rule.specific.concat(action.value) };
                });
            case 'set-between-min':
                return reduceRule(state, action.index, (rule) => ({ ...rule, between: { ...rule.between, min: action.value } }));
            case 'set-between-max':
                return reduceRule(state, action.index, (rule) => ({ ...rule, between: { ...rule.between, max: action.value } }));
            default:
                return state;
        }
    };
};

export const makeActionCreators = <T = number>() => ({
    setAll(value: boolean): SetAllAction {
        return { type: 'set-all', value };
    },
    setEvery(index: number, value: T | null): SetEveryAction<T> {
        return { type: 'set-every', index, value };
    },
    setMode(index: number, mode: RuleMode): SetModeAction {
        return { type: 'set-mode', index, mode };
    },
    setBetweenMin(index: number, value: T | null): SetBetweenMinAction<T> {
        return { type: 'set-between-min', index, value };
    },
    setBetweenMax(index: number, value: T | null): SetBetweenMaxAction<T> {
        return { type: 'set-between-max', index, value };
    },
    toggleSpecific(index: number, value: T): ToggleSpecificAction<T> {
        return { type: 'toggle-specific', index, value };
    },
    addRule(): AddRuleAction {
        return { type: 'add-rule' };
    },
    removeRule(index: number): RemoveRuleAction {
        return { type: 'remove-rule', index };
    }
});

// eslint-disable-next-line complexity
export const stateToExpression = <T>(state: State<T>): string => {
    if (state.all) {
        return '*';
    }

    const parts: string[] = [];
    for (const rule of state.rules) {
        switch (rule.mode) {
            case RuleMode.every:
                if (rule.every) {
                    parts.push(`*/${rule.every}`);
                }
                break;
            case RuleMode.specific:
                if (rule.specific.length > 0) {
                    parts.push(rule.specific.join(','));
                }
                break;
            case RuleMode.between:
                if (rule.between.min && rule.between.max) {
                    parts.push(`${rule.between.min}-${rule.between.max}`);
                }
                break;
        }
    }

    if (parts.length === 0) {
        return '*';
    }

    return parts.join(',');
};
