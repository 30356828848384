import * as React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import style from './style';

const useStyles = createUseStyles(style);

interface InputProps extends React.ComponentProps<'input'> {
    active?: boolean;
}

const Input = ({ className, active, ...props }: InputProps, ref: React.Ref<HTMLInputElement>) => {
    const classes = useStyles();

    return <input {...props} className={cx(classes.Input, className)} ref={ref}/>;
};

export default React.forwardRef(Input);
