import BreakLoop from '../../runner/BreakLoop';
import ContinueLoop from '../../runner/ContinueLoop';

import type { Interpreter, Node } from '../../types';
import type { BlockDefinition } from '../types';

export const name = 'controls_whileUntil';

export const definition: BlockDefinition = {
    message0: '%1 %2',
    args0: [
        {
            type: 'field_dropdown',
            name: 'MODE',
            options: [
                ['${while}', 'WHILE'],
                ['${until}', 'UNTIL']
            ]
        },
        {
            type: 'input_value',
            name: 'BOOL',
            check: 'Boolean'
        }
    ],
    message1: '${do} %1',
    args1: [{
        type: 'input_statement',
        name: 'DO'
    }],
    previousStatement: null,
    nextStatement: null,
    style: 'loop_blocks'
};

// eslint-disable-next-line max-statements,complexity
export default async function run(this: Interpreter, node: Node) {
    const mode = node.fields?.MODE;
    if (!mode) {
        throw new Error('mode missing');
    }
    const value = node.values?.BOOL;
    if (!value) {
        throw new Error('bool missing');
    }
    const task = node.statements?.DO;
    if (!task) {
        throw new Error('do missing');
    }

    if (mode === 'WHILE') {
        while (await this.execute(value)) {
            try {
                await this.executeStatement(task);
            } catch (e) {
                if (e instanceof BreakLoop) {
                    break;
                }
                if (e instanceof ContinueLoop) {
                    continue;
                }
                throw e;
            }
        }
    } else {
        do {
            try {
                await this.executeStatement(task);
            } catch (e) {
                if (e instanceof BreakLoop) {
                    break;
                }
                if (e instanceof ContinueLoop) {
                    continue;
                }
                throw e;
            }
        } while (!await this.execute(value));
    }
}
