import type { BlockCategoryInfo } from '../types';

export * as boolean from './boolean';
export * as operation from './operation';
export * as compare from './compare';
export * as negate from './negate';
export * as controls_if from './controls_if';
export * as ternary from './ternary';

export default {
    name: '${logic}',
    categorystyle: 'logic_category'
} as BlockCategoryInfo;
