import Blockly from '../blockly';
import { numerize } from './utils';

import type { ActionType, MutationsType } from 'app/graphql/fragment/automation';

import type { FlatNode, Node } from '../types';

abstract class Encoder<I> {
    protected encodeBlockBase(block: Blockly.Block): ActionType<I> {
        return {
            id: block.id,
            type: block.type
        };
    }

    protected abstract blockToJson(block: Blockly.Block): I | null;

    public abstract encode(workspace: Blockly.WorkspaceSvg): Array<ActionType<I>>;

    protected _mutationToJson(mutation: Element) {
        const result: MutationsType = {};
        if (mutation.hasAttributes()) {
            const names = mutation.getAttributeNames();
            for (const name of names) {
                const value = mutation.getAttribute(name)!;
                result[name] = numerize(value);
            }
        }
        if (mutation.hasChildNodes()) {
            result['children'] = [];
            // @ts-ignore childNodes has iterator!
            for (const child of mutation.childNodes) {
                result['children'].push(this._mutationToJson(child));
            }
        }

        return result;
    }

    // eslint-disable-next-line max-statements,complexity
    protected _blockToJson(block: Blockly.Block): ActionType<I> | null {
        if (!block || block.isShadow()) {
            return null;
        }

        const node = this.encodeBlockBase(block);

        if (block.mutationToDom) {
            // Custom data for an advanced block.
            const mutation = block.mutationToDom();
            if (mutation && (mutation.hasAttributes() || mutation.hasChildNodes())) {
                node.mutations = this._mutationToJson(mutation);
            }
        }

        for (const input of block.inputList) {
            for (const field of input.fieldRow) {
                if (field.name) {
                    if (!node.fields) {
                        node.fields = {};
                    }

                    const fakeNode = document.createElement('x');
                    field.toXml(fakeNode);
                    node.fields[field.name] = numerize(fakeNode.textContent!);
                }
            }
        }

        const commentText = block.getCommentText();
        if (commentText) {
            node.comment = commentText;
        }

        // if (block.data) {
        //     node.data = block.data;
        // }

        for (const input of block.inputList) {
            if (input.type == Blockly.inputTypes.VALUE) {
                const childBlock = this.blockToJson(input.connection.targetBlock());

                if (childBlock) {
                    if (!node.values) {
                        node.values = {};
                    }
                    node.values[input.name] = childBlock;
                }
            }
        }
        for (const input of block.inputList) {
            if (input.type == Blockly.inputTypes.STATEMENT) {
                const childBlock = this.blockToJson(input.connection.targetBlock());

                if (childBlock) {
                    if (!node.statements) {
                        node.statements = {};
                    }
                    node.statements[input.name] = childBlock;
                }
            }
        }

        const nextBlock = block.getNextBlock();
        if (nextBlock) {
            const childBlock = this.blockToJson(nextBlock);
            if (childBlock) {
                node.next = childBlock;
            }
        }

        return node;
    }
}


export class FatEncoder extends Encoder<Node> {
    protected blockToJson(block: Blockly.Block) {
        return this._blockToJson(block);
    }

    public encode(workspace: Blockly.WorkspaceSvg) {
        return workspace.getTopBlocks(true)
            .map((block) => this.blockToJson(block))
            .filter<Node>(Boolean as any);
    }
}

export class FlatEncoder extends Encoder<string> {
    protected readonly map = new Map<string, FlatNode>();

    protected encodeBlockBase(block: Blockly.Block) {
        const node = super.encodeBlockBase(block);
        this.map.set(node.id, node);
        return node;
    }

    protected blockToJson(block: Blockly.Block): string | null {
        return this._blockToJson(block)?.id || null;
    }

    public encode(workspace: Blockly.WorkspaceSvg) {
        this.map.clear();

        for (const block of workspace.getTopBlocks(true)) {
            this.blockToJson(block);
        }

        return Array.from(this.map.values());
    }
}


export default function workspaceToJson(workspace: Blockly.WorkspaceSvg): FlatNode[] {
    return (new FlatEncoder()).encode(workspace).sort((a, b) => a.id.localeCompare(b.id));
}
