import type { Theme } from 'app/theme';

export default ((theme: Theme) => ({
    AutomationOutputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        margin: 5,
        borderRadius: 6,
        background: theme.box.background,
        border: theme.box.border,
        padding: {
            left: 5,
            top: 5,
            right: 5
        }
    },
    Name: {
        flexGrow: 1,
        flexShrink: 1,
        width: '100%',

        fontSize: 12,
        marginBottom: 3
    },
    Outputs: {
        display: 'flex',
        flexDirection: 'row'
    }
}));
