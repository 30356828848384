import * as React from 'react';
import ReactDOM from 'react-dom';

interface RefinedPortalProps {
    className?: string;
    children?: React.ReactNode;
    isOpen?: boolean;
    container?: HTMLElement;
    onOpen?: () => void;
    onClose?: () => void;
    wrapperRef?: React.MutableRefObject<HTMLElement | null> | ((instance: HTMLElement | null) => void);
    style?: React.CSSProperties;
}

const Portal: React.FC<RefinedPortalProps> = ({
    className,
    children = null,
    isOpen = false,
    container = document.body,
    onOpen,
    onClose,
    style,
    wrapperRef
}) => {
    const el = React.useRef(document.createElement('div'));

    React.useEffect(() => {
        style && Object.assign(el.current.style, style);
    }, [style]);

    React.useEffect(() => {
        const cn = className;
        if (!cn) return;

        el.current.classList.add(cn);
        return () => {
            el.current.classList.remove(cn);
        };
    }, [className]);

    React.useEffect(() => {
        if (isOpen) {
            container.appendChild(el.current);
            onOpen && onOpen();
        } else if (el.current.parentNode) {
            el.current.parentNode.removeChild(el.current);
            onClose && onClose();
        }

        return () => {
            if (el.current.parentNode) {
                el.current.parentNode.removeChild(el.current);
                onClose && onClose();
            }
        };
    }, [isOpen, container, onOpen, onClose]);

    React.useEffect(() => {
        if (wrapperRef) {
            if (typeof wrapperRef === 'function') {
                wrapperRef(el.current);
            } else {
                wrapperRef.current = el.current;
            }
        }
    }, [wrapperRef]);

    return isOpen ? ReactDOM.createPortal(children, el.current) : null;
};

export default Portal;
