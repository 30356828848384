import gql from 'graphql-tag';

export default gql`
    subscription OnAutomationOutput($automationId: ID!, $outputId: ID!) {
        onAutomationOutputStatus(automationId: $automationId, outputId: $outputId) {
            id
            status
        }
    }
`;

export type AutomationOutputSubscription = {
    onAutomationOutput: {
        id: string;
        status: string;
    }
}
