import * as React from 'react';
import cx from 'classnames';
import { EntypoHome, EntypoPencil, EntypoSquaredCross, EntypoSquaredPlus } from 'react-entypo-icons';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Box, Title } from 'app/component';
import { withPopconfirm, withTooltip } from 'app/hoc';

import style from './style';
import useCreateRoomModal from './useCreateRoomModal';
import useRemoveHomeMutation from './useRemoveHomeMutation';
import useRenameHomeModal from './useRenameHomeModal';

type HomeProps = Omit<React.ComponentProps<typeof Box>, 'id'> & {
    id: number;
    name: string;
};

const useStyles = createUseStyles(style);

const DivWithTooltip = withTooltip('div');
const DivWithConformAndTooltip = withPopconfirm(DivWithTooltip);

const Home = ({ id, name, className, children, ...props }: HomeProps) => {
    const classes = useStyles();
    const [t] = useTranslation('homes');
    const [createRoom, setCreateRoomOpen] = useCreateRoomModal(id, t);
    const [renameHome, setRenameHomeOpen] = useRenameHomeModal(id, name, t);
    const remove = useRemoveHomeMutation(id);

    return <Box {...props} className={cx(classes.Home, className)}>
        {createRoom}
        {renameHome}
        <div className={classes.TopRow}>
            <Title><EntypoHome valign="bottom"/>{name}</Title>
            <div className={classes.Buttons}>
                <DivWithTooltip tooltip={t('home.rename.tooltip')} onClick={setRenameHomeOpen}>
                    <EntypoPencil/>
                </DivWithTooltip>
                <DivWithConformAndTooltip className={classes.Danger} tooltip={t('home.remove.tooltip')}
                    confirmLabel={t('home.remove.confirm')}
                    message={t('home.remove.message', { name })} onClick={() => remove()}>
                    <EntypoSquaredCross/>
                </DivWithConformAndTooltip>
            </div>
        </div>
        <div className={classes.Rooms}>
            {children}
        </div>
        <DivWithTooltip className={classes.AddRoom} tooltip={t('room.create.tooltip')} onClick={setCreateRoomOpen}>
            <EntypoSquaredPlus/>
        </DivWithTooltip>
    </Box>;
};

export default Home;
