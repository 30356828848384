

export interface OIDCState {
    redirect: string;
}

const isState = (v: any): v is OIDCState => {
    if (v && typeof v === 'object' && !Array.isArray(v)) {
        return typeof v.redirect === 'string';
    }

    return false;
};

export const getState = (state: string | null): OIDCState | null => {
    if (state) {
        try {
            const decoded = JSON.parse(atob(state));
            if (isState(decoded)) {
                return decoded;
            }
        } catch (e) {
            // no-op
        }
    }

    return null;
};
