import { CATEGORY_INPUT_COLOR } from './constants';

import type { BlockCategoryInfo } from 'app/component/Blockly/blocks/types';

export * as input_current_device_status from './input_current_device_status';
export * as input_current_subsystem_status from './input_current_subsystem_status';
export * as input_history_subsystem_status from './input_history_subsystem_status';
export * as alert from './alert';
export * as stateful_alert from './stateful_alert';

export default {
    name: '${iot}',
    colour: CATEGORY_INPUT_COLOR
} as BlockCategoryInfo;
