import type { Theme } from 'app/theme';

export default (({ warning: { fontSize, color } }: Theme) => ({
    Warning: {
        userSelect: 'none',
        boxSizing: 'border-box',
        textAlign: 'right',
        width: '100%',
        fontSize,
        color,
        fontStyle: 'italic'
    }
}));
