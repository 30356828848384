import type { Theme } from 'app/theme';

export default ({ box }: Theme) => ({
    Window: {
        maxWidth: '100%',
        maxHeight: '100%',

        display: 'flex',
        flexDirection: 'column',

        border: box.border,

        boxShadow: {
            blur: 15,
            spread: -5,
            color: '#000000',
            y: 3,
            x: 1
        },

        background: box.background,

        borderRadius: 6,

        '& > *': {
            padding: 10,

            '& + *': {
                borderTop: box.border
            }
        }
    },

    Content: {
        flexGrow: 1,
        flexShrink: 1,

        overflow: 'scroll'
    }
});
