import * as React from 'react';
import { useField, useFieldMeta, useFieldValidation } from 'vx-form';
import Feedback from 'app/composition/Form/Feedback';

import TriggerSelect from '.';

import type { FieldProps } from 'app/composition/Form';
import type { ErrorType, ValidatorSig } from 'vx-form/dist/type';
import type { AutomationTrigger } from 'app/graphql/fragment/automation';

type TriggerSelectFieldProps = React.ComponentProps<typeof TriggerSelect> & FieldProps;

export const TriggerSelectField = ({ name, validator, asyncValidator, className, ...props }: TriggerSelectFieldProps) => {
    const { value, onFocus, onBlur, onChange } = useField(name);
    const { errors, warnings } = useFieldMeta(name);
    const { setAsyncValidator, setValidator } = useFieldValidation(name);

    setValidator(validator);
    setAsyncValidator(asyncValidator);

    return (
        <Feedback errors={errors} warnings={warnings}>
            <TriggerSelect
                {...props}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                value={value}
            />
        </Feedback>
    );
};

export default TriggerSelectField;

export const required = (type?: ErrorType): ValidatorSig =>  {
    if (!type) {
        type = 'error';
    }

    return (value: AutomationTrigger, field) => {
        if (!value?.type || !value?.config) {
            return {
                [type as string]: {
                    [field]: 'Field cannot be empty!'
                }
            };
        }
    };
};
