import gql from 'graphql-tag';

export default gql`
    mutation ThingSubsystemPush($thingId: ID!, $subsystemId: ID!, $status: String!) {
        thing(id: $thingId) {
            subsystem(id: $subsystemId) {
                push(status: $status)
            }
        }
    }
`;
