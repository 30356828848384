import * as React from 'react';
import { getDisplayName } from 'app/utils/react';
import useSubsystemMutation from 'app/hook/useSubsystem/useSubsystemMutation';

type CoreButtonProps = {
    id: string;
};

export default <C extends React.ComponentType<any>>(Component: C, trigger = 'onClick') => {
    const WithPushSubsystem = ({ id, ...props }: CoreButtonProps & React.ComponentProps<C>) => {
        const push = useSubsystemMutation(id);

        return React.createElement(Component, {
            ...props,
            [trigger](...args: any) {
                push('.');

                if (props[trigger]) {
                    return props[trigger].apply(this, args);
                }
            }
        });
    };

    WithPushSubsystem.displayName = `WithPushSubsystem(${getDisplayName(Component)})`;

    return WithPushSubsystem;
};
