import TimingBase from './TimingBase';

export default class Interval extends TimingBase<any, any> {

    start() {
        this._setUp();
    }

    stop() {
        this._tearDown();
    }

    static init(fn: () => void, timeout: number) {
        return setInterval(fn, timeout);
    }

    static clear(id: any) {
        clearInterval(id);
    }
}
