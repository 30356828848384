import gql from 'graphql-tag';
import thing from '../fragment/thing';

import type { ThingType } from '../fragment/thing';

export default gql`
    query ThingsFull {
        me {
            id
            things {
                ...thing
            }
        }
    }
    ${thing}
`;

export type ThingsQueryData = {
    me: {
        id: number,
        things: Array<ThingType>
    }
};
