import type { Interpreter, Node } from '../../types';
import type { BlockDefinition } from '../types';

export const definition: BlockDefinition = {
    message0: '%1',
    args0: [
        {
            type: 'field_variable',
            name: 'VAR',
            variable: '${default.name}'
        }
    ],
    output: null,
    style: 'variable_blocks',
    extensions: ['contextMenu_variableSetterGetter']
};

export default function run(this: Interpreter, node: Node): any {
    const name = node.fields?.VAR;
    if (!name) {
        throw new Error('variable name missing');
    }

    return this.variables.get(String(name));
}
run.noTrace = true;
