import * as React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import Item from './Item';
import style from './style';

type ListProps = JSX.IntrinsicElements['div'] & {
    children: React.ReactChildren;
    active?: boolean;
};

const useStyles = createUseStyles(style);

const List = ({ className, active, children, ...props }: ListProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return (
        <div {...props} className={cx(classes.List, className)} ref={ref}>
            {children}
        </div>
    );
};

type RefListType = typeof List & {
    Item: typeof Item
}

const RefList = React.forwardRef(List) as any as RefListType;


RefList.Item = Item;

export default RefList;
export { Item };
