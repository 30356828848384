import * as React from 'react';
import cx from 'classnames';
import { EntypoMenu } from 'react-entypo-icons';
import { createUseStyles } from 'react-jss';
import Center from '../Center';
import Item from './Item';
import Dropdown from './Dropdown';
import style from './style';

const useStyles = createUseStyles(style);

const Navigation = ({ children, className, ...props }: JSX.IntrinsicElements['div'], ref: React.Ref<HTMLDivElement>) => {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    return (
        <div {...props} className={cx(classes.Navigation, className)} ref={ref}>
            <div className={cx(classes.Container, open && classes.Open)} onClick={() => setOpen(false)}>
                {children}
            </div>
            <Center verticalPosition={50} className={classes.Hamburger} onClick={() => setOpen(!open)}>
                <div>
                    <EntypoMenu/>
                </div>
            </Center>
        </div>
    );
};

type RefNavigationType = typeof Navigation & {
    Item: typeof Item;
    Dropdown: typeof Dropdown;
};

const RefNavigation = React.forwardRef(Navigation) as any as RefNavigationType;

RefNavigation.Item = Item;
RefNavigation.Dropdown = Dropdown;

export default RefNavigation;
export { Item };
