import type { Interpreter, Node } from '../../types';
import type { BlockDefinition } from '../types';

export const definition: BlockDefinition = {
    message0: '${number} ${from} %1 ${to} %2',
    args0: [
        {
            type: 'input_value',
            name: 'FROM',
            check: 'Number'
        },
        {
            type: 'input_value',
            name: 'TO',
            check: 'Number'
        }
    ],
    inputsInline: true,
    output: 'Number',
    style: 'math_blocks'
};

export default async function run(this: Interpreter, node: Node): Promise<number> {
    const fromNode = node.values?.FROM;
    if (!fromNode) {
        throw new Error('from missing');
    }

    const toNode = node.values?.TO;
    if (!toNode) {
        throw new Error('to missing');
    }

    const from = await this.execute(fromNode);
    const to = await this.execute(toNode);

    return Math.floor(Math.random() * (to - from)) + from;
}


