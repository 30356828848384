import React from 'react';

import Blockly, { ModalsSymbol, TranslatorSymbol, SetPortalsSymbol, PortalsSymbol } from './blockly';
import workspaceToJson from './json/encoder';
import jsonToWorkspace from './json/decoder';
import sanitizeNode from './json/sanitizeNode';

import type { PortalType } from './blockly';
import type { BlocklyTranslatorSig, ContextWorkspaceSvg, FlatNode, BlocklyModals, ToolboxConfig } from './types';

export type WorkspaceCallbackSig<W = Blockly.WorkspaceSvg> = (workspace: W) => void;
export type BlocklyOnChangeSig = (value: FlatNode[]) => void;

export interface UseBlocklyWorkspaceOptions<W = Blockly.WorkspaceSvg> {
    ref: React.RefObject<Element>
    onInject?: WorkspaceCallbackSig<W>,
    onDispose?: WorkspaceCallbackSig<W>,
    config?: Blockly.BlocklyOptions
}

export function useBlocklyWorkspace<W = Blockly.WorkspaceSvg>({
    ref,
    config,
    onInject,
    onDispose
}: UseBlocklyWorkspaceOptions<W>): Blockly.WorkspaceSvg | null {
    const onInjectRef = React.useRef(onInject);
    React.useEffect(() => {
        onInjectRef.current = onInject;
    }, [onInject]);

    const onDisposeRef = React.useRef(onDispose);
    React.useEffect(() => {
        onDisposeRef.current = onDispose;
    }, [onDispose]);

    const configRef = React.useRef<Blockly.BlocklyOptions>(config || {});
    React.useEffect(() => {
        configRef.current = config || {};
    }, [config]);

    const [workspace, setWorkspace] = React.useState<Blockly.WorkspaceSvg | null>(null);

    React.useEffect(() => {
        if (config?.toolbox && workspace) {
            workspace.updateToolbox(config.toolbox);
            for (const category of (config.toolbox as ToolboxConfig).contents || []) {
                if (category.builder && category.custom) {
                    workspace.registerToolboxCategoryCallback(category.custom, category.builder);
                }
            }
        }
    }, [config?.toolbox, workspace]);

    const [portals, setPortals] = React.useState<PortalType[]>([]);
    React.useEffect(() => {
        const newWorkspace = Blockly.inject(ref.current!, configRef.current);
        newWorkspace[PortalsSymbol] = portals;
        newWorkspace[SetPortalsSymbol] = setPortals;
        setWorkspace(newWorkspace);
        onInjectRef.current?.(newWorkspace as any);

        return () => {
            newWorkspace.dispose();
            onDisposeRef.current?.(newWorkspace as any);
        };
    }, [ref]);

    if (workspace) {
        workspace[PortalsSymbol] = portals;
        workspace[SetPortalsSymbol] = setPortals;
    }

    return workspace;
}

export function useBlocklyChangeListener<W = Blockly.WorkspaceSvg>(
    workspace: W | null,
    listener: WorkspaceCallbackSig<W>
) {
    React.useEffect(() => {
        if (!workspace || !listener) {
            return;
        }

        const wrapped = () => {
            listener(workspace);
        };
        (workspace as any as Blockly.WorkspaceSvg).addChangeListener(wrapped);
        return () => {
            (workspace as any as Blockly.WorkspaceSvg).removeChangeListener(wrapped);
        };
    }, [workspace, listener]);
}

export function useBlocklyContext<C>(
    workspace: Blockly.WorkspaceSvg | ContextWorkspaceSvg<any> | null,
    context: C | null
): ContextWorkspaceSvg<C> {
    if (workspace && context) {
        // @ts-ignore
        workspace.context = context;
    }

    return workspace as ContextWorkspaceSvg<C>;
}

export function useBlocklyTranslator<T = Blockly.WorkspaceSvg>(workspace: T | null, translator: BlocklyTranslatorSig | null) {
    if (workspace && translator) {
        // @ts-ignore
        workspace[TranslatorSymbol] = translator;
    }

    return workspace;
}

export function useIsBlocklyDirty(initialValue: FlatNode[], currentValue: FlatNode[]): boolean {
    const initial = React.useMemo(() => JSON.stringify((initialValue || []).map(sanitizeNode)), [initialValue]);
    return React.useMemo(() => JSON.stringify(currentValue) !== initial, [initial, currentValue]);
}

export function useBlocklyValue<T = Blockly.WorkspaceSvg>(workspace: T | null, value: FlatNode[]) {
    React.useEffect(() => {
        if (workspace && value) {
            jsonToWorkspace(workspace as any, value);
        }
    }, [workspace, value]);

    return workspace;
}

export function useBlocklyChange<T = Blockly.WorkspaceSvg>(workspace: T | null, onChange: BlocklyOnChangeSig | null, currentValue?: FlatNode[]) {
    const oldJson = React.useRef(JSON.stringify(currentValue || []));
    const handleChange = React.useCallback((workspace) => {
        if (workspace.isDragging()) {
            return;
        }

        const value = workspaceToJson(workspace);
        const json = JSON.stringify(value);
        if (oldJson.current !== json) {
            oldJson.current = json;
            onChange?.(value);
        }
    }, [workspace, onChange]);


    return useBlocklyChangeListener<T>(workspace, handleChange);
}

export function useBlocklyModals<T = Blockly.WorkspaceSvg>(workspace: T | null, modals: BlocklyModals | null) {
    React.useEffect(() => {
        if (workspace) {
            // @ts-ignore
            workspace[ModalsSymbol] = modals;
        }
    }, [workspace, modals]);

    return workspace;
}


export interface UseBlocklyOptions<C, W> extends UseBlocklyWorkspaceOptions<W> {
    onChange?: (value: FlatNode[]) => void;
    value?: FlatNode[];
    translator?: BlocklyTranslatorSig;
    context?: C;
    modals?: BlocklyModals
}

export default function useBlockly<C>({
    ref,
    config,
    onInject,
    onDispose,

    modals,
    context,
    translator,
    onChange,
    value
}: UseBlocklyOptions<C, ContextWorkspaceSvg<C>>) {
    const rawWorkspace = useBlocklyWorkspace<ContextWorkspaceSvg<C>>({ ref, config, onInject, onDispose });
    const workspace = useBlocklyContext<C>(rawWorkspace, context || null);

    useBlocklyModals(workspace, modals || null);
    useBlocklyChange(workspace, onChange || null, value);
    useBlocklyValue(workspace, value || []);
    useBlocklyTranslator(workspace, translator || null);

    return workspace;
}
