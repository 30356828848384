import { predicate } from 'vx-std';

export default {
    Table: {
        display: 'grid',
        gridTemplateColumns: ({ columns, sizes }: any) => {
            const result = Array(columns).fill('auto');
            if (sizes) {
                return result.map((s, i) => {
                    if ([undefined, null].includes(sizes[i])) {
                        return s;
                    }

                    const size = sizes[i];

                    if (predicate.isNumber(size)) {
                        return `${size}px`;
                    }

                    return size;
                }).join(' ');
            }

            return result.join(' ');
        }
    }
};
