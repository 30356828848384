export default {
    Container: {
        display: 'flex',
        flexDirection: 'row'
    },
    FutureButton: {
        flexGrow: 0,
        flexShrink: 0,

        padding: [7, 10]
    },
    Input: {
        flexGrow: 1,
        flexShrink: 1
    }
};
