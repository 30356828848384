import Blockly, { TranslatorSymbol } from '../../blockly';
import { FieldTextInput } from '../../fields';
import { builder as base } from './defnoreturn';

import type { DefNoReturnBlock } from './defnoreturn';

export const builder = {
    init(this: DefNoReturnBlock) {
        const initName = Blockly.Procedures.findLegalName('', this);
        const nameField = new FieldTextInput(initName, Blockly.Procedures.rename);
        nameField.setSpellcheck(false);
        this.appendDummyInput()
            .appendField(this[TranslatorSymbol]('procedures.def.title'))
            .appendField(nameField, 'NAME')
            .appendField('', 'PARAMS');
        this.appendValueInput('RETURN')
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(this[TranslatorSymbol]('procedures.def.return'));
        this.setMutator(new Blockly.Mutator(['procedures_mutatorarg']));
        if (this.workspace.options.comments || this.workspace.options.parentWorkspace?.options.comments) {
            this.setCommentText(this[TranslatorSymbol]('procedures.def.comment'));
        }
        this.setStyle('procedure_blocks');
        this.arguments_ = [];
        this.argumentVarModels_ = [];
        this.setStatements_(true);
        this.statementConnection_ = null;
    },
    setStatements_: base.setStatements_,
    updateParams_: base.updateParams_,
    mutationToDom: base.mutationToDom,
    domToMutation: base.domToMutation,
    decompose: base.decompose,
    compose: base.compose,
    getProcedureDef(this: DefNoReturnBlock) {
        return [this.getFieldValue('NAME'), this.arguments_, true];
    },
    getVars: base.getVars,
    getVarModels: base.getVarModels,
    renameVarById: base.renameVarById,
    updateVarName: base.updateVarName,
    displayRenamedVar_: base.displayRenamedVar_,
    customContextMenu: base.customContextMenu,
    callType_: 'procedures_callreturn'
};
