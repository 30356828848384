import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Button, Title, Window } from 'app/component';
import { ButtonKind } from 'app/component/Button';
import { createWithModal } from 'app/hoc';
import { FormButton, FormFeedback, InputField, MutationForm, validator } from 'app/composition/Form';
import CREATE_SHORTCUT_MUTATION from 'app/graphql/mutation/thing/shortcut/create';

import style from './CreateShortcut.style';

type RenameProps = {
    close: () => void;
    thingId: number;
    subsystemId: string;
    on: string;
    off: string;
};

const useStyles = createUseStyles(style);

const { withModal, withTrigger } = createWithModal();

const CreateShortcut = ({ close, thingId, subsystemId, on, off }: RenameProps) => {
    const classes = useStyles();
    const [t] = useTranslation('shortcut');
    return (
        <MutationForm mutation={CREATE_SHORTCUT_MUTATION} onSubmitSuccess={close} variables={{ thingId, subsystemId, on, off }} touchOn="blur">
            <Window className={classes.Window} contentClassName={classes.Content} title={<Title>{t('create.title')}</Title>}>
                <InputField name="name" placeholder={t('create.name')} validator={validator.required()}/>

                <FormFeedback/>
                <Window.ButtonFooter>
                    <FormButton kind={ButtonKind.primary} type="submit">
                        {t('create.create')}
                    </FormButton>
                    <Button kind={ButtonKind.lite} onClick={close}>
                        {t('create.cancel')}
                    </Button>
                </Window.ButtonFooter>
            </Window>
        </MutationForm>
    );
};

export default withModal(CreateShortcut);
export const withCreateTrigger = withTrigger;
