import type { SubsystemType } from 'app/graphql/fragment/subsystem';


type SegregatedSubsystemsType = {
    core: Record<string, SubsystemType>;
    thing: Array<SubsystemType>;
};

export const extractSubsystemId = (id: string): number => {
    const parts = /[a-z0-9]+[#:]([a-z0-9]+)/i.exec(id);

    if (!parts || !parts[1]) {
        throw new Error('Unknown id shape! ' + id);
    }

    return parseInt(parts[1]);
};

export const extractThingId = (id: string): number => {
    const parts = /([a-z0-9]+)[#:][a-z0-9]+/i.exec(id);

    if (!parts || !parts[1]) {
        throw new Error('Unknown id shape! ' + id);
    }

    return parseInt(parts[1]);
};

export const segregateSubsystems = (subsystems: Array<SubsystemType>): SegregatedSubsystemsType => {
    return subsystems.reduce((result, subsystem) => {
        if (subsystem.id.includes('#')) {
            result.core[subsystem.name.toLowerCase()] = subsystem;
        } else {
            result.thing.push(subsystem);
        }

        return result;
    }, {
        core: {},
        thing: []
    } as SegregatedSubsystemsType);
};
