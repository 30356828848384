export default {
    Thing: {
        padding: '10px',

        '& > * + *': {
            marginTop: 10
        }
    },
    Title: {
        flexShrink: 0,
        marginLeft: 5
    },
    AlwaysRow: {
        display: 'flex',

        padding: {
            left: 10,
            right: 10
        },

        '& > * + *': {
            marginLeft: 10
        },

        '@media screen and (max-width: 600px)': {
            justifyContent: 'space-between'
        }
    },
    Row: {
        marginTop: 3,
        display: 'flex',

        '@media screen and (max-width: 600px)': {
            flexDirection: 'column'
        }
    },
    Meta: {
        flexGrow: 0,
        flexShrink: 0,

        padding: {
            bottom: 10
        },
        fontSize: 12,
        color: '#AAAAAA',

        '& b': {
            userSelect: 'none'
        }
    },
    Room: {
        userSelect: 'none',

        flexGrow: 0,
        flexShrink: 0,

        padding: {
            bottom: 10
        },
        fontSize: 12
    },
    Home: {
        userSelect: 'none',

        flexGrow: 0,
        flexShrink: 0,

        padding: {
            bottom: 10
        },
        fontSize: 12
    },
    Buttons: {
        display: 'flex',

        '@media screen and (max-width: 600px)': {
            justifyContent: 'space-evenly'
        },
        '@media screen and (min-width: 600px)': {
            marginLeft: 'auto',
            marginRight: 10,
            alignItems: 'flex-end'
        },


        '& > *': {
            height: 30,
            fontSize: 30,
            cursor: 'pointer',
            opacity: 0.4,

            '&:hover': {
                opacity: 1
            },

            '& + *': {
                marginLeft: 10
            }
        }
    },
    Danger: {
        color: '#D40000'
    },
    Warning: {
        color: '#d46000'
    },
    Identify: {
        color: '#1270d4'
    },
    Update: {
        color: '#a62fd4'
    }
};
