export const CONTAINER_CLASS_NAME = `modal-container-${Math.random().toString(16).slice(2)}`;

const Modal = {
    position: 'fixed',

    top: 0,
    right: 0,
    bottom: 0,
    left: 0

};

export default {
    '@global': {
        [`.${CONTAINER_CLASS_NAME}`]: Modal
    },
    Modal,
    Content: {
        position: 'absolute',

        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    }
};
