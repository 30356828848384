import { useQuery } from '@apollo/client/react';
import SUBSYSTEM_QUERY from 'app/graphql/query/subsystem';
import type { SubsystemType } from 'app/graphql/fragment/subsystem';

type SubsystemQueryResultType = {
    loading: boolean;
    subsystem: SubsystemType | undefined;
};

const useSubsystemQuery = (id: string): SubsystemQueryResultType => {
    const { data: { subsystem } = {}, loading } = useQuery(SUBSYSTEM_QUERY, { variables: { id } });

    return {
        subsystem,
        loading
    };
};

export default useSubsystemQuery;
