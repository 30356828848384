import Blockly, { ModalsSymbol, TranslatorSymbol } from '../../blockly';

import type { BlockCategoryInfo } from '../types';

export * as get from './get';
export * as set from './set';

export default {
    name: '${variables}',
    categorystyle: 'variable_category',
    custom: 'VARIABLE'
} as BlockCategoryInfo;


const sanitizeName = (name: string) => {
    return name.replace(/[\s\xa0]+/g, ' ').trim();
};

/**
 * Handles "Create Variable" button in the default variables toolbox category.
 * It will prompt the user for a variable name, including re-prompts if a name
 * is already in use among the workspace's variables.
 *
 * Custom button handlers can delegate to this function, allowing variables
 * types and after-creation processing. More complex customization (e.g.,
 * prompting for variable type) is beyond the scope of this function.
 *
 * @param {!Blockly.Workspace} workspace The workspace on which to create the
 *     variable.
 * @param {function(?string=)=} opt_callback A callback. It will be passed an
 *     acceptable new variable name, or null if change is to be aborted (cancel
 *     button), or undefined if an existing variable was chosen.
 * @param {string=} opt_type The type of the variable like 'int', 'string', or
 *     ''. This will default to '', which is a specific type.
 */
Blockly.Variables.createVariableButtonHandler = (workspace: Blockly.Workspace, opt_callback?: (s: string) => void, opt_type?: string) => {
    const type = opt_type || '';
    // This function needs to be named so it can be called recursively.
    const promptAndCheckWithAlert = async (defaultName: string) => {
        let text = await workspace[ModalsSymbol].prompt(workspace[TranslatorSymbol]('variables.prompt.create'), defaultName);
        if (text) {
            text = sanitizeName(text);
            const existing = Blockly.Variables.nameUsedWithAnyType(text, workspace);
            if (existing) {
                let msg: string;
                if (existing.type == type) {
                    msg = workspace[TranslatorSymbol]('variables.error.already_exists').replace('%1', existing.name);
                } else {
                    msg = workspace[TranslatorSymbol]('variables.error.exists_for_another_type');
                    msg = msg.replace('%1', existing.name).replace('%2', existing.type);
                }
                await workspace[ModalsSymbol].alert(msg);
                promptAndCheckWithAlert(text);
            } else {
                // No conflict
                workspace.createVariable(text, type);
                if (opt_callback) {
                    opt_callback(text);
                }
            }
        } else {
            // User canceled prompt.
            if (opt_callback) {
                // @ts-ignore
                opt_callback(null);
            }
        }
    };
    promptAndCheckWithAlert('');
};

/**
 * Original name of Blockly.Variables.createVariableButtonHandler(..).
 * @deprecated Use Blockly.Variables.createVariableButtonHandler(..).
 *
 * @param {!Blockly.Workspace} workspace The workspace on which to create the
 *     variable.
 * @param {function(?string=)=} opt_callback A callback. It will be passed an
 *     acceptable new variable name, or null if change is to be aborted (cancel
 *     button), or undefined if an existing variable was chosen.
 * @param {string=} opt_type The type of the variable like 'int', 'string', or
 *     ''. This will default to '', which is a specific type.
 */
Blockly.Variables.createVariable =
    Blockly.Variables.createVariableButtonHandler;

/**
 * Opens a prompt that allows the user to enter a new name for a variable.
 * Triggers a rename if the new name is valid. Or re-prompts if there is a
 * collision.
 * @param {!Blockly.Workspace} workspace The workspace on which to rename the
 *     variable.
 * @param {!Blockly.VariableModel} variable Variable to rename.
 * @param {function(?string=)=} opt_callback A callback. It will
 *     be passed an acceptable new variable name, or null if change is to be
 *     aborted (cancel button), or undefined if an existing variable was chosen.
 */
Blockly.Variables.renameVariable = function (workspace, variable, opt_callback) {
    // This function needs to be named so it can be called recursively.
    const promptAndCheckWithAlert = async (defaultName: string) => {
        const promptText = workspace[TranslatorSymbol]('variables.prompt.rename').replace('%1', variable.name);
        let newName = await workspace[ModalsSymbol].prompt(promptText, defaultName);
        if (newName) {
            newName = sanitizeName(newName);
            // @ts-ignore
            const existing = Blockly.Variables.nameUsedWithOtherType_(newName, variable.type, workspace);
            if (existing) {
                const msg = workspace[TranslatorSymbol]('variables.error.exists_for_another_type')
                    .replace('%1', existing.name)
                    .replace('%2', existing.type);

                await workspace[ModalsSymbol].alert(msg);
                promptAndCheckWithAlert(newName);
            } else {
                workspace.renameVariableById(variable.getId(), newName);
                if (opt_callback) {
                    opt_callback(newName);
                }
            }
        } else {
            // User canceled prompt.
            if (opt_callback) {
                // @ts-ignore
                opt_callback(null);
            }
        }
    };
    promptAndCheckWithAlert('');
};

const oldDropdownCreate = Blockly.FieldVariable.dropdownCreate;
Blockly.FieldVariable.dropdownCreate = function(this: Blockly.FieldVariable): Array<[string, string]> {
    const orig: Array<[string, string]> = oldDropdownCreate.call(this) as any;
    orig[orig.length - 2][0] = this[TranslatorSymbol]('variables.rename').replace('%1', this.getText());
    orig[orig.length - 1][0] = this[TranslatorSymbol]('variables.delete').replace('%1', this.getText());
    return orig;
};

// @ts-ignore
Blockly.Constants.Variables.CUSTOM_CONTEXT_MENU_VARIABLE_GETTER_SETTER_MIXIN.customContextMenu =
    function (this: Blockly.Block, options: Array<any>) {
        if (!this.isInFlyout) {
            let opposite_type = 'variables_get';
            let contextMenuMsg = this[TranslatorSymbol]('variables.set-create-get');
            // Getter blocks have the option to create a setter block, and vice versa.
            if (this.type == 'variables_get') {
                opposite_type = 'variables_set';
                contextMenuMsg = this[TranslatorSymbol]('variables.get-create-set');
            }

            const name = this.getField('VAR').getText();
            const xmlField = Blockly.utils.xml.createElement('field');
            xmlField.setAttribute('name', 'VAR');
            xmlField.appendChild(Blockly.utils.xml.createTextNode(name));
            const xmlBlock = Blockly.utils.xml.createElement('block');
            xmlBlock.setAttribute('type', opposite_type);
            xmlBlock.appendChild(xmlField);
            options.push({
                enabled: this.workspace.remainingCapacity() > 0,
                text: contextMenuMsg.replace('%1', name),
                callback: Blockly.ContextMenu.callbackFactory(this, xmlBlock)
            });
            // Getter blocks have the option to rename or delete that variable.
        } else {
            if (this.type == 'variables_get' || this.type == 'variables_get_reporter') {
                const name = this.getField('VAR').getText();

                const renameOption = {
                    text: this[TranslatorSymbol]('variables.rename').replace('%1', name),
                    enabled: true,
                    // @ts-ignore
                    callback: Blockly.Constants.Variables.RENAME_OPTION_CALLBACK_FACTORY(this)
                };
                const deleteOption = {
                    text: this[TranslatorSymbol]('variables.delete').replace('%1', name),
                    enabled: true,
                    // @ts-ignore
                    callback: Blockly.Constants.Variables.DELETE_OPTION_CALLBACK_FACTORY(this)
                };
                options.unshift(renameOption);
                options.unshift(deleteOption);
            }
        }
    };
