export default {
    ListItem: {
        userSelect: 'none',

        display: 'flex',
        flexDirection: 'column',

        padding: 10,

        cursor: 'pointer',

        border: {
            style: 'solid',
            color: '#00000080',
            width: 0
        },

        borderBottomWidth: 1,

        '&:hover': {
            background: '#00000011'
        }
    },
    Active: {
        background: '#0000002A',
        borderBottom: {
            style: 'solid',
            color: '#ffffff',
            width: 1
        }
    }
};
