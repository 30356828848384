import * as React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { object } from 'vx-std';
import style from './style';

type SettingsEntryProps = JSX.IntrinsicElements['div'] & {
    label: React.ReactNode;
    Component: React.ComponentType<{ value: any; onValueChange: (arg0: any) => void; }>;
    data: any;
    path: string;
    set: (arg0: string, arg1: any) => void;
};

const useStyles = createUseStyles(style);

const SettingsEntry = ({ className, label, path, data, set, Component, ...props }: SettingsEntryProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return (
        <div {...props} className={cx(classes.SettingsEntry, className)} ref={ref}>
            <div className={classes.Label}>{label}</div>
            <div className={classes.Wrapper}>
                <Component value={object.getIn(data, path)} onValueChange={value => set(path, value)}/>
            </div>
        </div>
    );
};

export default React.forwardRef(SettingsEntry);
