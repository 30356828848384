import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import style from './Item.style';

const useStyles = createUseStyles(style);

type ListItemProps = JSX.IntrinsicElements['div'] & {
    active?: boolean
}

const ListItem = ({ className, active, children, ...props }: ListItemProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return (
        <div {...props} className={cx(classes.ListItem, active && classes.Active, className)} ref={ref}>
            {children}
        </div>
    );
};

export default React.forwardRef(ListItem);
