export default {
    Window: {
        width: 350
    },
    Content: {
        '& > * + *': {
            marginTop: 10
        }
    }
};
