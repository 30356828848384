import { GraphQLDate, GraphQLDateTime, GraphQLTime } from 'graphql-iso-date';
import gql from 'graphql-tag';
import GraphQLJSON from 'graphql-type-json';
import { resolvers, typeDefs } from 'app/graphql';

export default {
    resolvers: [{
        JSON: GraphQLJSON,
        Date: GraphQLDate,
        Time: GraphQLTime,
        DateTime: GraphQLDateTime
    } as Record<string, unknown>].concat(resolvers),
    typeDefs: [gql`
        scalar JSON
        scalar Date
        scalar Time
        scalar DateTime
    `].concat(typeDefs)
};
