import type { BlockCategoryInfo } from '../types';

export * as number from './number';
export * as arithmetic from './arithmetic';
export * as single from './single';
export * as number_property from './number_property';
export * as round from './round';
export * as constrain from './constrain';
export * as random_int from './random_int';
export * as random_float from './random_float';

export default {
    name: '${math}',
    categorystyle: 'math_category'
} as BlockCategoryInfo;
