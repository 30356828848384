import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';

import { Box, Carousel, Loader } from 'app/component';
import * as subsystems from 'app/subsystem';
import { extractThingId } from 'app/helper/subsystem';
import useSubsystem from 'app/hook/useSubsystem';
import useSubsystemHistory from 'app/hook/useSubsystem/useSubsystemHistory';
import useHistoryParams from 'app/hook/useSubsystem/history/useHistoryParams';

import Shortcuts from '../Shortcuts';

import Name from './Name';
import Unsupported from './Unsupported';

import style from './style';


type SubsystemProps = Omit<React.ComponentProps<typeof Box>, 'type' | 'id'> & {
    id: string;
    type: subsystems.SubsystemTypeType;
    disabled?: boolean;
};

const useStyles = createUseStyles(style);

// eslint-disable-next-line complexity
const Subsystem = ({ id, type, className, children, disabled, ...props }: SubsystemProps) => {
    const classes = useStyles();
    const supported = type in subsystems;
    const Subsystem = subsystems[type];
    const { subsystem, push, loading } = useSubsystem(id, Subsystem);
    const historyParams = useHistoryParams();
    const { history } = useSubsystemHistory(id, historyParams);

    if (loading || !subsystem) {
        return <Loader/>;
    }
    return (
        <div {...props}>
            <Box className={cx(classes.Subsystem, !supported && classes.Attention, className)}>
                {supported ? (
                    <React.Fragment>
                        <Name className={classes.Title} name={subsystem.name} type={subsystem.type} />
                        <Carousel className={classes.Statuses}>
                            {Subsystem.Value && <Subsystem.Value push={push} status={subsystem.status || ''} />}
                            {/* @ts-ignore */}
                            {Subsystem.Graph && <Subsystem.Graph history={history} onOpenSettings={historyParams.open} />}
                        </Carousel>
                    </React.Fragment>
                ) : (
                    <div className={classes.Unsupported}>
                        <Unsupported
                            id={subsystem.id}
                            name={subsystem.name}
                            type={subsystem.type}
                            status={subsystem.status}
                        />
                    </div>
                )}
                {children}
            </Box>
            {Subsystem?.shortcuts && (
                <Shortcuts
                    thingId={extractThingId(id)}
                    subsystemId={id}
                    config={Subsystem.shortcuts}
                    disabled={disabled}
                    shortcuts={subsystem.shortcuts}
                    status={subsystem.status || ''}
                />
            )}
        </div>
    );
};

export default Subsystem;
