/* eslint-disable max-len */
export default {
    DateTime: {
        '&.react-datetime-picker': {
            display: 'inline-flex',
            position: 'relative'
        },
        '&.react-datetime-picker, &.react-datetime-picker *, &.react-datetime-picker *:before, &.react-datetime-picker *:after': {
            boxSizing: 'border-box'
        },
        '&.react-datetime-picker--disabled': {
            backgroundColor: '#f0f0f0',
            color: '#6d6d6d'
        },
        '& .react-datetime-picker__wrapper': {
            display: 'flex',
            flexGrow: 1,
            flexShrink: 0,
            border: 'thin solid gray'
        },
        '& .react-datetime-picker__inputGroup': {
            minWidth: 'calc(4px + (4px * 3) +  0.54em * 6  +  0.217em * 2)',
            flexGrow: 1,
            padding: '0 2px'
        },
        '& .react-datetime-picker__inputGroup__divider': {
            padding: '1px 0',
            whiteSpace: 'pre'
        },
        '& .react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__year': {
            minWidth: '3.5em'
        },
        '& .react-datetime-picker__inputGroup__input': {
            minWidth: '2.5em',
            height: 'calc(100% - 2px)',
            position: 'relative',
            padding: 1,
            border: 0,
            background: 'none',
            font: 'inherit',
            boxSizing: 'content-box'
        },
        '& .react-datetime-picker__inputGroup__input::-webkit-outer-spin-button, & .react-datetime-picker__inputGroup__input::-webkit-inner-spin-button': {
            margin: 0
        },
        '& .react-datetime-picker__inputGroup__input:invalid': {
            background: 'rgba(255, 0, 0, 0.1)'
        },
        '& .react-datetime-picker__inputGroup__input--hasLeadingZero': {
            marginLeft: '-0.54em',
            paddingLeft: 'calc(1px +  0.54em)'
        },
        '& .react-datetime-picker__inputGroup__amPm': {
            font: 'inherit'
        },
        '& .react-datetime-picker__button': {
            border: 0,
            background: 'transparent',
            padding: '4px 6px'
        },
        '& .react-datetime-picker__button:enabled': {
            cursor: 'pointer'
        },
        '& .react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon, & .react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon': {
            stroke: '#0078d7'
        },
        '& .react-datetime-picker__button:disabled .react-datetime-picker__button__icon': {
            stroke: '#6d6d6d'
        },
        '& .react-datetime-picker__button svg': {
            display: 'inherit'
        },
        '& .react-datetime-picker__calendar, & .react-datetime-picker__clock': {
            position: 'absolute',
            top: '100%',
            left: 0,
            zIndex: 1
        },
        '& .react-datetime-picker__calendar--closed, & .react-datetime-picker__clock--closed': {
            display: 'none'
        },
        '& .react-datetime-picker__calendar': {
            width: 350,
            maxWidth: '100vw'
        },
        '& .react-datetime-picker__calendar .react-calendar': {
            borderWidth: 'thin'
        },
        '& .react-datetime-picker__clock': {
            width: 200,
            height: 200,
            maxWidth: '100vw',
            padding: 25,
            backgroundColor: 'white',
            border: 'thin solid #a0a096'
        },
        '& .react-calendar': {
            position: 'fixed',
            width: 350,
            maxWidth: '100',
            background: 'white',
            border: '1px solid #a0a096',
            fontFamily: 'Arial, Helvetica, sans-serif',
            lineHeight: '1.125em'
        },
        '& .react-calendar--doubleView': {
            width: 700
        },
        '& .react-calendar--doubleView .react-calendar__viewContainer': {
            display: 'flex',
            margin: '-0.5em'
        },
        '& .react-calendar--doubleView .react-calendar__viewContainer > *': {
            width: '50%',
            margin: '0.5em'
        },
        '& .react-calendar, & .react-calendar *, & .react-calendar *:before, & .react-calendar *:after': {
            boxSizing: 'border-box'
        },
        '& .react-calendar button': {
            margin: 0,
            border: 0,
            outline: 'none'
        },
        '& .react-calendar button:enabled:hover': {
            cursor: 'pointer'
        },
        '& .react-calendar__navigation': {
            height: 44,
            marginBottom: '1em'
        },
        '& .react-calendar__navigation button': {
            minWidth: 44,
            background: 'none'
        },
        '& .react-calendar__navigation button:enabled:hover, & .react-calendar__navigation button:enabled:focus': {
            backgroundColor: '#e6e6e6'
        },
        '& .react-calendar__navigation button[disabled]': {
            backgroundColor: '#f0f0f0'
        },
        '& .react-calendar__month-view__weekdays': {
            textAlign: 'center',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: '0.75em'
        },
        '& .react-calendar__month-view__weekdays__weekday': {
            padding: '0.5em'
        },
        '& .react-calendar__month-view__weekNumbers': {
            fontWeight: 'bold'
        },
        '& .react-calendar__month-view__weekNumbers .react-calendar__tile': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '0.75em',
            padding: 'calc(0.75em / 0.75) calc(0.5em / 0.75)'
        },
        '& .react-calendar__month-view__days__day--weekend': {
            color: '#d10000'
        },
        '& .react-calendar__month-view__days__day--neighboringMonth': {
            color: '#757575'
        },
        '& .react-calendar__year-view .react-calendar__tile, & .react-calendar__decade-view .react-calendar__tile, & .react-calendar__century-view .react-calendar__tile': {
            padding: '2em 0.5em'
        },
        '& .react-calendar__tile': {
            maxWidth: '100%',
            textAlign: 'center',
            padding: '0.75em 0.5em',
            background: 'none'
        },
        '& .react-calendar__tile:disabled': {
            backgroundColor: '#f0f0f0'
        },
        '& .react-calendar__tile:enabled:hover, & .react-calendar__tile:enabled:focus': {
            backgroundColor: '#e6e6e6'
        },
        '& .react-calendar__tile--now': {
            background: '#ffff76'
        },
        '& .react-calendar__tile--now:enabled:hover, & .react-calendar__tile--now:enabled:focus': {
            background: '#ffffa9'
        },
        '& .react-calendar__tile--hasActive': {
            background: '#76baff'
        },
        '& .react-calendar__tile--hasActive:enabled:hover, & .react-calendar__tile--hasActive:enabled:focus': {
            background: '#a9d4ff'
        },
        '& .react-calendar__tile--active': {
            background: '#006edc',
            color: 'white'
        },
        '& .react-calendar__tile--active:enabled:hover, & .react-calendar__tile--active:enabled:focus': {
            background: '#1087ff'
        },
        '& .react-calendar--selectRange .react-calendar__tile--hover': {
            backgroundColor: '#e6e6e6'
        },
        '& .react-clock': {
            display: 'block',
            position: 'fixed'
        },
        '& .react-clock, & .react-clock *, & .react-clock *:before, & .react-clock *:after': {
            boxSizing: 'border-box'
        },
        '& .react-clock__face': {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            border: '1px solid black',
            borderRadius: '50%'
        },
        '& .react-clock__hand': {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: '50%',
            right: '50%'
        },
        '& .react-clock__hand__body': {
            position: 'absolute',
            backgroundColor: 'black',
            transform: 'translateX(-50%)'
        },
        '& .react-clock__mark': {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: '50%',
            right: '50%'
        },
        '& .react-clock__mark__body': {
            position: 'absolute',
            backgroundColor: 'black',
            transform: 'translateX(-50%)'
        },
        '& .react-clock__mark__number': {
            position: 'absolute',
            left: -40,
            width: 80,
            textAlign: 'center'
        },
        '& .react-clock__second-hand__body': {
            backgroundColor: 'red'
        }
    }
};
