import { avg } from 'pondjs';
import type { Time } from 'pondjs';
import type { Aggregation } from 'pondjs/lib/types';

export { default as Graph } from './component/Graph';
export { default as Value } from './component/Value';
export const filter = (entry: any) => !!entry.data.light;
export const parser = (status: string) => ({ light: parseInt(status) });
export const aggregate = {
    light: [ 'light', avg() ] as Aggregation<Time>
};
export const window = '15s';
export const shortcuts = false;
