import { MOBILE_THRESHOLD } from 'app/theme';
import type { Theme } from 'app/theme';

export default (theme: Theme) => ({
    Shortcuts: {
        position: 'relative',
        top: -1,

        overflow: 'hidden',
        paddingBottom: 8,

        padding: {
            left: 15,
            right: 15
        },
        '& > *': {
            marginRight: 5
        },
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',

        [`@media screen and (max-width: ${MOBILE_THRESHOLD - 1}px)`]: {
            '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 15,
                bottom: 0,

                width: 20,
                background: theme.box.background,
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                borderLeft: theme.box.border,
                borderBottom: theme.box.border,
                borderRight: theme.box.border,

                userSelect: 'none'
            },

            padding: {
                top: 5,
                left: 45,
                right: 15
            }
        }
    } as any
});
