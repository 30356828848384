export default {
    Content: {
        marginTop: 20,
        marginBottom: 20,
        width: 320,

        '& > * + *': {
            marginTop: 10
        }
    },
    Slider: {
        width: '100%'
    }
};
