import type { BlockDefinition } from '../types';

export const definition: BlockDefinition = {
    message0: '${number}',
    output: 'Number',
    style: 'math_blocks'
};

export default function run(): number {
    return Math.random();
}


