export default {
    Background: {
        position: 'absolute',

        top: 0,
        right: 0,
        bottom: 0,
        left: 0,

        background: '#00000090'
    },
    '@global': {
        '#root': {
            filter: 'blur(2px) grayscale(0.5)'
        }
    }
};
