import gql from 'graphql-tag';

export default gql`
    mutation RenameAutomation($id: ID!, $name: String!) {
        automation(id: $id) {
            rename(name: $name) {
                id
                name
            }
        }
    }
`;
