export default class ContinueLoop extends Error {
    public readonly count: number;

    constructor(count = 1) {
        super();
        this.count = count;
        const actualProto = new.target.prototype;
        if (Object.setPrototypeOf) { Object.setPrototypeOf(this, actualProto); }
        // @ts-ignore
        else { this.__proto__ = actualProto; }
    }
}
