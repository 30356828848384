import Blockly, { TranslatorSymbol } from '../../blockly';
import { FieldLabel } from '../../fields';

import type { BlockSvg, Interpreter, Node } from '../../types';

export interface CallNoReturnBlock extends BlockSvg {
    defType_: string;
    arguments_: string[];
    argumentVarModels_: Array<Blockly.VariableModel>;
    quarkIds_: string[] | null;
    quarkConnections_: Record<string, Blockly.Connection>;
    previousEnabledState_: boolean;
    getProcedureCall(this: CallNoReturnBlock): string;
    updateShape_(): void;
    setProcedureParameters_(this: CallNoReturnBlock, paramNames: Array<string>, paramIds: Array<string>, varIds: Array<string>): void;
    renameProcedure(this: CallNoReturnBlock, oldName: string, newName: string): void;

}

export const builder = {
    init(this: CallNoReturnBlock) {
        this.appendDummyInput('TOPROW')
            .appendField('', 'NAME');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
        this.setStyle('procedure_blocks');
        // Tooltip is set in renameProcedure.
        this.arguments_ = [];
        this.argumentVarModels_ = [];
        this.quarkConnections_ = {};
        this.quarkIds_ = null;
        this.previousEnabledState_ = true;
    },
    getProcedureCall(this: CallNoReturnBlock) {
        // The NAME field is guaranteed to exist, null will never be returned.
        return /** @type {string} */ (this.getFieldValue('NAME'));
    },
    renameProcedure(this: CallNoReturnBlock, oldName: string, newName: string): void {
        if (Blockly.Names.equals(oldName, this.getProcedureCall())) {
            this.setFieldValue(newName, 'NAME');
        }
    },
    // eslint-disable-next-line max-statements,complexity
    setProcedureParameters_(this: CallNoReturnBlock, paramNames: Array<string>, paramIds: Array<string>, varIds: Array<string>): void {
        // Data structures:
        // this.arguments = ['x', 'y']
        //     Existing param names.
        // this.quarkConnections_ {piua: null, f8b_: Blockly.Connection}
        //     Look-up of paramIds to connections plugged into the call block.
        // this.quarkIds_ = ['piua', 'f8b_']
        //     Existing param IDs.
        // Note that quarkConnections_ may include IDs that no longer exist, but
        // which might reappear if a param is reattached in the mutator.
        const defBlock = Blockly.Procedures.getDefinition(this.getProcedureCall(), this.workspace);
        // @ts-ignore
        const mutatorOpen = defBlock?.mutator?.isVisible?.();
        if (!mutatorOpen) {
            this.quarkConnections_ = {};
            this.quarkIds_ = null;
        }
        if (!paramIds) {
            // Reset the quarks (a mutator is about to open).
            return;
        }
        // Test arguments (arrays of strings) for changes. '\n' is not a valid
        // argument name character, so it is a valid delimiter here.
        if (paramNames.join('\n') == this.arguments_.join('\n')) {
            // No change.
            this.quarkIds_ = paramIds;
            return;
        }
        if (paramIds.length != paramNames.length) {
            throw RangeError('paramNames and paramIds must be the same length.');
        }
        this.setCollapsed(false);
        if (!this.quarkIds_) {
            // Initialize tracking for this block.
            this.quarkConnections_ = {};
            this.quarkIds_ = [];
        }
        // Switch off rendering while the block is rebuilt.
        const savedRendered = this.rendered;
        this.rendered = false;
        // Update the quarkConnections_ with existing connections.
        for (let i = 0; i < this.arguments_.length; i++) {
            const input = this.getInput('ARG' + i);
            if (input) {
                const connection = input.connection.targetConnection;
                this.quarkConnections_[this.quarkIds_[i]] = connection;
                if (mutatorOpen && connection &&
                    paramIds.indexOf(this.quarkIds_[i]) == -1) {
                    // This connection should no longer be attached to this block.
                    connection.disconnect();
                    connection.getSourceBlock().bumpNeighbours();
                }
            }
        }
        // Rebuild the block's arguments.
        this.arguments_ = ([] as string[]).concat(paramNames);
        // And rebuild the argument model list.
        this.argumentVarModels_ = [];
        for (let i = 0; i < this.arguments_.length; i++) {
            const variable = Blockly.Variables.getOrCreateVariablePackage(this.workspace, varIds[i], this.arguments_[i], '');
            this.argumentVarModels_.push(variable);
        }

        this.updateShape_();
        this.quarkIds_ = paramIds;
        // Reconnect any child blocks.
        if (this.quarkIds_) {
            for (let i = 0; i < this.arguments_.length; i++) {
                const quarkId: string = this.quarkIds_[i];
                if (quarkId in this.quarkConnections_) {
                    const connection = this.quarkConnections_[quarkId];
                    if (!Blockly.Mutator.reconnect(connection, this, 'ARG' + i)) {
                        // Block no longer exists or has been attached elsewhere.
                        delete this.quarkConnections_[quarkId];
                    }
                }
            }
        }
        // Restore rendering and show the changes.
        this.rendered = savedRendered;
        if (this.rendered) {
            this.render();
        }
    },
    // eslint-disable-next-line max-statements
    updateShape_(this: CallNoReturnBlock): void {
        let i = 0;
        for (; i < this.arguments_.length; i++) {
            let field = this.getField('ARGNAME' + i);
            if (field) {
                // Ensure argument name is up to date.
                // The argument name field is deterministic based on the mutation,
                // no need to fire a change event.
                Blockly.Events.disable();
                try {
                    field.setValue(this.arguments_[i]);
                } finally {
                    Blockly.Events.enable();
                }
            } else {
                // Add new input.
                field = new FieldLabel(this.arguments_[i]);
                const input = this.appendValueInput('ARG' + i)
                    .setAlign(Blockly.ALIGN_RIGHT)
                    .appendField(field, 'ARGNAME' + i);
                input.init();
            }
        }
        // Remove deleted inputs.
        while (this.getInput('ARG' + i)) {
            this.removeInput('ARG' + i);
            i++;
        }
        // Add 'with:' if there are parameters, remove otherwise.
        const topRow = this.getInput('TOPROW');
        if (topRow) {
            if (this.arguments_.length) {
                if (!this.getField('WITH')) {
                    topRow.appendField(this[TranslatorSymbol]('procedures.before-params'), 'WITH');
                    topRow.init();
                }
            } else {
                if (this.getField('WITH')) {
                    topRow.removeField('WITH');
                }
            }
        }
    },
    mutationToDom(this: CallNoReturnBlock): Element {
        const container = Blockly.utils.xml.createElement('mutation');
        container.setAttribute('name', this.getProcedureCall());
        for (const argument of this.argumentVarModels_) {
            const parameter = Blockly.utils.xml.createElement('arg');
            parameter.setAttribute('type', 'arg');
            parameter.setAttribute('name', argument.name);
            parameter.setAttribute('varId', argument.getId());
            container.appendChild(parameter);
        }
        return container;
    },
    domToMutation(this: CallNoReturnBlock, xmlElement: Element): void {
        const name = xmlElement.getAttribute('name');
        this.renameProcedure(this.getProcedureCall(), name!);
        const args = [];
        const paramIds = [];
        const varIds = [];
        for (let i = 0, childNode; (childNode = xmlElement.childNodes[i] as Element); i++) {
            if (childNode.getAttribute('type') == 'arg') {
                args.push(childNode.getAttribute('name')!);
                paramIds.push(childNode.getAttribute('paramId')!);
                varIds.push(childNode.getAttribute('varId')!);
            }
        }
        this.setProcedureParameters_(args, paramIds, varIds);
    },
    getVars(this: CallNoReturnBlock): string[] {
        return this.arguments_;
    },
    getVarModels(this: CallNoReturnBlock): Array<Blockly.VariableModel> {
        return this.argumentVarModels_;
    },
    // eslint-disable-next-line max-statements,complexity
    onchange(this: CallNoReturnBlock, event: Blockly.Events.Create | Blockly.Events.Delete | Blockly.Events.BlockChange): void {
        if (!this.workspace || this.workspace.isFlyout) {
            // Block is deleted or is in a flyout.
            return;
        }
        if (!event.recordUndo) {
            // Events not generated by user. Skip handling.
            return;
        }
        if (event.type == Blockly.Events.BLOCK_CREATE && (event as any).ids?.indexOf(this.id) != -1) {
            // Look for the case where a procedure call was created (usually through
            // paste) and there is no matching definition.  In this case, create
            // an empty definition block with the correct signature.
            const name = this.getProcedureCall();
            let def: Blockly.Block | null = Blockly.Procedures.getDefinition(name, this.workspace);
            if (def && (def.type != this.defType_ || JSON.stringify(def.getVars()) != JSON.stringify(this.arguments_))) {
                // The signatures don't match.
                def = null;
            }
            if (!def) {
                Blockly.Events.setGroup(event.group);
                /**
                 * Create matching definition block.
                 * <xml xmlns="https://developers.google.com/blockly/xml">
                 *   <block type="procedures_defreturn" x="10" y="20">
                 *     <mutation name="test">
                 *       <arg name="x"></arg>
                 *     </mutation>
                 *     <field name="NAME">test</field>
                 *   </block>
                 * </xml>
                 */
                const xml = Blockly.utils.xml.createElement('xml');
                const block = Blockly.utils.xml.createElement('block');
                block.setAttribute('type', this.defType_);
                const xy = this.getRelativeToSurfaceXY();
                const x = xy.x + Blockly.SNAP_RADIUS * (this.RTL ? -1 : 1);
                const y = xy.y + Blockly.SNAP_RADIUS * 2;
                block.setAttribute('x', String(x));
                block.setAttribute('y', String(y));
                const mutation = this.mutationToDom();
                block.appendChild(mutation);
                const field = Blockly.utils.xml.createElement('field');
                field.setAttribute('name', 'NAME');
                let callName = this.getProcedureCall();
                if (!callName) {
                    // Rename if name is empty string.
                    callName = Blockly.Procedures.findLegalName('', this);
                    this.renameProcedure('', callName);
                }
                field.appendChild(Blockly.utils.xml.createTextNode(callName));
                block.appendChild(field);
                xml.appendChild(block);
                Blockly.Xml.domToWorkspace(xml, this.workspace);
                Blockly.Events.setGroup(false);
            }
        } else if (event.type == Blockly.Events.BLOCK_DELETE) {
            // Look for the case where a procedure definition has been deleted,
            // leaving this block (a procedure call) orphaned.  In this case, delete
            // the orphan.
            const name = this.getProcedureCall();
            const def = Blockly.Procedures.getDefinition(name, this.workspace);
            if (!def) {
                Blockly.Events.setGroup(event.group);
                this.dispose(true);
                Blockly.Events.setGroup(false);
            }
        } else if (event.type == Blockly.Events.CHANGE && (event as any).element == 'disabled') {
            const name = this.getProcedureCall();
            const def = Blockly.Procedures.getDefinition(name, this.workspace);
            if (def && def.id == event.blockId) {
                // in most cases the old group should be ''
                const oldGroup = Blockly.Events.getGroup();
                if (oldGroup) {
                    // This should only be possible programmatically and may indicate a problem
                    // with event grouping. If you see this message please investigate. If the
                    // use ends up being valid we may need to reorder events in the undo stack.
                    // eslint-disable-next-line no-console
                    console.log('Saw an existing group while responding to a definition change');
                }
                Blockly.Events.setGroup(event.group);
                if ((event as any).newValue) {
                    this.previousEnabledState_ = this.isEnabled();
                    this.setEnabled(false);
                } else {
                    this.setEnabled(this.previousEnabledState_);
                }
                Blockly.Events.setGroup(oldGroup);
            }
        }
    },
    customContextMenu(this: CallNoReturnBlock, options: any[]) {
        if (!this.workspace.isMovable()) {
            // If we center on the block and the workspace isn't movable we could
            // loose blocks at the edges of the workspace.
            return;
        }

        const name = this.getProcedureCall();
        const workspace = this.workspace;
        options.push({
            enabled: true,
            text: this[TranslatorSymbol]('procedures.highlight-definition'),
            callback() {
                const def = Blockly.Procedures.getDefinition(name, workspace);
                if (def) {
                    workspace.centerOnBlock(def.id);
                    // @ts-ignore
                    def.select?.();
                }
            }
        });
    },
    defType_: 'procedures_defnoreturn'
};

export default async function run(this: Interpreter, node: Node): Promise<any> {
    const name = node.fields?.NAME;
    if (!name) {
        throw new Error('variable name missing');
    }

    const variables = new Map<string, string | number | null>();
    if (node.mutations?.children && Array.isArray(node.mutations?.children)) {
        for (let i = 0; i < node.mutations.children.length; ++i) {
            // @ts-ignore
            const varName = node.mutations.children[i]?.name as string;

            const key = 'ARG' + i.toString();
            if (node.values?.[key]) {
                const varNode = node.values[key];
                variables.set(varName, await this.execute(varNode));
            } else {
                variables.set(varName, null);
            }
        }
    }

    await this.executeProcedure(String(name), variables);
}
