import type { Theme } from 'app/theme';

export default ((theme: Theme) => ({
    '@keyframes spin': {
        from: {
            transform: 'rotate(0deg)'
        },
        to: {
            transform: 'rotate(360deg)'
        }
    },
    Automation: {
        display: 'flex',
        flexDirection: 'row',

        width: '100%',

        padding: 10,

        borderBottom: theme.box.border
    },
    Buttons: {
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'flex-end',

        '& > *': {
            fontSize: 20,
            cursor: 'pointer',
            opacity: 0.4,

            '&:hover': {
                opacity: 1
            },

            '& + *': {
                marginLeft: 10
            }
        }
    },
    Danger: {
        color: theme.button.danger.background
    },
    Active: {
        background: theme.menu.background.active
    },
    Play: {
        color: theme.button.success.background,
        cursor: 'pointer',
        opacity: 0.4,

        '&:hover': {
            opacity: 1
        }
    },
    Running: {
        color: theme.button.success.background,
        animationName: 'spin',
        animationDuration: '5000ms',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear'
    },
    Bug: {
        color: theme.button.danger.background,
        cursor: 'pointer',
        opacity: 0.4,

        '&:hover': {
            opacity: 1
        }
    }
}));
