import { promise } from 'vx-std';

import ExecutionCancelled from '../ExecutionCancelled';
import TracedInterpreter from './TracedInterpreter';
import Context from './Context';

import type Blockly from '../../blockly';
import type { InstructionSet, ExecutorResult, InstructionExecutor } from '../types';
import type { StateActions } from '../../Tracer/state';
import type { Node } from '../../types';

export type WorkspaceLike = {
    highlightBlock: (id: string, opt_state?: boolean) => void;
}

interface TracerContextType {
    paused: Deferred<any> | null;
    lag: number;
}

class TracerContext extends Context {
    public readonly tracer: TracerContextType = {
        paused: null,
        lag: 500
    };
}

class Deferred<T> {
    private res: ((value: T | PromiseLike<T>) => void) | null = null;
    private rej: ((reason?: any) => void) | null = null;
    private readonly promise: Promise<T>;

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.res = resolve;
            this.rej = reject;
        });
    }

    then(res?: (value: T) => T | PromiseLike<T>, rej?: (reason: any) => PromiseLike<never>): Deferred<T> {
        return Object.assign(Object.create(this.constructor.prototype), {
            res: this.res,
            rej: this.rej,
            promise: this.promise.then(res, rej)
        });
    }

    catch(onRejected?: (reason: any) => PromiseLike<never>): Deferred<T> {
        return Object.assign(Object.create(this.constructor.prototype), {
            res: this.res,
            rej: this.rej,
            promise: this.promise.catch(onRejected)
        });
    }

    resolve(value: T | PromiseLike<T>): void {
        return this.res?.(value);
    }

    reject(reason?: any): void {
        return this.rej?.(reason);
    }
}

export default class UiTracedInterpreter<W extends WorkspaceLike = Blockly.WorkspaceSvg> extends TracedInterpreter<TracerContext> {
    protected readonly workspace: W;

    constructor(instructions: InstructionSet, workspace: W, tracedActions: StateActions) {
        super(instructions, new TracerContext(), tracedActions);
        this.workspace = workspace;
    }

    protected async _execute(executor: InstructionExecutor, node: Node): Promise<ExecutorResult> {
        if (executor.noTrace) {
            return super._execute(executor, node);
        }

        this.workspace.highlightBlock(node.id, true);
        try {
            const [result] = await Promise.all([
                super._execute(executor, node),
                promise.wait(this._context.tracer.lag || 10)
            ]);

            if (this._context.tracer.paused) {
                await this._context.tracer.paused;
            }

            return result;
        } finally {
            this.workspace.highlightBlock(node.id, false);
        }
    }

    protected setupPause<T extends PromiseLike<any>>(promise: T): T {
        return promise.then(
            (value) => {
                this._context.tracer.paused = null;
                return value;
            },
            (reason) => {
                this._context.tracer.paused = null;
                throw reason;
            }
        ) as any;
    }

    pause() {
        if (!this._context.tracer.paused) {
            this._context.tracer.paused = this.setupPause(new Deferred());
        }
    }

    continue() {
        this._context.tracer.paused?.resolve(void 0);
    }

    step() {
        this._context.tracer.paused?.then(() => this.pause());
        this.continue();
    }

    stop() {
        if (this._context.tracer.paused) {
            this._context.tracer.paused.reject(new ExecutionCancelled('cancelled paused'));
        } else {
            this._context.tracer.paused = Promise.reject(new ExecutionCancelled('cancelled running')) as any;
        }
    }

    public get paused(): boolean {
        return !!this._context.tracer.paused;
    }

    public onComplete(fn: () => void) {
        this.complete = fn;
    }
}
