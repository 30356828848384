/* eslint-disable max-len */
import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';

import style from './Value.style';

import type { SubsystemValueProps } from 'app/subsystem';

const useStyles = createUseStyles(style);

const calcOffset = (percent: number) => {
    const out_min = 823;
    const out_max = 388;
    return percent * (out_max - out_min) / 100 + out_min;
};

const calcOffset2 = (percent: number) => {
    const out_min = 714;
    const out_max = 530;

    return percent * (out_max - out_min) / 100 + out_min;
};

const calcWidth = (percent: number) => {
    const out_min = 0;
    const out_max = 435;
    return percent * (out_max - out_min) / 100 + out_min;
};

const calcColor = (percent: number) => {
    if (percent <= 1) {
        return 'black';
    }
    if (percent <= 10) {
        return 'red';
    }
    if (percent <= 20) {
        return 'darkorange';
    }
    if (percent <= 30) {
        return '#e8c800';
    }
    return 'green';
};

const Value = ({ status, mini }: SubsystemValueProps) => {
    const classes = useStyles();
    const percent = parseFloat(status);

    const offset = calcOffset(percent);
    const offset2 = calcOffset2(percent);
    const width = calcWidth(percent);
    return (
        <div className={cx(classes.Battery, mini && classes.Mini)}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="-600 220 1 270"
                width="200px"
                height="100px"
                version="1.1"
            >
                <defs>
                    <linearGradient id="linearGradient5870">
                        <stop style={{ stopColor: '#000000' }} offset="0"/>
                        <stop style={{ stopColor: '#1a1a1a' }} offset=".18616"/>
                        <stop style={{ stopColor: '#ffffff' }} offset=".35097"/>
                        <stop style={{ stopColor: '#000000' }} offset=".54466"/>
                        <stop style={{ stopColor: '#000000' }} offset=".68302"/>
                        <stop style={{ stopColor: '#ffffff' }} offset=".81357"/>
                        <stop style={{ stopColor: '#999999' }} offset=".91087"/>
                        <stop style={{ stopColor: '#cccccc' }} offset="1"/>
                    </linearGradient>
                    <linearGradient
                        id="linearGradient5663"
                        y2="835.22"
                        xlinkHref="#linearGradient5870"
                        gradientUnits="userSpaceOnUse"
                        x2="461.43"
                        gradientTransform="translate(.4334)"
                        y1="833.79"
                        x1="252.86"
                    />
                    <linearGradient
                        id="linearGradient5695"
                        y2="835.22"
                        xlinkHref="#linearGradient5870"
                        gradientUnits="userSpaceOnUse"
                        x2="461.43"
                        gradientTransform="matrix(1 0 0 -1 .32997 1226.5)"
                        y1="832.36"
                        x1="253.93"
                    />
                    <linearGradient
                        id="linearGradient5754"
                        y2="618.08"
                        gradientUnits="userSpaceOnUse"
                        x2="265.27"
                        y1="618.08"
                        x1="252.86"
                    >
                        <stop style={{ stopColor: '#ffffff' }} offset="0"/>
                        <stop style={{ stopColor: '#ffffff', stopOpacity: 0 }} offset="1"/>
                    </linearGradient>
                    <linearGradient
                        id="linearGradient5787"
                        y2="598.93"
                        gradientUnits="userSpaceOnUse"
                        x2="413.91"
                        gradientTransform="matrix(1.5387 0 0 1 -238.72 0)"
                        y1="598.93"
                        x1="444.54"
                    >
                        <stop style={{ stopColor: '#ffffff' }} offset="0"/>
                        <stop style={{ stopColor: '#ffffff', stopOpacity: 0 }} offset="1"/>
                    </linearGradient>
                    <linearGradient
                        id="linearGradient5789"
                        y2="601.65"
                        gradientUnits="userSpaceOnUse"
                        x2="449.32"
                        y1="601.65"
                        x1="477.14"
                    >
                        <stop style={{ stopColor: '#ffffff' }} offset="0"/>
                        <stop style={{ stopColor: '#ffffff', stopOpacity: 0 }} offset="1"/>
                    </linearGradient>
                    <linearGradient
                        id="linearGradient5826"
                        y2="345.93"
                        gradientUnits="userSpaceOnUse"
                        x2="386.79"
                        gradientTransform="matrix(1.0169 0 0 .50749 2.2981 187.77)"
                        y1="345.93"
                        x1="311.79"
                    >
                        <stop style={{ stopColor: '#000000' }} offset="0"/>
                        <stop style={{ stopColor: '#ffffff' }} offset=".24312"/>
                        <stop style={{ stopColor: '#ffffff' }} offset=".35097"/>
                        <stop style={{ stopColor: '#000000' }} offset=".54466"/>
                        <stop style={{ stopColor: '#000000' }} offset=".68302"/>
                        <stop style={{ stopColor: '#ffffff' }} offset=".81357"/>
                        <stop style={{ stopColor: '#ffffff' }} offset=".91087"/>
                        <stop style={{ stopColor: '#cccccc' }} offset="1"/>
                    </linearGradient>
                    <linearGradient
                        id="linearGradient5864"
                        y2="614.97"
                        gradientUnits="userSpaceOnUse"
                        x2="371.58"
                        gradientTransform="matrix(1.1311 0 0 1 -38.179 0)"
                        y1="613.45"
                        x1="291.59"
                    >
                        <stop style={{ stopColor: '#ffffff' }} offset="0"/>
                        <stop style={{ stopColor: '#ffffff', stopOpacity: 0 }} offset="1"/>
                    </linearGradient>
                    <linearGradient
                        id="linearGradient5916"
                        y2="626.08"
                        gradientUnits="userSpaceOnUse"
                        x2="463.66"
                        y1="626.08"
                        x1="253.55"
                    >
                        <stop style={{ stopColor: '#ffffff' }} offset="0"/>
                        <stop style={{ stopColor: '#ffffff', stopOpacity: 0 }} offset="1"/>
                    </linearGradient>
                    <filter
                        id="filter6180"
                        height="1.4143"
                        width="1.4065"
                        colorInterpolationFilters="sRGB"
                        y="-.20717"
                        x="-.20326"
                    >
                        <feGaussianBlur stdDeviation="12.895784"/>
                    </filter>
                </defs>
                <g transform="rotate(90)">
                    <path
                        d="m270.3 384.52c60.276-13.049 116.44-13.563 174.56 0 9.2629 2.1617 17.169 7.6576 17.169 17.169v417.79c0 9.5118-7.947 14.841-17.169 17.169-51.484 12.999-114.57 12.883-174.56 0-9.2998-1.9973-17.169-7.6576-17.169-17.169v-417.79c0-9.5118 7.8729-15.157 17.169-17.169z"
                        style={{ opacity: '.10204', fill: '#ffffff' }}
                    />
                    <path
                        style={{ fill: 'url(#linearGradient5826)' }}
                        d="m330.96 344.3c17.674-2.4899 35.348-2.2847 53.022 0 6.3011 0.81454 11.621 2.9909 11.621 6.7061v24.65c0 3.7152-5.183 6.7061-11.621 6.7061h-53.022c-6.4381 0-11.621-2.9909-11.621-6.7061v-24.65c0-3.7152 5.3448-5.8219 11.621-6.7061z"
                    />
                    <path
                        style={{ fill: calcColor(percent) }}
                        d={`m270.3 ${offset}c60.276-6.3878 16.44-6.6393 174.56 0 9.2629 .0582 17.169 3.7485 17.169 8.4046v${width}c0 4.6562-7.947 7.2648-17.169 8.4047-51.484 6.3631-114.57 6.3063-174.56 0-9.2998-0.97771-17.169-3.7485-17.169-8.4047v-${width}c0-4.6562 7.8729-7.4194 17.169-8.4046z`}
                    />
                    {percent <= 98 && (
                        <path
                            style={{ opacity: '.38435', fill: 'url(#linearGradient5916)' }}
                            d={`m463.66 ${offset2}c0 3.3474-47.035 6.0609-105.06 6.0609-58.021 0-105.89-2.7318-105.06-6.0609 0.2078-3.7664 22.956-4.4578 32.538-4.7257 0 0 36.319-1.0694 74.042-1.0694 30.156 0 56.366 0.74889 75.831 1.5192 26.8 0.85104 27.701 2.6683 27.701 4.2759z`}
                            transform="matrix(.99399 0 0 2.375 1.0187 -862.62)"
                        />
                    )}
                    <path
                        style={{ fill: 'url(#linearGradient5663)' }}
                        d="m461.87 818.72c-1.0886 8.1972-8.5157 12.912-17.031 15.062-51.484 12.999-114.55 12.883-174.53 0-8.5846-1.8437-15.949-6.7968-17.031-15.031v7.9062c0 9.4972 7.8708 15.131 17.156 17.125 59.891 12.863 122.88 12.979 174.28 0 9.2082-2.3249 17.156-7.6278 17.156-17.125v-7.9375z"
                    />
                    <path
                        d="m461.77 407.76c-1.0886-8.1972-8.5157-12.912-17.031-15.062-51.484-12.999-114.55-12.883-174.53 0-8.5846 1.8437-15.949 6.7968-17.031 15.031v-7.9062c0-9.4972 7.8708-15.131 17.156-17.125 59.891-12.863 122.88-12.979 174.28 0 9.2082 2.3249 17.156 7.6278 17.156 17.125v7.9375z"
                        style={{ fill: 'url(#linearGradient5695)' }}
                    />
                    <path
                        style={{ fill: 'url(#linearGradient5754)' }}
                        d="m253.13 397.01c2.7026-9.7751 10.883-13.654 20.893-14.732v462.23c-5.7077-1.2111-22.482-4.0639-20.982-20.893z"
                    />
                    <path
                        style={{ opacity: '.51701', fill: 'url(#linearGradient5787)' }}
                        d="m396.69 374.45c13.18 1.3623 28.645 3.7364 46.478 7.955l-0.0631 461.46c-15.951 3.2583-31.115 5.4928-44.997 6.0609z"
                    />
                    <path
                        style={{ fill: 'url(#linearGradient5789)' }}
                        d="m454.29 386.65c6.4885 4.8852 8.1379 8.3108 7.5774 20.456l0.82075 412.55c-0.0556 9.9195-2.2926 17.173-8.3981 19.698z"
                    />
                    <path
                        style={{ opacity: '.73810', fill: 'url(#linearGradient5864)' }}
                        d="m291.3 378.72c22.038-3.1481 45.041-5.3202 69.74-5.7793l-0.40395 480.58c-24.773-0.36932-46.894-2.1865-68.521-5.6604z"
                    />
                    <path
                        style={{ filter: 'url(#filter6180)', fill: '#ffffff' }}
                        d="m288.41 374.02c-0.27881 0.0642-23.887 67.422-24.155 67.323-0.27013-0.0996 17.333-69.701 17.137-69.912-0.19499-0.20936-70.333 13.025-70.381 12.743-0.0488-0.28377 69.03-19.84 69.114-20.115 0.0838-0.27355-46.446-54.398-46.226-54.581 0.22136-0.18411 51.696 49.862 51.977 49.797 0.27881-0.0642 23.887-67.422 24.155-67.323 0.27014 0.0996-17.333 69.701-17.137 69.912 0.19499 0.20937 70.333-13.025 70.381-12.743 0.0488 0.28376-69.03 19.84-69.114 20.115-0.0838 0.27355 46.446 54.398 46.226 54.581-0.22137 0.18412-51.696-49.862-51.977-49.797z"
                        transform="matrix(.36434 0 0 .36434 237.88 213.78)"
                    />
                    <path
                        style={{ filter: 'url(#filter6180)', fill: '#ffffff' }}
                        transform="matrix(.36434 0 0 .36434 223.6 248.78)"
                        d="m288.41 374.02c-0.27881 0.0642-23.887 67.422-24.155 67.323-0.27013-0.0996 17.333-69.701 17.137-69.912-0.19499-0.20936-70.333 13.025-70.381 12.743-0.0488-0.28377 69.03-19.84 69.114-20.115 0.0838-0.27355-46.446-54.398-46.226-54.581 0.22136-0.18411 51.696 49.862 51.977 49.797 0.27881-0.0642 23.887-67.422 24.155-67.323 0.27014 0.0996-17.333 69.701-17.137 69.912 0.19499 0.20937 70.333-13.025 70.381-12.743 0.0488 0.28376-69.03 19.84-69.114 20.115-0.0838 0.27355 46.446 54.398 46.226 54.581-0.22137 0.18412-51.696-49.862-51.977-49.797z"
                    />
                    <path
                        style={{ filter: 'url(#filter6180)', fill: '#ffffff' }}
                        d="m288.41 374.02c-0.27881 0.0642-23.887 67.422-24.155 67.323-0.27013-0.0996 17.333-69.701 17.137-69.912-0.19499-0.20936-70.333 13.025-70.381 12.743-0.0488-0.28377 69.03-19.84 69.114-20.115 0.0838-0.27355-46.446-54.398-46.226-54.581 0.22136-0.18411 51.696 49.862 51.977 49.797 0.27881-0.0642 23.887-67.422 24.155-67.323 0.27014 0.0996-17.333 69.701-17.137 69.912 0.19499 0.20937 70.333-13.025 70.381-12.743 0.0488 0.28376-69.03 19.84-69.114 20.115-0.0838 0.27355 46.446 54.398 46.226 54.581-0.22137 0.18412-51.696-49.862-51.977-49.797z"
                        transform="matrix(.36434 0 0 .36434 215.03 708.78)"
                    />
                </g>
            </svg>
        </div>
    );
};

export default Value;
