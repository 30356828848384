import * as React from 'react';
import { useApolloClient, useMutation } from '@apollo/client/react';
import OIDC_EXCHANGE from 'app/graphql/mutation/oidc/exchange';
import OIDC_REFRESH from 'app/graphql/mutation/oidc/refresh';
import { getRefreshToken, setRefreshToken } from 'app/connector/graphql/token';
import { getState } from './state';
import type { History } from 'history';
import type { OidcExchangeMutation, OidcExchangeMutationVariables } from 'app/graphql/mutation/oidc/exchange';
import type { OidcRefreshMutation, OidcRefreshMutationVariables } from 'app/graphql/mutation/oidc/refresh';


export default function useExchange(
    history: History,
    redirectUrlPath: string,
    autoRefresh = false
) {
    const client = useApolloClient();

    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const [exchange] = useMutation<OidcExchangeMutation, OidcExchangeMutationVariables>(
        OIDC_EXCHANGE, { fetchPolicy: 'network-only' }
    );
    const [refresh] = useMutation<OidcRefreshMutation, OidcRefreshMutationVariables>(
        OIDC_REFRESH, { fetchPolicy: 'network-only' }
    );
    React.useEffect(() => {
        if (history.location.pathname === redirectUrlPath) {
            const params = new URLSearchParams(history.location.search);
            const code = params.get('code');
            if (!code) {
                return;
            }

            setLoading(true);

            const state = getState(params.get('state'));
            history.replace(state?.redirect || '/');

            exchange({ variables: { code } }).then(({ data }) => {
                if (!data) {
                    setLoading(false);
                    return;
                }

                // @ts-ignore
                client.setSecret(data.oidc.exchange.token);
                setRefreshToken(data.oidc.exchange.refresh);
                client.resetStore();
            }, (err) => {
                setLoading(false);
                setError(err.message);
            });
        } else if (autoRefresh) {
            const token = getRefreshToken();
            if (!token) {
                return;
            }

            refresh({ variables: { token } }).then(({ data }) => {
                if (!data) {
                    setRefreshToken(null);
                    return;
                }
                // @ts-ignore
                client.setSecret(data.oidc.refresh.token);
                setRefreshToken(data.oidc.refresh.refresh);
                client.resetStore();
            }, () => {
                setRefreshToken(null);
            });
        }
    }, [autoRefresh]);

    return { error, loading };
}
