import gql from 'graphql-tag';

export type HistoryEntryType<D = any, T = string> = {
    time: T;
    data: D;
};

export default gql`
    query subsystemHistory($thingId: ID!, $subsystemId: ID!, $from: DateTime, $to: DateTime, $interval: String!, $aggregator: String) {
        thing(id: $thingId) {
            id
            subsystem(id: $subsystemId) {
                id
                type
                history(from: $from, to: $to, interval: $interval, aggregator: $aggregator) {
                    time
                    data
                }
            }
        }
    }
`;

export type SubsystemHistoryType<T = string, D = any> = {
    thing: {
        subsystem: {
            id: string;
            type: string;
            history: Array<HistoryEntryType<D, T>>;
        }
    }
};
