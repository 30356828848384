import * as React from 'react';
import { HorizontalGridLines, LineSeries, VerticalGridLines, XAxis, YAxis } from 'react-vis';
import { formatDateTick } from 'app/helper/graph';
import { Graph } from 'app/component';

import { normalizeHistoryData } from '../../utils';

import type { SubsystemGraphProps } from 'app/subsystem';

const TemperatureGraph = ({ history = [], onOpenSettings }: SubsystemGraphProps<{ pressure: number }>) => {
    const { data, yDomain } = normalizeHistoryData(600, 1400, history, 'pressure');
    const [hoveredNode, hoverNode] = Graph.useHintState();

    return (
        <Graph height={250} yDomain={yDomain} onOpenSettings={onOpenSettings} onMouseLeave={() => hoverNode(null)}>
            <HorizontalGridLines/>
            <VerticalGridLines/>
            <XAxis tickFormat={formatDateTick}/>
            <YAxis
                // @ts-ignore
                style={{ text: { paddingLeft: 14, transform: 'translate(-6px, 0px)' } }}
            />
            <LineSeries color="#b94eb9" data={data} onNearestXY={hoverNode} />
            {hoveredNode && (
                <Graph.Hint
                    {...hoveredNode}
                    value={`${Math.floor(hoveredNode.value as number * 100) / 100}hPA`}
                />
            )}
        </Graph>
    );
};

export default TemperatureGraph;
