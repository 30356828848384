export const getLocalStorage = (key: string) => {
    return localStorage.getItem(key) || null;
};

export function setLocalStorage(key: string, token: string | null): void;
export function setLocalStorage(key: string): (token: string | null) => void;
export function setLocalStorage(...args: [string] | [string, string | null]) {
    const key = args.shift();
    if (!key) {
        throw new Error('Missing key');
    }
    const setter = (token: string | null) => {
        if (token) {
            localStorage.setItem(key, token);
        } else {
            localStorage.removeItem(key);
        }
    };

    const token = args.shift();
    if (token === undefined) {
        return setter;
    }

    setter(token);
}
