export default {
    Slider: {},
    Vertical: {
        writingMode: 'bt-lr',
        /* IE */
        WebkitAppearance: 'slider-vertical',
        /* WebKit */
        width: 8,
        height: 175,
        padding: [0, 5]
    }
};
