import type { Theme } from 'app/theme';

export default ((theme: Theme) => ({
    Home: {
        display: 'flex',
        flexDirection: 'column',

        width: '100%',

        padding: {
            left: 10,
            right: 10,
            bottom: 10,
            top: 5
        },

        borderRadius: 6
    },
    TopRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',

        background: theme.background,
        padding: {
            left: 10,
            right: 10,
            top: 5,
            bottom: 5
        },
        margin: {
            left: -10,
            right: -10,
            top: -5,
            bottom: 5
        }
    },
    Rooms: {
        paddingLeft: 10
    },
    Buttons: {
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'flex-end',

        '& > *': {
            fontSize: 20,
            cursor: 'pointer',
            opacity: 0.4,

            '&:hover': {
                opacity: 1
            },

            '& + *': {
                marginLeft: 10
            }
        }
    },
    AddRoom: {
        marginTop: 5,
        marginLeft: 'auto',
        color: theme.button.success.background,
        fontSize: 20,
        cursor: 'pointer',
        opacity: 0.4,

        '&:hover': {
            opacity: 1
        }
    },
    Danger: {
        color: theme.button.danger.background
    }
}));
