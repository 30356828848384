import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Button, Title, Window } from 'app/component';
import { ButtonKind } from 'app/component/Button';
import { createWithModal } from 'app/hoc';
import { FormButton, FormFeedback, InputField, MutationForm, validator } from 'app/composition/Form';
import RENAME_SHORTCUT_MUTATION from 'app/graphql/mutation/thing/shortcut/rename';

import style from './RenameShortcut.style';

type RenameProps = {
    close: () => void;
    id: number;
    thingId: number;
    subsystemId: string;
    name: string;
};

const useStyles = createUseStyles(style);

const { withModal, withTrigger } = createWithModal();

const RenameShortcut = ({ close, id, thingId, subsystemId, name }: RenameProps) => {
    const classes = useStyles();
    const [t] = useTranslation('shortcut');
    return (
        <MutationForm
            mutation={RENAME_SHORTCUT_MUTATION}
            onSubmitSuccess={close}
            variables={{ thingId, subsystemId, id }}
            initialValues={{ name }}
            touchOn="blur"
        >
            <Window className={classes.Window} contentClassName={classes.Content} title={<Title>{t('rename.title')}</Title>}>
                <InputField name="name" placeholder={t('rename.name')} validator={validator.required()}/>

                <FormFeedback/>
                <Window.ButtonFooter>
                    <FormButton kind={ButtonKind.primary} type="submit">
                        {t('rename.rename')}
                    </FormButton>
                    <Button kind={ButtonKind.lite} onClick={close}>
                        {t('rename.cancel')}
                    </Button>
                </Window.ButtonFooter>
            </Window>
        </MutationForm>
    );
};

export default withModal(RenameShortcut);
export const withRenameTrigger = withTrigger;
