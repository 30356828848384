import type { Theme } from 'app/theme';

export default ((theme: Theme) => ({
    '@keyframes glow-grow': {
        '0%': {
            opacity: 0,
            transform: 'scale(1)'
        },
        '80%': {
            opacity: 1
        },
        '100%': {
            transform: 'scale(2)',
            opacity: 0
        }
    },
    Alert: {
        height: 22,
        width: 22,

        borderRadius: 6,

        '&.triggered': {
            color: theme.button.danger.background,

            position: 'relative',

            '&:before, &:after': {
                position: 'absolute',
                zIndex: 80,
                content: '""',
                height: '100%',
                width: '100%',
                top: '0%',
                left: '0%',
                borderRadius: 6,
                boxShadow: '0 0 15px #e6282e',
                animationTimingFunction: 'ease-out',
                animationDuration: '1.5s',
                animationIterationCount: 'infinite',
                animationName: '$glow-grow'
            },

            '&:after': {
                animationDelay: '0.75s'
            }
        }
    }
}));
