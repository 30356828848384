import * as React from 'react';
import { EntypoClock } from 'react-entypo-icons';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

import Input from 'app/component/Input';

import Button, { ButtonKind } from '../Button';
import Dropdown from '../Dropdown';
import VisualCron from './VisualCron';
import Preview from './Preview';
import { useExpression } from './hook';
import style from './style';

import type { Placement } from '@floating-ui/react-dom';

type CronProps = Omit<React.ComponentProps<typeof VisualCron>, 'value' | 'onChange'> & {
    onToggle?: (isOpen: boolean) => void;
    value?: string;
    onChange?: (value: string) => void;
    placeholder?: React.ReactElement | string;
    placement?: Placement;
    defaultOpen?: boolean;
};

const useStyles = createUseStyles(style);

const Cron = ({
    className,
    value = '',
    onChange,
    placeholder,
    placement = 'bottom-end',
    defaultOpen = false,
    onToggle,
    ...props
}: CronProps) => {
    const classes = useStyles();
    const expression = useExpression(value);
    return (
        <div className={cx(classes.Container, className)}>
            <Dropdown
                placement={placement}
                defaultOpen={defaultOpen}
                overlay={<VisualCron {...props} value={value} onChange={onChange} />}
                onToggle={onToggle}
                closeOnInsideClick={true}
            >
                <Input
                    className={classes.Input}
                    value={value}
                    onChange={(e) => onChange?.(e.currentTarget.value)}
                />
            </Dropdown>
            <Dropdown
                placement={placement}
                defaultOpen={defaultOpen}
                overlay={<Preview value={value} />}
                onToggle={onToggle}
                closeOnInsideClick={false}
            >
                <Button kind={expression.error ? ButtonKind.danger : ButtonKind.lite} className={classes.FutureButton}>
                    <EntypoClock />
                </Button>
            </Dropdown>
        </div>
    );
};

export default Cron;
