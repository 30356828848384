import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import style from './style';
import type i18next from 'i18next';

type SwitchProps = JSX.IntrinsicElements['div'] & {
    className?: string;
    classes: {
        Switch: string;
        Active: string;
        Knob: string;
        ON: string;
        OFF: string;
    };
    verticalPosition?: number;
    onValueChange?: (arg0: boolean) => void;
    value: boolean;
    elementRef?: React.Ref<any>;
    t: i18next.TFunction
};

const useStyles = createUseStyles(style);

const Switch = ({ children, className, value, onValueChange, onClick, ...props }: SwitchProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    const [t] = useTranslation('components');
    return (
        <div {...props} className={cx(classes.Switch, value && classes.Active, className)} onClick={e => {
            onClick && onClick(e);
            onValueChange && onValueChange(!value);
        }} ref={ref}>
            <div className={classes.OFF}>{t('switch.off')}</div>
            <div className={classes.ON}>{t('switch.on')}</div>
            <div className={classes.Shadow}/>
            <div className={classes.Knob}/>
        </div>
    );
};

export default React.forwardRef(Switch);
