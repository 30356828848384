import * as React from 'react';
import { useMutation } from '@apollo/client/react';
import SET_AUTOMATION_ACTIONS_MUTATION from 'app/graphql/mutation/automation/setActions';

import type { ActionType } from 'app/graphql/fragment/automation';

export default () => {
    const [mutation] = useMutation(SET_AUTOMATION_ACTIONS_MUTATION);
    return React.useCallback((id: string, actions: ActionType[]) => {
        return mutation({ variables: { id, actions } });
    }, [mutation]);
};
