import gql from 'graphql-tag';

export default gql`
    query Me {
        me {
            id
            login
        }
    }
`;

export type MeQueryData = {
    me: {
        id: number,
        login: string
    }
};
