import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Redirect, Route, Switch } from 'react-router';
import NestedRouter, { createNav } from 'react-router-nested';
import { Logo, Navigation } from 'app/component';

import Things from 'app/page/Things';
import Homes from 'app/page/Homes';
import User from 'app/page/User';
import Automations from 'app/page/Automations';
import { useLogout } from 'app/hook';

import style from './style';

const NavItem = createNav({ passedProps: { isActive: 'active' }, props: { global: 'global' } })(Navigation.Item);

const useStyles = createUseStyles(style);

const Dashboard = (): React.ReactElement<'div'> => {
    const classes = useStyles();
    const [t] = useTranslation('dashboard');
    const logout = useLogout();
    return <div className={classes.Layout}>
        <div className={classes.Top}>
            <Logo/>
            <Navigation>
                <NavItem to="/thing" global>{t('menu.things')}</NavItem>
                <NavItem to="/home" global>{t('menu.home')}</NavItem>
                <NavItem to="/automations" global>{t('menu.automations')}</NavItem>
                <NavItem to="/user" global>{t('menu.user')}</NavItem>
                <Navigation.Item className={classes.Logout} onClick={logout}>{t('menu.logout')}</Navigation.Item>
            </Navigation>
        </div>
        <div className={classes.Content}>
            <Switch>
                <NestedRouter path="/thing" component={Things}/>
                <Route path="/home" component={Homes}/>
                <Route path="/user" component={User}/>
                <NestedRouter path="/automations" component={Automations}/>
                <Redirect to="/thing"/>
            </Switch>
        </div>
    </div>;
};

export default Dashboard;
