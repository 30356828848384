import * as React from 'react';
import Portal from '../Portal';
import BaseModal from './Modal';
import { CONTAINER_CLASS_NAME } from './style';

type ModalProps = {
    isOpen?: boolean;
    children: React.ReactNodeArray | React.ReactNode;
    background?: boolean;
};

const Modal = ({ children, background, isOpen }: ModalProps) => (
    <Portal isOpen={isOpen} className={CONTAINER_CLASS_NAME}>
        <BaseModal background={background}>
            {children}
        </BaseModal>
    </Portal>
);

export default Modal;
export { BaseModal as Modal, CONTAINER_CLASS_NAME };
