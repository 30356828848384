import * as React from 'react';

import Variable from './Variable';

import type { FlatNode } from '../types';

const ACTION_CLEAR = 'clear';
const ACTION_VARIABLE_SET = 'variable-set';
const ACTION_BLOCK_START_EVALUATION = 'block-evaluation-start';
const ACTION_BLOCK_FINISH_EVALUATION = 'block-evaluation-finish';
const ACTION_BLOCK_FAIL_EVALUATION = 'block-evaluation-fail';

export interface StackInput {
    name: string;
    value: any;
    resolved: boolean;
    source: 'field' | 'value';
}

export interface StackFrame {
    id: string;
    type: string;
    output?: any;
    inputs: StackInput[],
    start: Date;
    stop?: Date;
    error?: Error;
    hidden: boolean;
}

class Stack<T extends StackFrame> extends Array<T> {
    public constructor();
    public constructor(frames: Array<T>);
    public constructor(...args: any[]) {
        if (args.length === 1 && args[0] instanceof Array) {
            super();
            for (const frame of args[0]) {
                this.push(frame);
            }
        } else {
            super(...args);
        }
        Object.setPrototypeOf(this, Stack.prototype);
    }

    protected static framePredicate<T extends StackFrame>(id: T['id']) {
        return (frame: T) => frame.id === id;
    }

    protected hasFrameId(id: T['id']): boolean {
        return this.some(Stack.framePredicate<T>(id));
    }

    public getFrameById(id: T['id']): T | undefined {
        return this.find(Stack.framePredicate<T>(id));
    }

    public withRemovedFrame(id: T['id']): Stack<T> {
        if (this.hasFrameId(id)) {
            return this.filter((frame) => !Stack.framePredicate<T>(id)(frame)) as any;
        }

        return this;
    }

    public withNewFrame(frame: T): Stack<T> {
        if (this.hasFrameId(frame.id)) {
            return this;
        }

        return new Stack([...this, frame]);
    }
}

type MutatorSig<T> = (subject: T) => T;

const setVariable = Symbol('set-variable');
const mutateProcessing = Symbol('mutate-processing');
const mutateStack = Symbol('mutate-stack');
const moveFrame = Symbol('move-frame');
export class State {
    public readonly variables: Record<string, any>;
    public readonly processing: Stack<StackFrame>;
    public readonly stack: Stack<StackFrame>;

    public constructor({ variables, processing, stack }: Partial<State> = {}) {
        this.variables = variables || {};
        this.processing = processing || new Stack();
        this.stack = stack || new Stack();
    }

    public [setVariable](name: string, value: any) {
        if (!(name in this.variables) || this.variables[name] !== value) {
            return new State({
                variables: {
                    ...this.variables,
                    [name]: value
                },
                processing: this.processing,
                stack: this.stack
            });
        }

        return this;
    }

    public [mutateProcessing](mutator: MutatorSig<Stack<StackFrame>>) {
        const processing = mutator(this.processing);
        if (processing !== this.processing) {
            return new State({
                variables: this.variables,
                stack: this.stack,
                processing
            });
        }

        return this;
    }

    public [mutateStack](mutator: MutatorSig<Stack<StackFrame>>) {
        const stack = mutator(this.stack);
        if (stack !== this.stack) {
            return new State({
                processing: this.processing,
                variables: this.variables,
                stack
            });
        }

        return this;
    }

    public [moveFrame](id: string, mutator: MutatorSig<StackFrame>) {
        const frame = this.processing.getFrameById(id);
        if (frame) {
            const processing = this.processing.withRemovedFrame(id);
            const stack = this.stack.withNewFrame(mutator(frame));
            return new State({ variables: this.variables, processing, stack });
        }

        return this;
    }
}


const initialState = new State();

interface Action {
    type: string;
}
interface ClearAction extends Action {
    type: typeof ACTION_CLEAR;
}
interface VariableSetAction extends Action {
    type: typeof ACTION_VARIABLE_SET;
    name: string;
    value: any;
}
interface BlockEvaluationStartAction extends Action {
    type: typeof ACTION_BLOCK_START_EVALUATION;
    node: FlatNode;
    timestamp: Date;
    hidden: boolean;
}
interface BlockEvaluationFinishAction extends Action {
    type: typeof ACTION_BLOCK_FINISH_EVALUATION;
    blockId: string;
    timestamp: Date;
    value: any;
}
interface BlockEvaluationFailAction extends Action {
    type: typeof ACTION_BLOCK_FAIL_EVALUATION;
    blockId: string;
    timestamp: Date;
    error: Error
}
type Actions = ClearAction
    | VariableSetAction
    | BlockEvaluationStartAction
    | BlockEvaluationFinishAction
    | BlockEvaluationFailAction;

const makeStackFrame = ({ node, timestamp, hidden }: BlockEvaluationStartAction): StackFrame => {
    const frame: StackFrame = {
        id: node.id,
        type: node.type,
        start: timestamp,
        hidden,
        inputs: []
    };

    if (node.fields) {
        for (const [name, value] of Object.entries(node.fields)) {
            frame.inputs.push({ name, value, resolved: true, source: 'field' });
        }
    }
    if (node.values) {
        for (const [name, id] of Object.entries(node.values)) {
            frame.inputs.push({ name, value: id, resolved: false, source: 'value' });
        }
    }

    return frame;
};

const updateFrameInputs = (stack: Stack<StackFrame>) => (input: StackInput) => {
    if (!input.resolved) {
        const valueFrame = stack.getFrameById(input.value);
        if (valueFrame) {
            if (valueFrame.type === 'variables_get') {
                const varInput = valueFrame.inputs.find(({ name }) => name === 'VAR');
                if (varInput) {
                    return {
                        ...input,
                        value: new Variable(varInput.value, valueFrame.output),
                        resolved: true
                    };
                }
            }

            return {
                ...input,
                value: valueFrame.output,
                resolved: true
            };
        }
    }
    return input;
};

const reducer = (state: State, action: Actions): State => {
    switch (action.type) {
        case ACTION_CLEAR:
            return initialState;
        case ACTION_VARIABLE_SET:
            return state[setVariable](action.name, action.value);
        case ACTION_BLOCK_START_EVALUATION:
            return state[mutateProcessing]((stack) => stack.withNewFrame(makeStackFrame(action)));
        case ACTION_BLOCK_FINISH_EVALUATION:
            return state[moveFrame](action.blockId, (frame) => ({
                ...frame,
                inputs: frame.inputs.map(updateFrameInputs(state.stack)),
                output: action.value,
                stop: action.timestamp
            }));
        case ACTION_BLOCK_FAIL_EVALUATION:
            return state[moveFrame](action.blockId, (frame) => ({
                ...frame,
                inputs: frame.inputs.map(updateFrameInputs(state.stack)),
                error: action.error,
                stop: action.timestamp
            }));
        default:
            return state;
    }
};

const makeActions = (dispatch: React.Dispatch<Actions>) => ({
    clear() {
        dispatch({ type: ACTION_CLEAR } as ClearAction);
    },
    block: {
        startEvaluation(node: FlatNode, timestamp: Date, hidden = false) {
            dispatch({ type: ACTION_BLOCK_START_EVALUATION, node, timestamp, hidden } as BlockEvaluationStartAction);
        },
        finishEvaluation(blockId: string, timestamp: Date, value: any) {
            dispatch({ type: ACTION_BLOCK_FINISH_EVALUATION, blockId, timestamp, value } as BlockEvaluationFinishAction);
        },
        failEvaluation(blockId: string, timestamp: Date, error: Error) {
            dispatch({ type: ACTION_BLOCK_FAIL_EVALUATION, blockId, timestamp, error } as BlockEvaluationFailAction);
        }
    },
    variable: {
        set(name: string, value: any) {
            dispatch({ type: ACTION_VARIABLE_SET, name, value } as VariableSetAction);
        }
    }
});

export type StateActions = ReturnType<typeof makeActions>;

interface UseStateResult {
    state: State;
    actions: StateActions;
}

export default function useState(): UseStateResult {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const actions = React.useMemo(() => makeActions(dispatch), [dispatch]);
    return React.useMemo(() => ({ state, actions }), [state, actions]);
}
