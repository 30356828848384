import { TranslatorSymbol } from '../../blockly';
import { FieldCheckbox } from '../../fields';

import type { Block } from '../../types';

export function builder(this: Block) {
    this.appendDummyInput()
        .appendField(this[TranslatorSymbol]('procedures.mutatorcontainer.title'));
    this.appendStatementInput('STACK');
    this.appendDummyInput('STATEMENT_INPUT')
        .appendField(this[TranslatorSymbol]('procedures.mutatorcontainer.allow-statements'))
        .appendField(new FieldCheckbox('TRUE'), 'STATEMENTS');
    this.setStyle('procedure_blocks');
    this.contextMenu = false;
}
