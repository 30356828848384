import type { Interpreter, Node } from '../../types';
import type { BlockDefinition } from '../types';

export const definition: BlockDefinition = {
    message0: '%1 %2',
    args0: [
        {
            type: 'input_value',
            name: 'NUMBER_TO_CHECK',
            check: 'Number'
        },
        {
            type: 'field_dropdown',
            name: 'PROPERTY',
            options: [
                ['${even}', 'EVEN'],
                ['${odd}', 'ODD'],
                ['${whole}', 'WHOLE'],
                ['${positive}', 'POSITIVE'],
                ['${negative}', 'NEGATIVE']
            ]
        }
    ],
    inputsInline: true,
    output: 'Boolean',
    style: 'math_blocks'
};

const numberProperty = (a: number, op: string): boolean => {
    switch (op) {
        case 'EVEN':
            return a % 2 === 0;
        case 'ODD':
            return a % 2 === 1;
        case 'WHOLE':
            return a === Math.floor(a);
        case 'POSITIVE':
            return a > 0;
        case 'NEGATIVE':
            return a < 0;
        default:
            throw new Error('Invalid property `' + op + '`');
    }
};

export default async function run(this: Interpreter, node: Node): Promise<boolean> {
    const aNode = node.values?.NUMBER_TO_CHECK;
    if (!aNode) {
        throw new Error('a missing');
    }

    const property = node.fields?.PROPERTY;
    if (!property) {
        throw new Error('property missing');
    }

    const a = await this.execute(aNode);

    return numberProperty(a, property as string);
}


