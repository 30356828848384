import gql from 'graphql-tag';
import shortcut from 'app/graphql/fragment/shortcut';

export default gql`
    mutation ThingShortcutCreate($thingId: ID!, $subsystemId: ID!, $name: String!, $on: String!, $off: String!) {
        thing(id: $thingId) {
            subsystem(id: $subsystemId) {
                shortcut {
                    create(name: $name, on: $on, off: $off) {
                        ...shortcut
                    }
                }
            }
        }
    }
    ${shortcut}
`;
