import * as React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import Loader from './Loader';
import style from './style';

export enum ButtonKind {
    success = 'success',
    primary = 'primary',
    danger = 'danger',
    lite = 'lite',
}

export enum ButtonSize {
    small = 'small',
    normal = 'normal',
}

type ButtonProps = JSX.IntrinsicElements['button'] & {
    kind?: ButtonKind;
    size?: ButtonSize;
    loading?: boolean;
    active?: boolean;
    noLoader?: boolean;
    className?: string;
};

const useStyles = createUseStyles(style);

const Button = ({
    children,
    className,
    loading,
    active,
    noLoader,
    size,
    type = 'button',
    kind = ButtonKind.primary,
    ...props
}: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const classes = useStyles({ ...props, kind, size });
    return (
        <button {...props} type={type} className={cx(classes.Button, active && classes.Active, className)} ref={ref}>
            {children}
            {noLoader !== true && <Loader className={classes.Loader} show={loading}/>}
        </button>
    );
};

export default React.forwardRef(Button);
