import * as React from 'react';
import { useState, useCallback } from 'react';
import { useFloating, autoUpdate, shift } from '@floating-ui/react-dom';

import Portal from '../Portal';

import type { Placement } from '@floating-ui/react-dom';

export type Trigger = 'onClick' | 'onMouseOver';
type DropdownProps = Partial<Record<Trigger, (e: React.SyntheticEvent) => void>> & {
    children: React.ReactElement;
    overlay: React.ReactElement;
    placement?: Placement;
    defaultOpen?: boolean;
    trigger?: Trigger;
    closeOnInsideClick?: boolean;
    offset?: number;
    onToggle?: (isOpen: boolean) => void;
};

const Dropdown: React.FC<DropdownProps> = ({
    children,
    overlay,
    placement = 'bottom-end',
    defaultOpen = false,
    trigger = 'onClick',
    closeOnInsideClick = true,
    offset,
    onToggle,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
    const { refs, floatingStyles } = useFloating({
        whileElementsMounted: autoUpdate,
        open: isOpen,
        placement,
        middleware: [
            shift()
        ]
    });

    const handleTrigger = useCallback((...args: any[]) => {
        if (trigger) {
            const theTrigger = props[trigger];
            if (theTrigger) {
                // @ts-ignore
                theTrigger(...args);
            }
        }
        setIsOpen(!isOpen);
        onToggle?.(!isOpen);
    }, [isOpen, trigger, props, onToggle]);

    const handleOutsideClick = useCallback(() => {
        setIsOpen(false);
        onToggle?.(false);
    }, [onToggle]);

    const handleInsideClick = useCallback(() => {
        if (closeOnInsideClick) {
            setIsOpen(false);
            onToggle?.(false);
        }
    }, [closeOnInsideClick, onToggle]);

    const child = React.Children.only(children);

    return (
        <React.Fragment>
            <Portal isOpen={isOpen}>
                <div
                    style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}
                    onClick={handleOutsideClick}
                />
            </Portal>
            {React.cloneElement(child, { [trigger]: handleTrigger, active: isOpen, ref: refs.setReference })}
            <Portal isOpen={isOpen}>
                <div ref={refs.setFloating} style={floatingStyles}>
                    {React.cloneElement(overlay, { onClick: handleInsideClick })}
                </div>
            </Portal>
        </React.Fragment>
    );
};

export default Dropdown;
