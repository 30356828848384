import type { Interpreter, Node } from '../../types';
import type { BlockDefinition } from '../types';

export const definition: BlockDefinition = {
    message0: '%1 %2',
    args0: [
        {
            type: 'field_dropdown',
            name: 'OP',
            options: [
                ['${round}', 'ROUND'],
                ['${round-up}', 'ROUNDUP'],
                ['${round-down}', 'ROUNDDOWN']
            ]
        },
        {
            type: 'input_value',
            name: 'NUM',
            check: 'Number'
        }
    ],
    output: 'Number',
    style: 'math_blocks'
};

const roundOperation = (a: number, op: string): number => {
    switch (op) {
        case 'ROUND':
            return Math.round(a);
        case 'ROUNDUP':
            return Math.ceil(a);
        case 'ROUNDDOWN':
            return Math.floor(a);
        default:
            throw new Error('Invalid operation `' + op + '`');
    }
};

export default async function run(this: Interpreter, node: Node): Promise<number> {
    const aNode = node.values?.NUM;
    if (!aNode) {
        throw new Error('num missing');
    }

    const operation = node.fields?.OP as string;
    if (!operation) {
        throw new Error('operation missing');
    }

    const a = await this.execute(aNode);

    return roundOperation(a, operation);
}


