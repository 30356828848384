import gql from 'graphql-tag';
import automation from '../fragment/automation';
import type { AutomationType } from '../fragment/automation';

export default gql`
    query Automations {
        me {
            id
            automations {
                ...automation
            }
        }
    }
    ${automation}
`;

export type AutomationsQueryData = {
    me: {
        id: number;
        automations: Array<AutomationType>;
    }
};
