import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import style from './Item.style';

type MenuItemProps = JSX.IntrinsicElements['div'] & {
    active?: boolean;
};

const useStyles = createUseStyles(style);

const MenuItem = ({ children, className, active, ...props }: MenuItemProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return (
        <div {...props} className={cx(classes.MenuItem, active && classes.Active, className)} ref={ref}>
            {children}
        </div>
    );
};

export default React.forwardRef(MenuItem);
