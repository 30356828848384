import { useMutation } from '@apollo/client/react';
import { object } from 'vx-std';
import REMOVE_AUTOMATION_MUTATION from 'app/graphql/mutation/automation/remove';
import AUTOMATIONS_QUERY from 'app/graphql/query/automations';

import type { AutomationsQueryData } from 'app/graphql/query/automations';

export default ((id: string) => {
    const [mutation] = useMutation(REMOVE_AUTOMATION_MUTATION, {
        variables: { id },
        update(store, { data: { automation: { remove } } }) {
            if (remove) {
                const data = store.readQuery<AutomationsQueryData>({ query: AUTOMATIONS_QUERY });
                const automations = data?.me.automations.filter(automation => automation.id !== id);

                if (automations) {
                    store.writeQuery({
                        query: AUTOMATIONS_QUERY,
                        data: object.replaceIn(data, 'me.automations', automations)
                    });
                }
            }
        }
    });
    return mutation;
});
