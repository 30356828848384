import Blockly, { TranslatorSymbol } from '../../blockly';

import type { BlockCategoryInfo } from '../types';

export * as defnoreturn from './defnoreturn';
export * as defreturn from './defreturn';
export * as callreturn from './callreturn';
export * as callnoreturn from './callnoreturn';
export * as ifreturn from './ifreturn';
export * as mutatorcontainer from './mutatorcontainer';
export * as mutatorarg from './mutatorarg';

export default {
    name: '${procedures}',
    categorystyle: 'math_category',
    custom: 'PROCEDURE'
} as BlockCategoryInfo;

Blockly.Procedures.findLegalName = function (name, block) {
    if (block.isInFlyout) {
        // Flyouts can have multiple procedures called 'do something'.
        return name;
    }
    name = name || block[TranslatorSymbol]('procedures.unnamed') || 'unnamed';
    // @ts-ignore
    while (!Blockly.Procedures.isLegalName_(name, block.workspace, block)) {
        // Collision with another procedure.
        const r = name.match(/^(.*?)(\d+)$/);
        if (!r) {
            name += '2';
        } else {
            name = r[1] + (parseInt(r[2], 10) + 1);
        }
    }
    return name;
};

// eslint-disable-next-line max-statements
Blockly.Procedures.flyoutCategory = function (workspace) {
    const xmlList = [];
    // <block type="procedures_defnoreturn" gap="16">
    //     <field name="NAME">do something</field>
    // </block>
    {
        const block = Blockly.utils.xml.createElement('block');
        block.setAttribute('type', 'procedures_defnoreturn');
        block.setAttribute('gap', '16');
        const nameField = Blockly.utils.xml.createElement('field');
        nameField.setAttribute('name', 'NAME');
        nameField.appendChild(Blockly.utils.xml.createTextNode(workspace[TranslatorSymbol]('procedures.unnamed')));
        block.appendChild(nameField);
        xmlList.push(block);
    }
    // <block type="procedures_defreturn" gap="16">
    //     <field name="NAME">do something</field>
    // </block>
    {
        const block = Blockly.utils.xml.createElement('block');
        block.setAttribute('type', 'procedures_defreturn');
        block.setAttribute('gap', '16');
        const nameField = Blockly.utils.xml.createElement('field');
        nameField.setAttribute('name', 'NAME');
        nameField.appendChild(Blockly.utils.xml.createTextNode(workspace[TranslatorSymbol]('procedures.unnamed')));
        block.appendChild(nameField);
        xmlList.push(block);
    }
    // <block type="procedures_ifreturn" gap="16"></block>
    {
        const block = Blockly.utils.xml.createElement('block');
        block.setAttribute('type', 'procedures_ifreturn');
        block.setAttribute('gap', '16');
        xmlList.push(block);
        if (xmlList.length) {
            // Add slightly larger gap between system blocks and user calls.
            xmlList[xmlList.length - 1].setAttribute('gap', '24');
        }
    }

    function populateProcedures(procedureList: Array<[string, string[]]>, templateName: string) {
        for (const [name, args] of procedureList) {
            // <block type="procedures_callnoreturn" gap="16">
            //   <mutation name="do something">
            //     <arg name="x"></arg>
            //   </mutation>
            // </block>
            const block = Blockly.utils.xml.createElement('block');
            block.setAttribute('type', templateName);
            block.setAttribute('gap', '16');
            const mutation = Blockly.utils.xml.createElement('mutation');
            mutation.setAttribute('name', name);
            block.appendChild(mutation);
            for (const argName of args) {
                const arg = Blockly.utils.xml.createElement('arg');
                arg.setAttribute('type', 'arg');
                arg.setAttribute('name', argName);
                mutation.appendChild(arg);
            }
            xmlList.push(block);
        }
    }

    const tuple = Blockly.Procedures.allProcedures(workspace);
    populateProcedures(tuple[0] as any, 'procedures_callnoreturn');
    populateProcedures(tuple[1] as any, 'procedures_callreturn');
    return xmlList;
};

