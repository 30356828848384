import type { Interpreter, Node } from '../../types';
import type { BlockDefinition } from '../types';

export const definition: BlockDefinition = {
    message0: '${constrain} %1 ${between} %2 ${and} %3',
    args0: [
        {
            type: 'input_value',
            name: 'VALUE',
            check: 'Number'
        },
        {
            type: 'input_value',
            name: 'LOW',
            check: 'Number'
        },
        {
            type: 'input_value',
            name: 'HIGH',
            check: 'Number'
        }
    ],
    inputsInline: true,
    output: 'Number',
    style: 'math_blocks'
};

export default async function run(this: Interpreter, node: Node): Promise<number> {
    const valueNode = node.values?.VALUE;
    if (!valueNode) {
        throw new Error('value missing');
    }

    const lowNode = node.values?.LOW;
    if (!lowNode) {
        throw new Error('low missing');
    }

    const highNode = node.values?.HIGH;
    if (!highNode) {
        throw new Error('high missing');
    }

    const value = await this.execute(valueNode);
    const low = await this.execute(lowNode);
    const high = await this.execute(highNode);

    return Math.min(Math.max(value, low), high);
}


