import { TIME_DATETIME_COLOR } from './constants';

import type { Interpreter, Block } from '../../types';

export const name = 'field_currenttime';

export function builder(this: Block) {
    this.appendDummyInput()
        .appendField('${current_time}');
    this.setOutput(true, 'DateTime');
    this.setInputsInline(true);

    this.setColour(TIME_DATETIME_COLOR);
}

export const NowMark = Symbol('now');
export default function run(this: Interpreter): Date {
    const date = new Date();
    // @ts-ignore
    date[NowMark] = true;
    return date;
}
run.noTrace = true;
