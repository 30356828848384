import * as React from 'react';
import { useQuery } from '@apollo/client/react';
import { EntypoSquaredPlus } from 'react-entypo-icons';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Box, Loader, Title } from 'app/component';
import { TitleSize } from 'app/component/Title/style';
import HOMES_ROOMS_QUERY from 'app/graphql/query/rooms';
import { withTooltip } from 'app/hoc';
import useCreateHomeModal from './useCreateHomeModal';
import style from './style';

import Home from './component/Home';
import Room from './component/Room';
import type { RoomsQueryData } from 'app/graphql/query/rooms';

const useStyles = createUseStyles(style);

const DivWithTooltip = withTooltip('div');

const Homes = () => {
    const classes = useStyles();
    const [t] = useTranslation('homes');
    const [createHome, setCreateHomeOpen] = useCreateHomeModal(t);

    const { data, loading } = useQuery<RoomsQueryData>(HOMES_ROOMS_QUERY);
    if (loading || !data || !data.me) {
        return <Loader/>;
    }

    return (
        <Box className={classes.Homes}>
            {createHome}
            <div className={classes.TopRow}>
                <Title size={TitleSize.large}>{t('title')}</Title>
                <DivWithTooltip className={classes.AddHome} tooltip={t('home.create.tooltip')} onClick={setCreateHomeOpen}>
                    <EntypoSquaredPlus/>
                </DivWithTooltip>
            </div>
            <div className={classes.HomesList}>
                {data.me.homes.map(({ id, name, rooms }) => (
                    <Home key={id} id={id} name={name}>
                        {rooms.map(({ id, name }) => <Room key={id} id={id} name={name}/>)}
                    </Home>
                ))}
            </div>
        </Box>
    );
};

export default Homes;
