import * as React from 'react';
import { EntypoPowerPlug, EntypoSquaredCross } from 'react-entypo-icons';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import style from './style';

type ThingStatusProps = JSX.IntrinsicElements['span'] & {
    status: 'online' | 'offline' | null | undefined;
};

const useStyles = createUseStyles(style);

const ThingStatus = ({ status, children, className, ...props }: ThingStatusProps, ref: React.Ref<HTMLSpanElement>) => {
    const classes = useStyles();
    const active = status === 'online';
    return (
        <span {...props} className={cx(classes.ThingStatus, active && classes.Active, className)} ref={ref}>
            {active ? <EntypoPowerPlug valign="middle"/> : <EntypoSquaredCross valign="middle"/>}
        </span>
    );
};

export default React.forwardRef(ThingStatus);
