import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { jss, JssProvider, ThemeProvider } from 'react-jss';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client/react';
import client from 'app/connector/graphql';
import i18n from 'app/connector/i18n';
import themeDark from 'app/theme/dark';
import themeLight from 'app/theme/light';
import { prefersDarkTheme } from 'app/utils/color';

const rootRenderer = (App: React.ComponentType<any>) => {
    const root = document.getElementById('root');
    if (root) {
        ReactDOM.render(
            <JssProvider jss={jss}>
                <ApolloProvider client={client}>
                    <I18nextProvider i18n={i18n as any}>
                        <ThemeProvider theme={prefersDarkTheme ? themeDark : themeLight}>
                            <Router>
                                <App/>
                            </Router>
                        </ThemeProvider>
                    </I18nextProvider>
                </ApolloProvider>
            </JssProvider>,
            root
        );
    }
};

export default rootRenderer;

export const reset = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById('root') as any);
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    rootRenderer(require('./App').default);
};

