import * as React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { EntypoChevronDown } from 'react-entypo-icons';
import Button, { ButtonKind } from '../Button';
import style from './Face.style';

interface FaceProps extends React.ComponentProps<typeof Button> {
    active?: boolean;
}

const useStyles = createUseStyles(style);

const Face = ({ className, children, ...props }: FaceProps, ref?: React.Ref<HTMLButtonElement>) => {
    const classes = useStyles();
    return (
        <Button {...props} ref={ref} kind={ButtonKind.lite} noLoader className={cx(classes.Face, className)}>
            {children}
            <EntypoChevronDown className={classes.Chevron}/>
        </Button>
    );
};

export default React.forwardRef(Face);
