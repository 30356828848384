import * as React from 'react';

import { Blockly } from 'app/component';
import baseCategories from 'app/component/Blockly/blocks';
import { buildToolbox, extractExecutors, initializeBlocks } from 'app/component/Blockly/blocks/utils';
import AutomationOutputs from '../../AutomationOutputs';
import Output from '../../Output';
import useBlocklyModal from '../useBlocklyModal';
import IotRunner from './IotRunner';
import TracerValue from './Value';
import { buildContext } from './context';

import * as iot from './blocks';
import * as iot_subsystems from './blocks/index.subsystems';
import * as iot_devices from './blocks/index.things';

import type { BlocklyProps, FlatNode } from 'app/component/Blockly';
export type { BlocklyProps, FlatNode };


const categories = {
    iot_devices,
    iot_subsystems,
    ...baseCategories,
    iot
};

initializeBlocks({ iot, iot_subsystems, iot_devices });
const toolbox = buildToolbox(categories);
const instructions = extractExecutors(categories);

interface AutomationBlocklyProps extends Omit<BlocklyProps, 'toolbox' | 'context' | 'modals' | 'runner'> {
    things: any[];
}

export default function AutomationBlockly({ things, ...props }: AutomationBlocklyProps) {
    const [outputStates, setOutputs] = React.useState<Record<string, any>>({});

    const runner = React.useMemo(() => new IotRunner(instructions, setOutputs), [setOutputs]);

    const context = React.useMemo(() => buildContext(things), [things]);

    const modals = useBlocklyModal();

    const outputs = props.value.filter((block) => block.type.startsWith('output_'));

    return (
        <>
            {modals.element}
            <Blockly
                {...props}
                modals={modals}
                context={context}
                toolbox={toolbox}
                runner={runner}
                tracerFooter={(
                    <AutomationOutputs style={{ minHeight: 70, maxHeight: 70 }}>
                        {outputs.map((output) => (
                            <Output
                                key={output.id}
                                id={output.id}
                                name={output.fields?.name as string || ''}
                                type={output.type.replace('output_', '').replace('stateful_', '')}
                                status={outputStates[output.id] ? 'true' : 'false'}
                            />
                        ))}
                    </AutomationOutputs>
                )}
                TracerValueComponent={TracerValue}
            />
        </>
    );
}
