import * as React from 'react';

const getWindowSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight
});

export default function useViewport() {
    const [size, setSize] = React.useState(getWindowSize);

    React.useLayoutEffect(() => {
        const listener = () => setSize(getWindowSize());

        window.addEventListener('resize', listener);
        return () => {
            window.removeEventListener('resize', listener);
        };
    }, []);

    return size;
}
