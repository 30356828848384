import * as React from 'react';

export const getDisplayName = (Component: React.ElementType): string => {
    if (Component) {
        if (typeof Component === 'string' || Component instanceof String) {
            // @ts-ignore
            return Component;
        }

        if (typeof Component === 'function' && Component.displayName) {
            return Component.displayName;
        }
    }

    return 'Unknown';
};

export const useElementProp = <R>(builder: () => R, input?: Array<any>): R => {
    const [value, setValue] = React.useState(builder);

    React.useEffect(() => {
        if (input) {
            setValue(builder());
        }
    }, input);

    return value;
};
