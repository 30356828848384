import { TIME_DATETIME_COLOR } from './constants';

import type { BlockCategoryInfo } from '../types';

export * as op_datetime_period from './op_datetime_period';
export * as op_period_period from './op_period_period';
export * as field_time_period from './field_time_period';
export * as field_currenttime from './field_currenttime';
export * as sleep from './sleep';

export default {
    name: '${date_time}',
    colour: TIME_DATETIME_COLOR
} as BlockCategoryInfo;
