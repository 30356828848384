import gql from 'graphql-tag';
import automation from 'app/graphql/fragment/automation';

export default gql`
    mutation SetAutomationActions($id: ID!, $actions: [AutomationActionInput!]!) {
        automation(id: $id) {
            setActions(actions: $actions) {
                ...automation
            }
        }
    }
    ${automation}
`;
