const active = {
    background: '#00000050'
};

export default {
    Active: active,
    NavigationDropdown: {
        flexGrow: 0,
        cursor: 'pointer',
        userSelect: 'none',
        color: '#ffffff',
        fontWeight: 'bold',

        marginRight: 1,

        padding: {
            left: 20,
            right: 20
        },

        '&:hover': active
    },
    Layout: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    }
};
