import { predicate } from 'vx-std';
import BlocklyCore from 'blockly';

export interface TypedTextFieldConfig {
    type: string;
    label: string;
    name: string;
    output: string | null;
    defaultValue: any;
    color: string | number;
    style?: Partial<CSSStyleDeclaration>;
}



export type TypeConfigBase = Partial<{
    defaultValue: string,
    color: number | string
}>;

export default class TypedTextField extends BlocklyCore.FieldTextInput {
    public static create(type: string, defaultValue?: any, config?: Omit<Partial<TypedTextFieldConfig>, 'type'|'defaultValue'>) {
        const options: TypedTextFieldConfig = {
            label: type,
            name: type,
            color: 315,
            output: null,
            ...config,
            type,
            defaultValue
        };

        return {
            init(this: BlocklyCore.Block) {
                const field = new TypedTextField(defaultValue, options);
                this.appendDummyInput()
                    .appendField(options.label)
                    .appendField(field, options.name);
                this.setColour(options.color);
                this.setOutput(true, options.output);
            }
        };
    }


    public static createTime(): TypedTextField;
    public static createTime(defaultValue: string): TypedTextField;
    public static createTime(config: TypeConfigBase): TypedTextField;
    public static createTime(defaultValue: string, config: TypeConfigBase): TypedTextField;
    public static createTime(...args: any[]) {
        const options: TypeConfigBase = args.find<TypeConfigBase>(predicate.isObject) || {};
        const defaultValue = args.find(predicate.isString) || options.defaultValue || '12:00';

        return this.create('time', defaultValue, {
            label: 'Time',
            color: options.color || 220,
            style: {
                width: '5em'
            },
            output: 'Time'
        });
    }

    public static createDate(): TypedTextField;
    public static createDate(defaultValue: string): TypedTextField;
    public static createDate(config: TypeConfigBase): TypedTextField;
    public static createDate(defaultValue: string, config: TypeConfigBase): TypedTextField;
    public static createDate(...args: any[]) {
        const options: TypeConfigBase = args.find<TypeConfigBase>(predicate.isObject) || {};
        const defaultValue = args.find(predicate.isString) || options.defaultValue || '2021-01-01';
        return this.create('date', defaultValue, {
            label: 'Date',
            color: options.color || 220,
            style: {
                width: '8em'
            },
            output: 'Date'
        });
    }


    public static createDateTime(): TypedTextField;
    public static createDateTime(defaultValue: string): TypedTextField;
    public static createDateTime(config: TypeConfigBase): TypedTextField;
    public static createDateTime(defaultValue: string, config: TypeConfigBase): TypedTextField;
    public static createDateTime(...args: any[]) {
        const options: TypeConfigBase = args.find<TypeConfigBase>(predicate.isObject) || {};
        const defaultValue = args.find(predicate.isString) || options.defaultValue || '2021-01-01 12:00';
        return this.create('datetime-local', defaultValue, {
            color: options.color || 220,
            label: 'Date time',
            name: 'datetime',
            style: {
                width: '12em'
            },
            output: 'DateTime'
        });
    }


    protected  readonly _type: string;
    protected  readonly _defaultValue: string;
    protected  readonly _style: Partial<CSSStyleDeclaration> | undefined;

    constructor(opt_value: string, { type, style, defaultValue }: Omit<TypedTextFieldConfig, 'label'>) {
        super(opt_value);
        this._type = type;
        this._style = style;
        this._defaultValue = defaultValue;
    }

    public widgetCreate_(): HTMLElement {
        const htmlElement = super.widgetCreate_() as HTMLInputElement;
        htmlElement.type = this._type;
        if (this._style) {
            Object.assign(htmlElement.style, this._style);
        }
        return htmlElement;
    }

    public updateSize_(opt_margin?: number) {
        const constants = this.getConstants();
        const xOffset = opt_margin != undefined ? opt_margin : (this.borderRect_ ? this.getConstants().FIELD_BORDER_RECT_X_PADDING : 0);
        let totalWidth = xOffset * 2;
        let totalHeight = constants.FIELD_TEXT_HEIGHT;

        const contentWidth = this.getEditorRect().width;
        totalWidth += contentWidth;

        if (this.borderRect_) {
            totalHeight = Math.max(totalHeight, constants.FIELD_BORDER_RECT_HEIGHT);
        }

        this.size_.height = totalHeight;
        this.size_.width = totalWidth;

        this.positionTextElement_(xOffset, contentWidth);
        this.positionBorderRect_();
    }

    protected getEditorRect() {
        const c = document.createElement('div');
        c.className = 'blocklyWidgetDiv geras-renderer dark-theme';
        const scale = this.workspace_?.getScale() || 1;
        const fontSize = (this.getConstants().FIELD_TEXT_FONTSIZE * scale) + 'pt';
        const borderRadius = (BlocklyCore.FieldTextInput.BORDERRADIUS * scale) + 'px';
        Object.assign(c.style, {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '16px',
            display: 'block',
            fontSize,
            borderRadius
        });
        document.body.appendChild(c);

        const el = document.createElement('input');
        el.className = 'blocklyHtmlInput';
        el.value = this._defaultValue;
        el.type = this._type;
        if (this._style) {
            Object.assign(el.style, this._style);
        }
        c.appendChild(el);
        const rect = el.getBoundingClientRect();
        document.body.removeChild(c);
        return rect;
    }
}
