import * as React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import Loader from '../Loader';
import style from './style';

const useStyles = createUseStyles(style);

const Logo = ({ children, className, ...props }: JSX.IntrinsicElements['div'], ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return (
        <div {...props} className={cx(classes.Logo, className)} ref={ref}>
            <div className={classes.Wrapper}>
                <Loader className={classes.Loader} size="100%" blur={25} animate={false}/>
                <div className={classes.Text}>IOT</div>
            </div>
        </div>
    );
};

export default React.forwardRef(Logo);
