import gql from 'graphql-tag';

export default gql`
    subscription OnShortcutStatus($id: ID!) {
        onShortcutStatus(id: $id) {
            id
            status
        }
    }
`;

export type ShortcutStatusSubscription = {
    onShortcutStatus: {
        id: number;
        status: boolean;
    }
}
