import * as React from 'react';
import { createUseStyles } from 'react-jss';
import Center from '../Center';
import Background from './Background';
import style from './style';

type ModalProps = React.ComponentProps<'div'> & {
    background?: boolean;
};

const useStyles = createUseStyles(style);

const Modal = ({ children, background, ...props }: ModalProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            {background !== false && <Background/>}
            <Center className={classes.Content}>
                <div {...props} ref={ref}>
                    {children}
                </div>
            </Center>
        </React.Fragment>
    );
};

export default React.forwardRef(Modal);
