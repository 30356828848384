import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { useTransition, animated } from 'react-spring';
import style from './style';

type LoaderProps = JSX.IntrinsicElements['div'] & {
    show?: boolean;
};

const useStyles = createUseStyles(style);

const Loader = ({ className, show, style, ...props }: LoaderProps) => {
    const classes = useStyles();

    const transitions = useTransition(show, null, {
        from: { width: '0em', opacity: 0 },
        enter: [{ width: '1em', opacity: 1 }],
        leave: [{ width: '0em', opacity: 0 }],
        delay: 200
    });

    return (
        <div style={{ display: 'inline-block' }}>
            {transitions.map(({ item, key, props: spring }) => item &&
                // @ts-ignore
                <animated.div {...props} style={{ ...style, ...spring }} key={key} className={cx(classes.Container, className)}>
                    <div className={classes.Loader} />
                </animated.div>
            )}
        </div>
    );
};

export default Loader;
