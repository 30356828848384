export default {
    ButtonFooter: {
        display: 'flex',
        justifyContent: 'flex-end',

        '& > * + *': {
            marginLeft: 10
        }
    }
};
