import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import Error from '../Error';
import { useExpression } from './hook';
import style from './Preview.style';

type PreviewProps = Omit<React.ComponentProps<'div'>, 'onChange'> & {
    value?: string;
    count?: number;
    onChange?: (value: string) => void;
};

const useStyles = createUseStyles(style);

const Preview = ({ className, onChange, value, count = 10, ...props }: PreviewProps, ref?: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    const [t] = useTranslation('components');
    const { expression, error } = useExpression(value);
    return (
        <div {...props} ref={ref} className={cx(classes.Container, className)}>
            {error && <Error>{error}</Error>}
            {expression && <div className={classes.Success}>
                <div>{t('cron.preview')}:</div>
                <div className={classes.Preview}>
                    {(new Array(count).fill(0).map((_, i) => (
                        <div key={i}>
                            &bull;{' '}{expression.next().toISOString().replace('T', ' ').replace('.000Z', '')}
                        </div>
                    )))}
                </div>
            </div>}
        </div>
    );
};

export default React.forwardRef(Preview);
