import gql from 'graphql-tag';
import type { FetchResult } from '@apollo/client/link/core';

export default gql`
    mutation CreateRoom($homeId: ID!, $name: String!) {
        room {
            create(homeId: $homeId, name: $name) {
                id
                name
            }
        }
    }
`;

export type CreateRoomMutationResult = FetchResult<{
    room: {
        create: {
            id: number;
            name: string;
        }
    }
}>;
