import * as React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

import { Button, Title, Window } from 'app/component';
import { FormButton, FormFeedback, MutationForm } from 'app/composition/Form';
import { ButtonKind } from 'app/component/Button';
import style from './style';

type MutationWindowProps = React.ComponentProps<typeof MutationForm> & {
    close: () => void;
    title?: string;
    labelOk?: string;
    labelCancel?: string;
    contentClassName?: string;
};

const useStyles = createUseStyles(style);

const MutationWindow = ({ children, close, title, labelOk, labelCancel, contentClassName, ...props }: MutationWindowProps) => {
    const classes = useStyles();

    return (
        <MutationForm touchOn="blur" {...props} onSubmitSuccess={close}>
            <Window
                className={classes.Window}
                contentClassName={cx(classes.Content, contentClassName)}
                title={<Title>{title || 'Form'}</Title>}
            >
                {children}

                <FormFeedback/>
                <Window.ButtonFooter>
                    <FormButton kind={ButtonKind.primary} type="submit">
                        {labelOk || 'OK'}
                    </FormButton>
                    <Button kind={ButtonKind.lite} onClick={close}>
                        {labelCancel || 'Cancel'}
                    </Button>
                </Window.ButtonFooter>
            </Window>
        </MutationForm>
    );
};

export default MutationWindow;
