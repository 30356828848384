import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { object } from 'vx-std';

import Dropdown from '../Dropdown';
import Loader from '../Loader';
import Menu from '../Menu';
import Face from './Face';

import type { Placement } from '@floating-ui/react-dom';

type SelectProps<T> = Omit<React.ComponentProps<typeof Face>, 'children' | 'onSelect'> & {
    children: React.ReactChildren | React.ReactChild[];
    keyProp?: string;
    value?: T;
    onToggle?: (isOpen: boolean) => void;
    onSelect?: (value: T) => void;

    placeholder?: React.ReactElement | string;

    placement?: Placement;
    defaultOpen?: boolean;
    closeOnInsideClick?: boolean;
    loading?: boolean;
};

const getKey = (el: React.ReactElement, keyProp: string) => object.getIn(el.props, keyProp) as string;

const Select = ({
    children,
    keyProp = 'id',
    value,
    onSelect,
    placeholder,
    placement = 'bottom-end',
    defaultOpen = false,
    closeOnInsideClick = true,
    loading = false,
    onToggle,
    ...props
}: SelectProps<any>) => {
    const [t] = useTranslation('components');

    const current = React.useMemo(() => {
        const current = React.Children.toArray(children).find(el => getKey(el as React.ReactElement, keyProp) === value);
        if (current) {
            return current;
        }

        return placeholder || <span>{t('select.choose')}</span>;
    }, [value, keyProp, children, placeholder, t]);

    const overlay = React.useMemo(() => (
        <Menu>
            {React.Children.toArray(children).map(el => {
                const elValue = getKey(el as React.ReactElement, keyProp);
                if (elValue !== undefined) {
                    return (
                        <Menu.Item
                            key={(el as React.ReactElement).key || elValue}
                            onClick={() => {
                                onSelect && onSelect(elValue);
                            }}
                            active={elValue === value}
                        >
                            {el}
                        </Menu.Item>
                    );
                }

                return el;
            })}
        </Menu>
    ), [value, keyProp, children, onSelect]);

    return (
        <Dropdown
            placement={placement}
            defaultOpen={defaultOpen}
            overlay={overlay}
            onToggle={onToggle}
            closeOnInsideClick={closeOnInsideClick}
        >
            <Face {...props}>
                {loading ? <Loader flat size="45%"/> : current}
            </Face>
        </Dropdown>
    );
};

export default Select;
