import * as React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import EventEmitter from 'eventemitter3';
import { getDisplayName } from 'app/utils/react';
import { Modal } from 'app/component';

type RemoteModalProps = {
    disabled?: boolean;
    mId?: string;
};

type ModalTriggerProps = {
    mId?: string;
};

export default () => {
    const bridge = new EventEmitter();
    const mId = '<default>';

    const withModal = <MP extends Record<string, unknown>>(ModalComponent: React.ComponentType<MP>, close = 'close') => {
        class RemoteModal extends React.Component<Omit<MP, 'close'> & RemoteModalProps, any> {

            static displayName = `RemoteModal(${getDisplayName(ModalComponent)})`;

            static defaultProps = { mId };

            state = {
                isOpen: false
            };

            constructor(...args: any) {
                // @ts-ignore
                super(...args);

                this.handleOpen = this.handleOpen.bind(this);
            }

            handleOpen() {
                this.setState({ isOpen: true });
            }

            componentDidMount() {
                bridge.on(this.props.mId as string, this.handleOpen);
            }

            componentWillUnmount() {
                bridge.off(this.props.mId as string, this.handleOpen);
            }

            render() {
                const { mId, disabled, ...props } = this.props;
                return (
                    <Modal isOpen={disabled !== false && this.state.isOpen}>
                        {/* @ts-ignore */}
                        {React.createElement(ModalComponent, { ...props, [close]: () => this.setState({ isOpen: false }) })}
                    </Modal>
                );
            }

            componentDidUpdate(prevProps: Readonly<MP & RemoteModalProps>) {
                if (prevProps.mId !== this.props.mId) {
                    bridge.off(prevProps.mId as string, this.handleOpen);
                    bridge.on(this.props.mId as string, this.handleOpen);
                }
            }
        }

        return hoistStatics(RemoteModal, ModalComponent, { contextType: true });
    };

    const withTrigger = <C extends React.ComponentType<any> | React.ElementType, TP extends React.ComponentProps<C>, T extends keyof TP>
        (TriggerComponent: C, trigger?: T) => {

        // @ts-ignore
        trigger ||= 'onClick';

        class ModalTrigger extends React.Component<TP & ModalTriggerProps, any> {

            static displayName = `ModalTrigger(${getDisplayName(TriggerComponent)})`;

            static defaultProps = { mId };

            constructor(...args: any) {
                // @ts-ignore
                super(...args);

                // $FlowIgnore
                this.handleOpen = this.handleOpen.bind(this);
            }

            handleOpen(...args: any) {
                bridge.emit(this.props.mId as string);
                // @ts-ignore
                if (this.props[trigger]) {
                    // @ts-ignore
                    this.props[trigger](...args);
                }
            }

            render() {
                const { mId, ...props } = this.props;
                // @ts-ignore
                return React.createElement(TriggerComponent, { ...props, [trigger]: this.handleOpen });
            }
        }

        return hoistStatics(ModalTrigger, TriggerComponent as React.ComponentType<TP & ModalTriggerProps>, { contextType: true });
    };

    return { withTrigger, withModal, mId, bridge, useTrigger: (customMId?: string) => bridge.emit(customMId || mId) };
};
