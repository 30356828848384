import * as React from 'react';
import { useMutation } from '@apollo/client/react';
import SET_AUTOMATION_TRIGGER_MUTATION from 'app/graphql/mutation/automation/setTrigger';

import type { AutomationTrigger } from 'app/graphql/fragment/automation';

export default () => {
    const [mutation] = useMutation(SET_AUTOMATION_TRIGGER_MUTATION);
    return React.useCallback((id: string, trigger: AutomationTrigger) => {
        return mutation({ variables: { id, trigger } });
    }, [mutation]);
};
