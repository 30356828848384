import * as React from 'react';
import cx from 'classnames';
import { EntypoBell } from 'react-entypo-icons';
import { createUseStyles } from 'react-jss';
import style from './style';

const useStyles = createUseStyles(style);

interface AlertProps {
    status: string;
}

const Alert = ({ status }: AlertProps) => {
    const classes = useStyles();
    return (
        <div className={cx(classes.Alert, (status === 'true') && 'triggered')}>
            <EntypoBell />
        </div>
    );
};

export default Alert;
