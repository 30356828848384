import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'app/component';

interface SubsystemNameProps extends React.ComponentProps<typeof Title> {
    type: string;
    name: string;
}
const SubsystemName = ({ type, name, ...props }: SubsystemNameProps) => {
    const [t] = useTranslation('subsystem');
    return <Title {...props}>{t(`name.${type}`, { defaultValue: name })}</Title>;
};

export default SubsystemName;
