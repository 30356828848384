import EarlyReturn from '../EarlyReturn';

import type { Interpreter as InterpreterInterface, Node } from '../../types';
import type { ExecutorResult, InstructionSet, InstructionExecutor } from '../types';
import type Context from './Context';

export default class Interpreter<C extends Context = Context> implements InterpreterInterface {
    protected readonly _context: C;
    protected readonly _instructions: InstructionSet;

    public constructor(instructions: InstructionSet, context: C) {
        this._instructions = instructions;
        this._context = context;
    }

    public get variables() {
        return this._context.variables;
    }

    protected async _execute(executor: InstructionExecutor, action: Node): Promise<ExecutorResult> {
        return executor.call(this, action);
    }

    public async execute(action: Node): Promise<ExecutorResult> {
        const executor = this._instructions[action.type];
        if (!executor) {
            // eslint-disable-next-line no-console
            console.error('Unknown action', action);
            throw new Error('unknown action `' + action.type + '`');
        }

        const result = await this._execute(executor, action);
        switch (true) {
            case result instanceof Boolean:
                return result.valueOf();
            default:
                return result;
        }
    }

    public async executeStatement(action: Node | undefined): Promise<void> {
        while (action) {
            await this.execute(action);
            action = action.next;
        }
    }

    public async executeProcedure(name: string, variables: Map<string, string | number | null>) {
        const procedure = this._context.procedures.get(name);
        if (!procedure) {
            throw new Error('unknown procedure');
        }

        const scopeInterpreter = this.withScope(variables);

        const stack = procedure.statements?.STACK;
        if (stack) {
            try {
                await scopeInterpreter.executeStatement(stack);
            } catch (e) {
                if (e instanceof EarlyReturn) {
                    return e.value;
                }
                throw e;
            }
        }

        if (procedure.values?.RETURN) {
            return scopeInterpreter.execute(procedure.values.RETURN);
        }
        return null;
    }

    protected withScope(variables: Map<string, string | number | null>) {
        const scope = Object.create(this.constructor.prototype);
        Object.assign(scope, this);
        scope._context = this._context.withScope(variables);
        return scope;
    }

    public setProcedure(action: Node) {
        if (action.fields?.NAME) {
            this._context.procedures.set(action.fields.NAME as string, action);
        }
    }

    public complete() {
        // no-op
    }
}
