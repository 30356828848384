import UiTracedInterpreter from 'app/component/Blockly/runner/interpreter/UiTracedInterpreter';

import type { InstructionSet } from 'app/component/Blockly/runner/types';
import type { StateActions } from 'app/component/Blockly/Tracer/state';
import type { ContextWorkspaceSvg } from 'app/component/Blockly/types';
import type { SetOutputsSig } from './IotRunner';
import type { Context } from './context';

export default class IotInterpreter extends UiTracedInterpreter<ContextWorkspaceSvg<Context>> {
    protected readonly setOutputs: SetOutputsSig;

    constructor(instructions: InstructionSet, workspace: ContextWorkspaceSvg<Context>, tracedActions: StateActions, setOutputs: SetOutputsSig) {
        super(instructions, workspace, tracedActions);
        this.setOutputs = setOutputs;
    }

    public setOutput(id: string, value: any) {
        this.setOutputs((old) => ({ ...old, [id]: value }));
    }
}
