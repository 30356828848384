import gql from 'graphql-tag';
import automation from 'app/graphql/fragment/automation';

export default gql`
    mutation SetAutomationTrigger($id: ID!, $trigger: AutomationTriggerInput!) {
        automation(id: $id) {
            setTrigger(trigger: $trigger) {
                ...automation
            }
        }
    }
    ${automation}
`;
