import type { Theme } from 'app/theme';

export default ({ window }: Theme) => ({
    Container: {
        display: 'flex',
        flexDirection: 'column',

        border: window.border
    },
    Options: {
        padding: {
            left: 20,
            top: 5,
            right: 5,
            bottom: 5
        }
    },
    Header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    Number: {
        padding: {
            left: 10,
            top: 5
        }
    }
});
