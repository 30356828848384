import type { Theme } from 'app/theme';

export enum TitleSize {
    small = 'small',
    large = 'large',
    normal = 'normal'
}

export default (({ font }: Theme) => ({
    Title: ({ size }: { size: TitleSize }) => ({
        userSelect: 'none',
        fontSize: { large: 40, small: 18, normal: 24 }[size] || 24,
        fontFamily: font.heading,
        fontWeight: 'bold'
    })
}));
