import * as React from 'react';
import { GradientDefs, HorizontalGridLines, LineSeries, VerticalGridLines, XAxis, YAxis } from 'react-vis';
import { formatDateTick } from 'app/helper/graph';
import { Graph } from 'app/component';

import { normalizeHistoryData } from '../../utils';

import type { SubsystemGraphProps } from 'app/subsystem';

const TemperatureGraph = ({ history = [], onOpenSettings }: SubsystemGraphProps<{ light: number }>) => {
    const { data, yDomain } = normalizeHistoryData(0, 1000, history, 'light');
    const [hoveredNode, hoverNode] = Graph.useHintState();

    return (
        <Graph height={250} yDomain={yDomain} onOpenSettings={onOpenSettings} onMouseLeave={() => hoverNode(null)}>
            <GradientDefs>
                <linearGradient
                    id="lightGradient"
                    gradientUnits="userSpaceOnUse"
                    x1="0" y1="250" x2="0" y2="0"
                >
                    <stop offset="0%" stopColor="gray"/>
                    <stop offset="80%" stopColor="orange"/>
                </linearGradient>
            </GradientDefs>
            <HorizontalGridLines/>
            <VerticalGridLines/>
            <XAxis tickFormat={formatDateTick}/>
            <YAxis
                tickFormat={v => v + '‰'}
                // @ts-ignore
                style={{ text: { paddingLeft: 14, transform: 'translate(-6px, 0px)' } }}
            />
            <LineSeries color={'url(#lightGradient)'} data={data} onNearestXY={hoverNode} />
            {hoveredNode && (
                <Graph.Hint
                    {...hoveredNode}
                    value={`${Math.floor(hoveredNode.value as number)} lux`}
                />
            )}
        </Graph>
    );
};

export default TemperatureGraph;
