import * as React from 'react';
import { useApolloClient, useMutation } from '@apollo/client/react';
import LOGOUT_MUTATION from 'app/graphql/mutation/oidc/logout';
import { getRefreshToken, setRefreshToken } from 'app/connector/graphql/token';
import type { OidcLogoutMutation, OidcLogoutMutationVariables } from 'app/graphql/mutation/oidc/logout';

export default function useLogout() {
    const client = useApolloClient();
    const [logout] = useMutation<OidcLogoutMutation, OidcLogoutMutationVariables>(LOGOUT_MUTATION);

    return React.useCallback(async () => {
        const token = getRefreshToken();
        if (!token) {
            return;
        }
        await logout({ variables: { token } });
        // @ts-ignore
        client.setSecret(null);
        setRefreshToken(null);
        try {
            await client.clearStore();
        } catch {}
        try {
            await client.resetStore();
        } catch {}

        // eslint-disable-next-line @typescript-eslint/no-var-requires
        setTimeout(() => require('app/rootRenderer').reset());
    }, [logout, client]);
}
