import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import style, { TitleSize } from './style';

type TitleProps = JSX.IntrinsicElements['div'] & {
    size?: TitleSize;
};

const useStyles = createUseStyles(style);

const Title = (
    { children, className, size = TitleSize.normal, ...props }: TitleProps,
    ref: React.Ref<HTMLDivElement>
): React.ReactElement<'div'> => {
    const classes = useStyles({ size });
    return (
        <div {...props} className={cx(classes.Title, className)} ref={ref}>
            {children}
        </div>
    );
};

export default React.forwardRef(Title);
