import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import Button, { ButtonKind, ButtonSize } from 'app/component/Button';
import Window from 'app/component/Window';

import style from './Body.style';

type PopconfirmBodyProps = JSX.IntrinsicElements['div'] & {
    onConfirm?: () => void;
    onCancel?: () => void;
    confirmKind?: ButtonKind;
    confirmLabel?: string;
    cancelKind?: ButtonKind;
    cancelLabel?: string;
};

const useStyles = createUseStyles(style);

const PopconfirmBody = ({
    className,
    children,
    confirmKind = ButtonKind.danger,
    confirmLabel,
    onConfirm,
    cancelKind = ButtonKind.lite,
    cancelLabel,
    onCancel,
    ...props
}: PopconfirmBodyProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    const [t] = useTranslation('components');

    return (
        <div {...props} className={cx(classes.Body, className)} ref={ref}>
            {children}
            <Window.ButtonFooter className={classes.Footer}>
                <Button size={ButtonSize.small} kind={confirmKind} onClick={onConfirm}>
                    {confirmLabel || t('confirmation.confirm')}
                </Button>
                <Button size={ButtonSize.small} kind={cancelKind} onClick={onCancel}>
                    {cancelLabel || t('confirmation.cancel')}
                </Button>
            </Window.ButtonFooter>
        </div>
    );
};

export default React.forwardRef(PopconfirmBody);
