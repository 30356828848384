import * as React from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import style from './style';

export interface CenterProps extends React.ComponentProps<'div'> {
  verticalPosition?: number;
}

const useStyles = createUseStyles(style);

const Center = ({ children, className, verticalPosition = 30, ...props }: CenterProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles({ verticalPosition });

    return (
        <div {...props} className={cx(classes.Center, className)} ref={ref}>
            {children}
        </div>
    );
};

export default React.forwardRef(Center);
