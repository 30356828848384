import type { Theme } from 'app/theme';

export default ({ color, error }: Theme) => ({
    Container: {
        position: 'relative',
        padding: 5,
        display: 'grid',
        gridTemplateColumns: '1fr min-content',
        gridTemplateRows: 'min-content min-content',
        gridTemplateAreas: '"string time" "output output"',
        gap: '0px 3px',
        overflow: 'hidden',

        '&:hover:after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top:0,
            left:0,
            background: color + '11',
            opacity: 1,
            transition: 'all 0.5s',
            zIndex: -1
        }
    },
    Description: {
        gridArea: 'string',
        lineHeight: '22px'
    },
    Time: {
        gridArea: 'time',
        fontSize: 11,
        color: color + '88'
    },
    Result: {
        gridArea: 'output'
    },
    Output: {
        margintTop: 3,
        lineHeight: '22px'
    },
    OutputPath: {
        display: 'inline-block',
        width: 10,
        height: 12,
        borderLeft: {
            color: color + '88',
            width: 1,
            style: 'dotted'
        },
        borderBottom: {
            color: color + '88',
            width: 1,
            style: 'dotted'
        },
        borderBottomLeftRadius: 2,
        verticalAlign: 'top',
        marginLeft: 5,
        marginRight: 2
    },
    Error: {
        margintTop: 3,
        color: error.color
    }
});
