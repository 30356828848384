import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles, useTheme } from 'react-jss';
import cx from 'classnames';
import { usePortal } from 'app/hook';
import { PortalsSymbol } from 'app/component/Blockly/blockly';

import { buildToolbox, initializeBlocks } from './blocks/utils';
import baseCategories from './blocks';
import RunnerControl from './runner/control';
import useBlockly from './useBlockly';
import Tracer from './Tracer';
import DefaultValue from './Tracer/Value';
import useState from './Tracer/state';
import style from './style';

import type { Theme } from 'app/theme';
import type Runner from './runner';
import type { ToolboxConfig, FlatNode, ContextWorkspaceSvg, BlocklyModals } from './types';
import type { TracerProps } from './Tracer';

export type { FlatNode };

export interface BlocklyProps<C = any> {
    className: string;
    toolbox: ToolboxConfig;
    value: FlatNode[];
    onChange: (arg: FlatNode[]) => void;
    context?: C;
    modals?: BlocklyModals;
    runner?: Runner;
    tracerOpen?: boolean;
    tracerFooter?: React.ReactNode;
    toolboxAppendix?: React.ReactNode;
    toolboxAppendixContainerClass?: string;
    TracerValueComponent?: TracerProps['Value'];
}

const useStyles = createUseStyles(style);
const baseToolbox = buildToolbox(baseCategories);
initializeBlocks(baseCategories);

// eslint-disable-next-line complexity
export default function Blockly<C = any>({
    className,
    toolbox,
    value,
    modals,
    onChange,
    context,
    runner,
    tracerOpen,
    tracerFooter,
    toolboxAppendix,
    toolboxAppendixContainerClass,
    TracerValueComponent
}: BlocklyProps<C>) {
    const classes = useStyles();
    const { blocklyTheme } = useTheme<Theme>();
    const [t] = useTranslation('blockly');

    const { state, actions } = useState();
    const runnerControl = React.useMemo(() => runner && new RunnerControl(runner), [runner]);
    React.useEffect(() => {
        runner?.connectActions?.(actions);
    }, [actions, runner]);

    const blocklyRef = React.useRef<HTMLDivElement>(null);
    const workspace = useBlockly<C>({
        ref: blocklyRef,
        config: {
            horizontalLayout: true,
            toolboxPosition: 'end',
            theme: blocklyTheme,
            toolbox: (toolbox || baseToolbox) as any
        },
        onInject(workspace: ContextWorkspaceSvg<C>) {
            runnerControl?.init(workspace);
        },
        translator(str: string) {
            return t(str);
        },
        modals,
        context,
        onChange,
        value
    });

    const [toolboxElement] = usePortal(
        toolboxAppendix,
        toolboxAppendixContainerClass ? { className: toolboxAppendixContainerClass } : undefined,
        true,
        blocklyRef.current?.querySelector('.blocklyToolboxContents') || undefined
    );

    const [logElement, setLogOpen] = usePortal(
        runnerControl && runner && (
            <Tracer
                state={state}
                run={() => runnerControl.run()}
                clear={() => actions.clear()}
                footer={tracerFooter}
                Value={TracerValueComponent || DefaultValue}
                workspace={workspace}
            />
        ),
        { className: classes.LogContainer },
        false,
        blocklyRef.current?.querySelector('.injectionDiv') || undefined
    );

    React.useEffect(() => {
        if (!runnerControl) {
            setLogOpen(false);
        } else if (tracerOpen === true || tracerOpen ===  false) {
            setLogOpen(tracerOpen);
        }
    }, [runnerControl, tracerOpen]);

    return (
        <>
            {logElement}
            {toolboxElement}
            {(workspace?.[PortalsSymbol] || []).map((portal) => portal())}
            <div className={cx(classes.Blockly, className)} ref={blocklyRef} />
        </>
    );
}
