import * as React from 'react';
import { GradientDefs, HorizontalGridLines, LineSeries, VerticalGridLines, XAxis, YAxis } from 'react-vis';
import { formatDateTick } from 'app/helper/graph';
import { Graph } from 'app/component';
import { normalizeHistoryData } from 'app/subsystem/utils';

import type { SubsystemGraphProps } from 'app/subsystem';

const TemperatureGraph = ({ history = [], onOpenSettings }: SubsystemGraphProps<{ level: number }>) => {
    const { data, yDomain } = normalizeHistoryData(0, 100, history, 'level');
    const [hoveredNode, hoverNode] = Graph.useHintState();
    return (
        <Graph height={150} yPadding={15} onOpenSettings={onOpenSettings} yDomain={yDomain} onMouseLeave={() => hoverNode(null)}>
            <GradientDefs>
                <linearGradient
                    id="levelGradient"
                    gradientUnits="userSpaceOnUse"
                    x1="0" y1="0" x2="0" y2="150"
                >
                    <stop offset="0%" stopColor="green"/>
                    <stop offset="70%" stopColor="yellow"/>
                    <stop offset="80%" stopColor="orange"/>
                    <stop offset="90%" stopColor="red"/>
                </linearGradient>
            </GradientDefs>
            <HorizontalGridLines/>
            <VerticalGridLines/>
            <XAxis tickFormat={formatDateTick}/>
            <YAxis
                tickFormat={v => v + '%'}
                // @ts-ignore
                style={{ text: { paddingLeft: 14, transform: 'translate(-6px, 0px)' } }}
            />
            <LineSeries color={'url(#levelGradient)'} data={data} onNearestXY={hoverNode} />
            {hoveredNode && (
                <Graph.Hint
                    {...hoveredNode}
                    value={`${hoveredNode.value}%`}
                />
            )}
        </Graph>
    );
};

export default TemperatureGraph;
