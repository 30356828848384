/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export type Automation = AutomationInterface & {
  __typename?: 'Automation';
  actions: Array<AutomationAction>;
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  outputs: Array<AutomationOutput>;
  running: Scalars['Boolean']['output'];
  trigger: AutomationTrigger;
};

export type AutomationAction = {
  __typename?: 'AutomationAction';
  comment?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Scalars['JSONObject']['output']>;
  id: Scalars['ID']['output'];
  mutations?: Maybe<Scalars['JSONObject']['output']>;
  next?: Maybe<Scalars['ID']['output']>;
  statements?: Maybe<Scalars['JSONObject']['output']>;
  type: Scalars['String']['output'];
  values?: Maybe<Scalars['JSONObject']['output']>;
};

export type AutomationActionInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Scalars['JSONObject']['input']>;
  id: Scalars['ID']['input'];
  mutations?: InputMaybe<Scalars['JSONObject']['input']>;
  next?: InputMaybe<Scalars['ID']['input']>;
  statements?: InputMaybe<Scalars['JSONObject']['input']>;
  type: Scalars['String']['input'];
  values?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type AutomationInterface = {
  actions: Array<AutomationAction>;
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  outputs: Array<AutomationOutput>;
  running: Scalars['Boolean']['output'];
  trigger: AutomationTrigger;
};

export type AutomationOutput = {
  __typename?: 'AutomationOutput';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type AutomationTrigger = {
  __typename?: 'AutomationTrigger';
  config: Scalars['String']['output'];
  type: TriggerType;
};

export type AutomationTriggerInput = {
  config: Scalars['String']['input'];
  type: TriggerType;
};

export type AutomationUpdate = AutomationInterface & {
  __typename?: 'AutomationUpdate';
  actions: Array<AutomationAction>;
  deleted: Scalars['Boolean']['output'];
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  outputs: Array<AutomationOutput>;
  running: Scalars['Boolean']['output'];
  trigger: AutomationTrigger;
};

export type Challenge = DeviceChallenge | OAuthChallenge;

export enum ChallengeKind {
  Bridge = 'BRIDGE',
  User = 'USER'
}

export type DeviceChallenge = {
  __typename?: 'DeviceChallenge';
  code: Scalars['String']['output'];
  verifierUrl: Scalars['String']['output'];
  verifyUrl: Scalars['String']['output'];
};

export type HistoryEntry = {
  __typename?: 'HistoryEntry';
  data: Scalars['JSON']['output'];
  time: Scalars['DateTime']['output'];
};

export type Home = {
  __typename?: 'Home';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  owner: User;
  rooms: Array<Room>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  me: User;
  refresh: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type MutateAdmin = {
  __typename?: 'MutateAdmin';
  impersonate: LoginResponse;
  switchScope: LoginResponse;
  unimpersonate: LoginResponse;
};


export type MutateAdminImpersonateArgs = {
  id: Scalars['ID']['input'];
};


export type MutateAdminSwitchScopeArgs = {
  id: Scalars['ID']['input'];
};

export type MutateAutomation = {
  __typename?: 'MutateAutomation';
  create?: Maybe<Automation>;
  remove: Scalars['Boolean']['output'];
  rename: Automation;
  run: Scalars['Boolean']['output'];
  setActions: Automation;
  setTrigger: Automation;
};


export type MutateAutomationCreateArgs = {
  actions?: InputMaybe<Array<AutomationActionInput>>;
  name: Scalars['String']['input'];
  trigger: AutomationTriggerInput;
};


export type MutateAutomationRenameArgs = {
  name: Scalars['String']['input'];
};


export type MutateAutomationSetActionsArgs = {
  actions: Array<AutomationActionInput>;
};


export type MutateAutomationSetTriggerArgs = {
  trigger: AutomationTriggerInput;
};

export type MutateHome = {
  __typename?: 'MutateHome';
  create: Home;
  remove: Scalars['Boolean']['output'];
  rename: Home;
};


export type MutateHomeCreateArgs = {
  name: Scalars['String']['input'];
};


export type MutateHomeRenameArgs = {
  name: Scalars['String']['input'];
};

export type MutateOidc = {
  __typename?: 'MutateOIDC';
  challenge: Challenge;
  exchange: LoginResponse;
  logout: Scalars['Boolean']['output'];
  refresh: LoginResponse;
};


export type MutateOidcChallengeArgs = {
  kind: ChallengeKind;
  redirect?: InputMaybe<Scalars['String']['input']>;
};


export type MutateOidcExchangeArgs = {
  code: Scalars['String']['input'];
};


export type MutateOidcLogoutArgs = {
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutateOidcRefreshArgs = {
  token: Scalars['String']['input'];
};

export type MutateRoom = {
  __typename?: 'MutateRoom';
  create: Room;
  remove: Scalars['Boolean']['output'];
  rename: Room;
};


export type MutateRoomCreateArgs = {
  homeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutateRoomRenameArgs = {
  name: Scalars['String']['input'];
};

export type MutateShortcut = {
  __typename?: 'MutateShortcut';
  create?: Maybe<Shortcut>;
  off: Scalars['Boolean']['output'];
  on: Scalars['Boolean']['output'];
  remove: Scalars['Boolean']['output'];
  rename: Shortcut;
  setOff: Shortcut;
  setOn: Shortcut;
};


export type MutateShortcutCreateArgs = {
  name: Scalars['String']['input'];
  off?: InputMaybe<Scalars['String']['input']>;
  on: Scalars['String']['input'];
};


export type MutateShortcutRenameArgs = {
  name: Scalars['String']['input'];
};


export type MutateShortcutSetOffArgs = {
  action?: InputMaybe<Scalars['String']['input']>;
};


export type MutateShortcutSetOnArgs = {
  action: Scalars['String']['input'];
};

export type MutateSubsystem = {
  __typename?: 'MutateSubsystem';
  push: Scalars['Boolean']['output'];
  shortcut: MutateShortcut;
};


export type MutateSubsystemPushArgs = {
  status: Scalars['String']['input'];
};


export type MutateSubsystemShortcutArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MutateThing = {
  __typename?: 'MutateThing';
  remove: Scalars['Boolean']['output'];
  rename: Thing;
  setRoom: Thing;
  shortcut: MutateShortcut;
  subsystem: MutateSubsystem;
};


export type MutateThingRenameArgs = {
  name: Scalars['String']['input'];
};


export type MutateThingSetRoomArgs = {
  roomId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutateThingShortcutArgs = {
  id: Scalars['ID']['input'];
};


export type MutateThingSubsystemArgs = {
  id: Scalars['ID']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  admin: MutateAdmin;
  automation: MutateAutomation;
  home?: Maybe<MutateHome>;
  oidc: MutateOidc;
  pushSubsystemHistory?: Maybe<Scalars['Boolean']['output']>;
  room?: Maybe<MutateRoom>;
  thing?: Maybe<MutateThing>;
};


export type MutationAutomationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationHomeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationPushSubsystemHistoryArgs = {
  data: Scalars['JSON']['input'];
  from?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  interval: Scalars['String']['input'];
  time: Scalars['DateTime']['input'];
};


export type MutationRoomArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationThingArgs = {
  id: Scalars['ID']['input'];
};

export type OAuthChallenge = {
  __typename?: 'OAuthChallenge';
  url: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  me: User;
  subsystem: Subsystem;
  thing?: Maybe<Thing>;
  version: Scalars['String']['output'];
};


export type QuerySubsystemArgs = {
  id: Scalars['String']['input'];
};


export type QueryThingArgs = {
  id: Scalars['ID']['input'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Room = {
  __typename?: 'Room';
  home?: Maybe<Home>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  things: Array<Thing>;
};

export type Scope = {
  __typename?: 'Scope';
  domain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Shortcut = ShortcutInterface & {
  __typename?: 'Shortcut';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  off?: Maybe<Scalars['String']['output']>;
  on: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  subsystem: Subsystem;
  subsystemId: Scalars['String']['output'];
  thing: Thing;
  thingId: Scalars['String']['output'];
};

export type ShortcutInterface = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  off?: Maybe<Scalars['String']['output']>;
  on: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  subsystem: Subsystem;
  subsystemId: Scalars['String']['output'];
  thing: Thing;
  thingId: Scalars['String']['output'];
};

export type ShortcutUpdate = ShortcutInterface & {
  __typename?: 'ShortcutUpdate';
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  off?: Maybe<Scalars['String']['output']>;
  on: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  subsystem: Subsystem;
  subsystemId: Scalars['String']['output'];
  thing: Thing;
  thingId: Scalars['String']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  onAutomationOutputStatus: AutomationOutput;
  onAutomationStatus: AutomationInterface;
  onAutomationUpdate: AutomationInterface;
  onShortcutStatus: Shortcut;
  onShortcutUpdate: ShortcutInterface;
  onSubsystemStatus: Subsystem;
  onThingStatus: Thing;
  onThingUpdate: ThingInterface;
};


export type SubscriptionOnAutomationOutputStatusArgs = {
  automationId: Scalars['ID']['input'];
  outputId: Scalars['ID']['input'];
};


export type SubscriptionOnAutomationStatusArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionOnShortcutStatusArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type SubscriptionOnSubsystemStatusArgs = {
  id: Scalars['String']['input'];
};


export type SubscriptionOnThingStatusArgs = {
  id: Scalars['String']['input'];
};

export type Subsystem = {
  __typename?: 'Subsystem';
  history: Array<HistoryEntry>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  shortcuts: Array<Shortcut>;
  status?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};


export type SubsystemHistoryArgs = {
  aggregator?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  interval?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Thing = ThingInterface & {
  __typename?: 'Thing';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  room?: Maybe<Room>;
  serial: Scalars['String']['output'];
  shortcuts: Array<Shortcut>;
  status: ThingStatus;
  subsystem: Subsystem;
  subsystems: Array<Subsystem>;
  type: Scalars['String']['output'];
};


export type ThingSubsystemArgs = {
  id: Scalars['ID']['input'];
};

export type ThingInterface = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  room?: Maybe<Room>;
  serial: Scalars['String']['output'];
  shortcuts: Array<Shortcut>;
  status: ThingStatus;
  subsystem?: Maybe<Subsystem>;
  subsystems: Array<Subsystem>;
  type: Scalars['String']['output'];
};


export type ThingInterfaceSubsystemArgs = {
  id: Scalars['ID']['input'];
};

export enum ThingStatus {
  Offline = 'offline',
  Online = 'online'
}

export type ThingUpdate = ThingInterface & {
  __typename?: 'ThingUpdate';
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  room?: Maybe<Room>;
  serial: Scalars['String']['output'];
  shortcuts: Array<Shortcut>;
  status: ThingStatus;
  subsystem?: Maybe<Subsystem>;
  subsystems: Array<Subsystem>;
  type: Scalars['String']['output'];
};


export type ThingUpdateSubsystemArgs = {
  id: Scalars['ID']['input'];
};

export enum TriggerType {
  Cron = 'cron',
  Push = 'push'
}

export type User = {
  __typename?: 'User';
  automations: Array<Automation>;
  homes: Array<Home>;
  id: Scalars['ID']['output'];
  login: Scalars['String']['output'];
  roles: Array<Role>;
  scopes: Array<Scope>;
  things: Array<Thing>;
};

export type AutomationFragment = { __typename?: 'Automation', id: string, name: string, error?: string | null, running: boolean, trigger: { __typename?: 'AutomationTrigger', type: TriggerType, config: string }, actions: Array<{ __typename?: 'AutomationAction', id: string, type: string, fields?: any | null, values?: any | null, statements?: any | null, mutations?: any | null, next?: string | null, comment?: string | null }>, outputs: Array<{ __typename?: 'AutomationOutput', id: string, type: string, name: string, status: string }> };

export type ShortcutFragment = { __typename?: 'Shortcut', id: string, name: string, status: boolean, subsystemId: string };

export type SubsystemFragment = { __typename?: 'Subsystem', id: string, type: string, name: string, status?: string | null, shortcuts: Array<{ __typename?: 'Shortcut', id: string, name: string, status: boolean, subsystemId: string }> };

export type ThingBaseFragment = { __typename?: 'Thing', id: string, name: string, type: string, status: ThingStatus, serial: string, room?: { __typename?: 'Room', id: string, name: string, home?: { __typename?: 'Home', id: string, name: string } | null } | null };

export type ThingFragment = { __typename?: 'Thing', id: string, name: string, type: string, status: ThingStatus, serial: string, subsystems: Array<{ __typename?: 'Subsystem', id: string, type: string, name: string, status?: string | null, shortcuts: Array<{ __typename?: 'Shortcut', id: string, name: string, status: boolean, subsystemId: string }> }>, shortcuts: Array<{ __typename?: 'Shortcut', id: string, name: string, status: boolean, subsystemId: string }>, room?: { __typename?: 'Room', id: string, name: string, home?: { __typename?: 'Home', id: string, name: string } | null } | null };

export type CreateAutomationMutationVariables = Exact<{
  name: Scalars['String']['input'];
  trigger: AutomationTriggerInput;
  actions?: InputMaybe<Array<AutomationActionInput> | AutomationActionInput>;
}>;


export type CreateAutomationMutation = { __typename?: 'Mutation', automation: { __typename?: 'MutateAutomation', create?: { __typename?: 'Automation', id: string, name: string, error?: string | null, running: boolean, trigger: { __typename?: 'AutomationTrigger', type: TriggerType, config: string }, actions: Array<{ __typename?: 'AutomationAction', id: string, type: string, fields?: any | null, values?: any | null, statements?: any | null, mutations?: any | null, next?: string | null, comment?: string | null }>, outputs: Array<{ __typename?: 'AutomationOutput', id: string, type: string, name: string, status: string }> } | null } };

export type RemoveAutomationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RemoveAutomationMutation = { __typename?: 'Mutation', automation: { __typename?: 'MutateAutomation', remove: boolean } };

export type RenameAutomationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;


export type RenameAutomationMutation = { __typename?: 'Mutation', automation: { __typename?: 'MutateAutomation', rename: { __typename?: 'Automation', id: string, name: string } } };

export type RunAutomationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RunAutomationMutation = { __typename?: 'Mutation', automation: { __typename?: 'MutateAutomation', run: boolean } };

export type SetAutomationActionsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  actions: Array<AutomationActionInput> | AutomationActionInput;
}>;


export type SetAutomationActionsMutation = { __typename?: 'Mutation', automation: { __typename?: 'MutateAutomation', setActions: { __typename?: 'Automation', id: string, name: string, error?: string | null, running: boolean, trigger: { __typename?: 'AutomationTrigger', type: TriggerType, config: string }, actions: Array<{ __typename?: 'AutomationAction', id: string, type: string, fields?: any | null, values?: any | null, statements?: any | null, mutations?: any | null, next?: string | null, comment?: string | null }>, outputs: Array<{ __typename?: 'AutomationOutput', id: string, type: string, name: string, status: string }> } } };

export type SetAutomationTriggerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  trigger: AutomationTriggerInput;
}>;


export type SetAutomationTriggerMutation = { __typename?: 'Mutation', automation: { __typename?: 'MutateAutomation', setTrigger: { __typename?: 'Automation', id: string, name: string, error?: string | null, running: boolean, trigger: { __typename?: 'AutomationTrigger', type: TriggerType, config: string }, actions: Array<{ __typename?: 'AutomationAction', id: string, type: string, fields?: any | null, values?: any | null, statements?: any | null, mutations?: any | null, next?: string | null, comment?: string | null }>, outputs: Array<{ __typename?: 'AutomationOutput', id: string, type: string, name: string, status: string }> } } };

export type CreateHomeMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type CreateHomeMutation = { __typename?: 'Mutation', home?: { __typename?: 'MutateHome', create: { __typename?: 'Home', id: string, name: string, rooms: Array<{ __typename?: 'Room', id: string, name: string }> } } | null };

export type RemoveHomeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RemoveHomeMutation = { __typename?: 'Mutation', home?: { __typename?: 'MutateHome', remove: boolean } | null };

export type RenameHomeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;


export type RenameHomeMutation = { __typename?: 'Mutation', home?: { __typename?: 'MutateHome', rename: { __typename?: 'Home', id: string, name: string } } | null };

export type OidcChallengeMutationVariables = Exact<{
  kind: ChallengeKind;
  redirect?: InputMaybe<Scalars['String']['input']>;
}>;


export type OidcChallengeMutation = { __typename?: 'Mutation', oidc: { __typename?: 'MutateOIDC', challenge: { __typename?: 'DeviceChallenge' } | { __typename?: 'OAuthChallenge', url: string } } };

export type OidcExchangeMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type OidcExchangeMutation = { __typename?: 'Mutation', oidc: { __typename?: 'MutateOIDC', exchange: { __typename?: 'LoginResponse', token: string, refresh: string } } };

export type OidcLogoutMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type OidcLogoutMutation = { __typename?: 'Mutation', oidc: { __typename?: 'MutateOIDC', logout: boolean } };

export type OidcRefreshMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type OidcRefreshMutation = { __typename?: 'Mutation', oidc: { __typename?: 'MutateOIDC', refresh: { __typename?: 'LoginResponse', token: string, refresh: string } } };

export type PushSubsystemHistoryMutationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  time: Scalars['DateTime']['input'];
  data: Scalars['JSON']['input'];
  from?: InputMaybe<Scalars['DateTime']['input']>;
  interval: Scalars['String']['input'];
}>;


export type PushSubsystemHistoryMutationMutation = { __typename?: 'Mutation', pushSubsystemHistory?: boolean | null };

export type CreateRoomMutationVariables = Exact<{
  homeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;


export type CreateRoomMutation = { __typename?: 'Mutation', room?: { __typename?: 'MutateRoom', create: { __typename?: 'Room', id: string, name: string } } | null };

export type RemoveRoomMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RemoveRoomMutation = { __typename?: 'Mutation', room?: { __typename?: 'MutateRoom', remove: boolean } | null };

export type RenameRoomMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;


export type RenameRoomMutation = { __typename?: 'Mutation', room?: { __typename?: 'MutateRoom', rename: { __typename?: 'Room', id: string, name: string } } | null };

export type RemoveThingMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RemoveThingMutation = { __typename?: 'Mutation', thing?: { __typename?: 'MutateThing', remove: boolean } | null };

export type RenameThingMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;


export type RenameThingMutation = { __typename?: 'Mutation', thing?: { __typename?: 'MutateThing', rename: { __typename?: 'Thing', id: string, name: string } } | null };

export type RoomThingMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  roomId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type RoomThingMutation = { __typename?: 'Mutation', thing?: { __typename?: 'MutateThing', setRoom: { __typename?: 'Thing', id: string, room?: { __typename?: 'Room', id: string, name: string } | null } } | null };

export type ThingShortcutCreateMutationVariables = Exact<{
  thingId: Scalars['ID']['input'];
  subsystemId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  on: Scalars['String']['input'];
  off: Scalars['String']['input'];
}>;


export type ThingShortcutCreateMutation = { __typename?: 'Mutation', thing?: { __typename?: 'MutateThing', subsystem: { __typename?: 'MutateSubsystem', shortcut: { __typename?: 'MutateShortcut', create?: { __typename?: 'Shortcut', id: string, name: string, status: boolean, subsystemId: string } | null } } } | null };

export type ThingShortcutOffMutationVariables = Exact<{
  thingId: Scalars['ID']['input'];
  subsystemId: Scalars['ID']['input'];
  shortcutId: Scalars['ID']['input'];
}>;


export type ThingShortcutOffMutation = { __typename?: 'Mutation', thing?: { __typename?: 'MutateThing', subsystem: { __typename?: 'MutateSubsystem', shortcut: { __typename?: 'MutateShortcut', off: boolean } } } | null };

export type ThingShortcutOnMutationVariables = Exact<{
  thingId: Scalars['ID']['input'];
  subsystemId: Scalars['ID']['input'];
  shortcutId: Scalars['ID']['input'];
}>;


export type ThingShortcutOnMutation = { __typename?: 'Mutation', thing?: { __typename?: 'MutateThing', subsystem: { __typename?: 'MutateSubsystem', shortcut: { __typename?: 'MutateShortcut', on: boolean } } } | null };

export type ThingShortcutRemoveMutationVariables = Exact<{
  thingId: Scalars['ID']['input'];
  subsystemId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}>;


export type ThingShortcutRemoveMutation = { __typename?: 'Mutation', thing?: { __typename?: 'MutateThing', subsystem: { __typename?: 'MutateSubsystem', shortcut: { __typename?: 'MutateShortcut', remove: boolean } } } | null };

export type ThingShortcutRenameMutationVariables = Exact<{
  thingId: Scalars['ID']['input'];
  subsystemId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;


export type ThingShortcutRenameMutation = { __typename?: 'Mutation', thing?: { __typename?: 'MutateThing', subsystem: { __typename?: 'MutateSubsystem', shortcut: { __typename?: 'MutateShortcut', rename: { __typename?: 'Shortcut', id: string, name: string, status: boolean, subsystemId: string } } } } | null };

export type ThingSubsystemPushMutationVariables = Exact<{
  thingId: Scalars['ID']['input'];
  subsystemId: Scalars['ID']['input'];
  status: Scalars['String']['input'];
}>;


export type ThingSubsystemPushMutation = { __typename?: 'Mutation', thing?: { __typename?: 'MutateThing', subsystem: { __typename?: 'MutateSubsystem', push: boolean } } | null };

export type AutomationsQueryVariables = Exact<{ [key: string]: never; }>;


export type AutomationsQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, automations: Array<{ __typename?: 'Automation', id: string, name: string, error?: string | null, running: boolean, trigger: { __typename?: 'AutomationTrigger', type: TriggerType, config: string }, actions: Array<{ __typename?: 'AutomationAction', id: string, type: string, fields?: any | null, values?: any | null, statements?: any | null, mutations?: any | null, next?: string | null, comment?: string | null }>, outputs: Array<{ __typename?: 'AutomationOutput', id: string, type: string, name: string, status: string }> }> } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, login: string } };

export type RoomsQueryVariables = Exact<{ [key: string]: never; }>;


export type RoomsQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, homes: Array<{ __typename?: 'Home', id: string, name: string, rooms: Array<{ __typename?: 'Room', id: string, name: string }> }> } };

export type SubsystemQueryQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type SubsystemQueryQuery = { __typename?: 'Query', subsystem: { __typename?: 'Subsystem', id: string, type: string, name: string, status?: string | null, shortcuts: Array<{ __typename?: 'Shortcut', id: string, name: string, status: boolean, subsystemId: string }> } };

export type SubsystemHistoryQueryVariables = Exact<{
  thingId: Scalars['ID']['input'];
  subsystemId: Scalars['ID']['input'];
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  interval: Scalars['String']['input'];
  aggregator?: InputMaybe<Scalars['String']['input']>;
}>;


export type SubsystemHistoryQuery = { __typename?: 'Query', thing?: { __typename?: 'Thing', id: string, subsystem: { __typename?: 'Subsystem', id: string, type: string, history: Array<{ __typename?: 'HistoryEntry', time: any, data: any }> } } | null };

export type SubsystemStatusQueryVariables = Exact<{
  thingId: Scalars['ID']['input'];
  subsystemId: Scalars['ID']['input'];
}>;


export type SubsystemStatusQuery = { __typename?: 'Query', thing?: { __typename?: 'Thing', id: string, subsystem: { __typename?: 'Subsystem', id: string, type: string, status?: string | null } } | null };

export type ThingQueryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ThingQueryQuery = { __typename?: 'Query', thing?: { __typename?: 'Thing', id: string, name: string, type: string, status: ThingStatus, serial: string, subsystems: Array<{ __typename?: 'Subsystem', id: string, type: string, name: string, status?: string | null, shortcuts: Array<{ __typename?: 'Shortcut', id: string, name: string, status: boolean, subsystemId: string }> }>, shortcuts: Array<{ __typename?: 'Shortcut', id: string, name: string, status: boolean, subsystemId: string }>, room?: { __typename?: 'Room', id: string, name: string, home?: { __typename?: 'Home', id: string, name: string } | null } | null } | null };

export type ThingStatusQueryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ThingStatusQueryQuery = { __typename?: 'Query', thing?: { __typename?: 'Thing', id: string, status: ThingStatus } | null };

export type ThingsQueryVariables = Exact<{ [key: string]: never; }>;


export type ThingsQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, things: Array<{ __typename?: 'Thing', id: string, name: string, type: string, status: ThingStatus, serial: string, room?: { __typename?: 'Room', id: string, name: string, home?: { __typename?: 'Home', id: string, name: string } | null } | null }> } };

export type ThingsFullQueryVariables = Exact<{ [key: string]: never; }>;


export type ThingsFullQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, things: Array<{ __typename?: 'Thing', id: string, name: string, type: string, status: ThingStatus, serial: string, subsystems: Array<{ __typename?: 'Subsystem', id: string, type: string, name: string, status?: string | null, shortcuts: Array<{ __typename?: 'Shortcut', id: string, name: string, status: boolean, subsystemId: string }> }>, shortcuts: Array<{ __typename?: 'Shortcut', id: string, name: string, status: boolean, subsystemId: string }>, room?: { __typename?: 'Room', id: string, name: string, home?: { __typename?: 'Home', id: string, name: string } | null } | null }> } };

export type OnAutomationOutputSubscriptionVariables = Exact<{
  automationId: Scalars['ID']['input'];
  outputId: Scalars['ID']['input'];
}>;


export type OnAutomationOutputSubscription = { __typename?: 'Subscription', onAutomationOutputStatus: { __typename?: 'AutomationOutput', id: string, status: string } };

export type OnAutomationStatusSubscriptionVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type OnAutomationStatusSubscription = { __typename?: 'Subscription', onAutomationStatus: { __typename?: 'Automation', id: string, error?: string | null, running: boolean } | { __typename?: 'AutomationUpdate', id: string, error?: string | null, running: boolean } };

export type OnShortcutStatusSubscriptionVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type OnShortcutStatusSubscription = { __typename?: 'Subscription', onShortcutStatus: { __typename?: 'Shortcut', id: string, status: boolean } };

export type OnShortcutUpdateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnShortcutUpdateSubscription = { __typename?: 'Subscription', onShortcutUpdate: { __typename?: 'Shortcut', id: string, name: string, status: boolean, subsystemId: string } | { __typename?: 'ShortcutUpdate', id: string, deleted: boolean } };

export type OnSubsystemStatusSubscriptionVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type OnSubsystemStatusSubscription = { __typename?: 'Subscription', onSubsystemStatus: { __typename?: 'Subsystem', id: string, status?: string | null } };

export type OnThingStatusSubscriptionVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type OnThingStatusSubscription = { __typename?: 'Subscription', onThingStatus: { __typename?: 'Thing', id: string, status: ThingStatus } };

export type OnThingUpdateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnThingUpdateSubscription = { __typename?: 'Subscription', onThingUpdate: { __typename?: 'Thing', id: string, name: string, type: string, status: ThingStatus, serial: string, room?: { __typename?: 'Room', id: string, name: string, home?: { __typename?: 'Home', id: string, name: string } | null } | null } | { __typename?: 'ThingUpdate', id: string, deleted: boolean } };
