import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Table, Title } from 'app/component';
import style from './Unsupported.style';

import type { SubsystemType } from 'app/graphql/fragment/subsystem';

const useStyles = createUseStyles(style);

const UnsupportedSubsystem = ({ id, name, status, type }: Pick<SubsystemType, 'id' | 'name' | 'status' | 'type'>) => {
    const classes = useStyles();
    const [t] = useTranslation('subsystem');

    return (
        <React.Fragment>
            <Title className={classes.Text}>{t('unsupported.text')}</Title>
            <Table columns={2} sizes={[150, null]}>
                <div>{t('unsupported.id')}:</div>
                <div>{id}</div>
                <div>{t('unsupported.name')}:</div>
                <div>{name}</div>
                <div>{t('unsupported.type')}:</div>
                <div>{type}</div>
                <div>{t('unsupported.status')}:</div>
                <div>{status}</div>
            </Table>
        </React.Fragment>
    );
};

export default UnsupportedSubsystem;
