import React, { useCallback, useState } from 'react';
import copyToClipboard from 'copy-to-clipboard';
import DeepClick from '../DeepClick';
import Message, { createMId, message } from '../Message';

type CopyProps = { children: React.ReactNode | React.ReactChildren; value: string; };

const Copy: React.FC<CopyProps> = ({ children, value, ...props }) => {
    const [_mId] = useState(createMId());
    const handleTrigger = useCallback(() => {
        copyToClipboard(value); message(_mId);
    }, [value, _mId]);

    return (
        <>
            <Message mId={_mId}>&ldquo;{value}&rdquo; Copied to clipboard!</Message>
            <DeepClick {...props} onDeepClick={handleTrigger}>
                {children}
            </DeepClick>
        </>
    );
};

export default Copy;
