import * as React from 'react';

type WatchProps = {
    watch: () => (void | (() => void));
    children?: React.ReactNode;
};

export default class Watch extends React.Component<WatchProps> {
    _disposer: undefined | void | (() => void);

    componentDidMount(): void {
        this._disposer = this.props.watch();
    }

    componentWillUnmount() {
        if (typeof this._disposer === 'function') {
            this._disposer();
        }
    }

    render() {
        return this.props.children ? (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        ) : null;
    }
}
