import * as React from 'react';
import thingByType from 'app/thing';
import { useSubsystemSubscription } from 'app/hook';
import ThingIcon from 'app/component/ThingIcon';
import { ReactField } from 'app/component/Blockly/fields';
import * as subsystems from 'app/subsystem';

import { CATEGORY_BASE_COLOR } from './constants';

import type { Node, ContextBlock } from 'app/component/Blockly/types';
import type IotInterpreter from '../IotInterpreter';
import type { Context, SubsystemWithThing } from '../context';

export const name = 'iot_subsystem';

const SubsystemSubscriber = ({ id }: { id: string }) => {
    useSubsystemSubscription(id);
    return null;
};

export function builder(this: ContextBlock<Context>) {
    this.appendDummyInput()
        .appendField(new ReactField<Context>((context, value) => {
            const subsystem = context.subsystems.find((subsystem) => subsystem.id == value);
            if (!subsystem) {
                return (
                    <ThingIcon name="&nbsp;" status="offline">
                        <b>?</b>
                    </ThingIcon>
                );
            }

            const device = subsystem.thing;
            const { Icon } = thingByType(device.type);
            const Subsystem = subsystems[subsystem.type];
            return (
                <ThingIcon name={device.name} type={device.type} status={device.status}>
                    <SubsystemSubscriber id={subsystem.id} />
                    {Subsystem.Value ? (
                        <Subsystem.Value status={subsystem.status || ''} mini />
                    ) : (
                        <Icon name={device.name} type={device.type} status={device.status} />
                    )}
                </ThingIcon>
            );
        }), 'subsystem');
    this.setColour(CATEGORY_BASE_COLOR);
    this.setTooltip(() => {
        const value = this.getFieldValue('subsystem');
        const subsystem = this.workspace.context.subsystems.find((subsystem) => subsystem.id == value);
        if (subsystem?.thing.room) {
            return subsystem.thing.room.home.name + ': ' + subsystem.thing.room.name;
        }
        return '';
    });
    this.setOutput(true, 'IotSubsystem');
}

export default function run(this: IotInterpreter, node: Node): SubsystemWithThing {
    const subsystemId = node.fields?.subsystem;
    if (!subsystemId) {
        throw new Error('subsystem id missing');
    }

    const subsystem = this.workspace.context.subsystems.find(({ id }) => id == subsystemId);
    if (!subsystem) {
        throw new Error('subsystem id invalid');
    }

    return subsystem;
}
run.noTrace = true;
