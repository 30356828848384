import Blockly from 'blockly';

import { CATEGORY_INPUT_COLOR } from './constants';

import type { BlockCategoryInfo } from 'app/component/Blockly/blocks/types';
import type { ContextWorkspace } from 'app/component/Blockly/types';
import type { Context } from '../context';

export * as iot_subsystem from './iot_subsystem';

export default {
    name: '${iot-subsystems}',
    colour: CATEGORY_INPUT_COLOR,
    custom(workspace: ContextWorkspace<Context>) {
        return workspace.context.subsystems.filter(({ type }) => !['stripe'].includes(type)).map((subsystem) => {
            const field = Blockly.utils.xml.createElement('field');
            field.setAttribute('name', 'subsystem');
            field.appendChild(Blockly.utils.xml.createTextNode(String(subsystem.id)));

            const block = Blockly.utils.xml.createElement('block');
            block.setAttribute('type', 'iot_subsystem');
            block.setAttribute('gap', '8');
            block.appendChild(field);
            return block;
        });
    }
} as BlockCategoryInfo;
